import { ApiError, ApiErrorCode } from "./ApiError";
import axios from "axios";
import {
  requestLogger,
  errorLogger,
  responseLogger,
  // setGlobalConfig
} from "axios-logger";
import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
} from "axios";

// setGlobalConfig({
//   prefixText: "your prefix",
//   dateFormat: "HH:MM:ss",
//   status: false,
//   headers: true,
//   logger: console.info.bind(this)
// });

export type AxiosCustomConfig = {
  url?: string;
  baseURL?: string;
  headers?: AxiosRequestHeaders;
  timeout?: number;
  timeoutErrorMessage?: string;
  withCredentials?: boolean;
};

export type CreateAxiosType = {
  config?: AxiosCustomConfig;
  modeDebug?: boolean;
  preRequestCall?: (config: AxiosRequestConfig) => AxiosRequestConfig;
  preResponseCall?: (response: AxiosResponse) => AxiosResponse;
};
export class AxiosFactory {
  public static create({
    config,
    modeDebug = false,
    preRequestCall,
    preResponseCall,
  }: CreateAxiosType): AxiosInstance {
    const axiosInstance = axios.create(config);
    // axiosInstance.defaults.withCredentials = true;

    if (modeDebug) {
      axiosInstance.interceptors.request.use(requestLogger, errorLogger);
      axiosInstance.interceptors.response.use(responseLogger, errorLogger);
    }

    axiosInstance.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        if (preRequestCall) preRequestCall(config);
        return config;
      },
      (error: unknown) => {
        if (axios.isAxiosError(error) && error?.request) {
          return Promise.reject(
            new ApiError(error, ApiErrorCode.REQUEST_ERROR)
          );
        }

        return Promise.reject(
          new ApiError(error, ApiErrorCode.UNEXPECTED_ERROR)
        );
      }
    );

    axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        if (preResponseCall) preResponseCall(response);
        return response;
      },
      (error: unknown) => {
        if (axios.isAxiosError(error) && error.response) {
          return Promise.reject(
            new ApiError(error, ApiErrorCode.RESPONSE_ERROR)
          );
        }

        return Promise.reject(
          new ApiError(error, ApiErrorCode.UNEXPECTED_ERROR)
        );
      }
    );

    return axiosInstance;
  }
}
