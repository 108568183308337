/* eslint-disable react/jsx-key */
import RowComponentDefault, { IRowComponentProps } from "./RowComponentDefault";
import { ICellComponentProps, IRowType } from "./CellComponentDefault";
import { Container } from "components/core/Styled/Layouts";
import { clickBehaviorCss, flexCss } from "components/core/Styled/AtomicCss";
import { textCss } from "components/core/Styled/AtomicCss";
import { useSortSetting } from "common/hooks/settings/useSetting";
import { ISortInfo } from "common/types/api/common";
import { TTableTypes } from "common/types/api/preferences";
import { useGetPreferencesQuery } from "services/query/preferences";
import TableSettings from "components/core/Table/TableSettings";
import Button from "components/reusable/Button";
import React, {
  FunctionComponent,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { useTable } from "react-table";
import { BsThreeDots } from "react-icons/bs";
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";

export type IHeader = {
  Header: string;
  accessor: string;
};

export type IProps = {
  columns: IHeader[];
  data: unknown[];
  withIndex?: boolean;
  onRowClick?: (data: unknown) => void;
  RowComponent?: FunctionComponent<IRowComponentProps>;
  CellComponent?: FunctionComponent<ICellComponentProps>;
  className?: string;
  keyAccessor?: string;
  sortable?: boolean;
  editable?: boolean;
  sortSettingLabel?: TTableTypes;
  sortList?: string[];
  sortListDefault?: string[];
  customHeaderStyle?: boolean;
  startIndex?: number;
};

const Table = ({
  className,
  columns,
  data,
  withIndex = false,
  onRowClick,
  RowComponent = RowComponentDefault,
  CellComponent,
  keyAccessor,
  sortable,
  editable,
  sortSettingLabel,
  sortList,
  sortListDefault,
  customHeaderStyle = false,
  startIndex = 1,
}: IProps) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: data as unknown as IRowType[] });
  const { sortSetting, updateSortSetting } = useSortSetting(
    sortable ? sortSettingLabel : undefined
  );
  const preferencesQuery = useGetPreferencesQuery(sortSettingLabel);
  const settingRef = useRef() as RefObject<HTMLDivElement>;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onOpenModel: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.stopPropagation();
      setIsOpen((isOpen) => !isOpen);
    },
    []
  );

  const onSortClick = useCallback(
    ({ field, direction }: ISortInfo) => {
      const sort = { field, direction };
      updateSortSetting({ sort });
    },
    [updateSortSetting]
  );

  const handleClickOutside = useCallback(
    (event: MouseEvent | TouchEvent) => {
      const isInside =
        !!settingRef?.current &&
        settingRef.current.contains(event.target as Node);
      const isOutside = isOpen && !isInside;
      if (isOpen) {
        event.stopPropagation();
      }

      if (isOutside) {
        setIsOpen(false);
      }

      return false;
    },
    [isOpen]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <Wrapper
      className={className}
      withIndex={withIndex}
      columnLength={columns.length}
      rowClickable={onRowClick !== undefined}
    >
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => {
            const { key: trKey, ...trRest } = headerGroup.getHeaderGroupProps();

            return (
              <tr key={trKey} {...trRest}>
                {withIndex && <th>#</th>}
                {headerGroup.headers.map((column) => {
                  const { key, ...rest } = column.getHeaderProps();
                  return (
                    <th key={key} {...rest}>
                      {customHeaderStyle
                        ? (column?.render("Header") as string | undefined)
                            ?.split("\n\n")
                            .map(
                              (
                                line: string,
                                index: number,
                                array: string[]
                              ) => (
                                <React.Fragment key={index}>
                                  {line}
                                  {index < array.length - 1 && <br />}
                                </React.Fragment>
                              )
                            )
                        : column.render("Header")}

                      {sortable &&
                        preferencesQuery.isSuccess &&
                        preferencesQuery.data?.sortFields?.find(
                          (x) => x === column.id
                        ) && (
                          <SortContainer>
                            {!sortSetting ? (
                              <Button
                                onClick={() =>
                                  onSortClick({
                                    field: column.id,
                                    direction: "asc",
                                  })
                                }
                              >
                                <RiArrowUpSFill
                                  color={
                                    column.id === "date" ||
                                    column.id === "lastUpdate"
                                      ? ""
                                      : "#B4BBC4"
                                  }
                                />
                                <RiArrowDownSFill color={"#B4BBC4"} />
                              </Button>
                            ) : (
                              <Button
                                onClick={() =>
                                  onSortClick({
                                    field: column.id,
                                    direction:
                                      sortSetting.sort?.direction !== "desc"
                                        ? "desc"
                                        : "asc",
                                  })
                                }
                              >
                                <RiArrowUpSFill
                                  color={
                                    column.id === sortSetting.sort?.field &&
                                    sortSetting.sort?.direction === "desc"
                                      ? ""
                                      : "#B4BBC4"
                                  }
                                />
                                <RiArrowDownSFill
                                  color={
                                    column.id === sortSetting.sort?.field &&
                                    sortSetting.sort?.direction === "asc"
                                      ? ""
                                      : "#B4BBC4"
                                  }
                                />
                              </Button>
                            )}
                          </SortContainer>
                        )}
                      {editable && column.id === "settings" && (
                        <IconContainer>
                          <Button onClick={onOpenModel}>
                            <BsThreeDots />
                          </Button>
                          {isOpen && (
                            <TableSettings
                              label={sortSettingLabel}
                              reference={settingRef}
                              list={sortList as string[]}
                              defaultList={sortListDefault as string[]}
                              setIsOpen={setIsOpen}
                            />
                          )}
                        </IconContainer>
                      )}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => (
            <RowComponent
              key={
                keyAccessor === undefined
                  ? index
                  : (data[index] as Record<string, string>)[keyAccessor]
              }
              row={row}
              index={index}
              prepareRow={prepareRow}
              withIndex={withIndex}
              onRowClick={onRowClick}
              CellComponent={CellComponent}
              startIndex={startIndex}
            />
          ))}
        </tbody>
      </table>
    </Wrapper>
  );
};

const Wrapper = styled(Container)<{
  withIndex: boolean;
  columnLength: number;
  rowClickable: boolean;
}>`
  ${flexCss};
  width: 100%;

  table {
    display: grid;
    border-collapse: collapse;
    min-width: 100%;
    grid-template-columns: ${(props) =>
      props.withIndex
        ? `auto 1fr repeat(${props.columnLength - 1}, auto)`
        : `1fr repeat(${props.columnLength - 1}, auto)`};
  }

  thead,
  tbody,
  tr {
    display: contents;
  }

  tr {
    :nth-child(even) td,
    th {
      background-color: ${(props) => props.theme.background.secondary};
    }
  }

  th,
  td {
    ${textCss};
    padding: 1.125em 1.25em;
  }

  th {
    color: ${(props) => props.theme.color.main};
    position: relative;
    :last-child {
      border: 0;
    }
  }
  thead th {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  tbody {
    tr {
      ${(props) => (props.rowClickable ? clickBehaviorCss : "")};
    }
  }

  ${(props) =>
    props.withIndex &&
    `
        td {
            :first-child {
                color: ${props.theme.color.secondary};
                padding-right: 0;
            }
        }
    `};
`;

const SortContainer = styled(Container)`
  margin: 0 0 0 0.5em;
  padding: 0 0;
  button {
    padding: 0;
    svg {
      padding: 0;
      margin: -0.34em 0;
    }
  }
`;

const IconContainer = styled(Container)`
  width: 2.5em;
  height: 2.5em;
  background-color: white;
  border-radius: 50%;
`;
export default Table;
