import { errorString } from "utils/assertion";
import { IUpdateCustomer } from "api/customerApi/updateCustomer";
import {
  useDeleteCustomerMutation,
  useDisableCustomerMutation,
  useEnableCustomerMutation,
  useGetCustomerByIdQuery,
  usePromoteCustomerMutation,
  useUpdateCustomerMutation,
} from "services/query/customers";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import { getMessageError } from "utils/helpers";
import {
  dateTimeToString,
  isEqualDateWithoutTIme,
  stringToDate,
} from "utils/dateHelpers";
import { EmptyType } from "common/types/common";
import { useIdPathname } from "common/hooks/paths/useParamsPathname";
import { useGoTo } from "common/hooks/paths/useGoTo";
import { routesList } from "utils/routesList";
import { customToast } from "utils/customToast";
import {
  useCreateProductMutation,
  useDeleteProductMutation,
  useGetProductsQuery,
  useUpdateProductMutation,
} from "services/query/products";
import {
  IProductInfo,
  ProductType,
  productListName,
} from "common/types/api/product";
import { IAddProduct } from "api/productApi/addProduct";
import { IDeleteProduct } from "api/productApi/deleteProduct";
import { IUpdateProduct } from "api/productApi/updateProduct";
import { Map2D } from "common/types/helpers/enumHelpers";
import { useGetDocumentListQuery } from "services/query/documents";
import { IDocumentInfo } from "common/types/api/document";

export type IPages = "MAIN" | "SCREENING" | "DATA_STORAGE" | "LOOKUP";
export type ISelectedPage = {
  page: IPages;
  product?: IProductInfo;
};

function useEditCustomer() {
  // page main
  const customerId = useIdPathname();
  const goTo = useGoTo();

  const [selectedPage, setSelectedPage] = useState<ISelectedPage>({
    page: "MAIN",
  });

  const onClickReturnButton = useCallback(() => {
    if (selectedPage.page === "MAIN") {
      goTo(routesList.customers.routes.main);
    } else {
      setSelectedPage({ page: "MAIN" });
    }
  }, [goTo, selectedPage]);

  const title = useMemo(() => {
    if (selectedPage.page === "MAIN") {
      return "Edit customer";
    } else if (selectedPage.page === "SCREENING") {
      return "Screening Configuration - Global Configuration";
    } else if (selectedPage.page === "DATA_STORAGE") {
      return `Data Storage Configuration - ${
        productListName?.[
          selectedPage.product?.productType ?? "KYC_ENROLMENT_FLOW"
        ] ?? ""
      }`;
    } else if (selectedPage.page === "LOOKUP") {
      return `Lookup Configuration - ${
        productListName?.[
          selectedPage.product?.productType ?? "KYC_ENROLMENT_FLOW"
        ] ?? ""
      }`;
    }
    throw new Error("Invalid selected page");
  }, [selectedPage.page, selectedPage.product?.productType]);

  const messageUpdate = useMemo(() => {
    if (selectedPage.page === "SCREENING") {
      return "Screening global configuration";
    }

    return title;
  }, [selectedPage.page, title]);

  // update customer information
  const getCustomerByIdQuery = useGetCustomerByIdQuery(customerId);
  const getCustomerByIdRefetch = useMemo(
    () => getCustomerByIdQuery.refetch,
    [getCustomerByIdQuery.refetch]
  );

  const refreshCustomer = useCallback(() => {
    return getCustomerByIdRefetch();
  }, [getCustomerByIdRefetch]);

  const updateCustomerMutation = useUpdateCustomerMutation(customerId);
  const updateCustomerMutateAsync = useMemo(
    () => updateCustomerMutation.mutateAsync,
    [updateCustomerMutation.mutateAsync]
  );

  const customerUpdateFormik = useFormik({
    initialValues: {
      clientId: getCustomerByIdQuery.data?.clientId ?? "",
      hubspotCompanyId: getCustomerByIdQuery.data?.hubspotCompanyId ?? "",
      companyNameAlias: getCustomerByIdQuery.data?.companyNameAlias ?? "",
      updateVersion: getCustomerByIdQuery.data?.updateVersion ?? 0,
    },
    validate: (customerData) => {
      const errors = {
        companyNameAlias: errorString({
          companyNameAlias: customerData.companyNameAlias,
        }),
      };

      return errors;
    },
    onSubmit: () => console.info("Customer edited"),
  });

  const [expiryDate, setExpiryDate] = useState<EmptyType<Date>>();

  const onUpdateCustomer = useCallback(async () => {
    const expiryDateStr = dateTimeToString(expiryDate);

    if (!customerUpdateFormik.values.companyNameAlias || !expiryDateStr) {
      customToast.error("Please fill in the required field");
      return;
    }

    try {
      const customerInfos: Omit<IUpdateCustomer, "customerId"> = {
        companyNameAlias: customerUpdateFormik.values.companyNameAlias,
        expiryDate: expiryDateStr,
        updateVersion: customerUpdateFormik.values.updateVersion,
      };
      await updateCustomerMutateAsync(customerInfos);
      customToast.success("Customer updated successfully");
      refreshCustomer();
    } catch (error) {
      customToast.error(getMessageError(error, "Customer update failed"));
      console.error(error);
    }
  }, [
    expiryDate,
    customerUpdateFormik.values.companyNameAlias,
    customerUpdateFormik.values.updateVersion,
    updateCustomerMutateAsync,
    refreshCustomer,
  ]);

  useEffect(() => {
    if (getCustomerByIdQuery.data !== undefined) {
      customerUpdateFormik.setValues({
        clientId: getCustomerByIdQuery.data?.clientId ?? "",
        hubspotCompanyId: getCustomerByIdQuery.data?.hubspotCompanyId ?? "",
        companyNameAlias: getCustomerByIdQuery.data?.companyNameAlias ?? "",
        updateVersion: getCustomerByIdQuery.data?.updateVersion ?? 0,
      });
      setExpiryDate(stringToDate(getCustomerByIdQuery.data?.expiryDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerUpdateFormik.setValues, getCustomerByIdQuery.data]);

  const touchedUpdateCustomer = useMemo(() => {
    if (
      (getCustomerByIdQuery.data?.companyNameAlias ?? "") !==
      customerUpdateFormik.values.companyNameAlias
    )
      return true;

    if (
      !isEqualDateWithoutTIme(
        stringToDate(getCustomerByIdQuery.data?.expiryDate),
        expiryDate || undefined
      )
    )
      return true;

    return false;
  }, [
    customerUpdateFormik.values.companyNameAlias,
    expiryDate,
    getCustomerByIdQuery.data?.companyNameAlias,
    getCustomerByIdQuery.data?.expiryDate,
  ]);

  // customer actions
  const enableCustomerMutation = useEnableCustomerMutation(customerId);
  const enableCustomerMutateAsync = useMemo(
    () => enableCustomerMutation.mutateAsync,
    [enableCustomerMutation.mutateAsync]
  );
  const onEnableCustomer = useCallback(async () => {
    try {
      await enableCustomerMutateAsync({});
      customToast.success("Customer enabled successfully");
      refreshCustomer();
    } catch (error) {
      customToast.error(getMessageError(error, "Customer enable failed"));
      console.error(error);
    }
  }, [enableCustomerMutateAsync, refreshCustomer]);

  const disableCustomerMutation = useDisableCustomerMutation(customerId);
  const disableCustomerMutateAsync = useMemo(
    () => disableCustomerMutation.mutateAsync,
    [disableCustomerMutation.mutateAsync]
  );

  const onDisableCustomer = useCallback(async () => {
    try {
      await disableCustomerMutateAsync({});
      customToast.success("Customer disabled successfully");
      refreshCustomer();
    } catch (error) {
      customToast.error(getMessageError(error, "Customer disable failed"));
      console.error(error);
    }
  }, [disableCustomerMutateAsync, refreshCustomer]);

  const deleteCustomerMutation = useDeleteCustomerMutation(customerId);
  const deleteCustomerMutateAsync = useMemo(
    () => deleteCustomerMutation.mutateAsync,
    [deleteCustomerMutation.mutateAsync]
  );

  const onDeleteCustomer = useCallback(async () => {
    try {
      await deleteCustomerMutateAsync({});
      customToast.success("Customer deleted successfully");
      goTo(routesList.customers.routes.main);
    } catch (error) {
      customToast.error(getMessageError(error, "Customer deletion failed"));
      console.error(error);
    }
  }, [deleteCustomerMutateAsync, goTo]);

  const promoteCustomerMutation = usePromoteCustomerMutation(customerId);
  const promoteCustomerMutateAsync = useMemo(
    () => promoteCustomerMutation.mutateAsync,
    [promoteCustomerMutation.mutateAsync]
  );
  const onPromoteCustomer = useCallback(async () => {
    try {
      const customerPromoted = await promoteCustomerMutateAsync({});
      customToast.success("Customer promoted successfully");
      goTo(routesList.customers.routes.update, customerPromoted.id);
    } catch (error) {
      customToast.error(getMessageError(error, "Customer promotion failed"));
      console.error(error);
    }
  }, [promoteCustomerMutateAsync, goTo]);

  // products
  // gets products
  const getProductsQuery = useGetProductsQuery(customerId);
  const getProductsRefetch = useMemo(
    () => getProductsQuery.refetch,
    [getProductsQuery.refetch]
  );

  const refreshProducts = useCallback(() => {
    return getProductsRefetch();
  }, [getProductsRefetch]);

  const products = useMemo(
    () => getProductsQuery.data?.products,
    [getProductsQuery.data]
  );

  const [productsConfigs, setProductsConfigs] = useState<Map2D<boolean>>({});
  const onChangeProductsConfigs = useCallback(
    (
      productId: IProductInfo["productId"],
      newValue: Record<string, boolean>
    ) => {
      setProductsConfigs((_values) => {
        return {
          ..._values,
          [productId]: {
            ..._values[productId],
            ...newValue,
          },
        };
      });
    },
    []
  );

  const toggleProductsConfigs = useCallback(
    (productId: IProductInfo["productId"], name: string) => {
      setProductsConfigs((_values) => {
        return {
          ..._values,
          [productId]: {
            ..._values[productId],
            [name]: !_values[productId][name],
          },
        };
      });
    },
    []
  );

  useEffect(() => {
    products?.forEach((product) => {
      if (product.storable)
        onChangeProductsConfigs(product.productId, {
          storeData: !!product.storeData,
        });
      if (product.productType === ProductType.KYC_ENROLMENT_FLOW) {
        onChangeProductsConfigs(product.productId, {
          scan: !!product?.configuration?.scan?.active,
          nfc: !!product?.configuration?.nfc?.active,
          facialRecognition:
            !!product?.configuration?.facialRecognition?.active,
          oneToNFaceVerification:
            !!product?.configuration?.oneToNFaceVerification?.active,
          lookup: !!product?.configuration?.lookup?.active,
          backgroundCheck: !!product?.configuration?.backgroundCheck?.active,
        });
      }
    });
  }, [onChangeProductsConfigs, products]);

  // add product
  const addProductMutation = useCreateProductMutation(customerId);
  const addProductMutateAsync = useMemo(
    () => addProductMutation.mutateAsync,
    [addProductMutation.mutateAsync]
  );
  const onAddProduct = useCallback(
    async (type: IAddProduct["productType"]) => {
      try {
        await addProductMutateAsync({ productType: type });
        customToast.success("Product added successfully");
        refreshProducts();
      } catch (error) {
        customToast.error(getMessageError(error, "Product addition failed"));
        console.error(error);
      }
    },
    [addProductMutateAsync, refreshProducts]
  );

  const listProductsToAdd = useMemo(() => {
    if (!products) return undefined;
    return ProductType.keys.filter(
      (productType) =>
        !products.some((product) => product.productType === productType)
    );
  }, [products]);

  // delete product
  const deleteProductMutation = useDeleteProductMutation(customerId);
  const deleteProductMutateAsync = useMemo(
    () => deleteProductMutation.mutateAsync,
    [deleteProductMutation.mutateAsync]
  );
  const onDeleteProduct = useCallback(
    async (arg: Omit<IDeleteProduct, "customerId">) => {
      try {
        await deleteProductMutateAsync(arg);
        customToast.success("Delete product successfully");
        refreshProducts();
      } catch (error) {
        customToast.error(getMessageError(error, "Delete product failed"));
        console.error(error);
      }
    },
    [deleteProductMutateAsync, refreshProducts]
  );

  // update product
  const updateProductMutation = useUpdateProductMutation(customerId);
  const updateProductMutateAsync = useMemo(
    () => updateProductMutation.mutateAsync,
    [updateProductMutation.mutateAsync]
  );
  const onUpdateProduct = useCallback(
    async (
      arg: Omit<IUpdateProduct, "customerId">,
      updateProductMessage?: string
    ) => {
      try {
        await updateProductMutateAsync(arg);
        customToast.success(
          `${updateProductMessage ?? "Product updated"} successfully`
        );
        refreshProducts();
      } catch (error) {
        customToast.error(
          getMessageError(
            error,
            `${updateProductMessage ?? "Update product"} failed`
          )
        );
        console.error(error);
      }
    },
    [updateProductMutateAsync, refreshProducts]
  );

  const documentListQuery = useGetDocumentListQuery();

  const documentList = useMemo(() => {
    if (documentListQuery.data === undefined) return {};
    const res = documentListQuery.data
      .filter((doc) => doc.canBeLookup || doc.documentType === "PASSPORT")
      .reduce((prev, doc) => {
        if (doc.documentType === "PASSPORT") doc.country = "Ghana";
        prev[doc.documentType] = doc;
        return prev;
      }, {} as Record<string, IDocumentInfo>);
    return res;
  }, [documentListQuery.data]);

  const documentListByCountry = useMemo(() => {
    const res = {} as Record<string, string[]>;

    Object.values(documentList).forEach((doc) => {
      if (res[doc.country] === undefined) res[doc.country] = [];
      res[doc.country].push(doc.documentType);
    }, []);

    return res;
  }, [documentList]);

  return {
    productsConfigs,
    onChangeProductsConfigs,
    toggleProductsConfigs,
    // loading
    isLoading: getCustomerByIdQuery.isLoading,
    customerId,
    // page
    selectedPage,
    setSelectedPage,
    // customer
    refreshCustomer,
    enabled: getCustomerByIdQuery.data?.status === "Enabled",
    isTestingCustomer: getCustomerByIdQuery.data?.type === "TESTING",
    customerStatus: getCustomerByIdQuery.data?.status ?? "Disabled",
    // navbar
    onClickReturnButton,
    title,
    messageUpdate,
    // update info
    customerUpdateFormik,
    expiryDate,
    setExpiryDate,

    // update
    onUpdateCustomer,
    updateCustomerLoading: updateCustomerMutation.isLoading,
    touchedUpdateCustomer,
    // enable
    onEnableCustomer,
    enableCustomerLoading: enableCustomerMutation.isLoading,
    // disable
    onDisableCustomer,
    disableCustomerLoading: disableCustomerMutation.isLoading,
    // delete
    onDeleteCustomer,
    deleteCustomerLoading: deleteCustomerMutation.isLoading,
    // promote
    onPromoteCustomer,
    promoteCustomerLoading: promoteCustomerMutation.isLoading,

    // products
    productsIsLoading: getProductsQuery.isLoading,
    products,
    listProductsToAdd,
    // update
    onUpdateProduct,
    updateProductLoading: updateProductMutation.isLoading,
    // add
    onAddProduct,
    addProductLoading: addProductMutation.isLoading,
    // delete
    onDeleteProduct,
    deleteProductLoading: deleteProductMutation.isLoading,

    // lookup
    documentListIsLoading: documentListQuery.isLoading,
    documentListByCountry,
    documentList,
  };
}

export default useEditCustomer;
