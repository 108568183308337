import { Container, Row } from "components/core/Styled/Layouts";
import { Heading3, Heading5 } from "components/core/Styled/Texts";
import React, { useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";
import "react-phone-number-input/style.css";
import {
  useGetCompanyDetailsQuery,
  useUpdateCompanyDetailsMutation,
} from "services/query/company";
import Loader from "components/core/Loaders/Loader";
import Button from "components/reusable/Button";
import InputField from "components/reusable/Input";
import { useFormik } from "formik";
import { HiOutlineCheck } from "react-icons/hi";
import { customToast, persistentCustomToast } from "utils/customToast";
import { getMessageError, refreshPage } from "utils/helpers";
import { useSelectCustomer } from "common/hooks/session/useSelectCustomer";
import ToggleBloc from "components/Toggle/ToggleBloc";
import { Input } from "components/core/Styled/Controls";
import { cleanUpStr, haveErrors } from "utils/assertion";

const CompanyPageDisplay = () => {
  const { customerId } = useSelectCustomer();
  const companyDataQuery = useGetCompanyDetailsQuery(customerId);
  const companyUpdateQuery = useUpdateCompanyDetailsMutation(customerId);
  const companyUpdateMutateAsync = useMemo(
    () => companyUpdateQuery.mutateAsync,
    [companyUpdateQuery.mutateAsync]
  );

  const {
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setValues: setFormikValues,
  } = useFormik({
    initialValues: {
      companyNameAlias: "",
    },
    onSubmit: () => console.info("Company edited"),
  });

  const onUpdateCompanyClick = useCallback(async () => {
    if (haveErrors(errors)) {
      customToast.error("Please fill the required inputs");
      return;
    }

    const companyNameAlias = cleanUpStr(values.companyNameAlias);
    if (companyNameAlias === companyDataQuery.data?.companyNameAlias) {
      customToast.success("Company updated successfully");
      return;
    }

    if (!companyNameAlias || companyNameAlias.length < 3) {
      customToast.error("company name alias should have at least 3 characters");
      return;
    }

    const companyData = {
      updateVersion: companyDataQuery.data?.updateVersion ?? 0,
      companyNameAlias: companyNameAlias,
    };
    try {
      await companyUpdateMutateAsync(companyData);
      persistentCustomToast.success("Company updated successfully");
      refreshPage();
    } catch (error) {
      customToast.error(getMessageError(error, "Company update failed"));
      console.error(error);
    }
  }, [
    errors,
    values.companyNameAlias,
    companyDataQuery.data?.companyNameAlias,
    companyDataQuery.data?.updateVersion,
    companyUpdateMutateAsync,
  ]);

  useEffect(() => {
    if (companyDataQuery.data !== undefined) {
      setFormikValues({
        companyNameAlias: companyDataQuery.data.companyNameAlias,
      });
    }
  }, [companyDataQuery.data, setFormikValues]);

  return (
    <Wrapper>
      <Title>Company Details</Title>
      {companyDataQuery.isFetched && (
        <>
          <InputField
            label="Company Name"
            name="companyNameAlias"
            onChange={handleChange}
            handleBlur={handleBlur}
            touched={touched.companyNameAlias}
            errors={errors.companyNameAlias}
            value={values.companyNameAlias}
          />

          <ToggleBlocStyled
            label="Store Data"
            withIcon={!!companyDataQuery.data?.dataClearanceTime}
            toggle={!!companyDataQuery.data?.storeData}
            readOnly
          >
            <ToggleContentStyled>
              {companyDataQuery.data?.dataClearanceTime && (
                <>
                  <SubTitle>Delete data older than:</SubTitle>
                  <Row
                    width="100%"
                    justifyContent="space-between"
                    gap="0.375em"
                  >
                    <StyledInputField
                      name="amount"
                      defaultValue={
                        companyDataQuery.data?.dataClearanceTime?.amount
                      }
                      readOnly
                    />
                    <StyledInputField
                      name="timeLine"
                      defaultValue={
                        companyDataQuery.data?.dataClearanceTime?.temporalUnit
                      }
                      readOnly
                    />
                  </Row>
                </>
              )}
            </ToggleContentStyled>
          </ToggleBlocStyled>
        </>
      )}
      {!companyDataQuery.isLoading ? (
        <ButtonStyled
          onClick={onUpdateCompanyClick}
          iconBefore={<HiOutlineCheck />}
        >
          <Heading3>Save</Heading3>
        </ButtonStyled>
      ) : (
        <ButtonStyled>
          <Loader />
        </ButtonStyled>
      )}
    </Wrapper>
  );
};

export default CompanyPageDisplay;

const Wrapper = styled(Container)`
  padding: 0 1.875em;
  margin: 1.875em 0;
  align-items: flex-start;
  justify-content: flex-start;
  height: 80vh;
`;

const ButtonStyled = styled(Button)`
  border: ${(props) => props.theme.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: 26.25em;
  padding: 0 0.625em;
  margin-top: 3em;
  min-height: 2.1875em;
`;

const Title = styled(Heading3)`
  font-size: 1.125em;
  font-weight: 600;
  color: ${(props) => props.theme.color.secondary2};
`;

const SubTitle = styled(Heading5)`
  padding: 1em 0;
  color: ${(props) => props.theme.color.grey};
`;

const ToggleContentStyled = styled(Container)`
  width: 100%;
  padding: 1.5em;
  padding-top: 0;
  align-items: flex-start;
  background: ${(props) => props.theme.background.secondary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
`;

const ToggleBlocStyled = styled(ToggleBloc)`
  opacity: 0.5;
`;

const StyledInputField = styled(InputField)`
  width: 50%;

  ${Container} {
    padding: 0 0.2em;
    background: white;
    min-height: 2.375em;
  }

  ${Input} {
    color: rgba(6, 27, 59, 0.5);
  }
`;
