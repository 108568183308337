import { useAppQuery } from "./hooks";
import { DEFAULT_LIMIT_PAGINATION } from "utils/constants";
import auditApi from "api/auditApi";
import { useSelectCustomer } from "common/hooks/session/useSelectCustomer";
import { selectFilterSortSetting } from "services/redux/reducers/settingsSlice";
import { TableTypes } from "common/types/api/preferences";
import { useCallback, useMemo } from "react";
import { IParmPagination, usePagination } from "./usePagination";

const selectFilterSort = selectFilterSortSetting(TableTypes.AUDITS);

export const useGetAuditByIdQuery = (sessionId: string) => {
  const { customerId } = useSelectCustomer();

  const query = useAppQuery(() => {
    return auditApi.getAuditById({
      sessionId,
      customerId,
    });
  }, ["useGetAuditById", sessionId, customerId]);

  return query;
};

export const useGetAuditsQuery = (sizeOfPage = DEFAULT_LIMIT_PAGINATION) => {
  const { customerId } = useSelectCustomer();
  const defaultValues = useMemo(
    () => ({
      customerId,
    }),
    [customerId]
  );

  const ifCondition = useCallback(
    (values: IParmPagination & typeof defaultValues) => {
      return !!values.customerId;
    },
    []
  );

  const getApi = useCallback(
    (param: IParmPagination & typeof defaultValues) => {
      return auditApi.getAudits(param);
    },
    []
  );

  const query = usePagination({
    key: "useGetAuditsQuery",
    sizeOfPage,
    getApi,
    selectFilterSort,
    defaultValues,
    ifCondition,
    type: TableTypes.AUDITS,
  });

  return query;
};
