import Logo from "components/Logo";
import { Container } from "components/core/Styled/Layouts";
import { Heading3 } from "components/core/Styled/Texts";
import { Button } from "components/core/Styled/Controls";
import styled from "styled-components";
import { useCustomAuth } from "services/auth/useCustomAuth";

function ForbiddenPage() {
  const { handleLogout } = useCustomAuth();

  return (
    <Wrapper>
      <StyledLogo />
      <Heading3>
        Customer account is disabled, Please contact uqudo sales or account
        management.
      </Heading3>
      <Button onClick={handleLogout}>Logout</Button>
    </Wrapper>
  );
}

export default ForbiddenPage;

const Wrapper = styled(Container)`
  background: ${(props) => props.theme.background.main};
  border: ${(props) => props.theme.border.main};
  border-radius: ${(props) => props.theme.borderRadius.big};
  gap: 1em;
  justify-content: flex-start;
  padding: 4em 2em;

  min-width: 26.25em;
  min-height: 35.125em;

  & > ${Heading3} {
    flex: 1;
    margin: 0 auto;
    max-width: 20em;
    height: 100%;
    text-transform: none;
  }

  & > ${Button} {
    width: 100%;

    background: ${(props) => props.theme.background.primary};
    color: ${(props) => props.theme.color.primary2};
    border-radius: ${(props) => props.theme.borderRadius.primary};
    border: none;
    padding: 0.5em;
    &:active {
      background: ${(props) => props.theme.background.primaryLight};
    }
  }
`;

const StyledLogo = styled(Logo)`
  font-size: 3rem;
`;
