import ModalPortal from "./ModelPortal";
import { Container } from "components/core/Styled/Layouts";
import React, { PropsWithChildren } from "react";
import styled from "styled-components";

type IModalProps = {
  show: boolean;
  onClose?: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
};

const Modal = ({
  show,
  onClose,
  className,
  children,
}: PropsWithChildren<IModalProps>) => {
  return (
    <ModalPortal enable={show} modalId="modal" className={className}>
      <Wrapper onClick={onClose}>
        <ModalContainer
          className={className}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </ModalContainer>
      </Wrapper>
    </ModalPortal>
  );
};

export default Modal;

const Wrapper = styled(Container)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  //opacity: 0;
  transition: all 0.3s ease-in-out;
  width: 100%;
  height: 100%;
  z-index: 100;
`;

const ModalContainer = styled(Container)``;
