import { ApiErrorCode } from "utils/CustomAxios/ApiError";

const AppErrorCode = {
  ...ApiErrorCode,
  UNEXPECTED_ERROR: "UNEXPECTED_ERROR",
  SERVER_ERROR: "SERVER_ERROR",
  UNAUTHORIZED: "UNAUTHORIZED",
  FORBIDDEN: "FORBIDDEN",
  NOT_FOUND: "NOT_FOUND",
  CONFLICT: "CONFLICT",
  INVALID_DATA: "INVALID_DATA",
} as const;

export type AppErrorCodeType = keyof typeof AppErrorCode;

export const keyAppErrorCode = Object.keys(AppErrorCode);

export const isAppErrorCode = (code: string): code is AppErrorCodeType => {
  return keyAppErrorCode.includes(code);
};

export default AppErrorCode;
