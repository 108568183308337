import { Container, Row } from "components/core/Styled/Layouts";
import Button from "components/reusable/Button";
import { errorString, haveErrors } from "utils/assertion";
import { Heading3 } from "components/core/Styled/Texts";
import InputField from "components/reusable/Input";
import Loader from "components/core/Loaders/Loader";
import CustomSelect from "components/Select/CustomSelect";
import { TElement } from "common/types/common";

import {
  useGetAdminByIdQuery,
  useUpdateAdminMutation,
} from "services/query/admins";
import { IUpdateAdmin } from "api/adminsApi/updateAdmin";
import { rolesToOptions, UserRoles } from "common/types/api/roles";
import { useGoTo } from "common/hooks/paths/useGoTo";
import { routesList } from "utils/routesList";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { HiOutlineCheck } from "react-icons/hi";
import { useFormik } from "formik";
import { customToast } from "utils/customToast";
import { getMessageError } from "utils/helpers";
import { IAdminErrorsProps } from "pages/Administration/Admins/AdminCreatePage/AdminCreate";

const options = rolesToOptions([
  UserRoles.ROLE_UQUDO_AUDITOR,
  UserRoles.ROLE_UQUDO_SALES,
]);
type IOption = TElement<typeof options>;

export type IAdminUpdate = {
  firstName: string;
  lastName: string;
  email: string;
  updateVersion: number;
};

const validate = (adminData: IAdminUpdate) => {
  const errors: IAdminErrorsProps = {};

  errors.firstName = errorString({ firstName: adminData.firstName });
  errors.lastName = errorString({ lastName: adminData.lastName });

  return errors;
};

const AdminUpdate = ({ adminId }: { adminId: string }) => {
  const goTo = useGoTo();
  const getAdminByIdQuery = useGetAdminByIdQuery(adminId);
  const getAdminByIdRefetch = useMemo(
    () => getAdminByIdQuery.refetch,
    [getAdminByIdQuery.refetch]
  );

  const updateAdminsQuery = useUpdateAdminMutation(adminId);
  const updateAdminsMutateAsync = useMemo(
    () => updateAdminsQuery.mutateAsync,
    [updateAdminsQuery.mutateAsync]
  );

  const [role, setRole] = useState<IOption["value"]>();

  const admin = useMemo(() => {
    if (getAdminByIdQuery.data === undefined) return undefined;
    setRole(getAdminByIdQuery.data.role as unknown as IOption["value"]);
    return {
      email: getAdminByIdQuery.data.email,
      firstName: getAdminByIdQuery.data.firstName,
      lastName: getAdminByIdQuery.data.lastName,
      role: getAdminByIdQuery.data.role,
      updateVersion: getAdminByIdQuery.data.updateVersion,
    };
  }, [getAdminByIdQuery.data]);

  const onRoleChange = useCallback((option: IOption) => {
    setRole(option.value);
  }, []);

  const {
    handleChange,
    handleBlur,
    values: adminData,
    errors,
    touched,
    setValues: setFormikValues,
  } = useFormik({
    initialValues: {
      firstName: (admin && admin.firstName) || "",
      lastName: (admin && admin.lastName) || "",
      email: (admin && admin.email) || "",
      updateVersion: (admin && admin.updateVersion) || 0,
    },
    validate,
    onSubmit: () => console.info("Admin edited"),
  });

  const onUpdateAdmin = useCallback(async () => {
    if (haveErrors(errors) || !role) {
      customToast.error("Please fill the required inputs");
      return;
    }
    try {
      const updateData: Omit<IUpdateAdmin, "customerId" | "adminId"> = {
        ...adminData,
        role,
      };

      await updateAdminsMutateAsync(updateData);
      customToast.success("Admin update successfully");
      getAdminByIdRefetch();
      goTo(routesList.admins.routes.main);
    } catch (error) {
      customToast.error(getMessageError(error, "Admin update failed"));
      console.error(error);
    }
  }, [
    errors,
    role,
    adminData,
    updateAdminsMutateAsync,
    getAdminByIdRefetch,
    goTo,
  ]);

  useEffect(() => {
    if (getAdminByIdQuery.data !== undefined) {
      setFormikValues({
        firstName: getAdminByIdQuery.data.firstName,
        lastName: getAdminByIdQuery.data.lastName,
        email: getAdminByIdQuery.data.email,
        updateVersion: getAdminByIdQuery.data.updateVersion,
      });
    }
  }, [setFormikValues, getAdminByIdQuery.data]);

  return (
    <Wrapper>
      <InputField
        label="First name"
        name="firstName"
        onChange={handleChange}
        handleBlur={handleBlur}
        touched={touched.firstName}
        errors={errors.firstName}
        value={adminData?.firstName}
      />
      <InputField
        label="Last name"
        name="lastName"
        onChange={handleChange}
        handleBlur={handleBlur}
        touched={touched.lastName}
        errors={errors.lastName}
        value={adminData?.lastName}
      />
      <InputField label="Email" value={admin?.email} readOnly />
      {role && (
        <CustomSelect
          label="Role"
          options={options}
          selected={options.find((x) => x.value === role)}
          onChange={onRoleChange}
        />
      )}

      <Row width="26.25em">
        {!getAdminByIdQuery.isLoading ? (
          <ButtonStyled onClick={onUpdateAdmin} iconBefore={<HiOutlineCheck />}>
            <Heading3>Save</Heading3>
          </ButtonStyled>
        ) : (
          <ButtonStyled>
            <Loader />
          </ButtonStyled>
        )}
      </Row>
    </Wrapper>
  );
};

export default AdminUpdate;

const Wrapper = styled(Container)`
  padding: 0 1.875em;
  margin: 1.875em 0;
  align-items: flex-start;
  min-height: 80vh;
  justify-content: flex-start;
`;

const ButtonStyled = styled(Button)<{ cancel?: boolean }>`
  border: ${(props) => (props.cancel ? "none" : props.theme.border.primary)};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: ${(props) => (props.cancel ? "8em" : "18.25em")};
  color: ${(props) =>
    props.cancel ? props.theme.color.black : props.theme.color.main};
  padding: 0 0.625em;
  margin-top: 2em;
  min-height: 2.1875em;
  width: 100%;
`;
