type TEnum = {
  [Key in string]: unknown;
};

export const getInfoEnum = <T extends Readonly<TEnum>>(obj: T) => {
  type TKeys = keyof T;
  type TValues = T[keyof T];

  const keys = Object.keys(obj) as TKeys[];
  const values = Object.values(obj) as TValues[];
  const entries = obj;

  const defaultKey: TKeys = keys[0];
  const defaultValue: TValues = values[0];

  const isValidKey = (key: unknown): key is TKeys => {
    return (keys as unknown[]).includes(key);
  };

  const isValidValue = (value: unknown): value is TValues => {
    return (values as unknown[]).includes(value);
  };

  const res = {
    ...obj,
    defaultKey,
    defaultValue,
    keys,
    values,
    entries,
    isValidKey,
    isValidValue,
  };

  return res;
};

export type TEnumKey<T extends ReturnType<typeof getInfoEnum>> =
  T["defaultKey"];

export type TEnumValue<T extends ReturnType<typeof getInfoEnum>> =
  T["defaultValue"];

export type Map2D<T> = Record<string, Record<string, T>>;
