import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // internal
  UserRoles.ROLE_UQUDO_SALES,
];

import ApiHelper from "api/ApiHelper";
import { IProductInfo } from "common/types/api/product";
import { API_BASE_URL } from "utils/UrlList";
import { assertNotEmpty } from "utils/assertion";
import { WithCustomerInfo } from "common/types/api/customer";

export type IAddProduct = WithCustomerInfo & Pick<IProductInfo, "productType">;

export type IAddProductResponse = IProductInfo;

const addProduct = async ({
  customerId,
  ...rest
}: IAddProduct): Promise<IAddProductResponse> => {
  try {
    assertNotEmpty(customerId);
    const res = await ApiHelper.apiClient.post<IAddProductResponse>(
      `${API_BASE_URL}/api/v1/customers/${customerId}/products`,
      rest,
      ApiHelper.getHeaders()
    );

    const data = res.data;
    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default addProduct;
