import styled from "styled-components";
import { useCallback, useMemo } from "react";
import SelectOption, { IElementList } from "components/core/SelectOption";
import { usePageSetting } from "common/hooks/settings/useSetting";

const OPTION_LIST: IElementList[] = [
  { value: "10", label: "10" },
  { value: "25", label: "25" },
  { value: "50", label: "50" },
  { value: "100", label: "100" },
];

type IProps = {
  pageInfo: ReturnType<typeof usePageSetting>;
};

const ListPageSize = ({ pageInfo }: IProps) => {
  const currentOption = useMemo(
    () => pageInfo.pageSize.toString(),
    [pageInfo.pageSize]
  );
  const onChange = useCallback(
    (value: string) => {
      pageInfo.updatePageSize(Number(value));
    },
    [pageInfo]
  );

  return (
    <SelectOptionStyled
      defaultValue={currentOption}
      list={OPTION_LIST}
      onChange={onChange}
      width="8em"
      notSingleSelect
    />
  );
};

export default ListPageSize;

const SelectOptionStyled = styled(SelectOption)`
  padding: 0.23em 1em;
  width: 8em;
`;
