import { usersRoutes } from "./Users";
import { companyRoutes } from "./Company";
import { profileRoutes } from "./Profile";
import PrivateRoutes from "routes/PrivateRoutes";
import { RouteObject } from "react-router-dom";
import { LinkAppRoutes } from "./LinkApp";

export const settingsRoutes: RouteObject = {
  element: <PrivateRoutes />,
  children: [usersRoutes, companyRoutes, profileRoutes, LinkAppRoutes],
};
