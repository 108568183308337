import { useSelectCustomerCanBeEmpty } from "./useSelectCustomer";
import { selectCurrentRole } from "services/redux/reducers/authSlice";
import { useAppSelector } from "services/redux/hooks";
import { useMemo } from "react";

export const useCurrentRole = () => {
  const currentRole = useAppSelector(selectCurrentRole);
  const selectedCustomer = useSelectCustomerCanBeEmpty();
  const hadCustomer = useMemo(() => !!selectedCustomer, [selectedCustomer]);

  return { currentRole, hadCustomer, selectedCustomer };
};
