import { useAppMutation, useAppQuery } from "./hooks";
import companyApi from "api/companyApi";
import { IUpdateCompanyDetails } from "api/companyApi/updateCompanyDetails";

export const useGetCompanyDetailsQuery = (customerId: string) => {
  const query = useAppQuery(() => {
    return companyApi.getCompanyDetails({
      customerId,
    });
  }, ["useGetCompanyDetailsQuery", customerId]);

  return query;
};

export const useUpdateCompanyDetailsMutation = (customerId: string) => {
  const query = useAppMutation(
    (arg: Omit<IUpdateCompanyDetails, "customerId">) => {
      return companyApi.updateCompanyDetails({
        ...arg,
        customerId,
      });
    }
  );

  return query;
};
