import { Container, Row } from "components/core/Styled/Layouts";
import { Heading2, Heading5 } from "components/core/Styled/Texts";
import { ImageSrc } from "components/reusable/Image";
import React from "react";
import styled from "styled-components";

type IProps = {
  title?: string;
  subTitle?: string;
  icon?: string;
};

const NodeCard = ({ title, subTitle, icon }: IProps) => {
  return (
    <TitleBloc>
      <StyledImage src={icon} />
      <Texts>
        <Title>{title}</Title>
        <SubTitle>{subTitle} </SubTitle>
      </Texts>
    </TitleBloc>
  );
};

export default NodeCard;

const TitleBloc = styled(Row)`
  width: 100%;
  height: 7.5em;
  align-items: center;
  padding: 0 1.5em;
`;

const StyledImage = styled(ImageSrc)`
  width: 1.875em;
  margin-right: 1.25em;
`;
const Texts = styled(Container)`
  width: 100%;
  align-items: flex-start;
`;
const Title = styled(Heading2)`
  color: ${(props) => props.theme.color.black};
`;

const SubTitle = styled(Heading5)`
  color: ${(props) => props.theme.color.secondary2};
  padding: 0.6em 0;
`;
