import { getInfoEnum, TEnumKey } from "common/types/helpers/enumHelpers";

// status
export const EventStatus = getInfoEnum({
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
} as const);

export type TEventStatus = TEnumKey<typeof EventStatus>;

// scan
export const ScanStatusCode = getInfoEnum({
  SCAN_DOCUMENT_NOT_RECOGNIZED: "SCAN_DOCUMENT_NOT_RECOGNIZED",
  SCAN_DOCUMENT_EXPIRED: "SCAN_DOCUMENT_EXPIRED",
  SCAN_DOCUMENT_FRONT_BACK_MISMATCH: "SCAN_DOCUMENT_FRONT_BACK_MISMATCH",
} as const);

export type TScanStatusCode = TEnumKey<typeof ScanStatusCode>;

// read
export const ReadStatusCode = getInfoEnum({
  READ_DOCUMENT_VALIDATION_FAILED: "READ_DOCUMENT_VALIDATION_FAILED",
} as const);

export type TReadStatusCode = TEnumKey<typeof ReadStatusCode>;

// face
export const FaceStatusCode = getInfoEnum({
  FACE_LIVENESS_FAILED: "FACE_LIVENESS_FAILED",
  FACE_NOT_MATCH: "FACE_NOT_MATCH",
} as const);

export type TFaceStatusCode = TEnumKey<typeof FaceStatusCode>;

// Background Check
export const BackgroundCheckStatusCode = getInfoEnum({} as const);

export type TBackgroundCheckStatusCode = TEnumKey<
  typeof BackgroundCheckStatusCode
>;

// lookup
export const LookupStatusCode = getInfoEnum({
  SERVICE_UNAVAILABLE: "SERVICE_UNAVAILABLE",
  UNEXPECTED_ERROR: "UNEXPECTED_ERROR",
  ID_NOT_FOUND: "ID_NOT_FOUND",
  INVALID_INPUT: "INVALID_INPUT",
} as const);

export type TLookupStatusCode = TEnumKey<typeof LookupStatusCode>;

export const EventStatusCode = getInfoEnum({
  ...ScanStatusCode.entries,
  ...ReadStatusCode.entries,
  ...FaceStatusCode.entries,
  ...BackgroundCheckStatusCode.entries,
  ...LookupStatusCode.entries,
} as const);

export type TEventStatusCode = TEnumKey<typeof EventStatusCode>;
