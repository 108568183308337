import { ISelectedCustomerInfo } from "common/types/api/customer";
import { ISessionInfo } from "common/types/api/profile";
import getCustomersDropDownList from "api/customerApi/getCustomersDropDownList";
import getSelectedCustomerFromLocalStorage from "./getSelectedCustomerFromLocalStorage";

export type IGetSelectedCustomerResponse = ISelectedCustomerInfo | undefined;

async function getSelectedCustomer(
  profile: ISessionInfo
): Promise<IGetSelectedCustomerResponse> {
  try {
    const customerFromLocalStorage = await getSelectedCustomerFromLocalStorage(
      profile
    );
    if (customerFromLocalStorage) return customerFromLocalStorage;

    const data = await getCustomersDropDownList({
      start: 0,
      limit: 1,
    });

    if (data.results.length === 0) {
      return undefined;
    }

    const res = data.results[0];
    return res;
  } catch (error) {
    return undefined;
  }
}

export default getSelectedCustomer;
