import { Container } from "components/core/Styled/Layouts";
import { Heading4 } from "components/core/Styled/Texts";
import { TInfo } from "common/types/api/common";
import { fromCamelCaseToString, fromValueToLabel } from "utils/helpers";
import Checkbox from "components/reusable/Checkbox";
import React, { useCallback } from "react";
import styled from "styled-components";

type IProps = {
  label: string;
  list: TInfo[];
  selectedList: TInfo[];
  setSelected: (label: string, list: TInfo[]) => void;
};
const CheckboxFilters = ({
  label,
  list,
  setSelected,
  selectedList,
}: IProps) => {
  const onChangeStoreData = useCallback(
    (name: TInfo) => {
      let newValues = [];
      if (selectedList.indexOf(name) === -1) {
        newValues = [...selectedList, name];
      } else {
        newValues = selectedList.filter((value) => name !== value);
      }

      setSelected(label, newValues);
    },
    [label, selectedList, setSelected]
  );

  if (list.length < 2) return null;

  return (
    <FilterWrapper>
      <Label>{fromCamelCaseToString(label)}</Label>
      {list.map((name, index) => {
        const checked = selectedList.indexOf(name) !== -1;

        return (
          <StyledCheckbox
            key={index}
            onChange={() => onChangeStoreData(name)}
            checked={checked}
            label={`${fromValueToLabel(name as string)}`}
          />
        );
      })}
    </FilterWrapper>
  );
};

export default CheckboxFilters;

const FilterWrapper = styled(Container)`
  width: 100%;
  padding: 0 1em;
  margin: 1.875em 0;
  margin-top: 0;
  align-items: flex-start;
`;

const Label = styled(Heading4)`
  color: ${(props) => props.theme.color.secondary2};
`;

const StyledCheckbox = styled(Checkbox)`
  margin-top: 0.625em;
`;
