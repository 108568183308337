import { flexCss } from "./AtomicCss";
import { IStyleCss, commonCss } from "./helpers";
import styled from "styled-components";

export const HEIGHT_NAVBAR = "5em";
export const WIDTH_SIDEBAR = "13.75em";

export const Container = styled.div<IStyleCss>`
  ${flexCss};
  ${commonCss};
`;

export const Row = styled(Container)<IStyleCss>`
  ${flexCss};
  flex-direction: row;
  ${commonCss};
`;

export const Divider = styled.div`
  width: 100%;
  height: 0;
  border-top: ${(props) => props.theme.border.main};
`;

export const Nav = styled(Container)`
  position: fixed;
  top: 0;
  left: ${WIDTH_SIDEBAR};
  width: calc(100vw - ${WIDTH_SIDEBAR});
  z-index: 100;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5em 1.875em;
  background-color: ${(props) => props.theme.background.main};
  border-bottom: ${(props) => props.theme.border.main};
  height: ${HEIGHT_NAVBAR};
`;

export const Side = styled(Container)`
  position: fixed;
  top: 0;
  height: 100vh;
  width: ${WIDTH_SIDEBAR};
  z-index: 100;
`;

export const PageContent = styled.div`
  width: 100%;
  min-height: 100vh;
`;

export const ContentBodyStyled = styled.div`
  position: fixed;
  top: ${HEIGHT_NAVBAR};
  left: ${WIDTH_SIDEBAR};
  width: calc(100vw - ${WIDTH_SIDEBAR});
  height: calc(100vh - ${HEIGHT_NAVBAR});
  overflow: auto;
`;

export const ContentBody = (props: React.HTMLAttributes<HTMLDivElement>) => (
  <ContentBodyStyled {...props} id={"CONTENT_BODY_ID"} />
);
