import { ISelectedCustomerInfo } from "common/types/api/customer";
import { REDIRECTION, ROUTES } from "./UrlList";
import {
  TUserRoles,
  UserRoles,
  authRoles,
  internalRoles,
  allRoles,
} from "common/types/api/roles";

export type IRouteInfo = {
  requiredCustomer: boolean;
  path: string;
  routes?: Record<string, string>;
  allowedRoles?: TUserRoles[];
  allowedFun?: (info: ISelectedCustomerInfo | undefined) => boolean;
};

export const routesList = {
  // analytics
  analytics: {
    requiredCustomer: true,
    path: ROUTES.analytic.main,
    routes: ROUTES.analytic,
    allowedRoles: authRoles,
  },

  reports: {
    requiredCustomer: true,
    path: ROUTES.reports.main,
    routes: ROUTES.reports,
    allowedRoles: [
      // internal
      UserRoles.ROLE_UQUDO_ADMIN,
      // customers
      UserRoles.ROLE_CUSTOMER_ADMIN,
      UserRoles.ROLE_CUSTOMER_OWNER,
      UserRoles.ROLE_CUSTOMER_USER,
    ] as TUserRoles[],
  },

  audits: {
    requiredCustomer: true,
    path: ROUTES.audits.main,
    routes: ROUTES.audits,
    allowedRoles: [
      // internal
      UserRoles.ROLE_UQUDO_ADMIN,
      // customers
      UserRoles.ROLE_CUSTOMER_ADMIN,
      UserRoles.ROLE_CUSTOMER_OWNER,
      UserRoles.ROLE_CUSTOMER_USER,
    ] as TUserRoles[],
  },

  // settings
  profile: {
    requiredCustomer: true,
    path: ROUTES.profile.main,
    routes: ROUTES.profile,
    allowedRoles: authRoles,
  },

  company: {
    requiredCustomer: true,
    path: ROUTES.company.main,
    routes: ROUTES.company,
    allowedRoles: [
      ...internalRoles,
      // customers
      UserRoles.ROLE_CUSTOMER_ADMIN,
      UserRoles.ROLE_CUSTOMER_OWNER,
    ] as TUserRoles[],
  },

  users: {
    requiredCustomer: true,
    path: ROUTES.users.main,
    routes: ROUTES.users,
    allowedRoles: [
      ...internalRoles,
      // customers
      UserRoles.ROLE_CUSTOMER_ADMIN,
      UserRoles.ROLE_CUSTOMER_OWNER,
    ] as TUserRoles[],
  },

  linkApp: {
    requiredCustomer: true,
    path: ROUTES.linkApp.main,
    routes: ROUTES.linkApp,
    allowedRoles: [
      "ROLE_CUSTOMER_ADMIN",
      "ROLE_CUSTOMER_OWNER",
      "ROLE_CUSTOMER_DEVELOPER",
      "ROLE_CUSTOMER_USER",
    ] as TUserRoles[],
    allowedFun: (arg: ISelectedCustomerInfo | undefined) =>
      arg?.type === "TESTING",
  },

  // development
  credentials: {
    requiredCustomer: true,
    path: ROUTES.credentials.main,
    routes: ROUTES.credentials,
    allowedRoles: [
      ...internalRoles,
      // customers
      UserRoles.ROLE_CUSTOMER_ADMIN,
      UserRoles.ROLE_CUSTOMER_OWNER,
      UserRoles.ROLE_CUSTOMER_DEVELOPER,
    ] as TUserRoles[],
  },

  noCodeIntegration: {
    requiredCustomer: true,
    path: ROUTES.noCodeIntegration.main,
    routes: ROUTES.noCodeIntegration,
    allowedRoles: [
      // internal
      UserRoles.ROLE_UQUDO_ADMIN,
      // customers
      UserRoles.ROLE_CUSTOMER_ADMIN,
      UserRoles.ROLE_CUSTOMER_OWNER,
      UserRoles.ROLE_CUSTOMER_DEVELOPER,
    ] as TUserRoles[],
  },

  cors: {
    requiredCustomer: true,
    path: ROUTES.cors.main,
    routes: ROUTES.cors,
    allowedRoles: [
      ...internalRoles,
      // customers
      UserRoles.ROLE_CUSTOMER_ADMIN,
      UserRoles.ROLE_CUSTOMER_OWNER,
      UserRoles.ROLE_CUSTOMER_DEVELOPER,
    ] as TUserRoles[],
  },

  // external link
  support: {
    requiredCustomer: false,
    path: REDIRECTION.support,
    routes: {},
    allowedRoles: authRoles,
  },

  status: {
    requiredCustomer: false,
    path: REDIRECTION.status,
    routes: {},
    allowedRoles: authRoles,
  },

  documentation: {
    requiredCustomer: false,
    path: REDIRECTION.documentation,
    routes: {},
    allowedRoles: authRoles,
  },

  // administration
  admins: {
    requiredCustomer: false,
    path: ROUTES.admins.main,
    routes: ROUTES.admins,
    allowedRoles: [UserRoles.ROLE_UQUDO_ADMIN] as TUserRoles[],
  },

  customers: {
    requiredCustomer: false,
    path: ROUTES.customers.main,
    routes: ROUTES.customers,
    allowedRoles: internalRoles,
  },

  // public
  login: {
    requiredCustomer: false,
    path: ROUTES.login.main,
    routes: ROUTES.login,
    allowedRoles: allRoles,
  },

  logout: {
    requiredCustomer: false,
    path: "/logout",
    routes: {},
    allowedRoles: allRoles,
  },
};

export const routes: IRouteInfo[] = Object.values(routesList);
