import { useContextEditCustomer } from "common/contexts/editCustomerContext";
import { IProductKycEnrolmentFlow } from "common/types/api/product";
import ButtonAction from "components/ButtonAction";
import { Container, Row } from "components/core/Styled/Layouts";
import { useCallback, useMemo } from "react";
import { HiOutlineCheck } from "react-icons/hi";
import styled from "styled-components";
import CustomToggle from "./CustomToggle";

export type IProps = {
  product: IProductKycEnrolmentFlow;
};

const KycEnrolmentFlowDisplay = ({ product }: IProps) => {
  const { productsConfigs, onUpdateProduct, updateProductLoading } =
    useContextEditCustomer();

  const configs = useMemo(
    () => productsConfigs[product.productId],
    [product.productId, productsConfigs]
  );

  const save = useCallback(() => {
    onUpdateProduct({
      ...product,
      storeData: !!configs?.["storeData"],
      dataClearanceTime: configs?.["storeData"]
        ? product.dataClearanceTime
        : undefined,
      configuration: {
        ...product?.configuration,
        scan: {
          ...(configs?.["scan"] ? product?.configuration?.scan : {}),
          active: !!configs?.["scan"],
        },
        nfc: {
          ...(configs?.["nfc"] ? product?.configuration?.nfc : {}),
          active: !!configs?.["nfc"],
        },
        facialRecognition: {
          ...(configs?.["facialRecognition"]
            ? product?.configuration?.facialRecognition
            : {}),
          active: !!configs?.["facialRecognition"],
        },
        oneToNFaceVerification: {
          ...(configs?.["oneToNFaceVerification"]
            ? product?.configuration?.oneToNFaceVerification
            : {}),
          active: !!configs?.["oneToNFaceVerification"],
        },
        lookup: {
          ...(configs?.["lookup"] ? product?.configuration?.lookup : {}),
          active: !!configs?.["lookup"],
        },
        backgroundCheck: {
          ...(configs?.["backgroundCheck"]
            ? product?.configuration?.backgroundCheck
            : {}),
          active: !!configs?.["backgroundCheck"],
        },
      },
    });
  }, [configs, onUpdateProduct, product]);

  const touched = useMemo(() => {
    if (!!configs?.["scan"] !== !!product?.configuration?.scan?.active)
      return true;

    if (!!configs?.["nfc"] !== !!product?.configuration?.nfc?.active)
      return true;
    if (
      !!configs?.["facialRecognition"] !==
      !!product?.configuration?.facialRecognition?.active
    )
      return true;
    if (
      !!configs?.["oneToNFaceVerification"] !==
      !!product?.configuration?.oneToNFaceVerification?.active
    )
      return true;
    if (!!configs?.["lookup"] !== !!product?.configuration?.lookup?.active)
      return true;
    if (
      !!configs?.["backgroundCheck"] !==
      !!product?.configuration?.backgroundCheck?.active
    )
      return true;
    if (!!configs?.["storeData"] !== !!product.storeData) return true;
    return false;
  }, [
    configs,
    product?.configuration?.backgroundCheck?.active,
    product?.configuration?.facialRecognition?.active,
    product?.configuration?.lookup?.active,
    product?.configuration?.nfc?.active,
    product?.configuration?.oneToNFaceVerification?.active,
    product?.configuration?.scan?.active,
    product.storeData,
  ]);

  return (
    <Wrapper width="100%">
      <CustomToggle
        product={product}
        toggleLabel="Scan"
        toggleID="scan"
        toggle={!!configs?.["scan"]}
      />

      <CustomToggle
        product={product}
        toggleLabel="NFC"
        toggleID="nfc"
        toggle={!!configs?.["nfc"]}
      />

      <CustomToggle
        product={product}
        toggleLabel="1:1 Face Matching & Liveness"
        toggleID="facialRecognition"
        toggle={!!configs?.["facialRecognition"]}
      />

      <CustomToggle
        product={product}
        toggleLabel="1:N Face Search"
        toggleID="oneToNFaceVerification"
        toggle={!!configs?.["oneToNFaceVerification"]}
      />

      <CustomToggle
        product={product}
        toggleLabel="Lookup"
        toggleID="lookup"
        toggle={!!configs?.["lookup"]}
        buttonLabel="Settings"
        pageID="LOOKUP"
      />

      <CustomToggle
        product={product}
        toggleLabel="Screening"
        toggleID="backgroundCheck"
        toggle={!!configs?.["backgroundCheck"]}
        buttonLabel="Settings"
        pageID="SCREENING"
      />

      {product.storable && (
        <CustomToggle
          product={product}
          toggleLabel="Store Data"
          toggleID="storeData"
          toggle={!!configs?.["storeData"]}
          buttonLabel="Settings"
          pageID="DATA_STORAGE"
        />
      )}

      {touched && (
        <Row>
          <ButtonActionStyled
            label="Save"
            loading={updateProductLoading}
            onClick={save}
            iconBefore={<HiOutlineCheck />}
          />
        </Row>
      )}
    </Wrapper>
  );
};

export default KycEnrolmentFlowDisplay;

const Wrapper = styled(Container)`
  width: 100%;
  align-items: flex-start;
`;

const ButtonActionStyled = styled(ButtonAction)`
  width: 26.25em;
  margin-top: 3em;
`;
