import { useCallback, useEffect, useMemo, useState } from "react";
import {
  IAction,
  IActionType,
  IActionTypeWithoutBC,
  IBackgroundCheckInfo,
  IDocument,
} from "common/types/api/document";
import { IDocumentActionWithDocumentType } from "pages/Analytics/Reports/ReportDisplayPage/DocumentChoice";
import { EmptyType } from "common/types/common";
import { getValueByKey } from "utils/helpers";
import { documentTypes } from "utils/strings";

export type IUseReportDisplayInfoProps = {
  documents?: IDocument[] | undefined;
  backgroundCheck?: EmptyType<IBackgroundCheckInfo>;
};

const menuList: Record<
  IActionType,
  "SCAN" | "NFC" | "Verification" | "Screening" | "Lookup"
> = {
  SCAN: "SCAN",
  READING: "NFC",
  FACE: "Verification",
  BACKGROUND_CHECK: "Screening",
  LOOKUP: "Lookup",
} as const;

const mapDocument = (
  documents: IDocument[] | undefined,
  getInfo: (document: IDocument) => IAction | undefined
): IDocumentActionWithDocumentType[] => {
  if (!documents) return [];
  const res = documents
    .map((document) => ({
      documentType: document.documentType,
      info: getInfo(document),
    }))
    .filter((document) => !!document.info);
  return res as IDocumentActionWithDocumentType[];
};

function useReportDisplayInfo({
  documents,
  backgroundCheck,
}: IUseReportDisplayInfoProps) {
  const { menuListKeys, menuListValues, listDocuments } = useMemo(() => {
    const listDocuments: Record<
      IActionTypeWithoutBC,
      IDocumentActionWithDocumentType[]
    > = {
      SCAN: mapDocument(documents, (document) => {
        if (!document.scan) return undefined;
        return {
          type: "SCAN",
          data: document.scan,
        };
      }),
      READING: mapDocument(documents, (document) => {
        if (!document.reading) return undefined;
        return {
          type: "READING",
          data: document.reading,
        };
      }),
      FACE: mapDocument(documents, (document) => {
        if (!document.face) return undefined;
        return {
          type: "FACE",
          data: document.face,
        };
      }),
      LOOKUP: mapDocument(documents, (document) => {
        if (!document.lookup) return undefined;
        return {
          type: "LOOKUP",
          data: document.lookup,
        };
      }),
    };

    const menuListKeys: IActionType[] = Object.keys(menuList).filter((key) => {
      if (key === "BACKGROUND_CHECK") return !!backgroundCheck;
      const data = listDocuments[key as IActionTypeWithoutBC] as unknown;
      return data !== undefined && Array.isArray(data) && data.length > 0;
    }) as IActionType[];

    const menuListValues = Object.values(menuListKeys).map(
      (key) => menuList[key]
    );

    return {
      menuListKeys,
      menuListValues,
      listDocuments,
    };
  }, [backgroundCheck, documents]);

  const [selected, setSelected] = useState<IActionType>();

  const updateSelected = useCallback(
    (newValue: string) => {
      const getKey = menuListKeys.find((val) => menuList[val] === newValue);
      if (getKey === undefined) return;
      setSelected(getKey);
    },
    [menuListKeys]
  );

  const filteredMenuListValues = useMemo(() => {
    if (
      menuListValues?.[0] !== "SCAN" &&
      menuListValues?.[menuListValues.length - 1] === "Lookup"
    ) {
      const res = [
        menuListValues?.[menuListValues.length - 1],
        ...menuListValues,
      ];
      return res.slice(0, -1);
    }

    return menuListValues;
  }, [menuListValues]);

  const selectedDocuments = useMemo(() => {
    if (!selected || selected == "BACKGROUND_CHECK") return [];
    return listDocuments[selected];
  }, [listDocuments, selected]);

  const documentsNameList = useMemo(() => {
    return selectedDocuments.map((val) => ({
      label: getValueByKey(documentTypes, val.documentType),
      value: val.documentType,
    }));
  }, [selectedDocuments]);

  const [documentTypeSelected, setDocumentTypeSelected] = useState<string>();

  const changeDocumentTypeSelected = useCallback(
    (value: string | undefined = undefined, force = true) => {
      const newValue = value !== undefined ? value : documentsNameList[0].value;
      const exist = documentsNameList.find((val) => val.value === newValue);

      setDocumentTypeSelected((old) => {
        if (!old) return newValue;
        if (exist && force) return newValue;
        const found = documentsNameList.find((val) => val.value === old);
        if (found) return old;
        return newValue;
      });
    },
    [documentsNameList]
  );

  useEffect(() => {
    if (documentsNameList.length > 0) {
      changeDocumentTypeSelected(undefined, false);
    }
  }, [changeDocumentTypeSelected, documentsNameList]);

  const documentChangeHandler = useCallback(
    (value: string) => {
      changeDocumentTypeSelected(value);
    },
    [changeDocumentTypeSelected]
  );

  const documentSelected = useMemo(() => {
    const res = selectedDocuments.find(
      (document) => document.documentType === documentTypeSelected
    );
    return res;
  }, [documentTypeSelected, selectedDocuments]);

  return {
    documents,
    backgroundCheck,
    menuListKeys,
    menuListValues,
    listDocuments,
    selected,
    updateSelected,
    filteredMenuListValues,
    documentsNameList,
    documentTypeSelected,
    documentChangeHandler,
    selectedDocuments,
    documentSelected,
  };
}

export default useReportDisplayInfo;
