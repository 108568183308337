import { Container, Row } from "components/core/Styled/Layouts";
import Button from "components/reusable/Button";
import { errorString, haveErrors } from "utils/assertion";
import { Heading3 } from "components/core/Styled/Texts";
import InputField from "components/reusable/Input";
import Loader from "components/core/Loaders/Loader";
import CustomSelect from "components/Select/CustomSelect";
import { TElement } from "common/types/common";
import {
  useGetUserByIdQuery,
  useUpdateUserMutation,
} from "services/query/users";
import { IUpdateUser } from "api/userApi/updateUser";
import { rolesToOptions, UserRoles } from "common/types/api/roles";
import { useGoTo } from "common/hooks/paths/useGoTo";
import { routesList } from "utils/routesList";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { HiOutlineCheck } from "react-icons/hi";
import { useFormik } from "formik";
import { customToast } from "utils/customToast";
import { getMessageError } from "utils/helpers";
import { useCurrentRole } from "common/hooks/session/useCurrentRole";
import { IUserErrorsProps } from "pages/Settings/Profile/MyProfileDisplay/MyProfileDisplay";

const options = rolesToOptions([
  UserRoles.ROLE_CUSTOMER_ADMIN,
  UserRoles.ROLE_CUSTOMER_USER,
  UserRoles.ROLE_CUSTOMER_OWNER,
  UserRoles.ROLE_CUSTOMER_DEVELOPER,
]);
type IOption = TElement<typeof options>;

export type IUserUpdate = {
  firstName: string;
  lastName: string;
  email: string;
  updateVersion: number;
};

const validate = (userData: IUserUpdate) => {
  const errors: IUserErrorsProps = {};

  errors.firstName = errorString({ firstName: userData.firstName });
  errors.lastName = errorString({ lastName: userData.lastName });

  return errors;
};

const UserUpdate = ({ userId }: { userId: string }) => {
  const goTo = useGoTo();
  const { currentRole } = useCurrentRole();
  const getUserByIdQuery = useGetUserByIdQuery(userId);
  const getUserByIdRefetch = useMemo(
    () => getUserByIdQuery.refetch,
    [getUserByIdQuery.refetch]
  );

  const updateUsersQuery = useUpdateUserMutation(userId);
  const updateUsersMutateAsync = useMemo(
    () => updateUsersQuery.mutateAsync,
    [updateUsersQuery.mutateAsync]
  );

  const [role, setRole] = useState<IOption["value"]>();

  const user = useMemo(() => {
    if (getUserByIdQuery.data === undefined) return undefined;
    setRole(getUserByIdQuery.data.role as unknown as IOption["value"]);
    return {
      email: getUserByIdQuery.data.email,
      firstName: getUserByIdQuery.data.firstName,
      lastName: getUserByIdQuery.data.lastName,
      role: getUserByIdQuery.data.role,
      updateVersion: getUserByIdQuery.data.updateVersion,
    };
  }, [getUserByIdQuery.data]);

  const onRoleChange = useCallback((option: IOption) => {
    setRole(option.value);
  }, []);

  const {
    handleChange,
    handleBlur,
    values: userData,
    errors,
    touched,
    setValues: setFormikValues,
  } = useFormik({
    initialValues: {
      firstName: (user && user.firstName) || "",
      lastName: (user && user.lastName) || "",
      email: (user && user.email) || "",
      updateVersion: (user && user.updateVersion) || 0,
    },
    validate,
    onSubmit: () => console.info("User edited"),
  });

  const onUpdateUser = useCallback(async () => {
    if (haveErrors(errors)) {
      customToast.error("Please fill the required inputs");
      return;
    }

    if (!role) {
      customToast.error("Please fill the required inputs");
      return;
    }
    try {
      const updateData: Omit<IUpdateUser, "customerId" | "userId"> = {
        ...userData,
        role,
      };

      await updateUsersMutateAsync(updateData);
      customToast.success("User update successfully");
      getUserByIdRefetch();
      goTo(routesList.users.routes.main);
    } catch (error) {
      customToast.error(getMessageError(error, "User update failed"));
      console.error(error);
    }
  }, [
    errors,
    role,
    userData,
    updateUsersMutateAsync,
    getUserByIdRefetch,
    goTo,
  ]);

  useEffect(() => {
    if (getUserByIdQuery.data !== undefined) {
      setFormikValues({
        firstName: getUserByIdQuery.data.firstName,
        lastName: getUserByIdQuery.data.lastName,
        email: getUserByIdQuery.data.email,
        updateVersion: getUserByIdQuery.data.updateVersion,
      });
    }
  }, [setFormikValues, getUserByIdQuery.data]);

  return (
    <Wrapper>
      <InputField
        label="First name"
        name="firstName"
        onChange={handleChange}
        handleBlur={handleBlur}
        touched={touched.firstName}
        errors={errors.firstName}
        value={userData?.firstName}
      />
      <InputField
        label="Last name"
        name="lastName"
        onChange={handleChange}
        handleBlur={handleBlur}
        touched={touched.lastName}
        errors={errors.lastName}
        value={userData?.lastName}
      />
      <InputField label="Email" value={user?.email} readOnly />
      {role && (
        <CustomSelect
          label="Role"
          options={
            currentRole === "ROLE_CUSTOMER_ADMIN"
              ? options.filter((role) => role.value !== "ROLE_CUSTOMER_OWNER")
              : options
          }
          selected={options.find((x) => x.value === role)}
          onChange={onRoleChange}
        />
      )}

      <Row width="26.25em">
        {!getUserByIdQuery.isLoading ? (
          <ButtonStyled onClick={onUpdateUser} iconBefore={<HiOutlineCheck />}>
            <Heading3>Save</Heading3>
          </ButtonStyled>
        ) : (
          <ButtonStyled>
            <Loader />
          </ButtonStyled>
        )}
      </Row>
    </Wrapper>
  );
};

export default UserUpdate;

const Wrapper = styled(Container)`
  padding: 0 1.875em;
  margin: 1.875em 0;
  align-items: flex-start;
  min-height: 80vh;
  justify-content: flex-start;
`;

const ButtonStyled = styled(Button)<{ cancel?: boolean }>`
  border: ${(props) => (props.cancel ? "none" : props.theme.border.primary)};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: ${(props) => (props.cancel ? "8em" : "18.25em")};
  color: ${(props) =>
    props.cancel ? props.theme.color.black : props.theme.color.main};
  padding: 0 0.625em;
  margin-top: 2em;
  min-height: 2.1875em;
  width: 100%;
`;
