export const authorize = [];

import ApiHelper from "api/ApiHelper";
import { API_BASE_URL } from "utils/UrlList";
import { assertNotEmpty } from "utils/assertion";

export type IDeleteAdmin = {
  adminId: string;
};

const deleteAdmin = async ({ adminId }: IDeleteAdmin): Promise<void> => {
  try {
    assertNotEmpty(adminId);
    await ApiHelper.apiClient.delete(
      `${API_BASE_URL}/api/v1/administration/users/${adminId}`,
      ApiHelper.getHeaders()
    );
    return;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default deleteAdmin;
