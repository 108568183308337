import addProduct from "./addProduct";
import deleteProduct from "./deleteProduct";
import getProducts from "./getProducts";
import updateProduct from "./updateProduct";

const productApi = {
  addProduct,
  deleteProduct,
  getProducts,
  updateProduct,
};

export default productApi;
