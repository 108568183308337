import AuditsList from "./AuditsList";
import { ContentBody } from "components/core/Styled/Layouts";
import NavBar from "components/layouts/Navbar";

const AuditsListPage = () => {
  return (
    <NavBar
      title="Audit"
      showListCustomers
      showEnvironmentDisplay
      showTitle
      showStatus
    >
      <ContentBody>
        <AuditsList />
      </ContentBody>
    </NavBar>
  );
};

export default AuditsListPage;
