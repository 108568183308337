import { routesList } from "./routesList";
import { IRouteInfo } from "utils/routesList";
import { RiFolderLockLine } from "react-icons/ri";
import { IoIosLogOut, IoMdPaper } from "react-icons/io";
import { FaHome } from "react-icons/fa";
import { BsGraphUp } from "react-icons/bs";
import { TbReportSearch } from "react-icons/tb";
import { ImUsers } from "react-icons/im";
import { HiUserGroup } from "react-icons/hi";
import { FiLink } from "react-icons/fi";
import { PiFolderSimpleUser, PiUserRectangleFill } from "react-icons/pi";
import { IoBusinessOutline, IoList } from "react-icons/io5";
import {
  HiOutlineChatBubbleBottomCenter,
  HiOutlineServer,
} from "react-icons/hi2";
import { AiOutlineUnlock } from "react-icons/ai";

export type IMenuInfo = Omit<IRouteInfo, "routes"> & {
  name: string;
  patterns: string[];
  Icon: typeof FaHome;
  testID: string;
};

export const menuList = {
  // analytics
  analytics: {
    name: "Dashboard",
    Icon: BsGraphUp,
    testID: "analytics-id",
    patterns: Object.values(routesList.analytics.routes),
    ...routesList.analytics,
  },

  reports: {
    name: "Reports",
    Icon: TbReportSearch,
    testID: "reports-id",
    patterns: Object.values(routesList.reports.routes),
    ...routesList.reports,
  },

  audits: {
    name: "Audit",
    Icon: PiFolderSimpleUser,
    testID: "audit-id",
    patterns: Object.values(routesList.audits.routes),
    ...routesList.audits,
  },

  // settings
  profile: {
    name: "My Profile",
    Icon: PiUserRectangleFill,
    testID: "profile-id",
    patterns: Object.values(routesList.profile.routes),
    ...routesList.profile,
  },

  company: {
    name: "Company",
    Icon: IoBusinessOutline,
    testID: "company-id",
    patterns: Object.values(routesList.company.routes),
    ...routesList.company,
  },

  linkApp: {
    name: "Link App",
    Icon: FiLink,
    testID: "linkApp-id",
    patterns: Object.values(routesList.linkApp.routes),
    ...routesList.linkApp,
  },

  users: {
    name: "User Access",
    Icon: ImUsers,
    testID: "users-id",
    patterns: Object.values(routesList.users.routes),
    ...routesList.users,
  },

  // Development
  credentials: {
    name: "Credentials",
    Icon: RiFolderLockLine,
    testID: "credentials-id",
    patterns: Object.values(routesList.credentials.routes),
    ...routesList.credentials,
  },

  documentation: {
    name: "Documentation",
    Icon: IoMdPaper,
    testID: "documentation-id",
    patterns: Object.values(routesList.documentation.routes) as string[],
    ...routesList.documentation,
  },

  status: {
    name: "Status",
    Icon: IoList,
    testID: "status-id",
    patterns: Object.values(routesList.status.routes) as string[],
    ...routesList.status,
  },
  support: {
    name: "Support",
    Icon: HiOutlineChatBubbleBottomCenter,
    testID: "support-id",
    patterns: Object.values(routesList.support.routes) as string[],
    ...routesList.support,
  },

  noCodeIntegration: {
    name: "No-code KYC",
    Icon: HiOutlineChatBubbleBottomCenter,
    testID: "noCodeIntegration-id",
    patterns: Object.values(routesList.noCodeIntegration.routes),
    ...routesList.noCodeIntegration,
  },
  cors: {
    name: "Cors",
    Icon: HiOutlineServer,
    testID: "cors-id",
    patterns: Object.values(routesList.cors.routes),
    ...routesList.cors,
  },

  // Administration
  customers: {
    name: "Customers",
    Icon: HiUserGroup,
    testID: "customers-id",
    patterns: Object.values(routesList.customers.routes),
    ...routesList.customers,
  },
  admins: {
    name: "Admins",
    Icon: AiOutlineUnlock,
    testID: "admins-id",
    patterns: Object.values(routesList.admins.routes),
    ...routesList.admins,
  },

  // auth
  logout: {
    name: "Log Out",
    Icon: IoIosLogOut,
    testID: "logout-id",
    patterns: Object.values(routesList.logout.routes) as string[],
    ...routesList.logout,
  },
};

export const menuListInfo: IMenuInfo[] = Object.values(menuList);
