import UsersListPage from "./UsersListPage";
import UserCreatePage from "./UserCreatePage";
import UserUpdatePage from "./UserUpdatePage";
import { routesList } from "utils/routesList";
import ProtectedRoutes from "routes/ProtectedRoutes";
import { RouteObject } from "react-router-dom";

export const usersRoutes: RouteObject = {
  element: (
    <ProtectedRoutes
      requiredCustomer={routesList.users.requiredCustomer}
      allowedRoles={routesList.users.allowedRoles}
    />
  ),
  children: [
    { path: routesList.users.routes.main, element: <UsersListPage /> },
    { path: routesList.users.routes.create, element: <UserCreatePage /> },
    { path: routesList.users.routes.update, element: <UserUpdatePage /> },
  ],
};
