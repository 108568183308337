import type { ApiErrorCodeType } from "./ApiErrorCode";

class ApiError extends Error {
  public axiosError: unknown;
  public code: ApiErrorCodeType;

  constructor(axiosError: unknown, code: ApiErrorCodeType, message?: string) {
    super(message || code.valueOf());
    this.code = code;
    this.axiosError = axiosError;
    Object.setPrototypeOf(this, ApiError.prototype);
  }
}

export const isApiError = (error: unknown): error is ApiError => {
  return error instanceof ApiError;
};

export default ApiError;
