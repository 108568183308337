import { ImageLoader, Img } from "components/core/Styled/Controls";
import { IFile, IFileID, isFileId } from "common/types/api/common";
import { useGetFileQuery } from "services/query/fileHelper";
import { useSelectCustomer } from "common/hooks/session/useSelectCustomer";
import Loader from "components/core/Loaders/Loader";

type IProps = {
  className?: string;
};

type IPropsImageSrc = IProps & {
  src?: IFile;
};

export const ImageSrc = ({ src, className }: IPropsImageSrc) => {
  return <Img src={src} className={className} loading="lazy" />;
};

export const IconSrc = ({ src, className }: IPropsImageSrc) => {
  return <Img src={src} className={className} />;
};

type IPropsImageById = IProps & {
  id: IFileID;
  type?: "face" | "document";
};

const ImageById: React.FC<IPropsImageById> = ({
  id: fileId,
  className,
  type,
}) => {
  const { customerId } = useSelectCustomer();
  const imageUrl = useGetFileQuery(customerId, fileId);
  if (imageUrl.isLoading)
    return (
      <ImageLoader type={type}>
        <Loader />
      </ImageLoader>
    );

  return (
    <a href={imageUrl.data} download={fileId}>
      <ImageSrc src={imageUrl.data} className={className} />
    </a>
  );
};

type IPropsImage = IProps & {
  info: IFileID | IFile;
  type?: "face" | "document";
};

export const Image = ({ info, ...rest }: IPropsImage) => {
  if (!info) return null;
  if (isFileId(info)) return <ImageById id={info} {...rest} />;

  return <ImageSrc src={`data:image/jpeg;base64,${info}`} {...rest} />;
};

export default Image;
