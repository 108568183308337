import { UserRoles } from "common/types/api/roles";

export const authorize = [
  UserRoles.ROLE_CUSTOMER_OWNER,
  UserRoles.ROLE_CUSTOMER_ADMIN,
  UserRoles.ROLE_CUSTOMER_USER,
];

import ApiHelper from "api/ApiHelper";
import { API_BASE_URL } from "utils/UrlList";
import { IAuditInfo } from "common/types/api/audit";
import { assertNotEmpty } from "utils/assertion";
import { WithCustomerInfo } from "common/types/api/customer";

export type IGetAuditById = WithCustomerInfo & {
  sessionId: string;
};

export type IGetAuditByIdResponse = Pick<
  IAuditInfo,
  | "name"
  | "sessionId"
  | "lastUpdate"
  | "clientId"
  | "customerId"
  | "documentId"
  | "status"
  | "events"
  | "source"
>;

const getAuditById = async ({
  customerId,
  sessionId,
}: IGetAuditById): Promise<IGetAuditByIdResponse> => {
  try {
    assertNotEmpty(customerId, sessionId);
    const res = await ApiHelper.apiClient.get<IGetAuditByIdResponse>(
      `${API_BASE_URL}/api/v1/customers/${customerId}/auditing/${sessionId}`,
      ApiHelper.getHeaders()
    );
    const data = res.data;
    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default getAuditById;
