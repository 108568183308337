import createConfig from "./createConfig";
import deleteConfigById from "./deleteConfigById";
import getConfigById from "./getConfigById";
import getSdkCustomerConfig from "./getSdkCustomerConfig";
import searchConfig from "./searchConfig";
import updateConfig from "./updateConfig";

const hostedKycApi = {
  createConfig,
  deleteConfigById,
  getConfigById,
  getSdkCustomerConfig,
  searchConfig,
  updateConfig,
};

export default hostedKycApi;
