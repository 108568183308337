import { icons } from "assets/images";
import { useContextWorkflow } from "common/contexts/workflowContext";
import { CONFIG_DEFAULT_VALUES } from "common/types/api/hostedKyc";
import { Container, Row, Divider } from "components/core/Styled/Layouts";
import { Heading3, Heading5 } from "components/core/Styled/Texts";
import Button from "components/reusable/Button";
import Checkbox from "components/reusable/Checkbox";
import NodeCard from "components/Workflow/NodeCard";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";

type IProps = {};

const FaceNode = ({}: IProps) => {
  const {
    onChangeFaceConfig: onChange,
    onboardingWorkflowConf,
    sdkCustomerConfig,
  } = useContextWorkflow();

  const conf = useMemo(
    () => onboardingWorkflowConf?.faceConfig,
    [onboardingWorkflowConf?.faceConfig]
  );

  const maxAttempts = useMemo(
    () => conf?.maxAttempts ?? CONFIG_DEFAULT_VALUES.maxAttempts,
    [conf?.maxAttempts]
  );

  const onIncrement = useCallback(() => {
    if (maxAttempts >= CONFIG_DEFAULT_VALUES.maxAttemptsMaxValue) return;
    onChange({ maxAttempts: maxAttempts + 1 });
  }, [maxAttempts, onChange]);

  const onDecrement = useCallback(() => {
    if (maxAttempts <= CONFIG_DEFAULT_VALUES.maxAttemptsMinValue) return;
    onChange({ maxAttempts: maxAttempts - 1 });
  }, [maxAttempts, onChange]);

  return (
    <Wrapper>
      <NodeCard
        title="Face Authentication"
        subTitle="User selfie must match face image from ID or Registry."
        icon={icons.faceCheck}
      />
      <Divider />
      <ConfigurationStyled>
        <Name>Configurations</Name>
        {/* <Checkbox
          onChange={() =>
            onChange({ enrollFace: !(conf?.enrollFace ?? CONFIG_DEFAULT_VALUES.enrollFace) })
          }
          checked={conf?.enrollFace ?? CONFIG_DEFAULT_VALUES.enrollFace}
          label="Enroll Face"
        /> */}
        {sdkCustomerConfig?.oneToNFaceVerificationAllowed && (
          <Checkbox
            onChange={() =>
              onChange({
                oneToNFaceVerification: !(
                  conf?.oneToNFaceVerification ??
                  CONFIG_DEFAULT_VALUES.oneToNFaceVerification
                ),
              })
            }
            checked={
              conf?.oneToNFaceVerification ??
              CONFIG_DEFAULT_VALUES.oneToNFaceVerification
            }
            label="Enable 1:N Face Verification"
          />
        )}
        <SubTitle>Maximal Attempts </SubTitle>
        <Incrementor>
          <StyledButton onClick={onDecrement}>
            <Heading3>-</Heading3>
          </StyledButton>
          <IncrementNumber>{maxAttempts}</IncrementNumber>
          <StyledButton increment onClick={onIncrement}>
            <Heading3>+</Heading3>
          </StyledButton>
        </Incrementor>
      </ConfigurationStyled>
    </Wrapper>
  );
};

export default FaceNode;

const Wrapper = styled(Container)`
  width: 100%;
`;

const ConfigurationStyled = styled(Container)`
  width: 100%;
  align-items: flex-start;
  padding: 0 1.5em;
`;

const Name = styled(Heading3)`
  color: ${(props) => props.theme.color.black};
  padding: 1em 0 0.6em 0;
`;

const SubTitle = styled(Heading5)`
  color: ${(props) => props.theme.color.secondary2};
  padding: 1.2em 0 0.6em 0;
`;

const IncrementNumber = styled(Heading3)`
  color: ${(props) => props.theme.color.black};
`;

const Incrementor = styled(Row)`
  width: 6.5em;
  height: 2.125em;
  background-color: ${(props) => props.theme.background.main};
  border: ${(props) => props.theme.border.secondary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  padding: 0.5em 1em;
  margin: 0.5em 0;
  justify-content: space-between;
`;

const StyledButton = styled(Button)<{ increment?: boolean }>`
  border: ${(props) =>
    props.increment
      ? props.theme.border.primary
      : props.theme.border.secondaryGrey};

  color: ${(props) =>
    props.increment ? props.theme.color.main : props.theme.color.grey};
  border-radius: 50%;
  width: 1.125em;
  height: 1.125em;
  padding: 0;
  margin: 0;
  line-height: 0;
`;
