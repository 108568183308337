import unlinkDevice from "./unlinkDevice";
import getLinkedDevices from "./getLinkedDevices";
import generateQR from "./generateQR";

const linkedDevicesApi = {
  unlinkDevice,
  getLinkedDevices,
  generateQR,
};

export default linkedDevicesApi;
