import { Container, Row } from "components/core/Styled/Layouts";
import { Heading3 } from "components/core/Styled/Texts";
import React, { PropsWithChildren } from "react";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import Toggle from "react-toggle";
import styled, { useTheme } from "styled-components";
import "assets/styles/toggle.css";

type IProps = {
  label?: string;
  className?: string;
  toggle?: boolean;
  withIcon?: boolean;
  onChange?: () => void | ((target: string, value: boolean) => void);
  readOnly?: boolean;
};

const ToggleBloc = ({
  label,
  toggle,
  withIcon = false,
  onChange,
  children,
  className,
  readOnly,
}: PropsWithChildren<IProps>) => {
  const theme = useTheme();

  return (
    <Wrapper className={className}>
      <StyledRow width="100%" justifyContent="space-between">
        <Row>
          <ToggleStyled
            checked={toggle}
            icons={false}
            onChange={onChange}
            readOnly={readOnly}
          />
          <Title>{label}</Title>
        </Row>
        {withIcon && (
          <>
            {toggle ? (
              <RiArrowDownSLine color={theme.color.main} />
            ) : (
              <RiArrowUpSLine color={theme.color.main} />
            )}
          </>
        )}
      </StyledRow>
      {toggle && <>{children}</>}
    </Wrapper>
  );
};

export default ToggleBloc;

const Wrapper = styled(Container)`
  width: 26.25em;
  min-height: 2.1875em;
  color: ${(props) => props.theme.color.primary};
`;

const StyledRow = styled(Row)`
  padding: 1em 0;
`;

const ToggleStyled = styled(Toggle)`
  .custom-classname.react-toggle--checked {
    .react-toggle-track {
      background-color: red;
    }
  }
`;

const Title = styled(Heading3)`
  padding-left: 1.125em;
  font-weight: 500;
  color: ${(props) => props.theme.color.main};
`;
