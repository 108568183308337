import CompanyPage from "./CompanyPage";
import { routesList } from "utils/routesList";
import ProtectedRoutes from "routes/ProtectedRoutes";
import { RouteObject } from "react-router-dom";

export const companyRoutes: RouteObject = {
  element: (
    <ProtectedRoutes
      allowedRoles={routesList.company.allowedRoles}
      requiredCustomer={routesList.company.requiredCustomer}
    />
  ),
  children: [
    { path: routesList.company.routes.main, element: <CompanyPage /> },
  ],
};
