import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // internal
  UserRoles.ROLE_UQUDO_SALES,
];

import ApiHelper from "api/ApiHelper";
import { ICustomer } from "common/types/api/customer";
import { API_BASE_URL } from "utils/UrlList";

export type ICreateCustomer = Pick<ICustomer, "hubspotCompanyId">;

export type ICreateCustomerResponse = Pick<
  ICustomer,
  "id" | "companyName" | "companyNameAlias" | "clientId"
>;

const createCustomer = async ({
  ...rest
}: ICreateCustomer): Promise<ICreateCustomerResponse> => {
  try {
    const res = await ApiHelper.apiClient.post<ICreateCustomerResponse>(
      `${API_BASE_URL}/api/v1/customers`,
      rest,
      ApiHelper.getHeaders()
    );

    const data = res.data;
    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default createCustomer;
