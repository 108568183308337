import Button from "components/reusable/Button";
import { Heading3 } from "components/core/Styled/Texts";
import React, { PropsWithChildren } from "react";
import styled from "styled-components";

export type IProps = {
  onClick?: () => void;
  title?: string;
};

const CustomButton = ({ onClick, title }: PropsWithChildren<IProps>) => {
  return (
    <ButtonStyled onClick={onClick}>
      <Heading3>{title}</Heading3>
    </ButtonStyled>
  );
};

const ButtonStyled = styled(Button)`
  border: ${(props) => props.theme.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: auto;
  min-width: 7.1875em;
  min-height: 2.1875em;
`;

export default CustomButton;
