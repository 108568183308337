import { TLoadingStatus, LoadingStatus } from "common/types/redux/state";
import { useCallback, useEffect, useState } from "react";

function readFilePromise(
  file: File,
  onComplete: (str: string) => void,
  onError: (err: unknown) => void
) {
  try {
    const reader = new FileReader();
    reader.addEventListener("load", (event: ProgressEvent<FileReader>) => {
      try {
        let result = event?.target?.result;
        if (!result) return;
        result = result.toString();
        onComplete(result);
      } catch (_error) {}
    });

    reader.readAsDataURL(file);
  } catch (error) {
    onError(error);
  }
}

function readFile(file: File) {
  return new Promise((resolve: (str: string) => void, reject) => {
    readFilePromise(file, resolve, reject);
  });
}

function useFile(
  file: File | undefined
): [string | undefined, boolean, Error | undefined] {
  const [status, setStatus] = useState<TLoadingStatus>(LoadingStatus.IDLE);
  const [error, setError] = useState<Error>();
  const [result, setResult] = useState<string>();

  const call = useCallback(async (file: File) => {
    try {
      setStatus(LoadingStatus.PENDING);
      const res = await readFile(file);
      setResult(res);
    } catch (err) {
      setStatus(LoadingStatus.REJECTED);
      setError(err as Error);
    }
  }, []);

  useEffect(() => {
    if (!file) return;
    void call(file);
  }, [call, file]);

  return [result, status === LoadingStatus.PENDING, error];
}

export default useFile;
