import MenuGroup, { IMenuGroup } from "./MenuGroup";
import { Container, Divider } from "components/core/Styled/Layouts";
import Logout from "components/auth/Logout";
import { menuList } from "utils/menuList";
import styled from "styled-components";
import { useCurrentRole } from "common/hooks/session/useCurrentRole";

const groups: IMenuGroup[] = [
  {
    name: "ANALYTICS",
    list: [
      { type: "item", info: menuList.analytics },
      { type: "item", info: menuList.reports },
      { type: "item", info: menuList.audits },
    ],
  },
  {
    name: "SETTINGS",
    list: [
      { type: "item", info: menuList.profile },
      { type: "item", info: menuList.company },
      { type: "item", info: menuList.linkApp },
      { type: "item", info: menuList.users },
    ],
  },
  {
    name: "DEVELOPMENT",
    list: [
      { type: "item", info: menuList.credentials },
      { type: "item", info: menuList.noCodeIntegration },
      { type: "item", info: menuList.cors },
      { type: "externalLink", info: menuList.documentation },
      { type: "externalLink", info: menuList.status },
      { type: "externalLink", info: menuList.support },
    ],
  },
  {
    name: "ADMINISTRATION",
    list: [
      { type: "item", info: menuList.customers },
      { type: "item", info: menuList.admins },
    ],
  },
];

const Menu = () => {
  const { currentRole } = useCurrentRole();

  const filterMenuList = (list: IMenuGroup["list"]) => {
    return list.filter(
      (item) =>
        !item.info.allowedRoles || item.info.allowedRoles.includes(currentRole)
    );
  };

  return (
    <MenuContainer>
      <PrimarySection>
        {groups.map(({ name, list }) => (
          <MenuGroup key={name} name={name} list={filterMenuList(list)} />
        ))}
      </PrimarySection>

      <FooterContainer>
        <Logout info={menuList.logout} />
      </FooterContainer>
    </MenuContainer>
  );
};

export default Menu;

const MenuContainer = styled(Container)`
  flex: 1;
  align-items: stretch;
  justify-content: space-between;
  padding: 0;
  min-width: 100%;
  border-top: ${(props) => props.theme.border.main};

  & > ${Divider} {
    margin: 1.25em 0;
  }
`;

const PrimarySection = styled(Container)`
  justify-content: flex-start;
  align-items: stretch;
  cursor: pointer;
`;

const FooterContainer = styled(Container)`
  align-items: stretch;
`;
