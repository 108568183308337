const ApiErrorCode = {
  REQUEST_ERROR: "REQUEST_ERROR",
  RESPONSE_ERROR: "RESPONSE_ERROR",
  UNEXPECTED_ERROR: "UNEXPECTED_ERROR",
} as const;

export type ApiErrorCodeType = keyof typeof ApiErrorCode;

export const keyApiErrorCode = Object.keys(ApiErrorCode);

export const isApiErrorCode = (code: string): code is ApiErrorCodeType => {
  return keyApiErrorCode.includes(code);
};

export default ApiErrorCode;
