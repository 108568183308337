import { authRoles } from "common/types/api/roles";

export const authorize = authRoles;

import ApiHelper from "api/ApiHelper";
import { API_BASE_URL } from "utils/UrlList";

export type IDeleteUserAccount = {
  customerId?: string;
};

const deleteUserAccount = async ({
  customerId,
}: IDeleteUserAccount): Promise<void> => {
  try {
    await ApiHelper.apiClient.delete(
      `${API_BASE_URL}/api/v1/profile`,
      ApiHelper.getHeaders({
        params: {
          customerId,
        },
      })
    );
    return;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default deleteUserAccount;
