import GlobalStyle from "./GlobalStyle";
import primaryTheme from "./primaryTheme";
import { ThemeProvider as ThemeProviderStyled } from "styled-components";
import { PropsWithChildren } from "react";

export const ThemeProvider = ({ children }: PropsWithChildren) => {
  return (
    <ThemeProviderStyled theme={primaryTheme}>
      <GlobalStyle />
      {children}
    </ThemeProviderStyled>
  );
};
