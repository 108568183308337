import { createBrowserHistory } from "history";
import type {
  History as IHistoryBrowser,
  Action as IHistoryBrowserAction,
  Location as IHistoryBrowserLocation,
} from "history";

const historyBrowser: IHistoryBrowser = createBrowserHistory();

export const goToPage = (pathname: string) => {
  historyBrowser.push(pathname);
};

export { createBrowserHistory, historyBrowser as history };

export default historyBrowser;

export type { IHistoryBrowser, IHistoryBrowserAction, IHistoryBrowserLocation };
