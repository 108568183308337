import { useAppMutation } from "./hooks";
import {
  IFilterSort,
  IGetPagination,
  IListPagination,
  IPagination,
} from "common/types/api/common";
import { contact2Page, getMessageError } from "utils/helpers";
import { ParameterFunction, RemoveAwaitType } from "common/types/common";
import { useAppSelector } from "services/redux/hooks";
import { RootState } from "services/redux/store";
import { AppOperationError } from "utils/AppOperationError";
import { isValidSearch } from "utils/assertion";
import { useCallback, useEffect, useMemo, useState } from "react";
import { UseMutationResult } from "@tanstack/react-query";
import { TTableTypes } from "common/types/api/preferences";
import { usePageSetting } from "common/hooks/settings/useSetting";

export type IParmPagination = IGetPagination;

type IParams<R, T> = {
  defaultValues: T;
  ifCondition?: (values: IParmPagination & T) => boolean;
  sizeOfPage: number | undefined;
  getApi: (arg: IParmPagination & T) => Promise<IListPagination<R>>;
  selectFilterSort: (state: RootState) => IFilterSort;
  key?: string;
  type: TTableTypes;
};

const ifParamFilterSortCondition = (params: IFilterSort) => {
  if (!isValidSearch(params.search)) return false;
  return true;
};

const defaultIfCondition = <T>(_values: IParmPagination & T) => true;

export type IReturnUsePagination<R, T> = {
  hasMore: boolean;
  allData: IListPagination<R> | undefined;
  query: UseMutationResult<
    IListPagination<R>,
    AppOperationError,
    IPagination & T,
    unknown
  >;
  refresh: () => void;
  isLoading: boolean;
  isEmpty: boolean | undefined;
  pageSetting: ReturnType<typeof usePageSetting>;
  refetch: () => void;
};

export const usePagination = <R, T>({
  getApi,
  selectFilterSort,
  defaultValues,
  ifCondition = defaultIfCondition,
  key,
  type,
}: IParams<R, T>): IReturnUsePagination<R, T> => {
  type IReturn = RemoveAwaitType<ReturnType<typeof getApi>>;
  type IParam = ParameterFunction<typeof getApi>;

  const paramFilterSort = useAppSelector(selectFilterSort);
  const pageSetting = usePageSetting(type);

  const getPageInfo = useMemo(() => {
    return {
      start: pageSetting.pageNum * pageSetting.pageSize,
      limit: pageSetting.pageSize,
    };
  }, [pageSetting.pageNum, pageSetting.pageSize]);

  type ILocalType = IReturn & { search?: string };
  const [allData, setAllData] = useState<ILocalType | undefined>();

  const queryInfo = useAppMutation(getApi, ["usePagination", key]);

  const { isLoading, mutateAsync } = queryInfo;

  const hasMore = useMemo(() => {
    return allData === undefined || allData.pagination.size < allData.total;
  }, [allData]);

  const query = useCallback(
    async (args: IParam) => {
      if (!ifCondition(args)) return;
      if (!ifParamFilterSortCondition(args)) return;

      try {
        const res = await mutateAsync(args);

        setAllData((oldData) => {
          return contact2Page(oldData, res, args.search);
        });
      } catch (error) {
        console.warn(getMessageError(error));
      }
    },
    [mutateAsync, ifCondition]
  );

  const refresh = useCallback(() => {
    setAllData(undefined);
    query({
      ...getPageInfo,
      ...paramFilterSort,
      ...defaultValues,
    });
  }, [query, getPageInfo, paramFilterSort, defaultValues]);

  useEffect(() => {
    if (!ifParamFilterSortCondition(paramFilterSort)) return;

    refresh();
  }, [paramFilterSort, refresh]);

  const isEmpty = useMemo(() => {
    return allData && allData.total === 0;
  }, [allData]);

  return {
    refresh,
    hasMore,
    allData,
    query: queryInfo,
    isLoading,
    isEmpty,
    pageSetting,
    refetch: refresh,
  };
};
