import IconContainer from "components/core/IconContainer";
import { Row } from "components/core/Styled/Layouts";
import { Heading3 } from "components/core/Styled/Texts";
import { clickBehaviorCss } from "components/core/Styled/AtomicCss";
import { IMenuInfo } from "utils/menuList";
import { useMemo } from "react";
import styled from "styled-components";

export type IProps = {
  info: IMenuInfo;
  selected?: boolean;
  onClick?: () => void;
};

const ListItem = ({ info, selected, onClick }: IProps) => {
  const Icon = useMemo(() => info.Icon, [info.Icon]);

  return (
    <Wrapper data-testid={info.testID} selected={selected} onClick={onClick}>
      <IconContainer selected={selected}>
        <Icon />
      </IconContainer>
      <Heading3>{info.name}</Heading3>
    </Wrapper>
  );
};

export default ListItem;

const Wrapper = styled(Row)<{ selected?: boolean }>`
  ${clickBehaviorCss};
  justify-content: flex-start;
  color: ${(props) => props.theme.color.primary};
  margin: 0;
  padding: 0.75em 0.9em;

  &:hover,
  &:focus {
    color: ${(props) => props.theme.color.main};
  }

  & > ${Heading3} {
    text-align: start;
    margin: 0;
    margin-left: 0.625em;
  }

  ${(props) =>
    props.selected &&
    `
        color: ${props.theme.color.main};
    `};
`;
