import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // internal
  UserRoles.ROLE_UQUDO_SALES,
];

import ApiHelper from "api/ApiHelper";
import { assertNotEmpty } from "utils/assertion";
import { API_BASE_URL } from "utils/UrlList";
import { WithCustomerInfo } from "common/types/api/customer";

export type IEnableCustomer = WithCustomerInfo;

export type IEnableCustomerResponse = void;

const enableCustomer = async ({
  customerId,
}: IEnableCustomer): Promise<IEnableCustomerResponse> => {
  try {
    assertNotEmpty(customerId);
    const res = await ApiHelper.apiClient.patch<IEnableCustomerResponse>(
      `${API_BASE_URL}/api/v1/customers/${customerId}/enable`,
      {},
      ApiHelper.getHeaders()
    );

    const data = res.data;
    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default enableCustomer;
