import LoadingPage from "./core/Loaders/LoadingPage";
import {
  EXIT_TIME_UNEXPECTED_ERROR,
  MODE_DEBUG,
  MODE_PROD,
} from "utils/constants";
import { navigateToPage } from "utils/navigateToPage";
import { ErrorSaved } from "utils/Storage";
import { REDIRECTION } from "utils/UrlList";
import { PropsWithChildren, useCallback, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { customToast } from "utils/customToast";

export type IDisplayErrorProps = {
  error: Error;
};

const DisplayError = ({ error }: IDisplayErrorProps) => {
  return (
    <details style={{ whiteSpace: "pre-wrap" }}>
      <pre>{error.message}</pre>
      <br />
      {error.stack}
    </details>
  );
};

export type IErrorFallbackProps = {
  error: Error;
  resetErrorBoundary: () => void;
};

const ErrorFallback = ({ error, resetErrorBoundary }: IErrorFallbackProps) => {
  useEffect(() => {
    if (MODE_PROD) {
      resetErrorBoundary();
    }
  }, [resetErrorBoundary]);

  useEffect(() => {
    if (MODE_PROD) return;
    if (MODE_DEBUG) console.error("error : ", error);
    setTimeout(resetErrorBoundary, EXIT_TIME_UNEXPECTED_ERROR);
  }, [error, resetErrorBoundary]);

  if (MODE_PROD) return <LoadingPage />;

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      {MODE_DEBUG && <DisplayError error={error} />}
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

const AppErrorBoundary = ({ children }: PropsWithChildren) => {
  const resetError = useCallback(() => {
    ErrorSaved.set("unexpected error");
    navigateToPage(REDIRECTION.successfulAuthentication);
  }, []);

  useEffect(() => {
    if (ErrorSaved.get()) {
      customToast.error(ErrorSaved.get());
      ErrorSaved.clear();
    }
  }, []);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={resetError}>
      {children}
    </ErrorBoundary>
  );
};

export default AppErrorBoundary;
