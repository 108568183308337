import AppErrorCode from "./AppErrorCode";
import AppOperationError from "./AppOperationError";

export const isAppOperationError = (
  error: unknown
): error is AppOperationError => {
  return error instanceof AppOperationError;
};

export const toAppOperationError = (error: unknown): AppOperationError => {
  if (isAppOperationError(error)) return error;
  return new AppOperationError(AppErrorCode.UNEXPECTED_ERROR);
};

export { AppOperationError, AppErrorCode };
