import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // customers
  UserRoles.ROLE_CUSTOMER_ADMIN,
  UserRoles.ROLE_CUSTOMER_OWNER,
  UserRoles.ROLE_CUSTOMER_USER,
];

import ApiHelper from "api/ApiHelper";
import { IFile } from "common/types/api/common";
import { assertNotEmpty } from "utils/assertion";
import { API_BASE_URL } from "utils/UrlList";
import { WithCustomerInfo } from "common/types/api/customer";

export type IGetFileById = WithCustomerInfo & {
  fileId: string;
};

export type IGetFileByIdResponse = IFile;

export const getFileById = async ({
  customerId,
  fileId,
}: IGetFileById): Promise<IFile> => {
  try {
    assertNotEmpty(customerId, fileId);
    const res = await ApiHelper.getFile(
      `${API_BASE_URL}/api/v1/customers/${customerId}/file/${fileId}`,
      ApiHelper.getHeaders()
    );
    const data = res.data;
    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};
