import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // internal
  UserRoles.ROLE_UQUDO_SALES,
];

import ApiHelper from "api/ApiHelper";
import { IProductInfo } from "common/types/api/product";
import { API_BASE_URL } from "utils/UrlList";
import { assertNotEmpty } from "utils/assertion";
import { WithCustomerInfo } from "common/types/api/customer";
import _ from "lodash";

export type IUpdateProduct = WithCustomerInfo & Omit<IProductInfo, "storable">;

export type IUpdateProductResponse = IProductInfo;

const updateProduct = async ({
  customerId,
  productId,
  ...rest
}: IUpdateProduct): Promise<IUpdateProductResponse> => {
  try {
    assertNotEmpty(customerId, productId);
    if (
      !_.isEmpty(rest.configuration) &&
      _.isEmpty(rest.configuration.productType)
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      rest.configuration.productType = rest.productType;
    }
    const res = await ApiHelper.apiClient.patch<IUpdateProductResponse>(
      `${API_BASE_URL}/api/v1/customers/${customerId}/products/${productId}`,
      rest,
      ApiHelper.getHeaders()
    );

    const data = res.data;
    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default updateProduct;
