import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // internal
  UserRoles.ROLE_UQUDO_AUDITOR,
  UserRoles.ROLE_UQUDO_SALES,
  // customers
  UserRoles.ROLE_CUSTOMER_ADMIN,
  UserRoles.ROLE_CUSTOMER_OWNER,
  UserRoles.ROLE_CUSTOMER_DEVELOPER,
];

import ApiHelper from "api/ApiHelper";
import { assertNotEmpty } from "utils/assertion";
import { API_BASE_URL } from "utils/UrlList";
import { IClientSecret } from "common/types/api/credentials";
import { WithCustomerInfo } from "common/types/api/customer";

export type IGetClientSecrets = WithCustomerInfo;

export type IGetClientSecretsResponse = {
  clientSecrets: IClientSecret[];
};

const getClientSecrets = async ({
  customerId,
}: IGetClientSecrets): Promise<IGetClientSecretsResponse> => {
  try {
    assertNotEmpty(customerId);
    const res = await ApiHelper.apiClient.get<IGetClientSecretsResponse>(
      `${API_BASE_URL}/api/v1/customers/${customerId}/credentials/client-secrets`,
      ApiHelper.getHeaders()
    );

    const data = res.data;

    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default getClientSecrets;
