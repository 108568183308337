import {
  IOption,
  IPropsUseSearchableList,
  useSearchableList,
} from "common/hooks/search/useSearchableList";
import { css, useTheme } from "styled-components";
import styled from "styled-components";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { useCallback, useState } from "react";
import { MIN_LENGTH_SEARCH_PATTERN } from "utils/constants";

type IProps<T, V> = IPropsUseSearchableList<T, V> & {
  onChangeSelected?: (newValue: IOption<V>) => void;
  customStyleEnabled?: boolean;
  className?: string;
  showDefaultValue?: boolean;
  selectStyle?: string;
  preFilled?: boolean;
};

const SearchList = <T, V>({
  fun,
  toOption,
  onChangeSelected,
  defaultValue,
  showDefaultValue = false,
  className,
  preFilled = false,
  selectStyle,
  searchIfEmpty = false,
  preSearch = false,
  preSearchValue = "",
  minLengthSearchPattern = MIN_LENGTH_SEARCH_PATTERN,
}: IProps<T, V>) => {
  const { loadOptions, onChange, firstOptions, isHasMore, query, selected } =
    useSearchableList({
      fun,
      toOption,
      defaultValue,
      searchIfEmpty,
      preSearchValue,
      preSearch,
      minLengthSearchPattern,
    });
  const theme = useTheme();
  const [inputValue, setInputValue] = useState<string | undefined>(
    selected?.label
  );
  const [hasStartedTyping] = useState(false);

  const customStyles = {
    control: (provided: Record<string, unknown>) => ({
      ...provided,
      borderColor: "white",
      height: "2em",
      width: selectStyle ? selectStyle : "26.1em",
      borderRadius: theme.borderRadius.primary,
      "&:hover": {
        borderColor: "white",
      },
    }),
  };

  const onChangeHandler = useCallback(
    (newValue: unknown) => {
      onChange(newValue);
      onChangeSelected?.(newValue as IOption<V>);
    },
    [onChange, onChangeSelected]
  );

  const onInputChange = useCallback(
    (query: string, { action }: { action: string }) => {
      if (action !== "set-value") setInputValue(query);
    },
    []
  );

  const noOptionsMessage = useCallback(
    (_obj: { inputValue: string }) => {
      return hasStartedTyping && inputValue?.trim() !== ""
        ? "No results found"
        : "Type to search";
    },
    [hasStartedTyping, inputValue]
  );

  const onInputFocus = useCallback(() => {
    onChange(selected);
    onChangeSelected?.(selected as IOption<V>);
    setInputValue(selected?.label);
  }, [onChange, onChangeSelected, selected]);

  if (!isHasMore) {
    return (
      <SelectStyled
        className={className}
        isLoading={query.isLoading}
        isClearable
        isSearchable
        options={firstOptions}
        onChange={onChangeHandler}
        styles={customStyles}
        inputValue={preFilled ? inputValue : undefined}
        value={showDefaultValue ? selected : undefined}
        onInputChange={preFilled ? onInputChange : undefined}
        onFocus={preFilled ? onInputFocus : undefined}
        blurInputOnSelect
        noOptionsMessage={noOptionsMessage}
      />
    );
  }

  return (
    <AsyncSelectStyled
      onChange={onChangeHandler}
      cacheOptions
      loadOptions={loadOptions}
      defaultOptions={firstOptions}
      styles={customStyles}
      value={showDefaultValue ? selected : undefined}
      inputValue={preFilled ? inputValue : undefined}
      onInputChange={preFilled ? onInputChange : undefined}
      onFocus={preFilled ? onInputFocus : undefined}
      blurInputOnSelect
      noOptionsMessage={noOptionsMessage}
    />
  );
};

const cssSelect = css`
  min-width: 10em;
  border-radius: ${(props) => props.theme.borderRadius.primary};
  color: ${(props) => props.theme.color.primary};
  border: ${(props) => props.theme.border.secondary};
`;

const AsyncSelectStyled = styled(AsyncSelect)`
  ${cssSelect}
`;

const SelectStyled = styled(Select)`
  ${cssSelect}
`;

export default SearchList;
