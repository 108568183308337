import { routesList } from "utils/routesList";
import ProtectedRoutes from "routes/ProtectedRoutes";
import { RouteObject } from "react-router-dom";
import CorsPage from "./CorsPage";

export const corsRoutes: RouteObject = {
  element: (
    <ProtectedRoutes
      allowedRoles={routesList.cors.allowedRoles}
      requiredCustomer={routesList.cors.requiredCustomer}
    />
  ),
  children: [{ path: routesList.cors.routes.main, element: <CorsPage /> }],
};
