import { CustomerSelectedIdLocalStorage } from "utils/Storage";
import getCustomerGlobalInfoById from "api/customerApi/getCustomerGlobalInfoById";
import { ISessionInfo } from "common/types/api/profile";
import { ISelectedCustomerInfo } from "common/types/api/customer";

export type IGetSelectedCustomerFromLocalStorageResponse =
  | ISelectedCustomerInfo
  | undefined;

async function getSelectedCustomerFromLocalStorage(
  profile: ISessionInfo
): Promise<IGetSelectedCustomerFromLocalStorageResponse> {
  try {
    const customerId = CustomerSelectedIdLocalStorage.get();
    if (!customerId) return undefined;

    const isAdmin = profile.adminRoles.length > 0;

    if (!isAdmin) {
      if (
        !profile.customerRoles.some(
          (customer) => customer.customerId === customerId
        )
      ) {
        return undefined;
      }
    }

    const customer = await getCustomerGlobalInfoById({
      customerId,
    });

    return customer;
  } catch (error) {
    return undefined;
  }
}

export default getSelectedCustomerFromLocalStorage;
