import { documentTypes } from "./strings";
import { MODE_TEST } from "./constants";
import { IListPagination, ISource, TInfo } from "common/types/api/common";
import { EmptyType } from "common/types/common";
import { goToPage } from "routes/historyBrowser";
import _ from "lodash";
import { authRolesLabel } from "common/types/api/roles";

const domainRegex =
  /^(?!https?:\/\/)(\*\.)?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;

const IPV4_PATTERN =
  /^(?:\b(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\b\.){3}\b(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\b$/;

export const fqdnRegex =
  /^(https?|http):\/\/([a-zA-Z0-9_-]+\.)*[a-zA-Z]{2,}(:[0-9]+)?(\/\S+)*\.[a-zA-Z]{2,}(:[0-9]+)?(\/\S+)*$/;

export const goToLocalPage = (pathname: string, newTab = false) => {
  const currentPathname = window.location.pathname;
  if (pathname === currentPathname && !newTab) return;
  if (newTab || MODE_TEST) {
    const href = `${window.location.origin}${pathname}`;
    if (newTab) {
      window.open(href, "_blank");
    } else {
      window.location.assign(href);
    }
  } else {
    goToPage(pathname);
  }
};

export const goToAnotherPage = (href: string, newTab = false) => {
  const currentHref = window.location.href;
  if (href === currentHref && !newTab) return;

  if (newTab) {
    window.open(href, "_blank");
  } else {
    window.location.assign(href);
  }
};

export const goToPageLocalOrExtern = (href: string, newTab: boolean) => {
  if (!href.includes("http")) goToLocalPage(href, newTab);
  else goToAnotherPage(href, newTab);
};

export const getRootPathname = (maxLink = 1) => {
  const pathname = window.location.pathname;
  const newPathname = pathname.split("/", maxLink + 1).join("/");
  return newPathname;
};

export const clone = <T>(data: T): T => {
  return JSON.parse(JSON.stringify(data)) as T;
};

export class EnumUtil {
  public static isSomeEnum<T extends Record<string, unknown>>(e: T) {
    const obj = Object.values(e);
    return (token: unknown): token is T[keyof T] =>
      obj.includes(token as T[keyof T]);
  }

  public static key<T extends Record<string, unknown>>(E: T) {
    const obj = new Map<T[keyof T], keyof T>();

    Object.entries(E).forEach(([k, v]) => {
      obj.set(v as T[keyof T], k as keyof T);
    });

    return (e: T[keyof T]): keyof T => {
      const val = e;
      const res = obj.get(val);
      if (!res) throw Error("error type enum");
      return res;
    };
  }
}

export const isError = (error: unknown): error is Error => {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof (error as Record<string, unknown>).message === "string"
  );
};

export const removeEmptyValue = (obj: Record<string, unknown>) => {
  const res = {} as Record<string, unknown>;
  for (const key in obj) {
    if (_.isNumber(obj[key]) || !!obj[key]) {
      res[key] = obj[key];
    }
  }
  return res;
};

export const debounce = _.debounce;

export const contact2Page = <T = unknown>(
  a: (IListPagination<T> & { search?: string }) | undefined,
  b: IListPagination<T> | undefined,
  search = ""
): (IListPagination<T> & { search?: string }) | undefined => {
  const asearch = a?.search ?? "";

  if (b === undefined) return a;

  if (a === undefined || asearch !== search || a.total !== b.total) {
    return {
      ...b,
      search,
    };
  }

  const sa = {
    start: a.pagination.start,
    end: a.pagination.start + a.pagination.size,
  };

  const sb = {
    start: b.pagination.start,
    end: b.pagination.start + b.pagination.size,
  };

  if (sa.end !== sb.start) {
    if (sa.start < sb.start) return a;
    return {
      ...b,
      search,
    };
  }

  const start = Math.min(sa.start, sb.start);
  const end = Math.max(sa.end, sb.end);
  const results = a.results.concat(b.results);

  return {
    total: b.total,
    results,
    pagination: {
      start: start,
      limit: end - start,
      size: results.length,
    },
    search,
  };
};

export const infoToString = (val: TInfo | TInfo[]): string => {
  if (Array.isArray(val)) {
    return val.map(infoToString).join(" ");
  }
  return val?.toString() || `-`;
};

export const fromCamelCaseToString = (val: string): string => {
  const res = val.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1");
  return res
    .split(" ")
    .map((str) => {
      if (str === "Id" || str === "id") return "ID";
      return str;
    })
    .join(" ");
};

export const isNumber = (value: unknown) => {
  return typeof value === "number" && isFinite(value);
};

export const getFromArray = <T>(arr: T[] | T, index = 0) => {
  if (Array.isArray(arr)) return arr[index];
  return arr;
};

export const formatSource = (source: EmptyType<ISource>) => {
  const sdkType = source?.sdkType ?? "Unknown";
  const sdkVersion = source?.sdkVersion ?? "";
  return sdkType + " " + sdkVersion;
};

export const getValueByKey = (object: Record<string, string>, key: string) => {
  return object[key] ?? key;
};

export const fromValueToLabel = (value: EmptyType<string>) => {
  if (!value) return "";
  const documents = documentTypes as Record<string, string>;
  if (documents[value]) return documents[value];
  if (value === "COMPLETED" || value === "NOT_COMPLETED")
    return value.replace("_", " ");

  const roles = authRolesLabel as Record<string, string>;
  if (roles[value]) return roles[value];
  return value;
};

export const randomNumber = (a: number, b: number) => {
  return Math.floor(Math.random() * (b - a + 1) + a);
};

export const maskString = (str: string, last: number) => {
  return (
    String("*").repeat(str.length - last) + str.substring(str.length - last)
  );
};

export const isImageType = (file?: File) => {
  if (!file) return false;
  const fileType = file.type;
  const validTypes = ["image/gif", "image/jpeg", "image/png"];
  if (validTypes.includes(fileType)) {
    return true;
  }
  return false;
};

export const isFileDocumentType = (file?: File) => {
  if (!file) return false;
  const fileType = file.type;
  const validTypes = ["application/pdf"];
  if (validTypes.includes(fileType)) {
    return true;
  }
  return false;
};

export const makeId = (length = 10) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const getMessageError = (error: unknown, defaultMessage = "") => {
  if (error instanceof Error) return error.message;
  return defaultMessage;
};

export const toBoolean = <T>(value?: T | undefined) => {
  return !!value;
};

export const isValidDomain = (domain: string) => {
  return domainRegex.test(domain) || IPV4_PATTERN.test(domain);
};

export const length = <T>(arr: EmptyType<T[]>) => {
  if (!arr) return 0;
  return arr.length;
};

export const getSearchParams = (param: string) => {
  const params = new URLSearchParams(window.location.search);
  const res = params.get(param) || "";
  return res;
};

export const toSearchParams = (obj: Record<string, string | undefined>) => {
  const queryParams = new URLSearchParams(
    obj as Record<string, string>
  ).toString();

  return queryParams;
};

export const waitIf = (cond = true) => {
  while (cond) {
    /* empty */
  }
};

export function refreshPage() {
  window.location.reload();
}
