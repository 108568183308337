import ListItemContainer from "components/Menu/ListItemContainer";
import { useCustomAuth } from "services/auth/useCustomAuth";
import { IMenuInfo } from "utils/menuList";

type IProps = {
  info: IMenuInfo;
};

const Logout = ({ info }: IProps) => {
  const { handleLogout } = useCustomAuth();

  return <ListItemContainer info={info} onClick={handleLogout} />;
};

export default Logout;
