import { IParmPagination, usePagination } from "./usePagination";
import { useAppMutation, useAppQuery } from "./hooks";
import { DEFAULT_LIMIT_PAGINATION } from "utils/constants";
import hostedKycApi from "api/hostedKycApi";
import { useSelectCustomer } from "common/hooks/session/useSelectCustomer";
import { selectFilterSortSetting } from "services/redux/reducers/settingsSlice";
import { TableTypes } from "common/types/api/preferences";
import { ICreateConfig } from "api/hostedKycApi/createConfig";
import { IDeleteConfigById } from "api/hostedKycApi/deleteConfigById";
import { IUpdateConfig } from "api/hostedKycApi/updateConfig";
import { useCallback, useMemo } from "react";

const selectFilterSort = selectFilterSortSetting(TableTypes.HOSTED_KYC_CONFIG);

export const useGetHostedConfigsQuery = (
  sizeOfPage = DEFAULT_LIMIT_PAGINATION
) => {
  const { customerId } = useSelectCustomer();
  const defaultValues = useMemo(
    () => ({
      customerId,
    }),
    [customerId]
  );

  const ifCondition = useCallback(
    (values: IParmPagination & typeof defaultValues) => {
      return !!values.customerId;
    },
    []
  );

  const getApi = useCallback(
    (param: IParmPagination & typeof defaultValues) => {
      return hostedKycApi.searchConfig(param);
    },
    []
  );

  const query = usePagination({
    key: "useGetHostedConfigsQuery",
    sizeOfPage,
    getApi,
    selectFilterSort,
    defaultValues,
    ifCondition,
    type: TableTypes.HOSTED_KYC_CONFIG,
  });

  return query;
};

export const useGetHostedConfigByIdQuery = (configId: string) => {
  const { customerId } = useSelectCustomer();

  const query = useAppQuery(() => {
    return hostedKycApi.getConfigById({
      configId,
      customerId,
    });
  }, ["useGetHostedConfigByIdQuery", configId, customerId]);

  return query;
};

export const useCreateHostedConfigMutation = () => {
  const { customerId } = useSelectCustomer();

  const query = useAppMutation((arg: Omit<ICreateConfig, "customerId">) => {
    return hostedKycApi.createConfig({
      customerId,
      ...arg,
    });
  });

  return query;
};

export const useDeleteHostedConfigByIdMutation = () => {
  const { customerId } = useSelectCustomer();

  const query = useAppMutation((arg: Omit<IDeleteConfigById, "customerId">) => {
    return hostedKycApi.deleteConfigById({
      customerId,
      ...arg,
    });
  });

  return query;
};

export const useUpdateHostedConfigMutation = (configId: string) => {
  const { customerId } = useSelectCustomer();

  const query = useAppMutation(
    (arg: Omit<IUpdateConfig, "customerId" | "configId">) => {
      return hostedKycApi.updateConfig({
        customerId,
        configId,
        ...arg,
      });
    }
  );

  return query;
};

export const useGetSdkCustomerConfigMutation = (configId: string) => {
  const { customerId } = useSelectCustomer();

  const query = useAppMutation(() => {
    return hostedKycApi.getSdkCustomerConfig({
      configId,
      customerId,
    });
  }, ["useGetSdkCustomerConfigMutation", configId, customerId]);

  return query;
};
