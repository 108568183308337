import ReportsList from "./ReportsList";
import { ContentBody } from "components/core/Styled/Layouts";
import NavBar from "components/layouts/Navbar";

const ReportsListPage = () => {
  return (
    <NavBar
      title="Reports"
      showListCustomers
      showEnvironmentDisplay
      showTitle
      showStatus
    >
      <ContentBody>
        <ReportsList />
      </ContentBody>
    </NavBar>
  );
};

export default ReportsListPage;
