import { Container } from "components/core/Styled/Layouts";
import React, { useCallback } from "react";
import styled from "styled-components";
import { useContextEditCustomer } from "common/contexts/editCustomerContext";
import ButtonAction from "components/ButtonAction";
import ConfirmationPopup from "components/Modal/ConfirmationPopup";
import { Heading3, Heading4 } from "components/core/Styled/Texts";
import useShowHide from "common/hooks/helpers/useShowHide";

const CustomerUpdateAction = () => {
  const {
    enabled,
    // enable
    onEnableCustomer,
    enableCustomerLoading,
    // disable
    onDisableCustomer,
    disableCustomerLoading,
    // delete
    onDeleteCustomer,
    deleteCustomerLoading,
    // promote
    onPromoteCustomer,
    promoteCustomerLoading,
  } = useContextEditCustomer();

  const popupDelete = useShowHide();
  const popupEnable = useShowHide();
  const popupDisable = useShowHide();
  const popupPromote = useShowHide();

  const onConfirmDelete = useCallback(() => {
    popupDelete.hide();
    onDeleteCustomer();
  }, [onDeleteCustomer, popupDelete]);

  const onConfirmEnable = useCallback(() => {
    popupEnable.hide();
    onEnableCustomer();
  }, [onEnableCustomer, popupEnable]);

  const onConfirmDisable = useCallback(() => {
    popupDisable.hide();
    onDisableCustomer();
  }, [onDisableCustomer, popupDisable]);

  const onConfirmPromote = useCallback(() => {
    popupPromote.hide();
    onPromoteCustomer();
  }, [onPromoteCustomer, popupPromote]);

  return (
    <Wrapper>
      <>
        <ConfirmationPopup
          text="Are you sure you want to delete this customer ?"
          cancelText="Cancel"
          confirmText="Confirm"
          show={popupDelete.isVisible}
          close={popupDelete.hide}
          onConfirmDelete={onConfirmDelete}
        />
        <ConfirmationPopup
          text="Are you sure you want to enable this customer ?"
          cancelText="Close"
          confirmText="Enable"
          onConfirmDelete={onConfirmEnable}
          show={popupEnable.isVisible}
          close={popupEnable.hide}
        />
        <ConfirmationPopup
          text="Are you sure you want to disable this customer ?"
          cancelText="Close"
          confirmText="Disable"
          onConfirmDelete={onConfirmDisable}
          show={popupDisable.isVisible}
          close={popupDisable.hide}
        />
        <ConfirmationPopup
          title="Promote to Production"
          cancelText="No, Later"
          confirmText="Yes, Promote now"
          onConfirmDelete={onConfirmPromote}
          show={popupPromote.isVisible}
          close={popupPromote.hide}
        >
          <SubTitle popup>Do you want to promote to Production? </SubTitle>
          <Note>
            Note : Nothing will happened if the customer is already in
            production
          </Note>
        </ConfirmationPopup>
      </>
      <ButtonActionStyled
        label="Promote to Production"
        loading={promoteCustomerLoading}
        onClick={popupPromote.show}
      />

      {enabled ? (
        <ButtonActionStyled
          label="Disable"
          loading={disableCustomerLoading}
          onClick={popupDisable.show}
        />
      ) : (
        <ButtonActionStyled
          label="Enable"
          loading={enableCustomerLoading}
          onClick={popupEnable.show}
        />
      )}
      <ButtonActionStyled
        label="Delete"
        loading={deleteCustomerLoading}
        onClick={popupDelete.show}
      />
    </Wrapper>
  );
};

export default CustomerUpdateAction;

const Wrapper = styled(Container)`
  padding: 0 1.875em;
  margin: 1.875em 0;
  align-items: flex-start;
  justify-content: flex-start;
`;

const ButtonActionStyled = styled(ButtonAction)`
  width: 12em;
  margin-top: 1em;
`;

const SubTitle = styled(Heading3)<{ popup?: boolean }>``;

const Note = styled(Heading4)`
  color: ${(props) => props.theme.color.main};
  padding: 0 0 1.5em 0;
  text-transform: none;
`;
