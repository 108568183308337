import {
  IBackgroundCheckInfo,
  IDocument,
  IFaceMatchInfo,
  IVerificationsInfo,
} from "common/types/api/document";
import { IDate, ISource } from "common/types/api/common";
import { EmptyType } from "common/types/common";

export type IReportInfo = {
  name: string;
  sessionId: string;
  date: IDate;
  customerId: string;
  documentId?: EmptyType<string>;
  backgroundCheck: EmptyType<IBackgroundCheckInfo>;
  documents: IDocument[];
  source: EmptyType<ISource>;
  documentsTypes?: EmptyType<string[]>;
};

export const addVerificationsToDocument = (
  report: IReportInfo & {
    verifications: IVerificationsInfo;
  },
  sessionIds?: string[]
) => {
  const { verifications = null, documents, ...rest } = report;
  const newVerifications = !verifications ? [] : verifications;
  const data = {
    documents: documents.map(({ face, ...restDoc }) => {
      let newFace: EmptyType<IFaceMatchInfo> = !face ? {} : face;
      const newVerification = newVerifications.find(
        (ver) => ver.documentType === restDoc.documentType
      );

      if (!face && !newVerification) {
        newFace = null;
      } else {
        newFace = {
          ...newFace,
          verification: newVerification,
          oneToNSessions: sessionIds,
        };
      }

      return {
        face: newFace,
        oneToNSessions: sessionIds,
        ...restDoc,
      };
    }),
    ...rest,
  };
  return data;
};
