import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { PropsWithChildren, useCallback } from "react";
import { CLIENT_ID, AUTH0_DOMAIN } from "./constants";
import { useNavigate } from "react-router-dom";

export function AuthProvider({ children }: PropsWithChildren) {
  const navigate = useNavigate();

  const onRedirectCallback = useCallback(
    (appState?: AppState) => {
      navigate(appState?.returnTo || window.location.pathname);
    },
    [navigate]
  );

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={CLIENT_ID}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
}
