import FaceNode from "./Face/FaceNode";
import BackgroundCheckNode from "./BackgroundCheck/BackgroundCheckNode";
import MandatoryIdNode from "./Scan/MandatoryIdNode";
import OptionalIdNode from "./Scan/OptionalIdNode";
import { images } from "assets/images";
import { Container, Row } from "components/core/Styled/Layouts";
import ConfirmationPopup from "components/Modal/ConfirmationPopup";
import { routesList } from "utils/routesList";
import { useGoTo } from "common/hooks/paths/useGoTo";
import { Heading3 } from "components/core/Styled/Texts";
import Button from "components/reusable/Button";
import { ImageSrc } from "components/reusable/Image";
import IconContainer from "components/core/IconContainer";
import React, {
  Fragment,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { BsArrowDownShort } from "react-icons/bs";
import { IoMdAdd } from "react-icons/io";
import styled, { useTheme } from "styled-components";
import { RiDeleteBin5Line } from "react-icons/ri";
import { customToast } from "utils/customToast";
import { getMessageError } from "utils/helpers";
import { IStopsProps } from "common/contexts/workflowContext/useWorkflowConfigs";
import { HiOutlineCheck } from "react-icons/hi";
import { ISdkCustomerConfig } from "common/types/api/hostedKyc";
import { useContextWorkflow } from "common/contexts/workflowContext";

const EmptyWorkflow = () => {
  return (
    <EmptyStyled>
      <StyledImageSrc src={images.emptyWorkflow} />
      <Heading3>Select a node to see configuration</Heading3>
    </EmptyStyled>
  );
};

type AddStepDisplayProps = {
  stop: IStopsProps;
  displayNotAllowedNotice: (title: string) => void;
  sdkCustomerConfig: ISdkCustomerConfig | undefined;
  onAddStop: (key: IStopsProps["key"]) => void;
  stepScanAdded: boolean | undefined;
};

const AddStepDisplay = ({
  stop,
  displayNotAllowedNotice,
  sdkCustomerConfig,
  onAddStop,
  stepScanAdded,
}: AddStepDisplayProps) => {
  const theme = useTheme();

  const allowed = useMemo(() => {
    if (stop.key === "required" || stop.key === "optional")
      return !!sdkCustomerConfig?.scanAllowed;
    if (stop.key === "faceConfig") return !!sdkCustomerConfig?.faceAllowed;
    if (stop.key === "backgroundCheckConfig")
      return !!sdkCustomerConfig?.bcAllowed;
    return false;
  }, [
    sdkCustomerConfig?.bcAllowed,
    sdkCustomerConfig?.faceAllowed,
    sdkCustomerConfig?.scanAllowed,
    stop.key,
  ]);

  const enabled = useMemo(() => {
    if (stop.key === "required" || stop.key === "optional") return true;
    if (stop.key === "faceConfig") return stepScanAdded;
    if (stop.key === "backgroundCheckConfig") return stepScanAdded;
    return false;
  }, [stepScanAdded, stop.key]);

  const onClick = useCallback(() => {
    if (!allowed) {
      displayNotAllowedNotice(stop.title);
    } else if (enabled) {
      if (!stop.active) onAddStop(stop.key);
    }
  }, [
    allowed,
    displayNotAllowedNotice,
    enabled,
    onAddStop,
    stop.active,
    stop.key,
    stop.title,
  ]);

  const iconBefore = useMemo(() => {
    if (!allowed) {
      return <IoMdAdd color={theme.color.grey} />;
    } else if (enabled) {
      if (!stop.active) return <IoMdAdd color={theme.color.main} />;
      else return <HiOutlineCheck color={theme.color.grey} />;
    } else {
      return <IoMdAdd color={theme.color.grey} />;
    }
  }, [allowed, enabled, stop.active, theme.color.grey, theme.color.main]);

  return (
    <StopButton
      active={!stop.active}
      key={stop.key}
      onClick={onClick}
      iconBefore={iconBefore}
    >
      <Heading3
        color={
          allowed && enabled && !stop.active
            ? theme.color.main
            : theme.color.grey
        }
      >
        {stop.title}
      </Heading3>
    </StopButton>
  );
};

const WorkflowPage = () => {
  const theme = useTheme();
  const goTo = useGoTo();
  const stopsRef = useRef<HTMLDivElement>(null);

  const {
    configId,
    stops,
    activeNode,
    isValidWorkflow,
    saveWorkflow,
    deleteStopByKey,
    onActivateNode,
    sdkCustomerConfig,
    onAddStop,
  } = useContextWorkflow();

  const [confirmDeleteStop, setConfirmDeleteStop] = useState<boolean>(false);
  const [deleteRestriction, setDeleteRestriction] = useState<boolean>(false);
  const [stopToDelete, setStopToDelete] = useState<IStopsProps>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [notAllowedNotice, setNotAllowedNotice] = useState<string>();

  const displayNotAllowedNotice = (title: string) => {
    if (!title) return;
    setNotAllowedNotice(
      `You plan doesn’t include ${title}. Please contact uqudo sale if you want to use the feature.`
    );
  };

  const closeNotAllowedNotice = () => {
    setNotAllowedNotice("");
  };

  const onSaveWorkflow = useCallback(async () => {
    if (!isValidWorkflow()) {
      customToast.error("Please fill the workflow configuration");
      return;
    }

    try {
      await saveWorkflow();
      customToast.success("No-code KYC workflow saved successfully");
      goTo(routesList.noCodeIntegration.routes.update, configId);
    } catch (error) {
      customToast.error(
        getMessageError(error, "No-code KYC workflow save failed")
      );
      console.error(error);
    }
  }, [configId, goTo, isValidWorkflow, saveWorkflow]);

  const handleClickOutside = useCallback(
    (event: MouseEvent | TouchEvent) => {
      const isInside =
        !!stopsRef?.current && stopsRef.current.contains(event.target as Node);
      const isOutside = isOpen && !isInside;

      if (isOpen) {
        event.stopPropagation();
      }

      if (isOutside) {
        setIsOpen(false);
      }

      return false;
    },
    [isOpen]
  );

  const onOpenAddStop: MouseEventHandler<HTMLHeadingElement> = useCallback(
    (event) => {
      setIsOpen((oldValue) => !oldValue);
      event.stopPropagation();
    },
    []
  );

  const onConfirmDeleteStop = useCallback(
    (key: IStopsProps["key"]) => {
      deleteStopByKey(key);
      setConfirmDeleteStop((oldValue) => !oldValue);
    },
    [deleteStopByKey]
  );

  const activeBodyNode = useMemo(() => {
    if (activeNode === "backgroundCheckConfig") {
      return <BackgroundCheckNode />;
    }

    if (activeNode === "faceConfig") {
      return <FaceNode />;
    }

    if (activeNode === "optional") {
      return <OptionalIdNode />;
    }

    if (activeNode === "required") {
      return <MandatoryIdNode />;
    }

    return <EmptyWorkflow />;
  }, [activeNode]);

  const onDeleteStop = useCallback(
    (stop: IStopsProps) => {
      if (
        (stop.key === "optional" || stop.key === "required") &&
        (stops.find((x) => x.key === "backgroundCheckConfig")?.active ===
          true ||
          stops.find((x) => x.key === "faceConfig")?.active === true)
      )
        if (
          (stop.key === "required" &&
            !stops.find((x) => x.key === "optional")?.active) ||
          (stop.key === "optional" &&
            !stops.find((x) => x.key === "required")?.active)
        ) {
          setDeleteRestriction(true);
        }
      setStopToDelete(stop);
      setConfirmDeleteStop((oldValue) => !oldValue);
    },
    [stops]
  );

  const onCloseDeleteStop = useCallback(() => {
    setDeleteRestriction(false);
    setConfirmDeleteStop((oldValue) => !oldValue);
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <Wrapper>
      <EditorArea>
        <OperationStyled>
          <ConfirmationPopup
            text={notAllowedNotice}
            cancelText="Close"
            close={closeNotAllowedNotice}
            singleButton
            show={!!notAllowedNotice}
          />
          {stopToDelete && !deleteRestriction && (
            <ConfirmationPopup
              title={`Remove ${stopToDelete.title}`}
              cancelText="Cancel"
              confirmText="Yes, Remove it"
              close={onCloseDeleteStop}
              onConfirmDelete={() => onConfirmDeleteStop(stopToDelete.key)}
              show={confirmDeleteStop}
            >
              <SubTitle>{`Do you want to Remove ${stopToDelete.title}?`}</SubTitle>
            </ConfirmationPopup>
          )}

          {stopToDelete && deleteRestriction && (
            <ConfirmationPopup
              title={`Remove ${stopToDelete.title}`}
              cancelText="Ok"
              close={onCloseDeleteStop}
              show={confirmDeleteStop}
              singleButton
            >
              <StyledTexts>
                <SubText>You need to remove</SubText>
                {stops
                  .filter(
                    (x) =>
                      x.key !== "required" &&
                      x.key !== "optional" &&
                      x.active === true
                  )
                  .map((stop) => {
                    return <SubDetail key={stop.key}>- {stop.title}</SubDetail>;
                  })}
                <SubText>Before you can remove this step.</SubText>
              </StyledTexts>
            </ConfirmationPopup>
          )}

          <AddStop onClick={onOpenAddStop} iconBefore={<IoMdAdd />}>
            <Heading3>Add Step</Heading3>
            {isOpen && (
              <Stops ref={stopsRef}>
                {stops.map((stop) => (
                  <AddStepDisplay
                    key={stop.key}
                    stop={stop}
                    onAddStop={onAddStop}
                    sdkCustomerConfig={sdkCustomerConfig}
                    stepScanAdded={
                      stops.find((x) => x.key === "required")?.active ||
                      stops.find((x) => x.key === "optional")?.active
                    }
                    displayNotAllowedNotice={displayNotAllowedNotice}
                  />
                ))}
              </Stops>
            )}
          </AddStop>

          <AddStop
            iconBefore={<HiOutlineCheck />}
            save
            onClick={onSaveWorkflow}
          >
            <Heading3>Save workflow</Heading3>
          </AddStop>
        </OperationStyled>
        <>
          <StartDivider>
            <Heading3>Start</Heading3>
          </StartDivider>
          <BsArrowDownShort color={theme.color.main} />
          {stops.map((stop) => {
            if (stop.active) {
              return (
                <Fragment key={stop.key}>
                  <StepCard onClick={() => onActivateNode(stop.key)}>
                    <StyledIconContainer>
                      <Button
                        iconAfter={
                          <RiDeleteBin5Line color={theme.color.main} />
                        }
                        onClick={() => onDeleteStop(stop)}
                      />
                    </StyledIconContainer>
                    <StyledStepImage src={stop.icon} />
                    <StepText>{stop.title}</StepText>
                  </StepCard>
                  <BsArrowDownShort color={theme.color.main} />
                </Fragment>
              );
            }
          })}

          <StartDivider end="true">
            <Heading3>End</Heading3>
          </StartDivider>
        </>
      </EditorArea>
      <ConfigurationArea>{activeBodyNode}</ConfigurationArea>
    </Wrapper>
  );
};

export default WorkflowPage;

const Wrapper = styled(Row)`
  align-items: flex-start;
  min-height: 80vh;
  justify-content: flex-start;
`;

const EditorArea = styled(Container)`
  flex: 1.8;
  min-height: 100vh;
  background-color: ${(props) => props.theme.background.mainLight};
  padding: 1.5em;
  justify-content: flex-start;
`;
const ConfigurationArea = styled(Container)`
  flex: 1;
  min-height: 100vh;
  justify-content: flex-start;
`;

const SubTitle = styled(Heading3)`
  padding: 0 0 2.5em 0;
`;

const SubText = styled(Heading3)`
  padding: 0.3em 0;
`;
const SubDetail = styled(Heading3)`
  color: ${(props) => props.theme.color.main};
  padding: 0.3em 0;
  padding-left: 1em;
`;
const StyledTexts = styled(Container)`
  width: 80%;
  padding: 0 0 2.5em 0;
  align-items: flex-start;
`;
const OperationStyled = styled(Row)`
  position: relative;
  width: 100%;
  justify-content: flex-end;
`;

const AddStop = styled(Button)<{ save?: boolean }>`
  border: ${(props) => props.theme.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  color: ${(props) => props.theme.color.main};

  width: ${(props) => (props.save ? "9.625em" : "6.5em")};
  min-height: 2.1875em;
  margin-left: 1em;
  position: relative;
`;

const Stops = styled(Container)`
  position: absolute;
  width: 15.375em;
  background-color: ${(props) => props.theme.background.main};
  box-shadow: ${(props) => props.theme.boxShadow.main};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  top: 2.5em;
  left: 0;
  padding: 0.5em;
  z-index: 10;
`;
const StopButton = styled(Button)<{ active?: boolean }>`
  margin: 0.2em 0;
  padding: 0;
  width: 100%;
  justify-content: flex-start;
  color: ${(props) =>
    props.active ? props.theme.color.main : props.theme.color.grey};
`;
const StartDivider = styled(Container)<{ end?: string }>`
  width: 3.75em;
  height: 3.75em;
  margin: 1em;
  border-radius: 50%;
  color: ${(props) => props.theme.color.primary2};
  background-color: ${(props) =>
    props.end === "true"
      ? "rgba(6, 27, 59, 0.35)"
      : props.theme.background.primary};
`;

const EmptyStyled = styled(Container)`
  mix-blend-mode: luminosity;
  width: 100%;
  min-height: 80vh;
  max-width: 7.8125em;
  justify-content: center;
`;

const StyledImageSrc = styled(ImageSrc)`
  mix-blend-mode: luminosity;
  margin: 2em;
`;

const StepCard = styled(Row)`
  position: relative;
  width: 22.5em;
  height: 4em;
  background-color: ${(props) => props.theme.background.main};
  border: ${(props) => props.theme.border.secondary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  margin: 1em 0;
  padding: 0 1.625em;
  cursor: pointer;
  justify-content: flex-start;
`;

const StepText = styled(Heading3)`
  color: ${(props) => props.theme.color.black};
`;

const StyledStepImage = styled(ImageSrc)`
  width: 1.875em;
  margin-right: 1.25em;
`;

const StyledIconContainer = styled(IconContainer)`
  position: absolute;
  top: 0em;
  right: 0em;
`;
