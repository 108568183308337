import CredentialsPage from "./CredentialsPage";
import { routesList } from "utils/routesList";
import ProtectedRoutes from "routes/ProtectedRoutes";
import { RouteObject } from "react-router-dom";

export const credentialsRoutes: RouteObject = {
  element: (
    <ProtectedRoutes
      allowedRoles={routesList.credentials.allowedRoles}
      requiredCustomer={routesList.credentials.requiredCustomer}
    />
  ),
  children: [
    { path: routesList.credentials.routes.main, element: <CredentialsPage /> },
  ],
};
