import LinkAppPageDisplay from "./LinkAppPageDisplay";
import { ContentBody } from "components/core/Styled/Layouts";
import NavBar from "components/layouts/Navbar";

const LinkAppPage = () => {
  return (
    <NavBar
      title="Linked App"
      showListCustomers
      showEnvironmentDisplay
      showTitle
      showStatus
    >
      <ContentBody>
        <LinkAppPageDisplay />
      </ContentBody>
    </NavBar>
  );
};

export default LinkAppPage;
