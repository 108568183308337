import AdminsListPage from "./AdminListPage";
import AdminCreatePage from "./AdminCreatePage";
import AdminUpdatePage from "./AdminUpdatePage";
import { routesList } from "utils/routesList";
import ProtectedRoutes from "routes/ProtectedRoutes";
import { RouteObject } from "react-router-dom";

export const adminRoutes: RouteObject = {
  element: (
    <ProtectedRoutes
      allowedRoles={routesList.admins.allowedRoles}
      requiredCustomer={routesList.admins.requiredCustomer}
    />
  ),
  children: [
    {
      path: routesList.admins.routes.main,
      element: <AdminsListPage />,
    },
    {
      path: routesList.admins.routes.create,
      element: <AdminCreatePage />,
    },
    {
      path: routesList.admins.routes.update,
      element: <AdminUpdatePage />,
    },
  ],
};
