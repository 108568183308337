import primaryTheme from "common/theme/primaryTheme";
import { Container } from "components/core/Styled/Layouts";
import {
  PropsWithChildren,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { BsThreeDots } from "react-icons/bs";
import styled from "styled-components";

type IProps = {
  defaultIsOpen?: boolean;
};

const Popup = ({
  defaultIsOpen = false,
  children,
}: PropsWithChildren<IProps>) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultIsOpen);

  useEffect(() => {
    setIsOpen(defaultIsOpen);
  }, [defaultIsOpen]);

  const ref = useRef() as RefObject<HTMLDivElement>;

  const handleClickOutside = useCallback(
    (event: MouseEvent | TouchEvent) => {
      const isInside =
        !!ref?.current && ref.current.contains(event.target as Node);
      const isOutside = isOpen && !isInside;

      if (isOpen) {
        event.stopPropagation();
      }

      if (isOutside) {
        setIsOpen(false);
      }

      return false;
    },
    [isOpen]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const onOpenModel: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.stopPropagation();
      setIsOpen((isOpen) => !isOpen);
    },
    []
  );

  const onClickPopup: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      setIsOpen(false);
      event.stopPropagation();
    },
    []
  );

  return (
    <StyledOptions onClick={onOpenModel}>
      <BsThreeDots color={primaryTheme.color.main} />
      {isOpen && (
        <Container ref={ref} onClick={onClickPopup}>
          {children}
        </Container>
      )}
    </StyledOptions>
  );
};

export default Popup;

const StyledOptions = styled(Container)`
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.background.mainLight};
  }
  width: 2.5em;
  height: 2.5em;
  min-width: 2.5em;
  min-height: 2.5em;
`;
