import LayoutRouter, { IPropsLayout } from "./LayoutRouter";
import AppLayout from "components/layouts/AppLayout";
import { PropsWithChildren } from "react";

const PublicRoutes = ({
  children,
  Layout = AppLayout,
}: PropsWithChildren<IPropsLayout>) => {
  return <LayoutRouter Layout={Layout}>{children}</LayoutRouter>;
};

export default PublicRoutes;
