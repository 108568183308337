import ReportDisplay from "./ReportDisplay";
import { ContentBody } from "components/core/Styled/Layouts";
import { routesList } from "utils/routesList";
import { useGoTo } from "common/hooks/paths/useGoTo";
import { useIdPathname } from "common/hooks/paths/useParamsPathname";
import { useCallback, useMemo } from "react";
import NavBar from "components/layouts/Navbar";

const ReportDisplayPage = () => {
  const sessionID = useIdPathname();
  const goTo = useGoTo();

  const subTitle = useMemo(() => {
    if (!sessionID) return undefined;
    return ` - ${sessionID}`;
  }, [sessionID]);

  const onClickReturnButton = useCallback(() => {
    goTo(routesList.reports.routes.main);
  }, [goTo]);

  return (
    <NavBar
      title="Report"
      subTitle={subTitle}
      onClickReturnButton={onClickReturnButton}
      showReturnButton
      showSubTitle
      showListCustomers
      showEnvironmentDisplay
      showTitle
      showStatus
    >
      <ContentBody>
        <ReportDisplay sessionID={sessionID} />
      </ContentBody>
    </NavBar>
  );
};

export default ReportDisplayPage;
