import Modal from "./Modal";
import { Heading1, Heading3 } from "components/core/Styled/Texts";
import { Container, Row } from "components/core/Styled/Layouts";
import IconContainer from "components/core/IconContainer";
import Button from "components/reusable/Button";
import styled from "styled-components";
import React, { PropsWithChildren } from "react";
import { GrClose } from "react-icons/gr";

type IProps = {
  show: boolean;
  close: React.MouseEventHandler<HTMLDivElement>;
  onConfirmDelete?: () => void;
  text?: string;
  title?: string;
  cancelText: string;
  confirmText?: string;
  icon?: JSX.Element;
  closeWithIcon?: boolean;
  singleButton?: boolean;
};

const ConfirmationPopup = ({
  show,
  close,
  onConfirmDelete,
  text,
  cancelText,
  confirmText,
  title,
  icon,
  closeWithIcon = false,
  singleButton = false,
  children,
}: PropsWithChildren<IProps>) => {
  return (
    <Modal show={show} onClose={close}>
      <ConfirmationContainer>
        <StyledIconContainer>
          <Button iconAfter={<GrClose />} onClick={close} />
        </StyledIconContainer>
        <Heading1>{title}</Heading1>
        <Title>{text}</Title>
        {children}
        <Row gap="1em" width="100%">
          {!closeWithIcon && (
            <ButtonStyled onClick={close}>
              <Heading3>{cancelText}</Heading3>
            </ButtonStyled>
          )}
          {!singleButton && (
            <ButtonStyled iconBefore={icon} confirm onClick={onConfirmDelete}>
              <Heading3>{confirmText}</Heading3>
            </ButtonStyled>
          )}
        </Row>
      </ConfirmationContainer>
    </Modal>
  );
};

export default ConfirmationPopup;

const ConfirmationContainer = styled(Container)`
  position: relative;
  padding: 1.5em 4em;
  width: 35em;
  min-height: 15em;
  background-color: ${(props) => props.theme.background.main};
  border-radius: ${(props) => props.theme.borderRadius.primary};
`;
const ButtonStyled = styled(Button)<{ confirm?: boolean }>`
  background-color: ${(props) => props.theme.background.main};
  border: ${(props) =>
    props.confirm
      ? props.theme.border.primary
      : props.theme.border.primaryGrey};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: 100%;
  color: ${(props) =>
    props.confirm ? props.theme.color.main : props.theme.color.secondary2};
  padding: 0 0.625em;
  min-height: 2.6em;
`;

const Title = styled(Heading3)`
  color: ${(props) => props.theme.color.primary};
  text-transform: none;
  margin-bottom: 3em;
`;

const StyledIconContainer = styled(IconContainer)`
  position: absolute;
  top: 1em;
  right: 1em;
`;
