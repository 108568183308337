import { authRoles } from "common/types/api/roles";

export const authorize = authRoles;

import ApiHelper from "api/ApiHelper";
import { IGetPagination, IListPagination } from "common/types/api/common";
import { ICustomerDropDownList } from "common/types/api/customer";
import { DEFAULT_LIMIT_PAGINATION } from "utils/constants";
import { assertSearch } from "utils/assertion";
import { API_BASE_URL } from "utils/UrlList";

export type IGetCustomersDropDownList = IGetPagination;

export type IGetCustomerNameResponse = ICustomerDropDownList;

export type IGetCustomersDropDownListResponse =
  IListPagination<IGetCustomerNameResponse>;

const getCustomersDropDownList = async ({
  start = 0,
  limit = DEFAULT_LIMIT_PAGINATION,
  search,
  ...filterInfo
}: IGetCustomersDropDownList): Promise<IGetCustomersDropDownListResponse> => {
  try {
    assertSearch(search);
    const res =
      await ApiHelper.apiClient.post<IGetCustomersDropDownListResponse>(
        `${API_BASE_URL}/api/v1/customers/drop-down/search`,
        { ...filterInfo, text: search || undefined },
        ApiHelper.getHeaders({
          params: {
            start,
            limit,
          },
        })
      );
    const data = res.data;
    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default getCustomersDropDownList;
