import { IParmPagination, usePagination } from "./usePagination";
import { useAppMutation, useAppQuery } from "./hooks";
import { DEFAULT_LIMIT_PAGINATION } from "utils/constants";
import adminApi from "api/adminsApi";
import { ICreateAdmin } from "api/adminsApi/createAdmin";
import { IUpdateAdmin } from "api/adminsApi/updateAdmin";
import { selectFilterSortSetting } from "services/redux/reducers/settingsSlice";
import { TableTypes } from "common/types/api/preferences";
import { useCallback, useMemo } from "react";

const selectFilterSort = selectFilterSortSetting(TableTypes.ADMINS);

export const useGetAdminsQuery = (sizeOfPage = DEFAULT_LIMIT_PAGINATION) => {
  const defaultValues = useMemo(() => ({}), []);

  const ifCondition = useCallback(
    (_values: IParmPagination & typeof defaultValues) => {
      return true;
    },
    []
  );

  const getApi = useCallback(
    (param: IParmPagination & typeof defaultValues) => {
      return adminApi.getAdmins(param);
    },
    []
  );

  const query = usePagination({
    key: "useGetAdminsQuery",
    sizeOfPage,
    getApi,
    selectFilterSort,
    defaultValues,
    ifCondition,
    type: TableTypes.ADMINS,
  });

  return query;
};

export const useGetAdminByIdQuery = (adminId: string) => {
  const query = useAppQuery(() => {
    return adminApi.getAdminById({
      adminId,
    });
  }, ["useGetAdminByIdQuery", adminId]);

  return query;
};

export const useCreateAdminMutation = () => {
  const query = useAppMutation((arg: Omit<ICreateAdmin, "customerId">) => {
    return adminApi.createAdmin({
      ...arg,
    });
  });

  return query;
};

export const useUpdateAdminMutation = (adminId: string) => {
  const query = useAppMutation(
    (arg: Omit<IUpdateAdmin, "customerId" | "adminId">) => {
      return adminApi.updateAdmin({
        ...arg,
        adminId,
      });
    }
  );

  return query;
};

export const useDeleteAdminMutation = (adminId: string) => {
  const query = useAppMutation(() => {
    return adminApi.deleteAdmin({
      adminId,
    });
  });

  return query;
};
