import { Container } from "components/core/Styled/Layouts";
import React from "react";
import styled from "styled-components";
import { useContextEditCustomer } from "common/contexts/editCustomerContext";
import Loader from "components/core/Loaders/Loader";
import AddProduct from "./AddProduct";
import ProductDisplay from "./ProductDisplay";

const CustomerProductList = () => {
  const {
    // products
    productsIsLoading,
    products,
  } = useContextEditCustomer();

  if (productsIsLoading) return <Loader />;

  return (
    <Wrapper width="100%">
      <AddProduct />
      {products?.map((product) => (
        <ProductDisplay key={product.productId} product={product} />
      ))}
    </Wrapper>
  );
};

export default CustomerProductList;

const Wrapper = styled(Container)`
  padding: 0 1.875em;
  margin: 1.875em 0;
  align-items: flex-start;
  justify-content: flex-start;
`;
