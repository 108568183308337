import { ContentBody } from "components/core/Styled/Layouts";
import { routesList } from "utils/routesList";
import { useGoTo } from "common/hooks/paths/useGoTo";
import { useCallback } from "react";
import CustomerCreate from "./CustomerCreationPage";
import NavBar from "components/layouts/Navbar";

const CustomerCreatePage = () => {
  const goTo = useGoTo();
  const onClickReturnButton = useCallback(() => {
    goTo(routesList.customers.routes.main);
  }, [goTo]);

  return (
    <NavBar
      title="Create customer"
      onClickReturnButton={onClickReturnButton}
      showReturnButton
      showEnvironmentDisplay
      showTitle
    >
      <ContentBody>
        <CustomerCreate />
      </ContentBody>
    </NavBar>
  );
};

export default CustomerCreatePage;
