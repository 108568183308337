import ListItem from "./ListItem";
import { usePathRoute } from "common/hooks/paths/usePathRouter";
import { IMenuInfo } from "utils/menuList";
import { useAccessAuth } from "common/hooks/session/useAccessAuth";
import { useCallback } from "react";

type IProps = {
  info: IMenuInfo;
  onClick?: () => void;
};

const ListItemContainer = ({ info, onClick }: IProps) => {
  const { isAllowed } = useAccessAuth({ info });

  const { isCurrentPath, navigateTo } = usePathRoute({
    to: info.path,
    patterns: info.patterns,
  });

  const onClickHandler = useCallback(() => {
    if (!onClick) navigateTo();
    else onClick();
  }, [navigateTo, onClick]);

  if (!isAllowed) return <></>;

  return (
    <ListItem info={info} selected={isCurrentPath} onClick={onClickHandler} />
  );
};

export default ListItemContainer;
