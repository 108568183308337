import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // customers
  UserRoles.ROLE_CUSTOMER_ADMIN,
  UserRoles.ROLE_CUSTOMER_OWNER,
  UserRoles.ROLE_CUSTOMER_DEVELOPER,
  UserRoles.ROLE_CUSTOMER_USER,
];

import ApiHelper from "api/ApiHelper";
import { assertNotEmpty } from "utils/assertion";
import { ILinkedDevicesInfo } from "common/types/api/linkedDevices";
import { API_BASE_URL } from "utils/UrlList";
import { WithCustomerInfo } from "common/types/api/customer";

export type IGetLinkedDevices = WithCustomerInfo;

export type IGetLinkedDevicesResponse = ILinkedDevicesInfo;

const getLinkedDevices = async ({
  customerId,
}: IGetLinkedDevices): Promise<IGetLinkedDevicesResponse> => {
  try {
    assertNotEmpty(customerId);
    const res = await ApiHelper.apiClient.get<IGetLinkedDevicesResponse>(
      `${API_BASE_URL}/api/v1/customers/${customerId}/linked-devices`,
      ApiHelper.getHeaders()
    );

    const data = res.data;

    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default getLinkedDevices;
