import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // customers
  UserRoles.ROLE_CUSTOMER_ADMIN,
  UserRoles.ROLE_CUSTOMER_OWNER,
  UserRoles.ROLE_CUSTOMER_DEVELOPER,
];

import ApiHelper from "api/ApiHelper";
import { IConfigHostedKyc, ITypeHostedKyc } from "common/types/api/hostedKyc";
import { API_BASE_URL } from "utils/UrlList";
import { assertNotEmpty } from "utils/assertion";
import { WithCustomerInfo } from "common/types/api/customer";

export type ICreateConfig = WithCustomerInfo & {
  name: string;
  type: ITypeHostedKyc;
};

export type ICreateConfigResponse = Pick<
  IConfigHostedKyc,
  "id" | "name" | "linkId" | "url" | "textSettings" | "type"
>;

const createConfig = async ({
  customerId,
  ...rest
}: ICreateConfig): Promise<ICreateConfigResponse> => {
  try {
    assertNotEmpty(customerId);
    const res = await ApiHelper.apiClient.post<ICreateConfigResponse>(
      `${API_BASE_URL}/api/v1/customers/${customerId}/hosted-service/kyc/config`,
      rest,
      ApiHelper.getHeaders()
    );

    const data = res.data;
    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default createConfig;
