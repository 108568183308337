import {
  authActions,
  selectSelectedCustomer,
} from "services/redux/reducers/authSlice";
import { useAppDispatch, useAppSelector } from "services/redux/hooks";
import { useCallback } from "react";
import { settingsActions } from "services/redux/reducers/settingsSlice";
import { ISelectedCustomerInfo } from "common/types/api/customer";

export const useSelectCustomerCanBeEmpty = () => {
  const selectedCustomer = useAppSelector(selectSelectedCustomer);
  if (!selectedCustomer) return undefined;

  return { ...selectedCustomer, customerId: selectedCustomer.id };
};

export const useSelectCustomer = () => {
  const selectedCustomer = useAppSelector(selectSelectedCustomer);
  if (!selectedCustomer) throw new Error("No selected customer selected");

  return { ...selectedCustomer, customerId: selectedCustomer.id };
};

export const useUpdateSelectedCustomer = () => {
  const dispatch = useAppDispatch();
  const customerSelected = useSelectCustomer();
  const updateSelectedCustomer = useCallback(
    (newSelectedCustomer: ISelectedCustomerInfo) => {
      dispatch(authActions.updateSelectedCustomer(newSelectedCustomer));
      dispatch(settingsActions.resetPagesNum());
    },
    [dispatch]
  );
  const patchSelectedCustomer = useCallback(
    (newSelectedCustomer: Partial<Omit<ISelectedCustomerInfo, "id">>) => {
      dispatch(authActions.patchSelectedCustomer(newSelectedCustomer));
    },
    [dispatch]
  );

  return {
    customerSelected,
    updateSelectedCustomer,
    patchSelectedCustomer,
  };
};
