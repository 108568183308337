import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // internal
  UserRoles.ROLE_UQUDO_AUDITOR,
  UserRoles.ROLE_UQUDO_SALES,
];

import ApiHelper from "api/ApiHelper";
import { TPreferencesCustomers } from "common/types/api/preferences";
import { API_BASE_URL } from "utils/UrlList";

export type IGetPreferencesCustomers = {};

export type IGetPreferencesCustomersResponse = TPreferencesCustomers;

const getPreferencesCustomers =
  async ({}: IGetPreferencesCustomers): Promise<IGetPreferencesCustomersResponse> => {
    try {
      const res =
        await ApiHelper.apiClient.get<IGetPreferencesCustomersResponse>(
          `${API_BASE_URL}/api/v1/customers/preferences`,
          ApiHelper.getHeaders()
        );

      const data = res.data;

      return data;
    } catch (error) {
      ApiHelper.errorHandler(error, true);
    }
  };

export default getPreferencesCustomers;
