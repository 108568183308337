import { useCallback, useState } from "react";

type T = boolean | undefined;
function useToggle(
  defaultValue?: T
): [T, React.Dispatch<React.SetStateAction<T>>, () => void] {
  const [value, setValue] = useState<T>(defaultValue);

  const toggle = useCallback(() => {
    setValue((oldValue) => !oldValue);
  }, []);

  return [value, setValue, toggle];
}

export default useToggle;
