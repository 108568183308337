import { useGetCustomerDashboardAnalyticsQuery } from "services/query/analytics";
import { Container } from "components/core/Styled/Layouts";
import LoadingPage from "components/core/Loaders/LoadingPage";
import styled from "styled-components";

const AnalyticsDisplayProdPageFrame = () => {
  const { data, error, isError, isLoading } =
    useGetCustomerDashboardAnalyticsQuery();

  if (isLoading) return <LoadingPage />;

  return (
    <Wrapper>
      {isError && error?.code === "NOT_FOUND" ? (
        <Paragraph>Report will be available soon...</Paragraph>
      ) : (
        <iframe
          width="100%"
          height="100%"
          title="Analytics"
          src={data?.url}
          frameBorder={0}
          style={{ border: "0" }}
          sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
        ></iframe>
      )}
    </Wrapper>
  );
};

export default AnalyticsDisplayProdPageFrame;
const Wrapper = styled(Container)`
  width: 100%;
  height: calc(100vh - 80px);
`;
const Paragraph = styled.p`
  font-weight: 500;
  color: #3377ff;
`;
