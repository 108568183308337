import ApiHelper from "api/ApiHelper";
import { AUTH_API_BASE_URL } from "utils/UrlList";
import { ITokenInfo } from "./tokenType";
import { TokenLocalStorage } from "./tokenStorage";
import { getCode } from "services/auth/codeGenerators";
import {
  CLIENT_ID,
  getAuthorizationCode,
  getRedirectUri,
} from "services/auth/constants";

export type IGetToken = {};

export type IGetTokenResponse = ITokenInfo;

const getToken = async ({}: IGetToken): Promise<IGetTokenResponse> => {
  try {
    const clientId = CLIENT_ID;
    const { codeVerifier } = getCode();
    const authorizationCode = getAuthorizationCode();
    const redirectUri = getRedirectUri();

    const res = await ApiHelper.apiClient.post<IGetTokenResponse>(
      `${AUTH_API_BASE_URL}/api/v1/token`,
      new URLSearchParams({
        client_id: clientId,
        authorization_code: authorizationCode,
        code_verifier: codeVerifier,
        redirect_uri: redirectUri,
      }).toString(),
      ApiHelper.getHeaders({
        withAccessToken: false,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
    );
    TokenLocalStorage.set(res.data);
    return res.data;
  } catch (error) {
    ApiHelper.errorHandler(error, false);
  }
};

export default getToken;
