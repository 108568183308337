import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import revokeToken from "./token/revokeTokenApi";
import { REDIRECTION } from "utils/UrlList";
import { useAppDispatch } from "services/redux/hooks";
import { authActions } from "services/redux/reducers/authSlice";
import { TokenLocalStorage } from "./token/tokenStorage";
import { customToast } from "utils/customToast";

export const useCustomAuth = () => {
  const { logout: logoutAuth0, ...rest } = useAuth0();
  const dispatch = useAppDispatch();

  const revokeTokens = useCallback(async () => {
    try {
      await revokeToken();
      TokenLocalStorage.clear();
      await logoutAuth0({
        logoutParams: {
          returnTo: `${window.location.origin}${REDIRECTION.failureAuthenticationOrLogoutRedirection}?code=logout`,
        },
      });
    } catch (error) {
      customToast.error("Logout failed please try again");
    }
  }, [logoutAuth0]);

  const handleLogout = useCallback(async () => {
    dispatch(authActions.logout());
    await revokeTokens();
  }, [dispatch, revokeTokens]);

  return {
    handleLogout,
    ...rest,
  };
};
