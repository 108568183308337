import { Container } from "components/core/Styled/Layouts";
import { Heading1 } from "components/core/Styled/Texts";
import Loader from "components/core/Loaders/Loader";
import { useGetReportByIdQuery } from "services/query/reports";
import React from "react";
import ReportDisplayContent from "./ReportDisplayContent";
import { ReportDisplayProvider } from "common/contexts/reportDisplayContext";

const ReportDisplay = ({ sessionID }: { sessionID: string }) => {
  const getReportByIdQuery = useGetReportByIdQuery(sessionID);

  if (getReportByIdQuery.isLoading) return <Loader />;

  if (getReportByIdQuery.isError)
    return (
      <Container>
        <Heading1>{sessionID} Not found</Heading1>
      </Container>
    );

  return (
    <ReportDisplayProvider
      documents={getReportByIdQuery.data?.documents}
      backgroundCheck={getReportByIdQuery.data?.backgroundCheck}
    >
      <ReportDisplayContent
        sessionID={sessionID}
        data={getReportByIdQuery.data}
      />
    </ReportDisplayProvider>
  );
};

export default ReportDisplay;
