import { icons } from "assets/images";
import { Container, Divider, Row } from "components/core/Styled/Layouts";
import { Heading3 } from "components/core/Styled/Texts";
import Checkbox from "components/reusable/Checkbox";
import CustomSelect from "components/Select/CustomSelect";
import NodeCard from "components/Workflow/NodeCard";
import Button from "components/reusable/Button";
import { isObjEmpty } from "utils/assertion";
import useSelectedDocument, {
  ICountryDocument,
  ICountryDocumentGroup,
} from "common/hooks/document/useSelectedDocument";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";
import { RiDeleteBin5Line } from "react-icons/ri";
import ToggleBloc from "components/Toggle/ToggleBloc";
import { useContextWorkflow } from "common/contexts/workflowContext";
import AdditionalScanConfigs from "./AdditionalScanConfigs";

type IProps = {};

export type TCountryOption = {
  value: ICountryDocument;
  label: string | number;
};

type IDocument = {
  documentType: string;
  documentName: string;
  selected: boolean;
  lookup: boolean;
  nfc: boolean;
};

const MandatoryIdNode = ({}: IProps) => {
  const {
    sdkCustomerConfig,
    onChangeIdConfig: onChange,
    onboardingWorkflowConf,
  } = useContextWorkflow();

  const conf = useMemo(
    () => onboardingWorkflowConf?.scanConfig?.required,
    [onboardingWorkflowConf?.scanConfig?.required]
  );

  const theme = useTheme();
  const { countryDocumentListByGroup, fromGroupToDocumentConfigs } =
    useSelectedDocument({
      documentsConfigs: conf?.documentsConfigs,
    });

  const [documents, setDocuments] = useState<
    ICountryDocumentGroup | undefined
  >();

  const [multiDocuments, setMultiDocuments] = useState<string[]>([]);

  const toggleSelected = useCallback(
    (country: string, documentType: string) => {
      const countryDocument = documents?.[country];

      if (countryDocument) {
        const documentToUpdate = countryDocument.documents.find(
          (document: IDocument) => document.documentType === documentType
        );

        if (documentToUpdate) {
          documentToUpdate.selected = !documentToUpdate.selected;
          setDocuments({ ...documents, [country]: countryDocument });
        }
      }
      const docs = fromGroupToDocumentConfigs(
        Object.values(documents ?? {}).map((value) => value)
      );
      onChange(docs);
    },
    [documents, fromGroupToDocumentConfigs, onChange]
  );

  const onCheckAllPassports = useCallback(() => {
    const country = "";
    const documentType = "PASSPORT";
    const countryDocument = documents?.[country];
    if (documents)
      if (countryDocument) {
        const documentToUpdate = countryDocument.documents.find(
          (document: IDocument) => document.documentType === documentType
        );

        if (documentToUpdate) {
          documentToUpdate.selected = !documentToUpdate.selected;
          setDocuments({ ...documents, [country]: countryDocument });
        }
      }

    const docs = fromGroupToDocumentConfigs(
      Object.values(documents ?? {}).map((value) => value)
    );
    onChange(docs);
  }, [documents, fromGroupToDocumentConfigs, onChange]);

  const onCheckLookupDocument = useCallback(
    (country: string, documentType: string) => {
      const countryDocument = documents?.[country];

      if (countryDocument) {
        const documentToUpdate = countryDocument.documents.find(
          (document: IDocument) => document.documentType === documentType
        );

        if (documentToUpdate && documentToUpdate.selected === true) {
          documentToUpdate.lookup = !documentToUpdate.lookup;
          setDocuments({ ...documents, [country]: countryDocument });
          const docs = fromGroupToDocumentConfigs(
            Object.values(documents ?? {}).map((value) => value)
          );
          onChange(docs);
        } else if (documentToUpdate && documentToUpdate.selected === false) {
          documentToUpdate.lookup = true;
          documentToUpdate.selected = true;
          setDocuments({ ...documents, [country]: countryDocument });
          const docs = fromGroupToDocumentConfigs(
            Object.values(documents ?? {}).map((value) => value)
          );
          onChange(docs);
        }
      }
    },
    [documents, fromGroupToDocumentConfigs, onChange]
  );

  const onCheckNfcDocument = useCallback(
    (country: string, documentType: string) => {
      const countryDocument = documents?.[country];

      if (countryDocument) {
        const documentToUpdate = countryDocument.documents.find(
          (document: IDocument) => document.documentType === documentType
        );

        if (documentToUpdate && documentToUpdate.selected === true) {
          documentToUpdate.nfc = !documentToUpdate.nfc;
          setDocuments({ ...documents, [country]: countryDocument });
          const docs = fromGroupToDocumentConfigs(
            Object.values(documents ?? {}).map((value) => value)
          );
          onChange(docs);
        } else if (documentToUpdate && documentToUpdate.selected === false) {
          documentToUpdate.nfc = true;
          documentToUpdate.selected = true;
          setDocuments({ ...documents, [country]: countryDocument });
          const docs = fromGroupToDocumentConfigs(
            Object.values(documents ?? {}).map((value) => value)
          );
          onChange(docs);
        }
      }
    },
    [documents, fromGroupToDocumentConfigs, onChange]
  );

  const onDeleteCountry = useCallback(
    (country: string) => {
      setMultiDocuments([]);
      const countryDocument = documents?.[country];

      if (countryDocument) {
        countryDocument.documents.forEach((doc) => {
          doc.selected = false;
        });
        setDocuments({ ...documents, [country]: countryDocument });
        const docs = fromGroupToDocumentConfigs(
          Object.values(documents ?? {}).map((value) => value)
        );
        onChange(docs);
      }
    },
    [documents, fromGroupToDocumentConfigs, onChange]
  );

  const onDocumentTypeChange = useCallback(
    (newValue: unknown) => {
      const option = newValue as TCountryOption;
      if (!multiDocuments.find((x) => x === option.label)) {
        setMultiDocuments([option.label as string]);
      }
    },
    [multiDocuments]
  );

  useEffect(() => {
    setDocuments(countryDocumentListByGroup);
  }, [countryDocumentListByGroup, documents]);

  const passportDoc = useMemo(
    () =>
      documents?.[""]?.documents.find((doc) => doc.documentType === "PASSPORT"),
    [documents]
  );

  return (
    <Wrapper>
      <NodeCard
        title="Mandatory ID Scanning"
        subTitle="User must onboard all selected IDs."
        icon={icons.reqId}
      />
      <AdditionalScanConfigs />
      <Divider />
      {documents && !isObjEmpty(documents) && (
        <ConfigurationStyled>
          <Name>ID Document</Name>
          <StyledCustomSelect
            options={Object.entries(documents ?? {})
              .filter(([key]) => key !== "")
              .map(([key, value]) => ({
                value: value,
                label: key,
              }))}
            onChange={onDocumentTypeChange}
            selectStyle="100%"
          />
          {Object.entries(documents ?? {}).map(([country, doc], key) => {
            if (
              (doc.documents.some((x) => x.selected) ||
                multiDocuments.find((x) => x === country)) &&
              country !== ""
            )
              return (
                <CountryRow key={key}>
                  <Row width="100%" justifyContent="space-between">
                    <CountryTitle>
                      <Heading3>{doc.country}</Heading3>
                    </CountryTitle>
                    <Button
                      iconAfter={<RiDeleteBin5Line color={theme.color.main} />}
                      onClick={() => onDeleteCountry(country)}
                    />
                  </Row>

                  {multiDocuments.length > 0 && <CountryDivider />}

                  {doc.documents.map((doc, index) => {
                    return (
                      <>
                        <DocumentTypeRow key={index}>
                          <StyledCheckbox
                            onChange={() =>
                              toggleSelected(country, doc.documentType)
                            }
                            checked={doc.selected ? true : false}
                            label={doc.documentName}
                          />
                        </DocumentTypeRow>
                        {doc.selected &&
                          doc.canBeLookup &&
                          sdkCustomerConfig?.lookupAllowed?.some(
                            (documentType) =>
                              documentType ===
                              "KYC_LOOKUP_SDK_" + doc.documentType
                          ) && (
                            <StyledToggleBloc
                              label="Lookup"
                              onChange={() =>
                                onCheckLookupDocument(country, doc.documentType)
                              }
                              toggle={doc.lookup ? true : false}
                            />
                          )}
                        {doc.selected &&
                          doc.canBeNfc &&
                          sdkCustomerConfig?.nfcAllowed && (
                            <StyledToggleBloc
                              label="NFC"
                              onChange={() =>
                                onCheckNfcDocument(country, doc.documentType)
                              }
                              toggle={doc.nfc ? true : false}
                            />
                          )}
                      </>
                    );
                  })}
                </CountryRow>
              );
          })}

          <>
            {multiDocuments.length > 0 && <StyledDivider />}
            <>
              <DocumentTypeRow>
                <StyledCheckbox
                  onChange={onCheckAllPassports}
                  checked={passportDoc?.selected ? true : false}
                  label={"Passports from all countries"}
                />
              </DocumentTypeRow>
              {passportDoc?.selected &&
                passportDoc?.canBeLookup &&
                sdkCustomerConfig?.lookupAllowed?.some(
                  (documentType) =>
                    documentType ===
                    "KYC_LOOKUP_SDK_" + passportDoc?.documentType
                ) && (
                  <StyledToggleBloc
                    label="Lookup"
                    onChange={() =>
                      onCheckLookupDocument("", passportDoc?.documentType)
                    }
                    toggle={passportDoc?.lookup ? true : false}
                  />
                )}
              {passportDoc?.selected &&
                passportDoc?.canBeNfc &&
                sdkCustomerConfig?.nfcAllowed && (
                  <StyledToggleBloc
                    label="NFC"
                    onChange={() =>
                      onCheckNfcDocument("", passportDoc?.documentType)
                    }
                    toggle={passportDoc?.nfc ? true : false}
                  />
                )}
            </>
          </>
        </ConfigurationStyled>
      )}
    </Wrapper>
  );
};

export default MandatoryIdNode;

const Wrapper = styled(Container)`
  width: 100%;
  padding-bottom: 1em;
`;

const ConfigurationStyled = styled(Container)`
  width: 100%;
  align-items: flex-start;
  padding: 0 1.5em;
`;

const Name = styled(Heading3)`
  color: ${(props) => props.theme.color.black};
  padding: 1em 0 0.6em 0;
`;
const StyledCheckbox = styled(Checkbox)`
  margin: 0.5em 0;
`;
const StyledDivider = styled(Divider)`
  margin: 1em 0;
`;
const StyledCustomSelect = styled(CustomSelect)`
  width: 100%;
  margin: 0 0 1em 0;
  ${Container} {
    border: none;
    border-radius: 0;
    border-bottom: ${(props) => props.theme.border.main};
  }
`;

const CountryRow = styled(Container)`
  width: 100%;
  min-height: 3em;
  height: auto;
  border: ${(props) => props.theme.border.main};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  padding: 0.6em 0.6em;
  margin: 0.3em 0;
`;
const CountryDivider = styled(Divider)`
  margin: 0.6em 0;
`;

const CountryTitle = styled(Row)`
  width: 80%;
  justify-content: flex-start;
`;

const DocumentTypeRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StyledToggleBloc = styled(ToggleBloc)`
  width: 100%;
  ${Row} {
    padding: 0 0.6em;
  }
  ${Heading3} {
    color: ${(props) => props.theme.color.black};
  }
  .react-toggle-track {
    width: 40px;
    height: 19px;
    border-radius: 18px;
  }
  .react-toggle-thumb {
    width: 15px;
    height: 15px;
  }
  .react-toggle--checked .react-toggle-thumb {
    left: 22px;
  }
`;
