import ButtonDownloadReport from "./ButtonDownloadReport";
import SmallMenu from "./SmallMenu";
import { DocumentChoice } from "./DocumentChoice";
import { formatSource } from "utils/helpers";
import { formatDate } from "utils/dateHelpers";
import { Container, Divider, Row } from "components/core/Styled/Layouts";
import { Heading3 } from "components/core/Styled/Texts";
import Button from "components/reusable/Button";
import { icons } from "assets/images";
import { IconSrc } from "components/reusable/Image";
import Info from "components/reusable/Info";
import BackgroundCheck from "components/Document/BackgroundCheck";
import { routesList } from "utils/routesList";
import { useGoTo } from "common/hooks/paths/useGoTo";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { IGetReportByIdResponse } from "api/reportApi/getReportById";
import { useContextReportDisplay } from "common/contexts/reportDisplayContext";

const ReportDisplayContent = ({
  sessionID,
  data,
}: {
  sessionID: string;
  data: IGetReportByIdResponse;
}) => {
  const { backgroundCheck, selected, updateSelected, filteredMenuListValues } =
    useContextReportDisplay();
  const goTo = useGoTo();

  const goToAuditClick = useCallback(() => {
    goTo(routesList.audits.routes.display, sessionID);
  }, [goTo, sessionID]);

  const contentDescription = useMemo(() => {
    if (!selected) return <></>;
    if (selected === "BACKGROUND_CHECK") {
      if (backgroundCheck) {
        return <BackgroundCheck info={backgroundCheck} />;
      }
      return <></>;
    }

    return <DocumentChoice />;
  }, [backgroundCheck, selected]);

  return (
    <Wrapper width="100%">
      <StyledRow gap="2em" justifyContent="flex-start" width="100%">
        <SmallMenu list={filteredMenuListValues} onChange={updateSelected} />
        <Row gap="1em" justifyContent="flex-start" width="100%">
          <Info name="name" content={data.name} />
          <Info name="date" content={formatDate(data.date)} />
          <Info name="source" content={formatSource(data.source)} />
        </Row>
        <ButtonsContainer>
          <ButtonDownloadReport sessionId={sessionID} />
          <StyledButtonAudit
            onClick={goToAuditClick}
            iconBefore={<IconSrc src={icons.AuditIcon} />}
          >
            <Heading3>View Audit</Heading3>
          </StyledButtonAudit>
        </ButtonsContainer>
      </StyledRow>
      <Divider />
      {contentDescription}
    </Wrapper>
  );
};

export default ReportDisplayContent;

const Wrapper = styled(Container)`
  width: 100%;
  padding: 0.625em;
`;

const ButtonsContainer = styled(Container)`
  width: 100%;
  max-width: 10em;
  align-items: flex-end;
`;

const StyledButtonAudit = styled(Button)`
  color: ${(props) => props.theme.color.main};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  margin-top: 0.4em;
  button {
    flex-direction: row;
  }
`;

const StyledRow = styled(Row)`
  min-height: 7.5em;
`;
