import {
  IDisplayHeader,
  IFiltersInfo,
  ISortsInfo,
} from "common/types/api/common";
import LocalStorage from "utils/Storage/LocalStorage";
import { DEFAULT_LIMIT_PAGINATION } from "utils/constants";
import { TABLE_TYPES_LIST, TTableTypes } from "common/types/api/preferences";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "services/redux/store";
import { PageSizeStorage } from "utils/Storage";

export type IWithTableType<T> = { value: T; type: TTableTypes };

export const SortLocalStorages = TABLE_TYPES_LIST.reduce((obj, type) => {
  obj[type] = new LocalStorage<ISortsInfo>(`${"SORT_LOCAL_STORAGE"}_${type}`);
  return obj;
}, {} as Record<TTableTypes, LocalStorage<ISortsInfo>>);

export const PageNumLocalStorages = TABLE_TYPES_LIST.reduce((obj, type) => {
  obj[type] = new LocalStorage<number>(`${"PAGE_NUM_LOCAL_STORAGES"}_${type}`);
  return obj;
}, {} as Record<TTableTypes, LocalStorage<number>>);

export const DisplaySettingLocalStorages = TABLE_TYPES_LIST.reduce(
  (obj, type) => {
    obj[type] = new LocalStorage<IDisplayHeader>(
      `${"DISPLAY_SETTING_LOCAL_STORAGE"}_${type}`
    );
    return obj;
  },
  {} as Record<TTableTypes, LocalStorage<IDisplayHeader>>
);

type ISettingState = {
  filter: IFiltersInfo;
  sort: ISortsInfo | undefined;
  displaySetting: IDisplayHeader | undefined;
  pageSetting: {
    num: number;
  };
};

type ISettingsState = {
  settings: Record<string, ISettingState>;
  paginationSize: number;
};

const getInitValue = (type: TTableTypes) => {
  return {
    filter: {
      search: "",
    },
    sort: SortLocalStorages[type].get(),
    displaySetting: DisplaySettingLocalStorages[type].get(),
    pageSetting: {
      num: PageNumLocalStorages[type].get() ?? 0,
    },
  };
};

const initialState: ISettingsState = {
  paginationSize: PageSizeStorage.get() ?? DEFAULT_LIMIT_PAGINATION,
  settings: TABLE_TYPES_LIST.reduce((obj, type) => {
    obj[type] = getInitValue(type);
    return obj;
  }, {} as Record<TTableTypes, ISettingState>),
};

export const selectFilterSetting = (type: TTableTypes) => (state: RootState) =>
  state.settings.settings[type].filter;

export const selectSearchSetting = (type: TTableTypes) => (state: RootState) =>
  state.settings.settings[type].filter.search ?? "";

export const selectSortSetting = (type: TTableTypes) => (state: RootState) =>
  state.settings.settings[type].sort;

export const selectDisplaySetting = (type: TTableTypes) => (state: RootState) =>
  state.settings.settings[type].displaySetting;

export const selectFilterSortSetting =
  (type: TTableTypes) => (state: RootState) => ({
    ...state.settings.settings[type].filter,
    ...state.settings.settings[type].sort,
  });

export const selectPageNum = (type: TTableTypes) => (state: RootState) =>
  state.settings.settings[type].pageSetting.num;

export const selectPageSize = (state: RootState) =>
  state.settings.paginationSize;

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    updateSearch: (state, action: PayloadAction<IWithTableType<string>>) => {
      const updated =
        state.settings[action.payload.type].filter.search !==
        action.payload.value;
      if (!updated) return;
      state.settings[action.payload.type].filter.search = action.payload.value;
      PageNumLocalStorages[action.payload.type].set(0);
      state.settings[action.payload.type].pageSetting.num = 0;
    },

    updateFilter: (
      state,
      action: PayloadAction<IWithTableType<IFiltersInfo>>
    ) => {
      const updated =
        state.settings[action.payload.type].filter !== action.payload.value;
      if (!updated) return;
      state.settings[action.payload.type].filter = action.payload.value;
      PageNumLocalStorages[action.payload.type].set(0);
      state.settings[action.payload.type].pageSetting.num = 0;
    },

    updateSort: (
      state,
      action: PayloadAction<IWithTableType<ISortsInfo | undefined>>
    ) => {
      const updated =
        state.settings[action.payload.type].sort !== action.payload.value;
      if (!updated) return;
      SortLocalStorages[action.payload.type].set(action.payload.value);
      state.settings[action.payload.type].sort = action.payload.value;
      PageNumLocalStorages[action.payload.type].set(0);
      state.settings[action.payload.type].pageSetting.num = 0;
    },

    updateDisplaySetting: (
      state,
      action: PayloadAction<IWithTableType<IDisplayHeader | undefined>>
    ) => {
      DisplaySettingLocalStorages[action.payload.type].set(
        action.payload.value
      );
      state.settings[action.payload.type].displaySetting = action.payload.value;
    },

    updatePageNum: (state, action: PayloadAction<IWithTableType<number>>) => {
      const updated =
        state.settings[action.payload.type].pageSetting.num !==
        action.payload.value;
      if (!updated) return;
      PageNumLocalStorages[action.payload.type].set(action.payload.value);
      state.settings[action.payload.type].pageSetting.num =
        action.payload.value;
    },

    updatePageSize: (state, action: PayloadAction<number>) => {
      const updated = state.paginationSize !== action.payload;
      if (!updated) return;
      PageSizeStorage.set(action.payload);
      state.paginationSize = action.payload;
    },

    resetPagesNum: (state) => {
      TABLE_TYPES_LIST.forEach((type) => {
        PageNumLocalStorages[type].set(0);
        state.settings[type].pageSetting.num = 0;
      });
    },
  },
});

export const settingsActions = settingsSlice.actions;

export default settingsSlice.reducer;
