import { Container } from "components/core/Styled/Layouts";
import { Heading1 } from "components/core/Styled/Texts";
import React from "react";
import styled from "styled-components";

const NotFoundPage = () => {
  return (
    <Wrapper>
      <Heading1>404 Not found</Heading1>
    </Wrapper>
  );
};

export default NotFoundPage;

const Wrapper = styled(Container)`
  background: ${(props) => props.theme.background.main};
  width: 100vw;
  height: 100vh;

  & > ${Heading1} {
    font-size: 3rem;
  }
`;
