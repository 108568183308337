import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // customers
  UserRoles.ROLE_CUSTOMER_ADMIN,
  UserRoles.ROLE_CUSTOMER_OWNER,
  UserRoles.ROLE_CUSTOMER_USER,
];

import ApiHelper from "api/ApiHelper";
import { assertNotEmpty, assertSearch } from "utils/assertion";
import { API_BASE_URL } from "utils/UrlList";
import { WithCustomerInfo } from "common/types/api/customer";

export type IGetReportSessions = WithCustomerInfo & {
  sessionId: string;
};

export type IGetReportSessionsResponse = {
  sessionIds: string[];
};

const getReportSessions = async ({
  customerId,
  sessionId,
}: IGetReportSessions): Promise<IGetReportSessionsResponse> => {
  try {
    assertNotEmpty(customerId);
    assertSearch(sessionId);
    const res = await ApiHelper.apiClient.get<IGetReportSessionsResponse>(
      `${API_BASE_URL}/api/v1/customers/${customerId}/reports/${sessionId}/face-matching/sessions`,
      ApiHelper.getHeaders()
    );
    const data = res.data;
    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};
export default getReportSessions;
