import IconContainer from "./IconContainer";
import { FaArrowLeft } from "react-icons/fa";
import styled from "styled-components";
import { PropsWithChildren } from "react";

export type IProps = {
  onClick?: () => void;
  className?: string;
};

const ReturnButton = ({
  children,
  className,
  onClick,
}: PropsWithChildren<IProps>) => {
  return (
    <Wrapper className={className} onClick={onClick}>
      <FaArrowLeft />
      {children}
    </Wrapper>
  );
};

export default ReturnButton;

const Wrapper = styled(IconContainer)`
  border: ${(props) => props.theme.border.main};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  border-color: ${(props) => props.theme.color.main};
  padding: 0.5625em 1em;
  min-height: 2.1875em;
`;
