import { useCallback, useState } from "react";

function useShowHide(defaultValue = false) {
  const [isVisible, setVisible] = useState<boolean>(defaultValue);

  const show = useCallback(() => setVisible(true), []);
  const hide = useCallback(() => setVisible(false), []);

  return { isVisible, show, hide };
}

export default useShowHide;
