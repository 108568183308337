import FiltersBloc from "components/Filters/FiltersBloc";
import { Container } from "components/core/Styled/Layouts";
import Table, { IHeader } from "components/core/Table";
import { ICellComponentProps } from "components/core/Table/CellComponentDefault";
import { useGetCustomersQuery } from "services/query/customers";
import { formatLocalDate } from "utils/dateHelpers";
import NormalPagination from "components/pagination/NormalPagination";
import { routesList } from "utils/routesList";
import { useGoTo } from "common/hooks/paths/useGoTo";
import { useDisplaySetting } from "common/hooks/settings/useSetting";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { StatusDisplay } from "components/Status/StatusDisplay";
import { TableTypes } from "common/types/api/preferences";
import { MdModeEdit } from "react-icons/md";

export const customersHeaders = [
  "companyName",
  "type",
  "clientId",
  "createdDate",
  "status",
  "companyNameAlias",
  "expiryDate",
];

export const customersHeadersDefault = [
  "companyName",
  "type",
  "clientId",
  "createdDate",
  "status",
];

type IRow = {
  id: string;
  companyName: string;
  type: "TESTING" | "PRODUCTION";
  clientId: string;
  createdDate: string;
  status: string;
  companyNameAlias: string;
  expiryDate: string;
  settings: {
    id: string;
    refetch: () => void;
  };
};

const headers: IHeader[] = [
  {
    Header: "Name",
    accessor: "companyName",
  },
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Client ID",
    accessor: "clientId",
  },
  {
    Header: "Created Date",
    accessor: "createdDate",
  },
  {
    Header: "Company Name Alias",
    accessor: "companyNameAlias",
  },
  {
    Header: "Expiry Date",
    accessor: "expiryDate",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "settings",
  },
];

export type IProps = {
  id: string;
  refetch: () => void;
};

export const ListOptions = ({}: IProps) => {
  return (
    <>
      <MdModeEdit />
    </>
  );
};

export const CellComponent = ({ cell, row, index }: ICellComponentProps) => {
  const info = cell.value as IProps;
  return (
    <td {...cell.getCellProps()}>
      {index === row.cells.length - 1 ? (
        <ListOptions {...info} />
      ) : cell.column.id === "status" ? (
        <StatusDisplay
          showBorder
          status={cell.value as string}
          content={cell.value as string}
        />
      ) : (
        cell.render("Cell")
      )}
    </td>
  );
};

const CustomersList = () => {
  const getCustomersQuery = useGetCustomersQuery();
  const getCustomersRefetch = useMemo(
    () => getCustomersQuery.refetch,
    [getCustomersQuery.refetch]
  );

  const { displaySetting } = useDisplaySetting(TableTypes.CUSTOMERS);
  const displaySettingList = displaySetting
    ? displaySetting
    : customersHeadersDefault;
  const goTo = useGoTo();

  const listRow = useMemo(() => {
    if (getCustomersQuery.allData === undefined) return [];
    return getCustomersQuery.allData.results.map((val) => ({
      id: val.id,
      companyName: val.companyName,
      type: val.type === "PRODUCTION" ? "PRODUCTION" : "TESTING",
      clientId: val.clientId,
      createdDate: formatLocalDate(val.createdDate as string),
      status: val.status === "Enabled" ? "Enabled" : "Disabled",
      companyNameAlias: val.companyNameAlias,
      expiryDate: formatLocalDate(val.expiryDate as string) ?? "",
      settings: {
        id: val.id,
        refetch: getCustomersRefetch,
      },
    }));
  }, [getCustomersQuery.allData, getCustomersRefetch]);

  const onRowClick = useCallback(
    (row: unknown) => {
      const { id } = row as IRow;
      goTo(routesList.customers.routes.update, id);
    },
    [goTo]
  );

  const customHeader = useMemo(() => {
    const headersWithoutSettings = headers.filter((a) => a.Header !== "");
    return headersWithoutSettings
      .sort((a, b) =>
        displaySettingList.indexOf(a.accessor) <
        displaySettingList.indexOf(b.accessor)
          ? -1
          : 1
      )
      .concat(headers.filter((a) => a.Header === ""));
  }, [displaySettingList]);

  const startIndex = useMemo(() => {
    if (!getCustomersQuery.allData?.total) return 1;
    return (
      getCustomersQuery.pageSetting.pageNum *
        getCustomersQuery.pageSetting.pageSize +
      1
    );
  }, [
    getCustomersQuery.allData?.total,
    getCustomersQuery.pageSetting.pageNum,
    getCustomersQuery.pageSetting.pageSize,
  ]);

  return (
    <Wrapper>
      <FiltersBloc type={TableTypes.CUSTOMERS} />
      <NormalPagination
        messageEmpty="No Customers found"
        queryPagination={getCustomersQuery}
      >
        <TableStyled
          keyAccessor="id"
          columns={customHeader.filter(
            (x) =>
              x.accessor ===
                displaySettingList?.find((y) => y === x.accessor) ||
              x.accessor === "settings"
          )}
          data={listRow}
          withIndex
          startIndex={startIndex}
          CellComponent={CellComponent}
          onRowClick={onRowClick}
          sortSettingLabel={TableTypes.CUSTOMERS}
          sortList={customersHeaders}
          sortListDefault={customersHeadersDefault}
          sortable
          editable
        />
      </NormalPagination>
    </Wrapper>
  );
};

export default CustomersList;

const Wrapper = styled(Container)`
  padding: 0 1.875em;
  margin: 1.875em 0;
  margin-top: 0.8em;
  min-height: 80vh;
  justify-content: flex-start;
  align-items: flex-start;
`;

const TableStyled = styled(Table)`
  min-height: 80vh;
  justify-content: flex-start;
  align-items: flex-start;

  td {
    position: relative;
    word-wrap: break-word;
  }
  th {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;
