import { allRoles } from "common/types/api/roles";

export const authorize = allRoles;

import ApiHelper from "api/ApiHelper";
import { IDocumentListInfo } from "common/types/api/document";
import { API_BASE_URL } from "utils/UrlList";
export type IGetDocumentList = {};

export type IGetDocumentListResponse = IDocumentListInfo;

const getDocumentList =
  async ({}: IGetDocumentList): Promise<IGetDocumentListResponse> => {
    try {
      const res = await ApiHelper.apiClient.get<IGetDocumentListResponse>(
        `${API_BASE_URL}/api/v1/documents-types.json`,
        ApiHelper.getHeaders()
      );

      const data = res.data;
      return data.map((doc) => ({
        ...doc,
        canBeLookup: (doc as unknown as { lookup: boolean }).lookup ?? false,
        canBeNfc: (doc as unknown as { nfc: boolean }).nfc ?? false,
      }));
    } catch (error) {
      ApiHelper.errorHandler(error, true);
    }
  };

export default getDocumentList;
