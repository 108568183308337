import AdminList from "./AdminList";
import CustomButton from "components/reusable/CustomButton";
import { ContentBody } from "components/core/Styled/Layouts";
import { routesList } from "utils/routesList";
import { useGoTo } from "common/hooks/paths/useGoTo";
import { useCallback, useMemo } from "react";
import NavBar from "components/layouts/Navbar";

const AdminsListPage = () => {
  const goTo = useGoTo();

  const onClickCreateAdminButton = useCallback(() => {
    goTo(routesList.admins.routes.create);
  }, [goTo]);

  const createAdminComponent = useMemo(
    () => (
      <CustomButton onClick={onClickCreateAdminButton} title="Create admin" />
    ),
    [onClickCreateAdminButton]
  );

  return (
    <NavBar
      title="Admins"
      showEnvironmentDisplay
      showTitle
      buttonTitle="Create admin"
      ContentComponent={createAdminComponent}
    >
      <ContentBody>
        <AdminList />
      </ContentBody>
    </NavBar>
  );
};

export default AdminsListPage;
