import React, { useMemo } from "react";
import { useSelectCustomer } from "common/hooks/session/useSelectCustomer";
import styled from "styled-components";
import { Container } from "components/core/Styled/Layouts";
import { routesList } from "utils/routesList";
import { formatLocalDate } from "utils/dateHelpers";
import { BiCalendar, BiListCheck, BiPackage } from "react-icons/bi";
import { FiExternalLink, FiSettings } from "react-icons/fi";
import { BsChat } from "react-icons/bs";
import { icons } from "assets/images";

const AnalyticsDisplayTestingPage = () => {
  const { companyNameAlias, expiryDate } = useSelectCustomer();
  const expiryDateText = useMemo(
    () => formatLocalDate(expiryDate),
    [expiryDate]
  );

  return (
    <ContentWrapper>
      <Box>
        <FlexGap24>
          <IconSpan>
            <BiCalendar />
          </IconSpan>
          <div>
            Your <b>{companyNameAlias}</b> account{" "}
            {expiryDateText && (
              <span>
                is valid until <B>{expiryDateText}</B>
              </span>
            )}
            <div>
              We provide SDKs and APIs that seamlessly integrate identity
              capabilities into your applications and services.
            </div>
          </div>
        </FlexGap24>
        <img src={icons.calendarIcon} alt="calendar" />
      </Box>
      <Box>
        <FlexGap24>
          <IconSpan>
            <FiSettings />
          </IconSpan>
          <div>
            <BoxTitle>Add client secrets</BoxTitle>
            To use our SDKs and APIs, you will need a client secret. You can add
            and enable a client secret on our Credentials page.
          </div>
        </FlexGap24>
        <Link href={routesList.credentials.routes.main} rel="noreferrer">
          Credentials
        </Link>
      </Box>
      <Box>
        <FlexGap24>
          <IconSpan>
            <BiListCheck />
          </IconSpan>
          <div>
            <BoxTitle>SDKs & APIs Integration</BoxTitle>
            For more information about integrating our SDKs and APIs, please
            refer to our documentation.
          </div>
        </FlexGap24>
        <Link
          href={routesList.documentation.path}
          target="_blank"
          rel="noreferrer"
        >
          Read More
          <FiExternalLink />
        </Link>
      </Box>
      <Box>
        <FlexGap24>
          <IconSpan>
            <BiPackage />
          </IconSpan>
          <div>
            <BoxTitle>No-code KYC Service</BoxTitle>
            Additionally, uqudo offers a No-code KYC Service that incorporates
            the uqudo SDK. This service provides our identity capabilities
            without the need for development or code integrations.
            <Muted>
              Learn more about{" "}
              <a
                href="https://docs.uqudo.com/docs/kyc/no-code-kyc"
                target="_blank"
                rel="noreferrer"
              >
                No-Code KYC service
                <FiExternalLink />
              </a>
            </Muted>
          </div>
        </FlexGap24>
        <Link href={routesList.noCodeIntegration.routes.main} rel="noreferrer">
          No-Code KYC
        </Link>
      </Box>

      <Box>
        <FlexGap24>
          <IconSpan>
            <BsChat />
          </IconSpan>
          <div>
            <BoxTitle>Help & Support</BoxTitle>
            If you have any questions, please raise a ticket through our Support
            website, and our Team will assist you in resolving your issues.
          </div>
        </FlexGap24>
        <Link href={routesList.support.path} target="_blank" rel="noreferrer">
          Help Center
          <FiExternalLink />
        </Link>
      </Box>
    </ContentWrapper>
  );
};

export default AnalyticsDisplayTestingPage;

const ContentWrapper = styled(Container)`
  width: 100%;
  padding: 3.75em;
  align-items: flex-start;
  @media only screen and (max-width: 768px) {
    padding: 2.75em 0em;
  }
`;

const Box = styled.div`
  border: 1px solid #d6e4ff;
  padding: 24px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const BoxTitle = styled.div`
  font-weight: 600;
  margin-bottom: 10px;
`;
const FlexGap24 = styled.div`
  display: flex;
  gap: 24px;
  max-width: 70%;
`;

const Link = styled.a`
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.color.main};
  color: ${(props) => props.theme.color.main};
  padding: 12px 16px;
  background: #fff;
  flex: none;
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 8px;
  transition: background 0.3s ease;
  cursor: pointer;
  &:hover {
    background: #ebf1ff;
  }
`;

const IconSpan = styled.span`
  flex: none;
  background: #ebf1ff;
  color: ${(props) => props.theme.color.main};
  width: 48px;
  height: 48px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const B = styled.b`
  color: ${(props) => props.theme.color.main};
  display: block;
  margin: 10px 0;
`;

const Muted = styled.div`
  color: grey;
  margin-top: 10px;
  font-size: 14px;
  & a {
    color: ${(props) => props.theme.color.main};
    text-decoration: underline;
    display: inline-flex;
    align-items: center;
    gap: 4px;
  }
`;
