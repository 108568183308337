import { Container } from "components/core/Styled/Layouts";
import { Heading3, SubTitle } from "components/core/Styled/Texts";
import IconContainer from "components/core/IconContainer";
import Button from "components/reusable/Button";
import { MAX_SIZE_FILE } from "utils/constants";
import { isImageType } from "utils/helpers";
import useFile from "common/hooks/helpers/useFile";
import { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { BsFileEarmarkPlus } from "react-icons/bs";
import styled from "styled-components";
import { GrClose } from "react-icons/gr";

const accept = {
  "image/svg+xml": [".svg"],
  "image/png": [".png"],
  "image/jpeg": [".jpeg", ".jpg"],
};

export type TFile = File | undefined;
interface IProps {
  className?: string;
  file: TFile;
  imgSrc: string | undefined | null;
  setFile: (str: TFile) => void;
  setImageSrc?: (imgSrc: string | undefined | null) => void;
  description?: string;
  buttonText?: string;
  acceptedOnlyThoseFiles?: string[];
  enable?: boolean;
}

const FileUpload = ({
  className,
  file,
  imgSrc,
  setFile,
  setImageSrc,
  description,
  buttonText,
  enable = true,
}: IProps) => {
  const [src] = useFile(file);
  const isImage = useMemo(() => isImageType(file), [file]);
  const onChange = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      setFile(file);
    },
    [setFile]
  );

  const removeFile = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      setFile(undefined);
      if (setImageSrc) setImageSrc(null);
    },
    [setFile, setImageSrc]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onChange,
    disabled: !enable,
    accept,
    maxFiles: 1,
    multiple: false,
    maxSize: MAX_SIZE_FILE,
  });

  return (
    <StyledContainer className={className}>
      {description && <SubTitle>{description}</SubTitle>}
      <Container width="100%" {...getRootProps({})}>
        <input {...getInputProps()} />
        {file ? (
          <StyledImageContainer display="true">
            {isImage ? (
              <Image alt="file" src={src} />
            ) : (
              <Title>{file.name}</Title>
            )}
            <RemoveButton onClick={removeFile}>
              <StyledIconContainer>
                <GrClose />
              </StyledIconContainer>
            </RemoveButton>
          </StyledImageContainer>
        ) : imgSrc ? (
          <StyledImageContainer display="true">
            {<Image alt="file" src={imgSrc} />}
            <RemoveButton onClick={removeFile}>
              <StyledIconContainer>
                <GrClose />
              </StyledIconContainer>
            </RemoveButton>
          </StyledImageContainer>
        ) : (
          <StyledImageContainer>
            <StyledUpload>
              <BsFileEarmarkPlus />
            </StyledUpload>
            <Title>{buttonText}</Title>
          </StyledImageContainer>
        )}
      </Container>
    </StyledContainer>
  );
};

export default FileUpload;

const StyledContainer = styled(Container)`
  width: 26.25em;
  height: 7.5em;
  max-height: 7.5em;
  margin: 1em 0 0 0;
`;

const Image = styled.img`
  height: 90%;
  width: 80%;
  object-fit: contain;
`;

const StyledImageContainer = styled(Container)<{ display?: string }>`
  width: 100%;
  min-height: 7.5em;
  max-height: 7.5em;
  position: relative;
  background-color: ${(props) =>
    props.display === "true"
      ? props.theme.background.grey
      : props.theme.background.mainLight};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  border-color: ${(props) => props.theme.color.main};
  border-style: ${(props) => (props.display === "true" ? "none" : "dotted")};
`;
const RemoveButton = styled(Button)`
  position: absolute;
  top: 0.2em;
  right: 0.2em;
  z-index: 10;
`;
const StyledIconContainer = styled(IconContainer)`
  width: 1.625em;
  height: 1.625em;
`;

const StyledUpload = styled(IconContainer)`
  svg {
    width: 1.125em;
    height: 1.125em;
  }
`;

const Title = styled(Heading3)`
  color: ${(props) => props.theme.color.main};
`;
