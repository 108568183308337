import { Container, Row } from "components/core/Styled/Layouts";
import Table, { IHeader } from "components/core/Table";
import { ICellComponentProps } from "components/core/Table/CellComponentDefault";
import ConfirmationPopup from "components/Modal/ConfirmationPopup";
import {
  useGetHostedConfigsQuery,
  useDeleteHostedConfigByIdMutation,
  useCreateHostedConfigMutation,
} from "services/query/hostedKyc";
import primaryTheme from "common/theme/primaryTheme";
import NormalPagination from "components/pagination/NormalPagination";
import Button from "components/reusable/Button";
import { routesList } from "utils/routesList";
import { useGoTo } from "common/hooks/paths/useGoTo";
import { useSearchSetting } from "common/hooks/settings/useSetting";
import Search from "components/Search/Search";
import { Heading3, Heading4 } from "components/core/Styled/Texts";
import { getMessageError } from "utils/helpers";
import { IoMdAdd } from "react-icons/io";
import { TiInfoOutline } from "react-icons/ti";
import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
import styled, { useTheme } from "styled-components";
import { customToast } from "utils/customToast";
import IconContainer from "components/core/IconContainer";
import {
  ITypeHostedKyc,
  typeHostedKycToText,
} from "common/types/api/hostedKyc";
import { TableTypes } from "common/types/api/preferences";
import { useStoreData } from "common/hooks/session/useStoreData";
import LoadingPage from "components/core/Loaders/LoadingPage";

type IRow = {
  id: string;
  name: string;
  linkId: string;
  type?: string;
  settings: {
    id: string;
    storeData?: boolean;
    webhook: boolean;
    refetch: () => void;
  };
};

const headers: IHeader[] = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Link ID",
    accessor: "linkId",
  },
  {
    Header: "Type",
    accessor: "type",
  },

  {
    Header: "",
    accessor: "settings",
  },
];

export type IProps = {
  id: string;
  storeData: boolean;
  webhook: boolean;
  refetch: () => void;
};

export const CellComponent = ({ cell, row, index }: ICellComponentProps) => {
  const theme = useTheme();

  const info = cell.value as IProps;
  const storeData = cell.row.values.settings.storeData;
  const webhook = cell.row.values.settings.webhook;
  const [confirmDeleteHostedKyc, setConfirmDeleteHostedKyc] =
    useState<boolean>(false);

  const deleteHostedKycQuery = useDeleteHostedConfigByIdMutation();
  const deleteHostedKycMutateAsync = useMemo(
    () => deleteHostedKycQuery.mutateAsync,
    [deleteHostedKycQuery.mutateAsync]
  );

  const onDeleteHostedKyc: React.MouseEventHandler<HTMLDivElement> =
    useCallback(
      (event) => {
        event.stopPropagation();
        setConfirmDeleteHostedKyc(!confirmDeleteHostedKyc);
      },
      [confirmDeleteHostedKyc]
    );

  const onConfirmDeleteHostedKyc = useCallback(async () => {
    try {
      await deleteHostedKycMutateAsync({ configId: info.id });
      customToast.success("No-code KYC deleted successfully");
      info.refetch();
    } catch (error) {
      customToast.error(getMessageError(error, "No-code KYC deletion failed"));
      console.error(error);
    } finally {
      setConfirmDeleteHostedKyc(false);
    }
  }, [deleteHostedKycMutateAsync, info]);

  return (
    <td {...cell.getCellProps()}>
      {index === row.cells.length - 1 ? (
        <>
          <StyledOptions>
            <ConfirmationPopup
              text="Are you sure you want to delete this No-code KYC ?"
              cancelText="Cancel"
              confirmText="Confirm"
              close={onDeleteHostedKyc}
              onConfirmDelete={onConfirmDeleteHostedKyc}
              show={confirmDeleteHostedKyc}
            />

            <Button onClick={onDeleteHostedKyc}>
              <RiDeleteBin5Line color={primaryTheme.color.main} />
            </Button>
          </StyledOptions>
        </>
      ) : index === 0 ? (
        <Row justifyContent="flex-start">
          {!storeData && !webhook && (
            <StyledIcon>
              <TiInfoOutline color={theme.color.danger} />
            </StyledIcon>
          )}
          <Heading3>{row.values.name}</Heading3>
        </Row>
      ) : (
        cell.render("Cell")
      )}
    </td>
  );
};

const nameNewConfiguration = "new configuration";
const createConfigTypes: ITypeHostedKyc[] = [
  "DESKTOP_MOBILE_BROWSER",
  "MOBILE_BROWSER",
  "MOBILE_APP",
];

const ListPageDisplay = () => {
  const goTo = useGoTo();
  const theme = useTheme();
  const { searchValue, updateSearch } = useSearchSetting(
    TableTypes.HOSTED_KYC_CONFIG
  );
  const getHostedConfigQuery = useGetHostedConfigsQuery();
  const { storeData, loading: loadingStoreData } = useStoreData();

  const getHostedConfigRefetch = useMemo(
    () => getHostedConfigQuery.refetch,
    [getHostedConfigQuery.refetch]
  );
  const createRef = useRef() as RefObject<HTMLDivElement>;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const createHostedKycQuery = useCreateHostedConfigMutation();

  const createHostedKycMutateAsync = useMemo(
    () => createHostedKycQuery.mutateAsync,
    [createHostedKycQuery.mutateAsync]
  );

  const listRow: IRow[] = useMemo(() => {
    if (getHostedConfigQuery.allData === undefined) return [];
    return getHostedConfigQuery.allData.results.map((val) => ({
      id: val.id,
      name: val.name,
      linkId: val.linkId,
      type: typeHostedKycToText(val.type ?? "DESKTOP_MOBILE_BROWSER"),
      settings: {
        id: val.id,
        storeData: storeData ?? false,
        webhook: val.webhook !== null ? true : false,
        refetch: getHostedConfigRefetch,
      },
    }));
  }, [storeData, getHostedConfigQuery.allData, getHostedConfigRefetch]);

  const isWebhookNull = useMemo(() => {
    let webhookStatus = true;
    getHostedConfigQuery?.allData?.results.forEach((val) => {
      if (val.webhook === null) {
        webhookStatus = false;
      }
    });
    return webhookStatus;
  }, [getHostedConfigQuery?.allData?.results]);

  const onRowClick = useCallback(
    (row: unknown) => {
      const { id } = row as IRow;
      goTo(routesList.noCodeIntegration.routes.update, id);
    },
    [goTo]
  );

  const newConfigurationsNumber = useMemo(() => {
    const res = getHostedConfigQuery.allData?.results.reduce(
      (curr, { name }) => {
        const str = `${nameNewConfiguration} `;
        const match = name.search(str);
        const num = parseInt(name.substr(match + str.length));
        return Math.max(curr, num || 0);
      },
      0 as number
    );
    return (res ?? 0) + 1;
  }, [getHostedConfigQuery.allData?.results]);

  const onConfirmCreateHostedKyc = useCallback(
    async (type: ITypeHostedKyc) => {
      try {
        const res = await createHostedKycMutateAsync({
          name: `${nameNewConfiguration} ${newConfigurationsNumber}`,
          type,
        });
        getHostedConfigRefetch();
        goTo(routesList.noCodeIntegration.routes.update, res.id);
      } catch (error) {
        customToast.error(
          getMessageError(error, "No-code KYC creation failed")
        );
        console.error(error);
      }
    },
    [
      createHostedKycMutateAsync,
      getHostedConfigRefetch,
      goTo,
      newConfigurationsNumber,
    ]
  );

  const onOpenModel: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.stopPropagation();
      setIsOpen((isOpen) => !isOpen);
    },
    []
  );

  const handleClickOutside = useCallback(
    (event: MouseEvent | TouchEvent) => {
      const isInside =
        !!createRef?.current &&
        createRef.current.contains(event.target as Node);
      const isOutside = isOpen && !isInside;
      if (isOpen) {
        event.stopPropagation();
      }

      if (isOutside) {
        setIsOpen(false);
      }

      return false;
    },
    [isOpen]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  const startIndex = useMemo(() => {
    if (!getHostedConfigQuery.allData?.total) return 1;
    return (
      getHostedConfigQuery.pageSetting.pageNum *
        getHostedConfigQuery.pageSetting.pageSize +
      1
    );
  }, [
    getHostedConfigQuery.allData?.total,
    getHostedConfigQuery.pageSetting.pageNum,
    getHostedConfigQuery.pageSetting.pageSize,
  ]);

  if (loadingStoreData) return <LoadingPage />;

  return (
    <Wrapper>
      <SearchWrapper>
        {updateSearch !== undefined && (
          <Search clearable defaultValue={searchValue} update={updateSearch} />
        )}
        <CreateButton iconBefore={<IoMdAdd />} onClick={onOpenModel}>
          <Heading3>Add Configuration</Heading3>
          {isOpen && (
            <DropdownWrapper ref={createRef}>
              {createConfigTypes.map((type) => (
                <StyledCreateButton
                  key={type}
                  onClick={() => onConfirmCreateHostedKyc(type)}
                >
                  <Heading3>{typeHostedKycToText(type)}</Heading3>
                </StyledCreateButton>
              ))}
            </DropdownWrapper>
          )}
        </CreateButton>
      </SearchWrapper>
      {!storeData && !isWebhookNull && (
        <Note>
          <StyledIcon>
            <TiInfoOutline color={theme.color.danger} />
          </StyledIcon>
          <Heading4>
            Marked configurations are not complete. Data will be lost. Enable a
            webhook inside the configuration or contact the uqudo support team
            to enable storage in the portal here{" "}
            <a href={routesList.support.path} target="_blank" rel="noreferrer">
              here
            </a>
          </Heading4>
        </Note>
      )}
      <NormalPagination
        messageEmpty="No configurations found"
        queryPagination={getHostedConfigQuery}
      >
        <TableStyled
          keyAccessor="id"
          columns={headers}
          data={listRow}
          withIndex
          startIndex={startIndex}
          CellComponent={CellComponent}
          onRowClick={onRowClick}
        />
      </NormalPagination>
    </Wrapper>
  );
};

export default ListPageDisplay;

const Wrapper = styled(Container)`
  padding: 0 1.875em;
  margin: 1.875em 0;
  margin-top: 0.8em;
  min-height: 80vh;
  justify-content: flex-start;
  align-items: flex-start;
`;

const DropdownWrapper = styled(Container)`
  position: absolute;
  top: 4em;
  right: 2em;
  padding: 0.6em 0.625em;
  border-radius: ${(props) => props.theme.borderRadius.primary};
  box-shadow: ${(props) => props.theme.boxShadow.main};
  background-color: white;
  width: 13.8em;
  align-items: flex-start;
  z-index: 10;
`;

const Note = styled(Row)`
  width: 100%;
  min-height: 3.375em;
  background-color: ${(props) => props.theme.background.lightDanger};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  margin: 0.75em 0 1.875em 0;
  justify-content: flex-start;
  h4 {
    text-transform: none;
  }
`;

const StyledIcon = styled(IconContainer)`
  padding: 0 0.8em 0 1em;
  svg {
    width: 1.2em;
    height: 1.2em;
  }
`;
const StyledCreateButton = styled(Button)`
  width: 100%;
  min-height: 2.0625em;
  margin: 0.3em 0;
  color: ${(props) => props.theme.color.black};
  justify-content: flex-start;
  &:hover {
    color: ${(props) => props.theme.color.main};
  }
`;

const SearchWrapper = styled(Row)`
  padding: 0.25em 0;
  margin-bottom: 0.8em;
  align-items: center;
  min-height: 2.8125em;
  width: 100%;
  justify-content: space-between;
`;

const CreateButton = styled(Button)`
  border: ${(props) => props.theme.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: 12.5em;
  min-height: 2.1875em;
`;

const StyledOptions = styled(Container)`
  width: 1.25em;
  height: 1.25em;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    background-color: white;
  }
`;

const TableStyled = styled(Table)`
  //min-height: 80vh;
  justify-content: flex-start;
  align-items: flex-start;
  td {
    position: relative;
    word-wrap: break-word;
  }
  th {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;
