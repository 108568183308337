import { getInfoEnum, TEnumValue } from "common/types/helpers/enumHelpers";
import { EmptyType } from "common/types/common";

export const TableTypes = getInfoEnum({
  GENERAL: "GENERAL",
  AUDITS: "AUDITS",
  REPORTS: "REPORTS",
  USERS: "USERS",
  CUSTOMERS: "CUSTOMERS",
  CLIENTS: "CLIENTS",
  ADMINS: "ADMINS",
  HOSTED_KYC_CONFIG: "HOSTED_KYC_CONFIG",
} as const);

export type TTableTypes = TEnumValue<typeof TableTypes>;

export type TPreferenceType = TTableTypes;

export const TABLE_TYPES_LIST = TableTypes.keys;

export type TSortFields = {
  sortFields?: EmptyType<string[]>;
};

export type TPreferences = TSortFields & {
  filter?: EmptyType<Record<string, string[]>>;
};

export type TPreferencesAuditing = TPreferences;
export type TPreferencesReports = TPreferences;
export type TPreferencesCustomers = TPreferences;
export type TPreferencesUsers = TPreferences;
