import { useGetCustomerGlobalInfoByIdMutation } from "services/query/customers";
import { useUpdateSelectedCustomer } from "./useSelectCustomer";
import { useCallback, useEffect, useMemo } from "react";

export const useStoreData = () => {
  const { customerSelected, patchSelectedCustomer } =
    useUpdateSelectedCustomer();

  const getCustomerGlobalInfoByIdMutation =
    useGetCustomerGlobalInfoByIdMutation();

  const getCustomerGlobalInfoByIdMutateAsync = useMemo(
    () => getCustomerGlobalInfoByIdMutation.mutateAsync,
    [getCustomerGlobalInfoByIdMutation.mutateAsync]
  );

  const fetch = useCallback(async () => {
    const res = await getCustomerGlobalInfoByIdMutateAsync({
      customerId: customerSelected.id,
    });
    patchSelectedCustomer({ storeData: res.storeData });
  }, [
    getCustomerGlobalInfoByIdMutateAsync,
    customerSelected.id,
    patchSelectedCustomer,
  ]);

  useEffect(() => {
    if (customerSelected.storeData === undefined) {
      fetch();
    }
  }, [fetch, customerSelected.storeData]);

  return {
    storeData: customerSelected.storeData,
    loading: customerSelected.storeData === undefined,
  };
};
