import { useContextEditCustomer } from "common/contexts/editCustomerContext";
import { IProductFaceFlow } from "common/types/api/product";
import ButtonAction from "components/ButtonAction";
import { Container, Row } from "components/core/Styled/Layouts";
import { useCallback, useMemo } from "react";
import { HiOutlineCheck } from "react-icons/hi";
import styled from "styled-components";
import CustomToggle from "./CustomToggle";

export type IProps = {
  product: IProductFaceFlow;
};

const FaceFlowDisplay = ({ product }: IProps) => {
  const { productsConfigs, onUpdateProduct, updateProductLoading } =
    useContextEditCustomer();

  const configs = useMemo(
    () => productsConfigs[product.productId],
    [product.productId, productsConfigs]
  );

  const save = useCallback(() => {
    onUpdateProduct({
      ...product,
      storeData: !!configs?.["storeData"],
      dataClearanceTime: configs?.["storeData"]
        ? product.dataClearanceTime
        : undefined,
    });
  }, [configs, onUpdateProduct, product]);

  const touched = useMemo(() => {
    if (!!configs?.["storeData"] !== !!product.storeData) return true;
    return false;
  }, [configs, product.storeData]);

  return (
    <Wrapper width="100%">
      {product.storable && (
        <CustomToggle
          product={product}
          toggleLabel="Store Data"
          toggleID="storeData"
          toggle={!!configs?.["storeData"]}
          buttonLabel="Settings"
          pageID="DATA_STORAGE"
        />
      )}
      {touched && (
        <Row>
          <ButtonActionStyled
            label="Save"
            loading={updateProductLoading}
            onClick={save}
            iconBefore={<HiOutlineCheck />}
          />
        </Row>
      )}
    </Wrapper>
  );
};

export default FaceFlowDisplay;

const Wrapper = styled(Container)`
  width: 100%;
  align-items: flex-start;
`;

const ButtonActionStyled = styled(ButtonAction)`
  width: 26.25em;
  margin-top: 3em;
`;
