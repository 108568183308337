import Button from "components/reusable/Button";
import { clickBehaviorCss } from "components/core/Styled/AtomicCss";
import { Container } from "components/core/Styled/Layouts";
import React, { PropsWithChildren, ReactElement } from "react";
import styled from "styled-components";
import { Heading3 } from "components/core/Styled/Texts";

export type IProps = {
  icon?: ReactElement;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  text: string;
};

type IDropProps = {
  isCustomer?: boolean;
};

export const DropdownOptions = ({
  children,
  isCustomer,
}: PropsWithChildren<IDropProps>) => {
  return (
    <StyledContainer isCustomer={isCustomer}>
      <Pointer />
      <Wrapper>{children}</Wrapper>
    </StyledContainer>
  );
};

export const Options = ({ onClick, icon, text }: PropsWithChildren<IProps>) => {
  return (
    <Item onClick={onClick} iconBefore={icon}>
      <Title>{text}</Title>
    </Item>
  );
};

const Wrapper = styled(Container)`
  width: 100%;
  box-shadow: ${(props) => props.theme.boxShadow.main};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  color: ${(props) => props.theme.color.main};
  background-color: ${(props) => props.theme.background.main};
  gap: 0.375em;
  padding: 0.625em;
  z-index: 2;
`;
const Pointer = styled.div`
  position: absolute;
  height: 1.25em;
  width: 1.25em;
  margin: auto 0;
  transform: rotate(315deg);
  border-radius: 0 0 0.375em 0;
  margin-right: -0.625em;
  box-shadow: ${(props) => props.theme.boxShadow.main};
  background-color: ${(props) => props.theme.background.main};
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
`;
const StyledContainer = styled(Container)<{ isCustomer?: boolean }>`
  right: 5em;
  min-width: ${(props) => (props.isCustomer ? "16.725em" : "10.6254em")};
  min-height: 5.625em;
  position: absolute;
  z-index: 10;
  top: ${(props) => (props.isCustomer ? "-4.5em" : "-1.5em")};
`;
const Item = styled(Button)`
  min-width: 100%;
  flex-direction: row;
  align-items: flex-start;

  ${clickBehaviorCss};
  color: ${(props) => props.theme.color.primary};
  &:hover {
    color: ${(props) => props.theme.color.main};
  }
  button {
    width: 100%;
    align-items: flex-start;
  }
`;

const Title = styled(Heading3)`
  font-weight: 500;
`;
