import { useAppMutation, useAppQuery } from "./hooks";
import linkedDevicesApi from "api/linkedDevicesApi";
import { useSelectCustomer } from "common/hooks/session/useSelectCustomer";
import { IUnlinkDevice } from "api/linkedDevicesApi/unlinkDevice";

export const useGetLinkedDevicesQuery = () => {
  const { customerId } = useSelectCustomer();

  const query = useAppQuery(() => {
    return linkedDevicesApi.getLinkedDevices({
      customerId,
    });
  }, ["useGetLinkedDevicesQuery", customerId]);

  return query;
};

export const useGenerateQRQuery = () => {
  const { customerId } = useSelectCustomer();
  const query = useAppQuery(() => {
    return linkedDevicesApi.generateQR({
      customerId,
    });
  }, ["useGenerateQRQuery", customerId]);

  return query;
};

export const useUnlinkDeviceMutation = () => {
  const { customerId } = useSelectCustomer();

  const query = useAppMutation((arg: Omit<IUnlinkDevice, "customerId">) => {
    return linkedDevicesApi.unlinkDevice({
      ...arg,
      customerId,
    });
  });

  return query;
};
