import CustomRouter from "./CustomRouter";
import defaultHistory, { IHistoryBrowser } from "./historyBrowser";

export type IProps = {
  children?: React.ReactNode;
  historyBrowser?: IHistoryBrowser;
};

const RouterProvider = ({
  children,
  historyBrowser = defaultHistory,
}: IProps) => {
  return (
    <CustomRouter historyBrowser={historyBrowser}>{children}</CustomRouter>
  );
};

export default RouterProvider;
