import { useContextNoCode } from "common/contexts/noCodeContext";
import {
  CONFIG_DEFAULT_VALUES,
  IAdditionalScanConfig,
  IBackgroundCheckConfig,
  IDocumentConfigs,
  IFaceConfig,
  IOnboardingWorkflow,
} from "common/types/api/hostedKyc";
import { EmptyType } from "common/types/common";
import { useCallback, useEffect, useMemo, useState } from "react";
import { icons } from "assets/images";
import { length } from "utils/helpers";

export type IStopsProps = {
  title: string;
  icon: string;
  active: boolean;
  key: "optional" | "required" | "backgroundCheckConfig" | "faceConfig";
};

export const workflowStatic: IStopsProps[] = [
  {
    key: "required",
    title: "Mandatory ID Scanning",
    icon: icons.opId,
    active: false,
  },
  {
    key: "optional",
    title: "User's Choice ID Scanning",
    icon: icons.reqId,
    active: false,
  },
  {
    key: "faceConfig",
    title: "Face Authentication",
    icon: icons.faceCheck,
    active: false,
  },
  {
    key: "backgroundCheckConfig",
    title: "Background Screening",
    icon: icons.bgCheck,
    active: false,
  },
];

function useWorkflowConfigs() {
  const {
    configId,
    configsDisplay,
    saveConfigs,
    sdkCustomerConfig,
    fetchSdkCustomerConfig,
  } = useContextNoCode();

  useEffect(() => {
    if (!sdkCustomerConfig) fetchSdkCustomerConfig();
  }, [fetchSdkCustomerConfig, sdkCustomerConfig]);

  const [onboardingWorkflowConf, setOnboardingWorkflowConf] =
    useState<EmptyType<IOnboardingWorkflow>>();
  const [stops, setStops] = useState<IStopsProps[]>(workflowStatic);
  const [activeNode, setActiveNode] = useState<IStopsProps["key"]>();

  const onChangeScanAdditionalConfigs = useCallback(
    (configs: Partial<IAdditionalScanConfig>) => {
      setOnboardingWorkflowConf((old) => ({
        ...old,
        scanConfig: {
          ...old?.scanConfig,
          ...configs,
        },
      }));
    },
    []
  );

  const onChangeIdConfig = useCallback((config: IDocumentConfigs[]) => {
    if (config.length > 0) {
      setOnboardingWorkflowConf((old) => ({
        ...old,
        scanConfig: {
          ...old?.scanConfig,
          required: { documentsConfigs: config },
        },
      }));
    } else {
      setOnboardingWorkflowConf((old) => ({
        ...old,
        scanConfig: {
          ...old?.scanConfig,
          required: null,
        },
      }));
    }
  }, []);

  const onChangeOptionalIdConfig = useCallback(
    (
      config: EmptyType<
        {
          documentsConfigs: IDocumentConfigs[];
        }[]
      >
    ) => {
      if (config)
        if (config[0].documentsConfigs.length > 0) {
          setOnboardingWorkflowConf((old) => ({
            ...old,
            scanConfig: {
              ...old?.scanConfig,
              optional: config,
            },
          }));
        } else {
          setOnboardingWorkflowConf((old) => ({
            ...old,
            scanConfig: {
              ...old?.scanConfig,
              optional: null,
            },
          }));
        }
    },
    []
  );

  const onChangeFaceConfig = useCallback((config: EmptyType<IFaceConfig>) => {
    setOnboardingWorkflowConf((old) => ({
      ...old,
      faceConfig: {
        ...old?.faceConfig,
        enabled: true,
        ...config,
      },
    }));
  }, []);

  const onChangeBgCheckConfig = useCallback(
    (config: EmptyType<IBackgroundCheckConfig>) => {
      setOnboardingWorkflowConf((old) => ({
        ...old,
        backgroundCheckConfig: {
          ...old?.backgroundCheckConfig,
          enabled: true,
          ...config,
        },
      }));
    },
    []
  );

  const onAddStop = useCallback(
    (key: IStopsProps["key"]) => {
      setStops((stops) => {
        const newStops = [...stops];
        newStops[stops.findIndex((x) => x.key === key)].active = true;
        return stops;
      });

      if (key === "backgroundCheckConfig") {
        onChangeBgCheckConfig({
          enabled: true,
          disableConsent: CONFIG_DEFAULT_VALUES.disableConsent,
          skipView: CONFIG_DEFAULT_VALUES.skipView,
          ...(sdkCustomerConfig?.bcMonitoringAllowed
            ? { enableMonitoring: CONFIG_DEFAULT_VALUES.enableMonitoring }
            : {}),
        });
      } else if (key === "faceConfig") {
        onChangeFaceConfig({
          enabled: true,
          maxAttempts: CONFIG_DEFAULT_VALUES.maxAttempts,
          ...(sdkCustomerConfig?.oneToNFaceVerificationAllowed
            ? {
                oneToNFaceVerification:
                  CONFIG_DEFAULT_VALUES.oneToNFaceVerification,
              }
            : {}),
        });
      } else {
        onChangeScanAdditionalConfigs({
          ...(sdkCustomerConfig?.enforceDocumentExpiryCheckAllowed
            ? {
                enforceDocumentExpiryCheck:
                  CONFIG_DEFAULT_VALUES.enforceDocumentExpiryCheck,
              }
            : {}),
          ...(sdkCustomerConfig?.screenScanningAllowed
            ? {
                allowNonPhysicalDocuments:
                  CONFIG_DEFAULT_VALUES.allowNonPhysicalDocuments,
              }
            : {}),
          ...(sdkCustomerConfig?.disableTamperingRejectionAllowed
            ? {
                disableTamperingRejection:
                  CONFIG_DEFAULT_VALUES.disableTamperingRejection,
              }
            : {}),
        });
      }
    },
    [
      onChangeBgCheckConfig,
      onChangeFaceConfig,
      onChangeScanAdditionalConfigs,
      sdkCustomerConfig?.bcMonitoringAllowed,
      sdkCustomerConfig?.disableTamperingRejectionAllowed,
      sdkCustomerConfig?.enforceDocumentExpiryCheckAllowed,
      sdkCustomerConfig?.oneToNFaceVerificationAllowed,
      sdkCustomerConfig?.screenScanningAllowed,
    ]
  );

  const onActivateNode = useCallback(
    (node: IStopsProps["key"]) => {
      setActiveNode(node);
    },
    [setActiveNode]
  );

  const isValidWorkflow = useCallback(() => {
    let enabled = false;
    if (
      length(onboardingWorkflowConf?.scanConfig?.required?.documentsConfigs) > 0
    ) {
      enabled = true;
    }
    if (length(onboardingWorkflowConf?.scanConfig?.optional) > 0) {
      return true;
    }
    return enabled;
  }, [onboardingWorkflowConf]);

  const saveWorkflow = useCallback(async () => {
    return await saveConfigs({ onboardingWorkflow: onboardingWorkflowConf });
  }, [onboardingWorkflowConf, saveConfigs]);

  const onboardingWorkflow = useMemo(
    () => configsDisplay?.onboardingWorkflow,
    [configsDisplay?.onboardingWorkflow]
  );

  useEffect(() => {
    if (onboardingWorkflow !== undefined) {
      setOnboardingWorkflowConf(onboardingWorkflow);
      setStops((oldStops) => {
        const updatedStops = oldStops.map((stp) => ({ ...stp, active: false }));
        const newStops = [...updatedStops];
        Object.entries(onboardingWorkflow ?? {}).forEach(([key, value]) => {
          if (value) {
            if (key !== "scanConfig") {
              const index = newStops.findIndex((x) => x.key === key);
              newStops[index].active = true;
            } else {
              Object.entries(onboardingWorkflow?.scanConfig ?? {}).forEach(
                ([scanKeys, values]) => {
                  if (values) {
                    const index = newStops.findIndex((x) => x.key === scanKeys);
                    if (workflowStatic.find((x) => x.key === scanKeys)) {
                      newStops[index].active = true;
                    }
                  }
                }
              );
            }
          }
        });
        return newStops;
      });
    }
  }, [onboardingWorkflow]);

  const deleteStopByKey = useCallback(
    (key: IStopsProps["key"]) => {
      const newStops = [...stops];
      newStops[stops.findIndex((x) => x.key === key)].active = false;
      const obj = { ...onboardingWorkflowConf };
      if (key !== "required" && key !== "optional") obj[key] = null;
      else {
        if (obj.scanConfig) {
          obj.scanConfig[key] = null;
        }
      }
      setOnboardingWorkflowConf(obj);
      if (activeNode === key) setActiveNode(undefined);
      setStops(newStops);
    },
    [activeNode, onboardingWorkflowConf, stops]
  );

  return {
    configId,
    configsDisplay,
    saveConfigs,
    sdkCustomerConfig,
    fetchSdkCustomerConfig,
    onboardingWorkflowConf,
    setOnboardingWorkflowConf,
    stops,
    setStops,
    activeNode,
    setActiveNode,
    onChangeOptionalIdConfig,
    onChangeFaceConfig,
    onChangeBgCheckConfig,
    onAddStop,
    onActivateNode,
    saveWorkflow,
    isValidWorkflow,
    onboardingWorkflow,
    deleteStopByKey,
    onChangeIdConfig,
    onChangeScanAdditionalConfigs,
  };
}

export default useWorkflowConfigs;
