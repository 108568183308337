import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // internal
  UserRoles.ROLE_UQUDO_SALES,
  // customers
  UserRoles.ROLE_CUSTOMER_ADMIN,
  UserRoles.ROLE_CUSTOMER_OWNER,
];

import ApiHelper from "api/ApiHelper";
import { assertNotEmpty } from "utils/assertion";
import { ICompany } from "common/types/api/company";
import { API_BASE_URL } from "utils/UrlList";
import { WithCustomerInfo } from "common/types/api/customer";

export type IUpdateCompanyDetails = WithCustomerInfo &
  Pick<ICompany, "companyNameAlias" | "updateVersion">;

export type IUpdateCompanyDetailsResponse = ICompany;

const updateCompanyDetails = async ({
  customerId,
  ...rest
}: IUpdateCompanyDetails): Promise<IUpdateCompanyDetailsResponse> => {
  try {
    assertNotEmpty(customerId);
    const res = await ApiHelper.apiClient.put<IUpdateCompanyDetailsResponse>(
      `${API_BASE_URL}/api/v1/customers/${customerId}/company`,
      rest,
      ApiHelper.getHeaders()
    );

    const data = res.data;

    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default updateCompanyDetails;
