import auth from "./authSlice";
import settings from "./settingsSlice";
import { combineReducers } from "redux";

const staticReducers = { auth, settings };

const createReducer = () => {
  return combineReducers(staticReducers);
};

const rootReducer = createReducer();

export default rootReducer;
