import useWorkflowConfigs from "./useWorkflowConfigs";
import { createContext, ReactNode, useContext } from "react";

type IWorkflowProps = ReturnType<typeof useWorkflowConfigs>;

export const ConfigWorkflowContext = createContext<IWorkflowProps | null>(null);

interface IProps {
  children?: ReactNode;
}

export const ConfigWorkflowProvider = ({ children }: IProps) => {
  const value = useWorkflowConfigs();

  return (
    <ConfigWorkflowContext.Provider value={value}>
      {children}
    </ConfigWorkflowContext.Provider>
  );
};

export const useContextWorkflow = () => {
  const value = useContext(ConfigWorkflowContext);
  if (!value) throw Error("context not provided");
  return value;
};
