import {
  BreakPagination,
  NextButton,
  PagesNumber,
  PrevButton,
} from "./PaginationButtons";
import { useMemo } from "react";

type IProps = {
  current?: number;
  count: number;
  setSelectedNumber: (newValue: number) => void;
};

const configPagination = {
  first: 2,
  middleLeft: 1,
  middleRight: 1,
  last: 2,
  next: true,
  prev: true,
  forceShowBreak: true,
};

const middleSize =
  configPagination.middleLeft + configPagination.middleRight + 1;

const minNumber = configPagination.first + configPagination.last + middleSize;

const CustomPagination = ({ current, count, setSelectedNumber }: IProps) => {
  const prevEnable = useMemo(
    () => configPagination.prev && current && current > 1,
    [current]
  );
  const nextEnable = useMemo(
    () => configPagination.next && current && current < count,
    [current, count]
  );

  const startMiddle = useMemo(() => {
    if (!current) return 0;
    return current - configPagination.middleLeft;
  }, [current]);

  const endMiddle = useMemo(() => {
    if (!current) return 0;
    return current + configPagination.middleRight;
  }, [current]);

  const leftBreak = useMemo(() => {
    return startMiddle > configPagination.first;
  }, [startMiddle]);

  const rightBreak = useMemo(() => {
    return endMiddle < count - configPagination.last;
  }, [count, endMiddle]);

  const middle = useMemo(() => {
    return Math.min(
      Math.max(startMiddle, configPagination.first + 1),
      count - configPagination.last - middleSize + 1
    );
  }, [count, startMiddle]);

  if (current === undefined || !count || count < 2) return null;

  if (count <= minNumber) {
    return (
      <>
        <PrevButton
          disabled={!prevEnable}
          title="Previous"
          onClick={() => setSelectedNumber(current - 1)}
        />
        <PagesNumber
          start={1}
          length={count}
          current={current}
          setSelectedNumber={setSelectedNumber}
        />
        <NextButton
          disabled={!nextEnable}
          title="Next"
          onClick={() => setSelectedNumber(current + 1)}
        />
      </>
    );
  }

  return (
    <>
      <PrevButton
        disabled={!prevEnable}
        title="Previous"
        onClick={() => setSelectedNumber(current - 1)}
      />
      <PagesNumber
        start={1}
        length={configPagination.first}
        current={current}
        setSelectedNumber={setSelectedNumber}
      />
      {(configPagination.forceShowBreak || leftBreak) && <BreakPagination />}
      <PagesNumber
        start={middle}
        length={middleSize}
        current={current}
        setSelectedNumber={setSelectedNumber}
      />
      {(configPagination.forceShowBreak || rightBreak) && <BreakPagination />}
      <PagesNumber
        start={count - configPagination.last + 1}
        length={configPagination.last}
        current={current}
        setSelectedNumber={setSelectedNumber}
      />
      <NextButton
        disabled={!nextEnable}
        title="Next"
        onClick={() => setSelectedNumber(current + 1)}
      />
    </>
  );
};
export default CustomPagination;
