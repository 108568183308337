import { useGoTo } from "common/hooks/paths/useGoTo";
import CustomersList from "./CustomersList";
import { ContentBody } from "components/core/Styled/Layouts";
import CustomButton from "components/reusable/CustomButton";
import { useCallback, useMemo } from "react";
import { routesList } from "utils/routesList";
import NavBar from "components/layouts/Navbar";

const CustomersListPage = () => {
  const goTo = useGoTo();

  const onClickCreateCustomerButton = useCallback(() => {
    goTo(routesList.customers.routes.create);
  }, [goTo]);
  const createCustomerComponent = useMemo(
    () => (
      <CustomButton
        onClick={onClickCreateCustomerButton}
        title="Create customer"
      />
    ),
    [onClickCreateCustomerButton]
  );
  return (
    <NavBar
      title="Customers"
      showEnvironmentDisplay
      showTitle
      buttonTitle="Create customer"
      ContentComponent={createCustomerComponent}
    >
      <ContentBody>
        <CustomersList />
      </ContentBody>
    </NavBar>
  );
};

export default CustomersListPage;
