import { Container } from "components/core/Styled/Layouts";
import Button from "components/reusable/Button";
import {
  useFilterSetting,
  useSearchSetting,
} from "common/hooks/settings/useSetting";
import { TTableTypes } from "common/types/api/preferences";
import { Heading3 } from "components/core/Styled/Texts";
import { TInfo } from "common/types/api/common";
import { stringToDate } from "utils/dateHelpers";
import { formatLocalDateType } from "utils/dateHelpers";
import CheckboxFilters from "components/Filters/CheckboxFilters";
import Filters from "components/Filters/Filters";
import DateFilters from "components/Filters/DateFilters";
import { useGetPreferencesQuery } from "services/query/preferences";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { customToast } from "utils/customToast";

type IProps = {
  type: TTableTypes;
};

const FiltersBloc = ({ type }: IProps) => {
  const { filterSetting, updateFilterSetting } = useFilterSetting(type);
  const { searchValue, updateSearch } = useSearchSetting(type);
  const preferencesQuery = useGetPreferencesQuery(type);

  const [open, setOpen] = useState<boolean>(false);
  const [dateTo, setDateTo] = useState<Date | null | undefined>();
  const [dateFrom, setDateFrom] = useState<Date | null | undefined>();
  const [selectedList, setSelectedList] = useState<Record<string, TInfo[]>>({});

  const filterApplied = useMemo(() => {
    const hasDate = !!filterSetting.dateTo || !!filterSetting.dateFrom;
    const hasFilter =
      filterSetting.filters !== undefined &&
      filterSetting.filters.some(({ values }) => values.length > 0);
    return !!hasDate || !!hasFilter;
  }, [filterSetting.dateFrom, filterSetting.dateTo, filterSetting.filters]);

  const onApplyFilters = useCallback(() => {
    if (!!dateFrom && !!dateTo && dateTo < dateFrom) {
      customToast.error("Please select a valid date range");
      return;
    }

    updateFilterSetting({
      dateFrom: formatLocalDateType(dateFrom),
      dateTo: formatLocalDateType(dateTo),
      filters: Object.entries(selectedList).map(([field, values]) => ({
        field,
        values,
      })),
    });
    setOpen(!open);
  }, [dateFrom, dateTo, open, selectedList, updateFilterSetting]);

  const onResetFilters = useCallback(() => {
    updateFilterSetting({});
    setDateTo(null);
    setDateFrom(null);
    setSelectedList({});
  }, [updateFilterSetting]);

  const triggerFilters = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleToDateChange = useCallback((value: Date | null) => {
    setDateTo(value);
  }, []);

  const handleFromDateChange = useCallback((value: Date | null) => {
    setDateFrom(value);
  }, []);

  const onUpdateSelected = useCallback((label: string, list: TInfo[]) => {
    setSelectedList((old) => ({ ...old, [label]: list }));
  }, []);

  useEffect(() => {
    if (filterSetting.filters) {
      const current = Object.values(filterSetting.filters).reduce(
        (prev, { field, values }) => ({ ...prev, [field]: values }),
        {} as Record<string, TInfo[]>
      );
      setSelectedList((old) => {
        return { ...old, ...current };
      });
    }
  }, [filterSetting.filters]);

  useEffect(() => {
    const newValue = stringToDate(filterSetting.dateFrom);
    setDateFrom(newValue);
  }, [filterSetting.dateFrom]);

  useEffect(() => {
    const newValue = stringToDate(filterSetting.dateTo);
    setDateTo(newValue);
  }, [filterSetting.dateTo]);

  return (
    <Filters
      filterApplied={filterApplied}
      searchValue={searchValue}
      updateSearch={updateSearch}
      triggerFilters={triggerFilters}
      resetFilters={onResetFilters}
    >
      {open && (
        <FiltersWrapper>
          <DateFilters
            dateFrom={dateFrom}
            dateTo={dateTo}
            setDateFrom={handleFromDateChange}
            setDateTo={handleToDateChange}
          />
          <PreferencesWrapper>
            {preferencesQuery.isSuccess &&
              Object.entries(preferencesQuery.data.filter ?? {}).map(
                ([label, list]) => {
                  return (
                    <CheckboxFilters
                      key={label}
                      label={label}
                      list={list}
                      selectedList={selectedList[label] ?? []}
                      setSelected={onUpdateSelected}
                    />
                  );
                }
              )}
          </PreferencesWrapper>
          <ApplyWrapper>
            <ApplyButton onClick={onApplyFilters}>
              <Heading3>Apply</Heading3>
            </ApplyButton>
          </ApplyWrapper>
        </FiltersWrapper>
      )}
    </Filters>
  );
};

export default FiltersBloc;

const FiltersWrapper = styled(Container)`
  position: absolute;
  top: 5em;
  right: 2em;
  z-index: 5;
  border-radius: ${(props) => props.theme.borderRadius.primary};
  box-shadow: ${(props) => props.theme.boxShadow.main};
  width: 17.125em;
  min-height: 4.25em;
  background-color: white;
  padding: 0.5em 0;
  justify-content: flex-start;
`;

const PreferencesWrapper = styled(Container)`
  width: 100%;
  max-height: 18em;
  overflow-y: auto;
  justify-content: flex-start;
`;

const ApplyWrapper = styled(Container)`
  width: 100%;
  min-height: 4.0625em;
  align-items: flex-end;
  padding: 0.625em;
  border-top: ${(props) => props.theme.border.secondary};
`;

const ApplyButton = styled(Button)`
  border: ${(props) => props.theme.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: 4.375em;
  padding: 0 0.625em;
  min-height: 2.1875em;
`;
