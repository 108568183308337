import { auditRoutes } from "./Audit";
import { dashboardRoutes } from "./Dashboard";
import { reportsRoutes } from "./Reports";
import PrivateRoutes from "routes/PrivateRoutes";
import { RouteObject } from "react-router-dom";

export const analyticsRoutes: RouteObject = {
  element: <PrivateRoutes />,
  children: [dashboardRoutes, reportsRoutes, auditRoutes],
};
