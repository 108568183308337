import UserUpdate from "./UserUpdate";
import { ContentBody } from "components/core/Styled/Layouts";
import { useGoTo } from "common/hooks/paths/useGoTo";
import { routesList } from "utils/routesList";
import { useIdPathname } from "common/hooks/paths/useParamsPathname";
import { useCallback } from "react";
import NavBar from "components/layouts/Navbar";

const UserUpdatePage = () => {
  const userId = useIdPathname();
  const goTo = useGoTo();

  const onClickReturnButton = useCallback(() => {
    goTo(routesList.users.routes.main);
  }, [goTo]);

  return (
    <NavBar
      title="Edit user"
      onClickReturnButton={onClickReturnButton}
      showReturnButton
      showEnvironmentDisplay
      showTitle
      showStatus
    >
      <ContentBody>
        <UserUpdate userId={userId} />
      </ContentBody>
    </NavBar>
  );
};

export default UserUpdatePage;
