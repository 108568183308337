import { useAppMutation, useAppQuery } from "./hooks";
import { IParmPagination, usePagination } from "./usePagination";
import { DEFAULT_LIMIT_PAGINATION } from "utils/constants";
import customerApi from "api/customerApi";
import { ICreateCustomer } from "api/customerApi/createCustomer";
import { IUpdateCustomer } from "api/customerApi/updateCustomer";
import { selectFilterSortSetting } from "services/redux/reducers/settingsSlice";
import { TableTypes } from "common/types/api/preferences";
import { useCallback, useMemo } from "react";
import { IGetCustomerGlobalInfoById } from "api/customerApi/getCustomerGlobalInfoById";

const selectFilterSort = selectFilterSortSetting(TableTypes.CUSTOMERS);

export const useGetCustomersQuery = (sizeOfPage = DEFAULT_LIMIT_PAGINATION) => {
  const defaultValues = useMemo(() => ({}), []);

  const getApi = useCallback(
    (param: IParmPagination & typeof defaultValues) => {
      return customerApi.getCustomers(param);
    },
    []
  );

  const query = usePagination({
    key: "useGetCustomersQuery",
    sizeOfPage,
    getApi,
    defaultValues,
    selectFilterSort,
    type: TableTypes.CUSTOMERS,
  });

  return query;
};

export const useGetCustomerByIdQuery = (customerId: string) => {
  const query = useAppQuery(() => {
    return customerApi.getCustomerById({
      customerId,
    });
  }, ["useGetCustomerByIdQuery", customerId]);

  return query;
};

export const useGetCustomerGlobalInfoByIdMutation = () => {
  const query = useAppMutation((arg: IGetCustomerGlobalInfoById) => {
    return customerApi.getCustomerGlobalInfoById({
      ...arg,
    });
  });

  return query;
};

export const useCreateCustomerMutation = () => {
  const query = useAppMutation((arg: ICreateCustomer) => {
    return customerApi.createCustomer({
      ...arg,
    });
  });

  return query;
};

export const useEnableCustomerMutation = (customerId: string) => {
  const query = useAppMutation(() => {
    return customerApi.enableCustomer({
      customerId,
    });
  });

  return query;
};

export const useDisableCustomerMutation = (customerId: string) => {
  const query = useAppMutation(() => {
    return customerApi.disableCustomer({
      customerId,
    });
  });

  return query;
};

export const useUpdateCustomerMutation = (customerId: string) => {
  const query = useAppMutation((arg: Omit<IUpdateCustomer, "customerId">) => {
    return customerApi.updateCustomer({
      ...arg,
      customerId,
    });
  });

  return query;
};

export const useDeleteCustomerMutation = (customerId: string) => {
  const query = useAppMutation(() => {
    return customerApi.deleteCustomer({
      customerId,
    });
  });

  return query;
};

export const usePromoteCustomerMutation = (customerId: string) => {
  const query = useAppMutation(() => {
    return customerApi.promoteCustomer({
      customerId,
    });
  });

  return query;
};
