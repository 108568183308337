import Button from "components/reusable/Button";
import { Container, Row } from "components/core/Styled/Layouts";
import { Heading3 } from "components/core/Styled/Texts";
import React, { RefObject, useCallback, useMemo, useState } from "react";
import { Calendar } from "react-calendar";
import styled from "styled-components";
import { BsCalendar } from "react-icons/bs";
import { FiArrowRight } from "react-icons/fi";
import { dateToString } from "utils/dateHelpers";

type IProps = {
  dateFrom?: Date | null;
  dateTo?: Date | null;
  setDateFrom: (value: Date | null) => void;
  setDateTo: (value: Date | null) => void;
  ref?: RefObject<HTMLDivElement>;
};

const DateFilters = ({
  dateFrom,
  dateTo,
  setDateFrom,
  setDateTo,
  ref,
}: IProps) => {
  const [fromModel, setFromModel] = useState<boolean>(false);
  const [toModel, setToModel] = useState<boolean>(false);

  const triggerDate = useCallback((type: "FROM" | "TO") => {
    if (type === "FROM") {
      setToModel(true);
      setFromModel(false);
    } else {
      setFromModel(true);
      setToModel(false);
    }
  }, []);

  const handleDateChange = useCallback(
    (value: Date | null) => {
      if (fromModel) {
        setDateFrom(value);
        setToModel(false);
        setFromModel(false);
      } else {
        setDateTo(value);
        setToModel(false);
        setFromModel(false);
      }
    },
    [fromModel, setDateFrom, setDateTo]
  );

  const onClearDate = useCallback(() => {
    if (fromModel) {
      setDateFrom(null);
    } else {
      setDateTo(null);
    }
  }, [fromModel, setDateFrom, setDateTo]);

  const onCloseDate = useCallback(() => {
    if (fromModel) {
      setFromModel(false);
    } else {
      setToModel(false);
    }
  }, [fromModel]);

  const dateFromStr = useMemo(
    () => dateToString(dateFrom) || "From",
    [dateFrom]
  );

  const dateToStr = useMemo(() => dateToString(dateTo) || "To", [dateTo]);

  return (
    <DateWrapper justifyContent="flex-start">
      <DateButton
        iconBefore={<BsCalendar />}
        onClick={() => triggerDate("TO")}
        selected={dateFrom ? true : false}
      >
        <Heading3>{dateFromStr}</Heading3>
      </DateButton>
      <DateButton
        iconBefore={<FiArrowRight />}
        onClick={() => triggerDate("FROM")}
        selected={dateTo ? true : false}
      >
        <Heading3>{dateToStr}</Heading3>
      </DateButton>
      {(toModel || fromModel) && (
        <PickDateContainer ref={ref}>
          <DateTrigger>
            <InnerDateButton
              onClick={() => triggerDate("TO")}
              active={fromModel}
            >
              <Heading3>{dateFromStr}</Heading3>
            </InnerDateButton>
            <InnerDateButton
              onClick={() => triggerDate("FROM")}
              active={toModel}
            >
              <Heading3>{dateToStr}</Heading3>
            </InnerDateButton>
          </DateTrigger>
          <CalendarContainer>
            {fromModel && (
              <PickDateWrapper>
                <Calendar
                  onChange={handleDateChange}
                  value={dateFrom}
                  calendarType="US"
                  maxDate={dateTo ?? undefined}
                />
              </PickDateWrapper>
            )}
            {toModel && (
              <PickDateWrapper>
                <Calendar
                  onChange={handleDateChange}
                  value={dateTo}
                  calendarType="US"
                  minDate={dateFrom ?? undefined}
                />
              </PickDateWrapper>
            )}
            <Row>
              <ClearButton onClick={onClearDate}>Clear</ClearButton>
              <CloseButton onClick={onCloseDate}>Close</CloseButton>
            </Row>
          </CalendarContainer>
        </PickDateContainer>
      )}
    </DateWrapper>
  );
};

export default DateFilters;

const DateWrapper = styled(Row)`
  width: 100%;
  padding: 0.625em;
`;

const DateTrigger = styled(Row)`
  background-color: ${(props) => props.theme.background.secondary};
  width: 100%;
  height: 3.4375em;
  padding: 1.28125em;
`;
const DateButton = styled(Button)<{ selected?: boolean }>`
  border-radius: ${(props) => props.theme.borderRadius.primary};
  min-height: 2.1875em;
  color: ${(props) =>
    props.selected ? props.theme.color.black : props.theme.color.grey};
  justify-content: flex-start;
  min-width: 50%;
  background-color: white;
`;

const InnerDateButton = styled(Button)<{ active?: boolean }>`
  border-radius: ${(props) => props.theme.borderRadius.primary};
  border: ${(props) => props.active && props.theme.border.primary};
  min-height: 2.1875em;
  color: ${(props) => props.theme.color.grey};
  width: 100%;
  background-color: white;
  margin: 0.28125em;
`;

const PickDateContainer = styled(Container)`
  position: absolute;
  top: 4em;
  right: 0;
  z-index: 15;
  box-shadow: ${(props) => props.theme.boxShadow.main};
`;

const CalendarContainer = styled(Container)`
  background-color: white;
  align-items: flex-end;
  width: 26.25em;
`;

const ClearButton = styled(Button)`
  min-height: 2.1875em;
  background-color: white;
  margin: 0.975em 0;
`;
const CloseButton = styled(Button)`
  min-height: 2.1875em;
  margin: 0.975em 0.65em;
`;
const PickDateWrapper = styled(Container)`
  width: 26.25em;
  background-color: white;
  padding: 0.5em 0;

  .react-calendar__navigation {
    display: flex;
    width: 26.25em;
    height: 3.5em;
    .react-calendar__navigation__label {
      font-weight: 500;
    }

    .react-calendar__navigation__arrow {
      flex-grow: 0.333;
    }
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    font-size: 0.75em;
  }

  .react-calendar__tile:disabled {
    background: #cccccc;
    color: #ffffff;
  }

  button {
    margin: 0.1875em;
    background-color: white;
    border: 0;
    border-radius: 0.1875em;
    color: black;
    padding: 0.3125em 0;
    text-align: center;
    height: 2.5em;
    &:hover {
      background-color: #3377ff;
      color: white;
      cursor: pointer;
    }

    &:active {
      background-color: #3377ff;
    }
  }

  .react-calendar__month-view__days {
    display: grid !important;
    grid-template-columns: 14.2% 14.2% 14.2% 14.2% 14.2% 14.2% 14.2%;

    .react-calendar__tile {
      max-width: initial !important;
    }
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    opacity: 0.7;
  }

  .react-calendar__month-view__days__day--weekend {
    color: #b4bbc4;
  }

  .react-calendar__tile--range {
    background-color: #3377ff;
    color: white;
  }
`;
