import { IEvent } from "common/types/api/documentEvent";
import { IDate, ISource } from "common/types/api/common";
import { EmptyType } from "common/types/common";
import { getInfoEnum, TEnumKey } from "common/types/helpers/enumHelpers";

export const AuditStatus = getInfoEnum({
  NOT_COMPLETED: "NOT_COMPLETED",
  COMPLETED: "COMPLETED",
} as const);

export type TAuditStatus = TEnumKey<typeof AuditStatus>;

export type IAuditInfo = {
  sessionId: string;
  name: string;
  lastUpdate: IDate;
  status: TAuditStatus;
  source: EmptyType<ISource>;
  documentsTypes?: EmptyType<string[]>;
  documentId?: EmptyType<string>;
  clientId: string;
  customerId: string;
  events: EmptyType<IEvent[]>;
};
