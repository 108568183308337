export const authorize = [];

import ApiHelper from "api/ApiHelper";
import { assertNotEmpty } from "utils/assertion";
import { IAdminInfo } from "common/types/api/admin";
import { API_BASE_URL } from "utils/UrlList";

export type IUpdateAdmin = Omit<IAdminInfo, "id"> & {
  adminId: string;
};

export type IUpdateAdminResponse = IAdminInfo;

const updateAdmin = async ({
  adminId,
  ...rest
}: IUpdateAdmin): Promise<IUpdateAdminResponse> => {
  try {
    assertNotEmpty(adminId);
    const res = await ApiHelper.apiClient.put<IUpdateAdminResponse>(
      `${API_BASE_URL}/api/v1/administration/users/${adminId}`,
      rest,
      ApiHelper.getHeaders()
    );

    const data = res.data;
    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default updateAdmin;
