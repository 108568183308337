import Button from "components/reusable/Button";
import { Heading3 } from "components/core/Styled/Texts";

import React, { useCallback } from "react";
import styled from "styled-components";
import { FiDownload } from "react-icons/fi";
import { useDownloadReportByIdQuery } from "services/query/reports";
import { customToast } from "utils/customToast";

const ButtonDownloadReport = ({ sessionId }: { sessionId: string }) => {
  const downloadReportQuery = useDownloadReportByIdQuery(sessionId);

  const onClickHandler = useCallback(() => {
    if (downloadReportQuery.isError) {
      customToast.error("Report download failed please try again");
    }
    if (downloadReportQuery.data) {
      window.open(downloadReportQuery.data, "_blank");
    }
  }, [downloadReportQuery.data, downloadReportQuery.isError]);

  return (
    <ButtonStyled onClick={onClickHandler} iconBefore={<FiDownload />}>
      <Heading3>Download Report</Heading3>
    </ButtonStyled>
  );
};

const ButtonStyled = styled(Button)`
  border: ${(props) => props.theme.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: 100%;
  max-width: 10em;
`;

export default ButtonDownloadReport;
