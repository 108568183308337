import Loader, { IPropsLoader } from "./Loader";
import { HEIGHT_NAVBAR, WIDTH_SIDEBAR } from "components/core/Styled/Layouts";
import styled from "styled-components";

type IPropsLoadingPage = IPropsLoader & {};

const LoadingPage = ({ className, ...rest }: IPropsLoadingPage) => {
  return <StyledLoader {...rest} className={className} />;
};

export default LoadingPage;

const StyledLoader = styled(Loader)`
  flex-direction: row;
  width: calc(100vw - ${WIDTH_SIDEBAR});
  height: calc(100vh - ${HEIGHT_NAVBAR});
  gap: 0.375em;
  padding: 0 0.625em;

  svg {
    width: 1.875em;
    height: 1.875em;
  }
`;
