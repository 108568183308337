import { TEnumKey, getInfoEnum } from "common/types/helpers/enumHelpers";
import { IForUpdate } from "./common";
import {
  IActiveConfig,
  IBackgroundCheckConfiguration,
  IBackgroundCheckConfigurationActive,
  ILookupConfiguration,
  ILookupConfigurationActive,
} from "./sdkConfigurations";
import { EmptyType } from "common/types/common";

export const ProductType = getInfoEnum({
  KYC_ENROLMENT_FLOW: "KYC_ENROLMENT_FLOW",
  FACE_FLOW: "FACE_FLOW",
  OCR_API: "OCR_API",
  LOOKUP_API: "LOOKUP_API",
  SCREENING_API: "SCREENING_API",
  FACE_MATCHING_API: "FACE_MATCHING_API",
  FACE_SEARCHING_API: "FACE_SEARCHING_API",
  // COMPANY_INFO_API: "COMPANY_INFO_API",
  // COMPANY_SCREENING_API: "COMPANY_SCREENING_API",
  // FAST_SCREENING_API: "FAST_SCREENING_API",
  // DATA_MATCHING_API: "DATA_MATCHING_API",
} as const);

export type TProductType = TEnumKey<typeof ProductType>;

export type TTemporalUnit = "DAYS" | "WEEKS" | "MONTHS" | "YEARS";

export type IDataClearanceTime = EmptyType<{
  temporalUnit: TTemporalUnit;
  amount?: number;
}>;

export type IProductStoreDataInfo = {
  storeData?: EmptyType<boolean>;
  dataClearanceTime?: IDataClearanceTime;
  storable?: EmptyType<boolean>;
};

export type IProductCommonInfo = IForUpdate &
  IProductStoreDataInfo & {
    productId: string;
  };

export type IProductKycEnrolmentFlow = IProductCommonInfo & {
  productType: "KYC_ENROLMENT_FLOW";
  configuration?: {
    productType?: "KYC_ENROLMENT_FLOW";
    scan?: IActiveConfig;
    nfc?: IActiveConfig;
    facialRecognition?: IActiveConfig;
    oneToNFaceVerification?: IActiveConfig;
    lookup?: ILookupConfiguration;
    backgroundCheck?: IBackgroundCheckConfiguration;
  };
};

export type IProductFaceFlow = IProductCommonInfo & {
  productType: "FACE_FLOW";
  configuration?: {
    productType?: "FACE_FLOW";
    licenseType?: "USER_PER_YEAR" | "PER_USER";
  };
};

export type IProductScreeningApi = IProductCommonInfo & {
  productType: "SCREENING_API";
  configuration?: IBackgroundCheckConfigurationActive & {
    productType?: "SCREENING_API";
  };
};

export type IProductLookupApi = IProductCommonInfo & {
  productType: "LOOKUP_API";
  configuration?: ILookupConfigurationActive & {
    productType?: "LOOKUP_API";
  };
};

export type IProductOcrApi = IProductCommonInfo & {
  productType: "OCR_API";
  configuration?: undefined;
};

export type IProductFaceMatchingApi = IProductCommonInfo & {
  productType: "FACE_MATCHING_API";
  configuration?: undefined;
};

export type IProductFaceSearchingApi = IProductCommonInfo & {
  productType: "FACE_SEARCHING_API";
  configuration?: undefined;
};

export type IProductCompanyInfoApi = IProductCommonInfo & {
  productType: "COMPANY_INFO_API";
  configuration?: undefined;
};

export type IProductCompanyScreeningApi = IProductCommonInfo & {
  productType: "COMPANY_SCREENING_API";
  configuration?: undefined;
};

export type IProductFastScreeningApi = IProductCommonInfo & {
  productType: "FAST_SCREENING_API";
  configuration?: undefined;
};

export type IProductDataMatchingApi = IProductCommonInfo & {
  productType: "DATA_MATCHING_API";
  configuration?: undefined;
};

export type IProductInfo =
  | IProductKycEnrolmentFlow
  | IProductFaceFlow
  | IProductOcrApi
  | IProductLookupApi
  | IProductScreeningApi
  | IProductFaceMatchingApi
  | IProductFaceSearchingApi;
// | IProductCompanyInfoApi
// | IProductCompanyScreeningApi
// | IProductFastScreeningApi
// | IProductDataMatchingApi;

export type IProductType = IProductInfo["productType"];

export const productListName = {
  KYC_ENROLMENT_FLOW: "Kyc Enrolment Flow",
  FACE_FLOW: "Face Flow",
  OCR_API: "Ocr Api",
  LOOKUP_API: "Lookup Api",
  SCREENING_API: "Screening Api",
  FACE_MATCHING_API: "Face Matching Api",
  FACE_SEARCHING_API: "Face Searching Api",
  // COMPANY_INFO_API: "Company Info Api",
  // COMPANY_SCREENING_API: "Company Screening Api",
  // FAST_SCREENING_API: "Fast Screening Api",
  // DATA_MATCHING_API: "Data Matching Api",
};

export type IProductWithLicensesInfo<T = IProductInfo> = T & IForUpdate & {};

export type IProductsInfo = {
  products: IProductInfo[];
};
