import App from "./App";
import AppProvider from "AppProvider";
import setupWorker from "setupWorker";
import ReactDOM from "react-dom/client";
import React from "react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const renderApp = () => {
  root.render(
    // <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
    // </React.StrictMode>
  );
};

if (process.env.REACT_APP_NODE_ENV !== "production" && module.hot) {
  module.hot.accept("./App", renderApp);
}

setupWorker().then(renderApp);
