import { useLocation, useParams } from "react-router-dom";

export const useIdPathname = () => {
  const { id } = useParams();
  if (id === undefined) throw new Error("unexpected error");
  return id;
};

export const useCurrentLocation = () => {
  const location = useLocation();
  return location;
};
