import { clickBehaviorCss } from "./Styled/AtomicCss";
import { Container } from "./Styled/Layouts";
import styled from "styled-components";
import { PropsWithChildren } from "react";

export type IProps = {
  selected?: boolean;
  onClick?: () => void;
  className?: string;
};

const IconContainer = ({
  children,
  className,
  selected,
  onClick,
}: PropsWithChildren<IProps>) => {
  return (
    <Wrapper className={className} selected={selected} onClick={onClick}>
      {children}
    </Wrapper>
  );
};

export default IconContainer;

const Wrapper = styled(Container)<{ selected?: boolean }>`
  ${clickBehaviorCss};
  color: ${(props) => props.theme.color.main};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  padding: 0.4em;

  svg {
    width: 1.075em;
    aspect-ratio: 1;
  }

  ${(props) =>
    props.selected &&
    `
        color: ${props.theme.color.primary2};
        background: ${props.theme.background.primary};
    `};
`;
