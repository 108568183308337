import { IForUpdate } from "./common";
import { ISelectedCustomerInfo } from "./customer";
import {
  TAuthRoles,
  TCustomerRole,
  TInternalRole,
  TUserRoles,
  UserRoles,
} from "./roles";

export type ICustomerRoleInfo = {
  customerId: string;
  role: TCustomerRole;
};

export type ISessionInfo = {
  email: string;
  nickname: string;
  customerRoles: ICustomerRoleInfo[];
  adminRoles: TInternalRole[];
};

export type ISessionUser = ISessionInfo & {
  selectedCustomer: ISelectedCustomerInfo;
};

export const getProfileRole = (
  profile?: ISessionInfo | null,
  selectedCustomerId?: string
): TUserRoles => {
  if (!profile) return UserRoles.PUBLIC;
  if (profile.adminRoles.length > 0) return profile.adminRoles[0];

  const customer = selectedCustomerId
    ? profile.customerRoles.find((val) => val.customerId === selectedCustomerId)
    : undefined;

  if (!customer) {
    if (profile.customerRoles.length > 0) return profile.adminRoles[0];
    return UserRoles.PUBLIC;
  }

  return customer.role;
};

export type IProfileInfo = IForUpdate & {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  role: TAuthRoles;
};
