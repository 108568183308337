import { EventList } from "./EventsList";
import { formatSource } from "utils/helpers";
import { formatDate } from "utils/dateHelpers";
import Button from "components/reusable/Button";
import { Container, Divider, Row } from "components/core/Styled/Layouts";
import { Heading1, Heading2, Heading3 } from "components/core/Styled/Texts";
import { icons } from "assets/images";
import { IconSrc } from "components/reusable/Image";
import Info from "components/reusable/Info";
import Loader from "components/core/Loaders/Loader";
import { useGetAuditByIdQuery } from "services/query/audits";
import { AuditStatus } from "common/types/api/audit";
import { routesList } from "utils/routesList";
import { useGoTo } from "common/hooks/paths/useGoTo";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { FaArrowDown } from "react-icons/fa";

export type IProps = {
  sessionID: string;
};

const AuditDisplay = ({ sessionID }: IProps) => {
  const getAuditByIdQuery = useGetAuditByIdQuery(sessionID);

  const [numberOpenAllClicked, setNumberOpenAllClicked] = useState(0);
  const goTo = useGoTo();

  const onClickOpenAll = useCallback(() => {
    setNumberOpenAllClicked((old) => old + 1);
  }, []);

  const goToReportClick = useCallback(() => {
    goTo(routesList.reports.routes.display, sessionID);
  }, [goTo, sessionID]);

  if (getAuditByIdQuery.isLoading) return <Loader />;

  if (getAuditByIdQuery.isError)
    return (
      <Wrapper>
        <Heading1>{sessionID} Not found</Heading1>
      </Wrapper>
    );

  return (
    <Wrapper>
      <StyledRow gap="1em" justifyContent="space-between" width="100%">
        <Row gap="1em" justifyContent="flex-start" width="100%">
          <Info name="name" content={getAuditByIdQuery.data.name} />
          <Info
            name="date"
            content={formatDate(getAuditByIdQuery.data.lastUpdate)}
          />
          <Info
            name="source"
            content={formatSource(getAuditByIdQuery.data.source)}
          />
        </Row>
        {getAuditByIdQuery.data.status === AuditStatus.COMPLETED && (
          <StyledButtonAudit
            onClick={goToReportClick}
            iconBefore={<IconSrc src={icons.ReportIcon} />}
          >
            <Heading3>View Report</Heading3>
          </StyledButtonAudit>
        )}
      </StyledRow>
      <Divider />
      <Container width="100%" gap="1em">
        <StyledEventsRow width="100%" justifyContent="space-between">
          <Heading2>Events</Heading2>
          <Button onClick={onClickOpenAll} iconAfter={<FaArrowDown />}>
            Open All
          </Button>
        </StyledEventsRow>
        {getAuditByIdQuery.data.events && (
          <EventList
            list={getAuditByIdQuery.data.events}
            numberOpenAllClicked={numberOpenAllClicked}
          />
        )}
      </Container>
    </Wrapper>
  );
};

export default AuditDisplay;

const Wrapper = styled(Container)`
  width: 100%;
  padding: 0 1.875em;
`;
const StyledEventsRow = styled(Row)`
  width: 100%;
  padding-top: 1.875em;
`;

const StyledRow = styled(Row)`
  min-height: 7.5em;
`;

const StyledButtonAudit = styled(Button)`
  justify-content: flex-end;
  color: ${(props) => props.theme.color.main};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  min-width: 10em;
  button {
    flex-direction: row;
  }
`;
