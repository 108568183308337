import LoadingPage from "components/core/Loaders/LoadingPage";
import { navigateToPage } from "utils/navigateToPage";
import { useCurrentRole } from "common/hooks/session/useCurrentRole";
import { TUserRoles, UserRoles } from "common/types/api/roles";
import { REDIRECTION, ROUTES } from "utils/UrlList";
import { PropsWithChildren, Suspense, useEffect, useMemo } from "react";
import { Outlet } from "react-router-dom";

export type IPropsProtected = {
  allowedRoles?: TUserRoles[];
  requiredRole?: TUserRoles;
  requiredCustomer?: boolean;
  deniedRoles?: TUserRoles[];
};

const ProtectedRoutes = ({
  allowedRoles,
  children,
}: PropsWithChildren<IPropsProtected>) => {
  const { currentRole, hadCustomer } = useCurrentRole();

  const isAllowed = useMemo(() => {
    const canAccessWithCustomer = UserRoles.canAccessWithCustomer(
      currentRole,
      allowedRoles || []
    );

    return canAccessWithCustomer;
  }, [currentRole, allowedRoles]);

  useEffect(() => {
    if (!isAllowed) {
      if (!hadCustomer && UserRoles.isInternalRole(currentRole)) {
        navigateToPage(ROUTES.customers.main);
      } else {
        navigateToPage(REDIRECTION.successfulAuthentication);
      }
    }
  }, [currentRole, hadCustomer, isAllowed]);

  if (!isAllowed) return <LoadingPage />;

  return (
    <Suspense fallback={<LoadingPage />}>
      {children ? children : <Outlet />}
    </Suspense>
  );
};

export default ProtectedRoutes;
