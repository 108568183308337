import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // internal
  UserRoles.ROLE_UQUDO_SALES,
  // customers
  UserRoles.ROLE_CUSTOMER_ADMIN,
  UserRoles.ROLE_CUSTOMER_OWNER,
];

import ApiHelper from "api/ApiHelper";
import { API_BASE_URL } from "utils/UrlList";
import { assertNotEmpty } from "utils/assertion";
import { WithCustomerInfo } from "common/types/api/customer";

export type IDeleteUser = WithCustomerInfo & {
  userId: string;
};

const deleteUser = async ({
  customerId,
  userId,
}: IDeleteUser): Promise<void> => {
  try {
    assertNotEmpty(customerId, userId);
    await ApiHelper.apiClient.delete(
      `${API_BASE_URL}/api/v1/customers/${customerId}/users/${userId}`,
      ApiHelper.getHeaders()
    );
    return;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default deleteUser;
