import AppErrorCode, { AppErrorCodeType } from "./AppErrorCode";

class AppOperationError extends Error {
  public code: AppErrorCodeType;

  constructor(code: AppErrorCodeType, message?: string) {
    super(message ?? code.valueOf());
    this.message = message ?? code.valueOf();
    this.code = code;

    Object.setPrototypeOf(this, AppOperationError.prototype);
  }

  public isForbidden() {
    return this.code === AppErrorCode.FORBIDDEN;
  }
}

export default AppOperationError;
