import MyProfilePage from "./MyProfileDisplay";
import MyProfileChangePassword from "./MyProfileChangePassword";
import { routesList } from "utils/routesList";
import ProtectedRoutes from "routes/ProtectedRoutes";
import { RouteObject } from "react-router-dom";

export const profileRoutes: RouteObject = {
  element: (
    <ProtectedRoutes
      allowedRoles={routesList.profile.allowedRoles}
      requiredCustomer={routesList.profile.requiredCustomer}
    />
  ),
  children: [
    { path: routesList.profile.routes.main, element: <MyProfilePage /> },
    {
      path: routesList.profile.routes.update,
      element: <MyProfileChangePassword />,
    },
  ],
};
