import { IGetPagination, IListPagination } from "common/types/api/common";
import { debounce } from "utils/helpers";
import {
  DEBOUNCE_WAIT_TIME,
  DROP_DOWN_LIST_FIRST_LENGTH_FETCH,
  DROP_DOWN_LIST_LENGTH_SEARCH,
  MIN_LENGTH_SEARCH_PATTERN,
} from "utils/constants";
import { useAppMutation } from "services/query/hooks";
import { useEffect } from "react";
import { useCallback, useState, useMemo } from "react";

export type IOption<V> = {
  label: string;
  value: V;
};

export type IPropsUseSearchableList<T, V> = {
  fun: (val: IGetPagination) => Promise<IListPagination<T>>;
  toOption: (val: T) => IOption<V>;
  defaultValue?: IOption<V>;
  searchIfEmpty?: boolean;
  preSearch?: boolean;
  preSearchValue?: string;
  minLengthSearchPattern?: number;
};

export const useSearchableList = <T, V>({
  toOption,
  fun,
  defaultValue,
  searchIfEmpty = false,
  preSearch = false,
  preSearchValue = "",
  minLengthSearchPattern = MIN_LENGTH_SEARCH_PATTERN,
}: IPropsUseSearchableList<T, V>) => {
  const query = useAppMutation(() =>
    fun({
      start: 0,
      limit: DROP_DOWN_LIST_FIRST_LENGTH_FETCH,
      search: preSearchValue,
    })
  );

  const mutate = useMemo(() => query.mutate, [query.mutate]);

  useEffect(() => {
    if (preSearch) mutate({});
  }, [mutate, preSearch]);

  const [selected, setSelected] = useState<IOption<V> | undefined>(
    defaultValue
  );

  const filterResults = useCallback(
    async (paginationInfo: IGetPagination) => {
      const res = await fun(paginationInfo);
      const mapped = res?.results ? res?.results?.map(toOption) : [];
      return mapped;
    },
    [fun, toOption]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadOptionsApi = useCallback(
    debounce(
      (
        paginationInfo: IGetPagination,
        callback: (options: IOption<V>[]) => void
      ) => {
        if (!searchIfEmpty && paginationInfo.search === undefined) return;
        if (
          paginationInfo.search !== undefined &&
          paginationInfo.search.length < minLengthSearchPattern
        )
          return;
        else
          filterResults(paginationInfo).then((res: IOption<V>[]) =>
            callback(res)
          );
      },
      DEBOUNCE_WAIT_TIME
    ),
    [filterResults]
  );

  const isHasMore = useMemo(() => {
    if (searchIfEmpty)
      return (
        query.data?.pagination &&
        query.data?.total > query.data?.pagination?.size
      );
    else return true;
  }, [query.data?.pagination, query.data?.total, searchIfEmpty]);

  const firstOptions = useMemo(
    () => (query.data?.results ? query.data?.results.map(toOption) : []),
    [query.data?.results, toOption]
  );

  const updateSelected = useCallback((newValue: IOption<V>) => {
    setSelected(newValue);
  }, []);

  const onChange = useCallback(
    (newValue: unknown) => {
      updateSelected(newValue as IOption<V>);
    },
    [updateSelected]
  );

  useEffect(() => {
    if (selected === undefined && query.isSuccess) {
      updateSelected(toOption(query.data?.results[0]));
    }
  }, [
    query.data?.results,
    query.isSuccess,
    selected,
    toOption,
    updateSelected,
  ]);

  const loadOptions = useCallback(
    (inputValue: string, callback: (options: IOption<V>[]) => void) => {
      loadOptionsApi(
        { search: inputValue, start: 0, limit: DROP_DOWN_LIST_LENGTH_SEARCH },
        callback
      );
    },
    [loadOptionsApi]
  );

  return { loadOptions, onChange, firstOptions, isHasMore, query, selected };
};
