import activateClientSecret from "./activateClientSecret";
import addClientSecret from "./addClientSecret";
import deleteClientSecret from "./deleteClientSecret";
import disableClientSecret from "./disableClientSecret";
import getClientSecrets from "./getClientSecrets";
import getCustomersCredentials from "./getCustomersCredentials";

const credentialsApi = {
  addClientSecret,
  activateClientSecret,
  deleteClientSecret,
  disableClientSecret,
  getClientSecrets,
  getCustomersCredentials,
};

export default credentialsApi;
