import { createContext, ReactNode, useContext } from "react";
import useNoCodeConfigs from "./useNoCodeConfigs";

type INoCodeProps = ReturnType<typeof useNoCodeConfigs>;

export const ConfigNoCodeContext = createContext<INoCodeProps | null>(null);

interface IProps {
  children?: ReactNode;
}

export const ConfigNoCodeProvider = ({ children }: IProps) => {
  const value = useNoCodeConfigs();

  return (
    <ConfigNoCodeContext.Provider value={value}>
      {children}
    </ConfigNoCodeContext.Provider>
  );
};

export const useContextNoCode = () => {
  const value = useContext(ConfigNoCodeContext);
  if (!value) throw Error("context not provided");
  return value;
};
