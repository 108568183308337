import { createContext, ReactNode, useContext } from "react";
import useEditCustomer from "./useEditCustomer";

type IEditCustomerProps = ReturnType<typeof useEditCustomer>;

export const EditCustomerContext = createContext<IEditCustomerProps | null>(
  null
);

interface IProps {
  children?: ReactNode;
}

export const EditCustomerProvider = ({ children }: IProps) => {
  const value = useEditCustomer();

  return (
    <EditCustomerContext.Provider value={value}>
      {children}
    </EditCustomerContext.Provider>
  );
};

export const useContextEditCustomer = () => {
  const value = useContext(EditCustomerContext);
  if (!value) throw Error("context not provided");
  return value;
};
