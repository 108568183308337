import { PropsWithChildren, useMemo } from "react";
import { Heading3 } from "components/core/Styled/Texts";
import styled, { useTheme } from "styled-components";
import { MdOutlineInfo } from "react-icons/md";
import Button from "components/reusable/Button";
import NavBarWithoutStatus, {
  INavBarWithoutStatusProps,
} from "./NavBarWithoutStatus";
import { useSelectCustomer } from "common/hooks/session/useSelectCustomer";
import { ICustomerStatus } from "common/types/api/customer";

export type INavbarProps = INavBarWithoutStatusProps & {
  showStatus?: boolean;
  forceStatus?: ICustomerStatus;
};

const Navbar = ({
  showStatus,
  forceStatus,
  children,
  ...rest
}: PropsWithChildren<INavbarProps>) => {
  const theme = useTheme();
  const { status } = useSelectCustomer();

  const addedComponent = useMemo(() => {
    if (!showStatus) return undefined;
    return (
      <>
        <StyledButton
          status={forceStatus ?? status}
          iconBefore={
            <MdOutlineInfo
              color={
                (forceStatus ?? status) === "Disabled"
                  ? theme.color.danger
                  : theme.color.success
              }
            />
          }
        >
          <Heading3>{forceStatus ?? status}</Heading3>
        </StyledButton>
      </>
    );
  }, [
    status,
    forceStatus,
    showStatus,
    theme.color.danger,
    theme.color.success,
  ]);

  return (
    <>
      <NavBarWithoutStatus addedComponent={addedComponent} {...rest} />
      {children}
    </>
  );
};

export default Navbar;

const StyledButton = styled(Button)<{ status?: string }>`
  color: ${(props) =>
    props.status === "Disabled"
      ? props.theme.color.danger
      : props.theme.color.success};
`;
