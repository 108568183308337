import Button from "components/reusable/Button";
import { Container, Row } from "components/core/Styled/Layouts";
import { Heading3, Heading4, Heading5 } from "components/core/Styled/Texts";
import ConfirmationPopup from "components/Modal/ConfirmationPopup";
import InputField from "components/reusable/Input";
import { Input } from "components/core/Styled/Controls";
import Table, { IHeader } from "components/core/Table";
import {
  useGetCorsConfigurationsQuery,
  useUpdateCorsConfigurationsMutation,
} from "services/query/cors";
import { ICellComponentProps } from "components/core/Table/CellComponentDefault";
import { getMessageError, isValidDomain } from "utils/helpers";
import React, { useCallback, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";
import { IoMdAdd } from "react-icons/io";
import { RiDeleteBin5Line } from "react-icons/ri";
import { customToast } from "utils/customToast";
import IconContainer from "components/core/IconContainer";
import { MdOutlineInfo } from "react-icons/md";
import primaryTheme from "common/theme/primaryTheme";
import { useFormik } from "formik";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const headers: IHeader[] = [
  {
    Header: "Domain",
    accessor: "domains",
  },

  {
    Header: "",
    accessor: "settings",
  },
];

export type IProps = {
  id: string;
  isDefault: boolean;
  refetch: () => void;
};

const appNames = [
  { id: "WEB_SDK", name: "Web SDK" },
  { id: "WEB_SOCKET_API", name: "WebSocket API for QR code flow" },
];
export const CellComponent = ({ cell, row, index }: ICellComponentProps) => {
  const info = cell.value as IProps;
  const [confirmDeleteDomain, setConfirmDeleteDomain] =
    useState<boolean>(false);
  const updateDomains = useUpdateCorsConfigurationsMutation();
  const allowedDomainsQuery = useGetCorsConfigurationsQuery();
  const appIndex = info.id === "WEB_SDK" ? 0 : 1;

  const onDeleteDomain: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.stopPropagation();
      setConfirmDeleteDomain(!confirmDeleteDomain);
    },
    [confirmDeleteDomain]
  );

  const onConfirmDeleteDomain = useCallback(async () => {
    const applicationsCorsConfigs = [
      ...(allowedDomainsQuery.data?.applicationsCorsConfigs ?? []),
    ];
    const index =
      applicationsCorsConfigs[appIndex].domains?.indexOf(
        cell.row.values.domains
      ) ?? 0;
    if (index > -1) {
      applicationsCorsConfigs[appIndex].domains?.splice(index, 1);
    }

    applicationsCorsConfigs[appIndex].updateVersion = allowedDomainsQuery.data
      ?.applicationsCorsConfigs[appIndex].updateVersion as number;

    try {
      await updateDomains.mutateAsync({ applicationsCorsConfigs });
      info.refetch();
      customToast.success("Domain deleted successfully");
    } catch (error) {
      customToast.error(getMessageError(error, "Domain deletion failed"));
      console.error(error);
    } finally {
      setConfirmDeleteDomain(false);
    }
  }, [
    allowedDomainsQuery.data?.applicationsCorsConfigs,
    appIndex,
    cell.row.values.domains,
    info,
    updateDomains,
  ]);
  if (
    row.index <=
    (allowedDomainsQuery.data?.applicationsCorsConfigs[appIndex].defaultDomains
      .length as number) -
      1
  ) {
    return (
      <td style={{ color: "#061B3B80" }}>
        {index === row.cells.length - 1 ? (
          <>
            <StyledOptions>
              <ConfirmationPopup
                text="Are you sure you want to delete this Domain?"
                cancelText="Cancel"
                confirmText="Confirm"
                close={onDeleteDomain}
                onConfirmDelete={onConfirmDeleteDomain}
                show={confirmDeleteDomain}
              />

              {!info.isDefault && (
                <Button onClick={onDeleteDomain}>
                  <RiDeleteBin5Line color={primaryTheme.color.main} />
                </Button>
              )}
            </StyledOptions>
          </>
        ) : (
          cell.render("Cell")
        )}
      </td>
    );
  } else {
    return (
      <td {...cell.getCellProps()}>
        {index === row.cells.length - 1 ? (
          <>
            <StyledOptions>
              <ConfirmationPopup
                text="Are you sure you want to delete this Domain?"
                cancelText="Cancel"
                confirmText="Confirm"
                close={onDeleteDomain}
                onConfirmDelete={onConfirmDeleteDomain}
                show={confirmDeleteDomain}
              />

              {!info.isDefault && (
                <Button onClick={onDeleteDomain}>
                  <RiDeleteBin5Line color={primaryTheme.color.main} />
                </Button>
              )}
            </StyledOptions>
          </>
        ) : (
          cell.render("Cell")
        )}
      </td>
    );
  }
};

const CorsPageDisplay = () => {
  const theme = useTheme();
  const allowedDomainsQuery = useGetCorsConfigurationsQuery();
  const updateDomains = useUpdateCorsConfigurationsMutation();
  const allowedDomainsRefetch = useMemo(
    () => allowedDomainsQuery.refetch,
    [allowedDomainsQuery.refetch]
  );

  const [confirmAddDomain, setConfirmAddDomain] = useState<boolean>(false);
  const [domainError, setDomainError] = useState<string>("");

  const { values, setValues, handleChange, handleBlur, touched } = useFormik({
    initialValues: {
      name: "",
      index: 0,
    },
    onSubmit: () => console.info("Configuration submitted"),
  });

  const onAddDomain = useCallback(() => {
    setConfirmAddDomain(!confirmAddDomain);
    setDomainError("");
  }, [confirmAddDomain]);

  const onAddDomainInit = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
      event.stopPropagation();
      onAddDomain();
      setValues({
        index,
        name: "",
      });
    },
    [onAddDomain, setValues]
  );

  const onConfirmAddDomain = useCallback(async () => {
    if (!isValidDomain(values.name)) {
      setDomainError("Please enter a valid domain");
      return;
    }

    const applicationsCorsConfigs = [
      ...(allowedDomainsQuery.data?.applicationsCorsConfigs ?? []),
    ];
    const domainsLength =
      allowedDomainsQuery?.data?.applicationsCorsConfigs[values.index].domains
        ?.length ?? 0;
    if (allowedDomainsQuery.data?.applicationsCorsConfigs)
      if (
        allowedDomainsQuery.data?.applicationsCorsConfigs[values.index]
          .defaultDomains.length +
          domainsLength ===
        50
      ) {
        customToast.warn(
          "Domain addition failed, You reached the maximum number of domains allowed"
        );
        setConfirmAddDomain(false);
        setValues({ name: "", index: 0 });
        setDomainError("");
        return;
      }
    if (
      applicationsCorsConfigs[values.index].domains?.find(
        (x) => x === values.name
      ) ||
      applicationsCorsConfigs[values.index].defaultDomains?.find(
        (x) => x === values.name
      )
    ) {
      setDomainError("");
      customToast.warn("Domain already added");
      setConfirmAddDomain(false);
      setValues({ name: "", index: 0 });

      return;
    }

    applicationsCorsConfigs[values.index].domains = [
      ...(allowedDomainsQuery.data?.applicationsCorsConfigs[values.index]
        .domains as string[]),
      values.name,
    ];

    applicationsCorsConfigs[values.index].updateVersion = allowedDomainsQuery
      .data?.applicationsCorsConfigs[values.index].updateVersion as number;

    try {
      await updateDomains.mutateAsync({ applicationsCorsConfigs });
      allowedDomainsQuery.refetch();
      customToast.success("Domain added successfully");
    } catch (error) {
      customToast.error(getMessageError(error, "Domain addition failed"));
      console.error(error);
    } finally {
      setConfirmAddDomain(false);
      setValues({ name: "", index: 0 });
      setDomainError("");
    }
  }, [
    allowedDomainsQuery,
    setValues,
    updateDomains,
    values.index,
    values.name,
  ]);

  return (
    <Wrapper>
      <ConfirmationPopup
        title="Add New Domain"
        cancelText="Cancel"
        confirmText="Add Domain"
        close={onAddDomain}
        onConfirmDelete={onConfirmAddDomain}
        show={confirmAddDomain}
      >
        <CreationStyled>
          <SubTitle>Please enter your domain.</SubTitle>
          <StyledInputField
            name="name"
            value={values.name}
            onChange={handleChange}
            handleBlur={handleBlur}
            touched={touched.name}
            errors={domainError}
          />
        </CreationStyled>
      </ConfirmationPopup>
      <SubTitle>
        Cross-origin resource sharing (CORS) is a mechanism that allows
        restricted resources on a web page to be requested from another domain
        outside the domain from which the first resource was served (ref.{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="https://en.wikipedia.org/wiki/Cross-origin_resource_sharing"
        >
          here
        </a>
        )
      </SubTitle>
      <NoteStyled>
        <StyledIconContainer>
          <MdOutlineInfo color={theme.color.success} />
        </StyledIconContainer>
        <ListContainer>
          <ItemsTitle>The following types of domains are accepted:</ItemsTitle>
          <ListItem>
            FQDN (see{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://en.wikipedia.org/wiki/Fully_qualified_domain_name"
            >
              here
            </a>{" "}
            for details) Only 2nd and above level domains are accepted (e.g.
            example.com or test.example.com). The domain must not include any
            path, port, query or fragment
          </ListItem>
          <ListItem>
            IP v4 (see{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://en.wikipedia.org/wiki/Internet_Protocol_version_4"
            >
              here
            </a>{" "}
            for details)
          </ListItem>
          <ListItem>Patterns like *.example.com</ListItem>
        </ListContainer>
      </NoteStyled>

      {allowedDomainsQuery.data?.applicationsCorsConfigs.map((val, index) => {
        return (
          <>
            <StyledRow>
              <Row>
                <Title>
                  {appNames.find((x) => x.id === val.application)?.name}
                </Title>
                <Tippy
                  placement="right-end"
                  content="A valid domain requires a host and must not include any path, port, query or fragment. Changes to this field may take up to 30 minutes to take effect."
                >
                  <div>
                    <IconContainer>
                      <MdOutlineInfo color={theme.color.main} />
                    </IconContainer>
                  </div>
                </Tippy>
              </Row>

              <AddButton
                iconBefore={<IoMdAdd />}
                onClick={(e) => onAddDomainInit(e, index)}
              >
                <Heading3>Add Domain</Heading3>
              </AddButton>
            </StyledRow>
            <TableStyled
              keyAccessor="id"
              columns={headers}
              data={val.defaultDomains
                .concat(val.domains ?? [])
                .map((domain, length) => ({
                  id: val.application,
                  domains: domain,
                  settings: {
                    isDefault:
                      length < val.defaultDomains.length ? true : false,
                    id: val.application,
                    refetch: allowedDomainsRefetch,
                  },
                }))}
              withIndex
              key={index}
              CellComponent={CellComponent}
            />
          </>
        );
      })}
    </Wrapper>
  );
};

export default CorsPageDisplay;

const Wrapper = styled(Container)`
  padding: 0 1.875em;
  margin: 1.25em 0;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Title = styled(Heading3)`
  font-size: 1.125em;
  color: ${(props) => props.theme.color.black};
  padding: 0.75em 0;
  text-transform: none;
`;

const ItemsTitle = styled(Heading3)`
  color: ${(props) => props.theme.color.secondary2};
  font-size: 0.75em;
  padding: 0.275em 0;
  text-transform: none;
`;

const CreationStyled = styled(Container)`
  width: 100%;
  margin-bottom: 2em;
  align-items: flex-start;
`;

const StyledIconContainer = styled(IconContainer)`
  margin-right: 0.5em;
`;

const SubTitle = styled(Heading5)<{ popup?: boolean }>`
  padding: ${(props) => (props.popup ? "1.25em 0 2.5em 0" : "0.2em 0")};
  margin-bottom: ${(props) => (props.popup ? "0" : "1.875em")};
  color: ${(props) => props.theme.color.black};
  text-align: left;
  text-transform: none;
`;

const StyledRow = styled(Row)`
  width: 100%;
  justify-content: space-between;
  padding-top: 1em;
`;

const NoteStyled = styled(Row)`
  width: 100%;
  padding: 0.9375em 0.8em;
  background-color: ${(props) => props.theme.background.lightSuccess};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  justify-content: flex-start;
  align-items: center;
`;

const ListContainer = styled(Container)`
  align-items: flex-start;
`;

const ListItem = styled.li`
  text-align: left;
  color: ${(props) => props.theme.color.secondary2};
  font-size: 0.75em;
  margin: 0.275em 0.6em;
`;

const StyledInputField = styled(InputField)`
  border-bottom: none;

  ${Heading4} {
    padding: 0.625em 0;
    line-height: 0;
  }
  ${Container} {
    gap: 0;
    ${Input} {
      color: ${(props) => props.theme.color.main};
      font-size: 0.875em;
    }
  }
`;

const AddButton = styled(Button)`
  border: ${(props) => props.theme.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: 8em;
  min-height: 2.1875em;
`;

const StyledOptions = styled(Container)`
  width: 1.25em;
  height: 1.25em;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    background-color: white;
  }
`;
const TableStyled = styled(Table)`
  min-height: 20vh;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 1em;
  td {
    position: relative;
    word-wrap: break-word;
  }

  th {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;
