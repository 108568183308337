import {
  CellComponentDefault,
  ICellComponentProps,
  IRowType,
} from "./CellComponentDefault";
import React, { FunctionComponent, useCallback } from "react";
import { Row } from "react-table";

export type IRowComponentProps = {
  CellComponent?: FunctionComponent<ICellComponentProps>;
  row: Row<IRowType>;
  index: number;
  onRowClick?: (data: unknown) => void;
  prepareRow?: (row: Row<IRowType>) => void;
  withIndex?: boolean;
  startIndex?: number;
};

export const RowComponentDefault = ({
  withIndex,
  row,
  index,
  prepareRow,
  CellComponent = CellComponentDefault,
  onRowClick,
  startIndex = 1,
}: IRowComponentProps) => {
  prepareRow?.(row);

  const onCLickHandler = useCallback(() => {
    onRowClick?.(row.original as unknown);
  }, [onRowClick, row.original]);

  const { key, ...rest } = row.getRowProps();

  return (
    <tr key={key} {...rest} onClick={onCLickHandler}>
      <>
        {withIndex && <td>{index + startIndex}</td>}
        {row.cells.map((cell, indexCell) => (
          <CellComponent
            key={indexCell}
            cell={cell}
            index={indexCell}
            row={row}
            indexRow={index}
          />
        ))}
      </>
    </tr>
  );
};

export default RowComponentDefault;
