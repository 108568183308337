import {
  selectDisplaySetting,
  selectFilterSetting,
  selectPageNum,
  selectPageSize,
  selectSearchSetting,
  selectSortSetting,
  settingsActions,
} from "services/redux/reducers/settingsSlice";
import { useAppDispatch, useAppSelector } from "services/redux/hooks";
import { ArgFunction } from "common/types/common";
import { TTableTypes, TableTypes } from "common/types/api/preferences";
import { useCallback } from "react";

export const useSearchSetting = (
  type: TTableTypes | undefined = TableTypes.GENERAL
) => {
  const dispatch = useAppDispatch();
  const searchValue = useAppSelector(selectSearchSetting(type));
  const updateSearch = useCallback(
    (newValue: ArgFunction<typeof settingsActions.updateSearch>["value"]) => {
      dispatch(
        settingsActions.updateSearch({
          value: newValue,
          type,
        })
      );
    },
    [dispatch, type]
  );

  return { searchValue, updateSearch };
};

export const usePageSetting = (
  type: TTableTypes | undefined = TableTypes.GENERAL
) => {
  const dispatch = useAppDispatch();
  const pageNum = useAppSelector(selectPageNum(type));
  const pageSize = useAppSelector(selectPageSize);

  const updatePageNum = useCallback(
    (newValue: number) => {
      dispatch(
        settingsActions.updatePageNum({
          value: newValue,
          type,
        })
      );
    },
    [dispatch, type]
  );

  const updatePageSize = useCallback(
    (newValue: number) => {
      dispatch(settingsActions.updatePageSize(newValue));
      dispatch(settingsActions.resetPagesNum());
    },
    [dispatch]
  );

  return { pageNum, pageSize, updatePageNum, updatePageSize };
};

export const useSortSetting = (
  type: TTableTypes | undefined = TableTypes.GENERAL
) => {
  const dispatch = useAppDispatch();
  const sortSetting = useAppSelector(selectSortSetting(type));
  const updateSortSetting = useCallback(
    (newValue: ArgFunction<typeof settingsActions.updateSort>["value"]) => {
      dispatch(
        settingsActions.updateSort({
          value: newValue,
          type,
        })
      );
    },
    [dispatch, type]
  );

  return { sortSetting, updateSortSetting };
};

export const useFilterSetting = (
  type: TTableTypes | undefined = TableTypes.GENERAL
) => {
  const dispatch = useAppDispatch();
  const filterSetting = useAppSelector(selectFilterSetting(type));
  const updateFilterSetting = useCallback(
    (newValue: ArgFunction<typeof settingsActions.updateFilter>["value"]) => {
      dispatch(
        settingsActions.updateFilter({
          value: newValue,
          type,
        })
      );
    },
    [dispatch, type]
  );

  return { filterSetting, updateFilterSetting };
};

export const useDisplaySetting = (
  type: TTableTypes | undefined = TableTypes.GENERAL
) => {
  const dispatch = useAppDispatch();
  const displaySetting = useAppSelector(selectDisplaySetting(type));
  const updateDisplaySetting = useCallback(
    (
      newValue: ArgFunction<
        typeof settingsActions.updateDisplaySetting
      >["value"]
    ) => {
      dispatch(
        settingsActions.updateDisplaySetting({
          value: newValue,
          type,
        })
      );
    },
    [dispatch, type]
  );
  return { displaySetting, updateDisplaySetting };
};
