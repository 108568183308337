import AdminCreate from "./AdminCreate";
import { ContentBody } from "components/core/Styled/Layouts";
import { routesList } from "utils/routesList";
import { useGoTo } from "common/hooks/paths/useGoTo";
import { useCallback } from "react";
import NavBar from "components/layouts/Navbar";

const AdminCreatePage = () => {
  const goTo = useGoTo();
  const onClickReturnButton = useCallback(() => {
    goTo(routesList.admins.routes.main);
  }, [goTo]);

  return (
    <NavBar
      title="Create admin"
      onClickReturnButton={onClickReturnButton}
      showReturnButton
      showEnvironmentDisplay
      showTitle
    >
      <ContentBody>
        <AdminCreate />
      </ContentBody>
    </NavBar>
  );
};

export default AdminCreatePage;
