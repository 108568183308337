// env
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || "development";
export const USE_MSW_MOCK_API = process.env.REACT_APP_USE_MOCK_SERVER === "yes";
export const ENV = process.env.NODE_ENV || "development";

// mode
export const MODE_DEV = ENV === "development";
export const MODE_PROD = ENV === "production";
export const MODE_TEST = ENV === "test";
export const MODE_DEBUG = USE_MSW_MOCK_API && MODE_DEV;

export const EXIT_TIME_UNEXPECTED_ERROR = MODE_DEBUG ? 30000 : 1000;

// constants
export const DEFAULT_LIMIT_PAGINATION = 25;
export const MIN_LENGTH_SEARCH_PATTERN = 3;
export const DEBOUNCE_WAIT_TIME = 500;
export const DROP_DOWN_LIST_FIRST_LENGTH_FETCH = 50;
export const DROP_DOWN_LIST_LENGTH_SEARCH = 20;
export const MIN_LENGTH_STRING = 3;

// Files

export const MAX_SIZE_FILE = 5 * 1048576; // 5mb

// Links

export const HUBSPOT_COMPANY_URL =
  "https://app.hubspot.com/contacts/5716965/company/";
