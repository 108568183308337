import { authRoles } from "common/types/api/roles";

export const authorize = authRoles;

import ApiHelper from "api/ApiHelper";
import { API_BASE_URL } from "utils/UrlList";

export type IUpdateUserPassword = {
  customerId?: string;
} & {
  oldPassword: string;
  newPassword: string;
};

export type IUpdateUserPasswordResponse = void;

const updateUserPassword = async ({
  customerId,
  ...rest
}: IUpdateUserPassword): Promise<IUpdateUserPasswordResponse> => {
  try {
    const res = await ApiHelper.apiClient.put<IUpdateUserPasswordResponse>(
      `${API_BASE_URL}/api/v1/profile/change-password`,
      rest,
      ApiHelper.getHeaders({
        params: {
          customerId,
        },
      })
    );

    const data = res.data;

    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default updateUserPassword;
