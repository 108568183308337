import { TPreferenceType, TableTypes } from "common/types/api/preferences";
import { useAppQuery } from "services/query/hooks";
import preferenceApi from "api/preferenceApi";
import { useSelectCustomer } from "common/hooks/session/useSelectCustomer";

export const useGetPreferencesQuery = (
  type: TPreferenceType | undefined = TableTypes.GENERAL
) => {
  const { customerId } = useSelectCustomer();

  const query = useAppQuery(() => {
    if (customerId) {
      if (type === TableTypes.AUDITS)
        return preferenceApi.getPreferencesAuditing({ customerId });
      if (type === TableTypes.REPORTS)
        return preferenceApi.getPreferencesReports({ customerId });
      if (type === TableTypes.USERS || type === TableTypes.ADMINS)
        return preferenceApi.getPreferencesUsers({ customerId });
    }

    if (type === TableTypes.CUSTOMERS)
      return preferenceApi.getPreferencesCustomers({});

    return preferenceApi.getPreferences({});
  }, ["useGetPreferencesQuery", customerId, type]);

  return query;
};
