import { EmptyType } from "common/types/common";
import { IForUpdate } from "./common";

export type IDocumentConfigs = {
  documentType: string;
  country: string;
  lookup: boolean;
  nfc: boolean;
};

export const CONFIG_DEFAULT_VALUES = {
  enforceDocumentExpiryCheck: true,
  allowNonPhysicalDocuments: false,
  disableTamperingRejection: false,
  enableMonitoring: false,
  disableConsent: false,
  skipView: false,
  oneToNFaceVerification: false,
  enrollFace: false,
  maxAttempts: 3,
  maxAttemptsMinValue: 1,
  maxAttemptsMaxValue: 3,
};

export type IAdditionalScanConfig = {
  enforceDocumentExpiryCheck?: EmptyType<boolean>;
  allowNonPhysicalDocuments?: EmptyType<boolean>;
  disableTamperingRejection?: EmptyType<boolean>;
};

export type IScanConfig = IAdditionalScanConfig & {
  required?: EmptyType<{
    documentsConfigs: IDocumentConfigs[];
  }>;
  optional?: EmptyType<
    {
      documentsConfigs: IDocumentConfigs[];
    }[]
  >;
};

export type IFaceConfig = {
  enabled?: EmptyType<boolean>;
  enrollFace?: EmptyType<boolean>;
  maxAttempts?: EmptyType<number>;
  oneToNFaceVerification?: EmptyType<boolean>;
};

export type IBackgroundCheckConfig = {
  enabled?: EmptyType<boolean>;
  enableMonitoring?: EmptyType<boolean>;
  disableConsent?: EmptyType<boolean>;
  skipView?: EmptyType<boolean>;
};

export type IOnboardingWorkflow = {
  scanConfig?: EmptyType<IScanConfig>;
  faceConfig?: EmptyType<IFaceConfig>;
  backgroundCheckConfig?: EmptyType<IBackgroundCheckConfig>;
};

export type ITextSettings = {
  [key: string]: { [key: string]: string };
};

export type ITypeHostedKyc =
  | "DESKTOP_MOBILE_BROWSER"
  | "MOBILE_BROWSER"
  | "MOBILE_APP";

export type IConfigHostedKyc = IForUpdate & {
  id: string;
  name: string;
  linkId: string;
  url: string;
  onboardingWorkflow?: EmptyType<IOnboardingWorkflow>;
  companyLogo?: EmptyType<string>;
  returnUrl?: EmptyType<string>;
  webhook?: EmptyType<{
    url: string;
    userId?: EmptyType<string>;
    password?: EmptyType<string>;
  }>;
  notificationEmail?: EmptyType<string>;
  textSettings?: EmptyType<ITextSettings>;
  defaultTextSettings?: EmptyType<ITextSettings>;
  type: ITypeHostedKyc;
};

export type ISdkCustomerConfig = {
  scanAllowed?: EmptyType<boolean>;
  faceAllowed?: EmptyType<boolean>;
  bcAllowed?: EmptyType<boolean>;
  bcMonitoringAllowed?: EmptyType<boolean>;
  lookupAllowed?: EmptyType<string[]>;
  nfcAllowed?: EmptyType<boolean>;
  screenScanningAllowed?: EmptyType<boolean>;
  enforceDocumentExpiryCheckAllowed?: EmptyType<boolean>;
  oneToNFaceVerificationAllowed?: EmptyType<boolean>;
  disableTamperingRejectionAllowed?: EmptyType<boolean>;
};

export const typeHostedKycToText = (type: ITypeHostedKyc) => {
  if (type === "DESKTOP_MOBILE_BROWSER") return "Desktop & Mobile Browser";
  if (type === "MOBILE_BROWSER") return "Mobile Browser only";
  if (type === "MOBILE_APP") return "Mobile App only";
  throw new Error("unexpected error");
};
