import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // internal
  UserRoles.ROLE_UQUDO_AUDITOR,
  UserRoles.ROLE_UQUDO_SALES,
  // customers
  UserRoles.ROLE_CUSTOMER_ADMIN,
  UserRoles.ROLE_CUSTOMER_OWNER,
];

import ApiHelper from "api/ApiHelper";
import { assertNotEmpty } from "utils/assertion";
import { ICompany } from "common/types/api/company";
import { API_BASE_URL } from "utils/UrlList";
import { WithCustomerInfo } from "common/types/api/customer";

export type IGetCompanyDetails = WithCustomerInfo;

export type IGetCompanyDetailsResponse = ICompany;

const getCompanyDetails = async ({
  customerId,
}: IGetCompanyDetails): Promise<IGetCompanyDetailsResponse> => {
  try {
    assertNotEmpty(customerId);
    const res = await ApiHelper.apiClient.get<IGetCompanyDetailsResponse>(
      `${API_BASE_URL}/api/v1/customers/${customerId}/company`,
      ApiHelper.getHeaders()
    );

    const data = res.data;

    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default getCompanyDetails;
