import { useAppMutation, useAppQuery } from "./hooks";
import profileApi from "api/profileApi";
import { useSelectCustomer } from "common/hooks/session/useSelectCustomer";
import { IUpdateUserProfile } from "api/profileApi/updateUserProfile";
import { IUpdateUserPassword } from "api/profileApi/updateUserPassword";
import { useCurrentRole } from "common/hooks/session/useCurrentRole";
import { UserRoles } from "common/types/api/roles";

export const useGetUserProfileQuery = () => {
  const { customerId } = useSelectCustomer();
  const { currentRole } = useCurrentRole();

  const query = useAppQuery(() => {
    return profileApi.getUserProfile({
      customerId: UserRoles.isInternalRole(currentRole)
        ? undefined
        : customerId,
    });
  }, ["useGetUserProfileQuery", customerId]);

  return query;
};

export const useUpdateUserProfileMutation = () => {
  const { customerId } = useSelectCustomer();
  const { currentRole } = useCurrentRole();

  const query = useAppMutation(
    (arg: Omit<IUpdateUserProfile, "customerId">) => {
      return profileApi.updateUserProfile({
        ...arg,
        customerId: UserRoles.isInternalRole(currentRole)
          ? undefined
          : customerId,
      });
    }
  );

  return query;
};

export const useUpdateUserPasswordMutation = () => {
  const { customerId } = useSelectCustomer();
  const { currentRole } = useCurrentRole();

  const query = useAppMutation(
    (arg: Omit<IUpdateUserPassword, "customerId">) => {
      return profileApi.updateUserPassword({
        ...arg,
        customerId: UserRoles.isInternalRole(currentRole)
          ? undefined
          : customerId,
      });
    }
  );

  return query;
};

export const useDeleteUserAccountMutation = () => {
  const { customerId } = useSelectCustomer();
  const { currentRole } = useCurrentRole();

  const query = useAppMutation(() => {
    return profileApi.deleteUserAccount({
      customerId: UserRoles.isInternalRole(currentRole)
        ? undefined
        : customerId,
    });
  });

  return query;
};
