import React from "react";
import { Cell, Row } from "react-table";

export type IRowType = Record<string, unknown>;

export type ICellComponentProps = {
  cell: Cell<IRowType, unknown>;
  index: number;
  row: Row<IRowType>;
  indexRow: number;
};

export const CellComponentDefault = ({ cell }: ICellComponentProps) => {
  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
};
