import { Heading3 } from "components/core/Styled/Texts";
import { Row } from "components/core/Styled/Layouts";
import Search from "components/Search/Search";
import Button from "components/reusable/Button";
import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { TbArrowsSort } from "react-icons/tb";
import { MdArrowDropDown } from "react-icons/md";

export type IProps = {
  searchValue?: string;
  filterApplied?: boolean;
  updateSearch?: (newValue: string) => void;
  resetFilters?: React.MouseEventHandler<HTMLDivElement> | undefined;
  triggerFilters: React.MouseEventHandler<HTMLDivElement> | undefined;
};

const Filters = ({
  searchValue,
  filterApplied,
  updateSearch,
  triggerFilters,
  resetFilters,
  children,
}: PropsWithChildren<IProps>) => {
  return (
    <Wrapper justifyContent="space-between">
      {updateSearch && (
        <Search clearable defaultValue={searchValue} update={updateSearch} />
      )}
      <StyledFilter>
        {filterApplied && (
          <Button onClick={resetFilters}>
            <Heading3>Reset Filters</Heading3>
          </Button>
        )}
        <ButtonStyled
          onClick={triggerFilters}
          iconBefore={<TbArrowsSort />}
          iconAfter={<MdArrowDropDown />}
        >
          <Heading3>Filters</Heading3>
        </ButtonStyled>
        {children}
      </StyledFilter>
    </Wrapper>
  );
};

export default Filters;

const Wrapper = styled(Row)`
  padding: 0.25em 0;
  margin-bottom: 0.8em;
  align-items: center;
  min-height: 2.8125em;
  width: 100%;
`;
const StyledFilter = styled(Row)`
  min-height: 2.8125em;
`;

const ButtonStyled = styled(Button)`
  border: ${(props) => props.theme.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: 7.75em;
  padding: 0 0.625em;
  min-height: 2.1875em;
  margin-left: 0.5em;
`;
