import SideBar from "./SideBar";
import { Container, PageContent } from "components/core/Styled/Layouts";
import styled from "styled-components";
import { PropsWithChildren } from "react";

const AuthLayout = ({ children }: PropsWithChildren) => {
  return (
    <Wrapper>
      <SideBar />
      <ContentContainer>{children}</ContentContainer>
    </Wrapper>
  );
};

export default AuthLayout;

const Wrapper = styled(Container)`
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background: ${(props) => props.theme.background.main};
`;

const ContentContainer = styled(PageContent)`
  flex: 1;
  width: 100%;
  position: sticky;
`;
