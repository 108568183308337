import Button, { IPropsButton } from "components/reusable/Button";
import { Heading3 } from "components/core/Styled/Texts";
import Loader from "components/core/Loaders/Loader";
import React from "react";
import styled from "styled-components";

type IProps = IPropsButton & {
  loading?: boolean;
  label?: string;
};

const ButtonAction = ({ loading, label, onClick, ...rest }: IProps) => {
  if (loading) {
    return (
      <ButtonStyled {...rest} disabled={true}>
        <Loader />
      </ButtonStyled>
    );
  }

  return (
    <ButtonStyled onClick={onClick} {...rest}>
      <Heading3>{label}</Heading3>
    </ButtonStyled>
  );
};

export default ButtonAction;

const ButtonStyled = styled(Button)`
  border: ${(props) => props.theme.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: 12em;
  color: ${(props) => props.theme.color.main};
  padding: 0 0.625em;
  margin-top: 1em;
  min-height: 2.1875em;
`;
