import { StoreProvider } from "./redux";
import { AppStore } from "./redux/store";
import { IQueryClient, QueryProvider } from "./query";
import { AuthProvider } from "./auth";

export type IProps = {
  children?: React.ReactNode;
  store?: AppStore;
  queryClient?: IQueryClient;
};

export function ServicesProvider({ children, store, queryClient }: IProps) {
  return (
    <StoreProvider store={store}>
      <QueryProvider queryClient={queryClient}>
        <AuthProvider>{children}</AuthProvider>
      </QueryProvider>
    </StoreProvider>
  );
}
