import { IStorage } from "common/types/common";
import { MODE_DEBUG } from "utils/constants";

class LocalStorage<T> implements IStorage<T> {
  id: string;

  constructor(id: string) {
    this.id = id;
  }

  get: () => T | undefined = () => {
    const item = localStorage.getItem(this.id);
    if (item === null) return undefined;
    const res = JSON.parse(item);
    if (MODE_DEBUG) console.info(`id: ${this.id} local storage get: ${res}`);
    return res;
  };

  set: (value?: T | undefined) => void = (value?: T | undefined) => {
    if (MODE_DEBUG) console.info(`id: ${this.id} local storage set: ${value}`);

    if (value === undefined) {
      this.clear();
    } else {
      const item = JSON.stringify(value);
      localStorage.setItem(this.id, item);
    }
  };

  clear: () => void = () => {
    if (MODE_DEBUG) console.info(`id: ${this.id} local storage clear`);
    localStorage.removeItem(this.id);
  };
}

export default LocalStorage;
