import { getInfoEnum, TEnumKey } from "common/types/helpers/enumHelpers";

// loading state
const LoadingStatusEnum = getInfoEnum({
  IDLE: "IDLE",
  PENDING: "PENDING",
  RESOLVED: "RESOLVED",
  REJECTED: "REJECTED",
} as const);
export type TLoadingStatus = TEnumKey<typeof LoadingStatusEnum>;

export const LoadingStatus = {
  ...LoadingStatusEnum,
  isOnProgressOrFinished: (
    status: TEnumKey<typeof LoadingStatusEnum>
  ): boolean => {
    return (
      status === LoadingStatus.PENDING || status === LoadingStatus.RESOLVED
    );
  },
  isShouldBeRecalled: (status: TEnumKey<typeof LoadingStatusEnum>): boolean => {
    return status === LoadingStatus.IDLE || status === LoadingStatus.REJECTED;
  },
  notFinished: (status: TEnumKey<typeof LoadingStatusEnum>): boolean => {
    return !(
      status === LoadingStatus.RESOLVED || status === LoadingStatus.REJECTED
    );
  },
};
