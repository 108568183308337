import { Container } from "components/core/Styled/Layouts";
import { PropsWithChildren } from "react";
import styled from "styled-components";

const AppLayout = ({ children }: PropsWithChildren) => {
  return <Wrapper>{children}</Wrapper>;
};

export default AppLayout;

const Wrapper = styled(Container)``;
