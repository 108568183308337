import { assertSearch } from "utils/assertion";
import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // internal
  UserRoles.ROLE_UQUDO_AUDITOR,
  UserRoles.ROLE_UQUDO_SALES,
  // customers
  UserRoles.ROLE_CUSTOMER_ADMIN,
  UserRoles.ROLE_CUSTOMER_OWNER,
];

import ApiHelper from "api/ApiHelper";
import { IGetPagination, IListPagination } from "common/types/api/common";
import { API_BASE_URL } from "utils/UrlList";
import { IUserInfo } from "common/types/api/user";
import { assertNotEmpty } from "utils/assertion";
import { WithCustomerInfo } from "common/types/api/customer";

export type IGetUsers = WithCustomerInfo & IGetPagination;

export type IUserResponse = IUserInfo;

export type IGetUsersResponse = IListPagination<IUserResponse>;

const getUsers = async ({
  customerId,
  start,
  limit,
  search,
  ...filterInfo
}: IGetUsers): Promise<IGetUsersResponse> => {
  try {
    assertNotEmpty(customerId);
    assertSearch(search);
    const res = await ApiHelper.apiClient.post<IGetUsersResponse>(
      `${API_BASE_URL}/api/v1/customers/${customerId}/users/search`,
      { ...filterInfo, text: search || undefined },
      ApiHelper.getHeaders({
        params: {
          start,
          limit,
        },
      })
    );

    const data = res.data;

    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default getUsers;
