import AdminUpdate from "./AdminUpdate";
import { ContentBody } from "components/core/Styled/Layouts";
import { useGoTo } from "common/hooks/paths/useGoTo";
import { routesList } from "utils/routesList";
import { useIdPathname } from "common/hooks/paths/useParamsPathname";
import { useCallback } from "react";
import NavBar from "components/layouts/Navbar";

const AdminUpdatePage = () => {
  const adminId = useIdPathname();
  const goTo = useGoTo();

  const onClickReturnButton = useCallback(() => {
    goTo(routesList.admins.routes.main);
  }, [goTo]);

  return (
    <NavBar
      title="Edit admin"
      onClickReturnButton={onClickReturnButton}
      showReturnButton
      showEnvironmentDisplay
      showTitle
    >
      <ContentBody>
        <AdminUpdate adminId={adminId} />
      </ContentBody>
    </NavBar>
  );
};

export default AdminUpdatePage;
