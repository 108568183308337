import { authRoles } from "common/types/api/roles";

export const authorize = authRoles;

import ApiHelper from "api/ApiHelper";
import { TPreferences } from "common/types/api/preferences";

export type IGetPreferences = {
  customerId?: string;
};

export type IGetPreferencesResponse = TPreferences;

const getPreferences =
  async ({}: IGetPreferences): Promise<IGetPreferencesResponse> => {
    try {
      return {};
    } catch (error) {
      ApiHelper.errorHandler(error, true);
    }
  };

export default getPreferences;
