import { IReadingNfcInfo } from "common/types/api/document";
import { Container, Divider, Row } from "components/core/Styled/Layouts";
import { Heading3, Heading4 } from "components/core/Styled/Texts";
import { fromCamelCaseToString, infoToString } from "utils/helpers";
import Image from "components/reusable/Image";
import { TEventInfo } from "common/types/api/documentEvent";
import { Fragment } from "react";
import styled from "styled-components";

type IProps = {
  info: IReadingNfcInfo;
};

export const ReadNfc = ({ info }: IProps) => {
  return (
    <Wrapper>
      <PictureContainer>
        <ImageStyled info={info.photo} />
        <ImageStyled info={info.signature} />
      </PictureContainer>
      <DescriptionContainer>
        {info.content?.map((section, index) => (
          <Fragment key={index}>
            <Section>
              <Title>{fromCamelCaseToString(section.title)}</Title>
              {section.attributes &&
                Object.entries(section.attributes).map(
                  ([name, value], index) => (
                    <Row
                      key={index}
                      gap="1em"
                      width="100%"
                      minHeight="2.1875em"
                      justifyContent="space-between"
                    >
                      <StyledSubTitle>
                        {fromCamelCaseToString(name)}
                      </StyledSubTitle>
                      <RowValue>
                        <Heading3 textAlign="left">
                          {infoToString(value)}
                        </Heading3>
                      </RowValue>
                    </Row>
                  )
                )}
            </Section>
            <Divider />
          </Fragment>
        ))}
      </DescriptionContainer>
    </Wrapper>
  );
};

type IPropsError = {
  info: IReadingNfcInfo;
  infoEvent: TEventInfo;
};

export const ReadNfcError = ({ info, infoEvent }: IPropsError) => {
  return (
    <Wrapper>
      <PictureContainer>
        <ImageStyled info={info.photo} />
        <ImageStyled info={info.signature} />
      </PictureContainer>
      <DescriptionContainer>
        <WarningText>{infoEvent.statusCode}</WarningText>
      </DescriptionContainer>
    </Wrapper>
  );
};

export default ReadNfc;

const Wrapper = styled(Row)`
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;

const PictureContainer = styled(Container)`
  min-width: 12em;
  display: flex;
  align-self: flex-start;
  padding: 2em 2em 0 0;
`;

const DescriptionContainer = styled(Container)`
  width: 100%;
  justify-content: flex-start;
`;

const Section = styled(Container)`
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 1em;
`;

const RowValue = styled(Row)`
  width: 100%;
  justify-content: space-between;
  flex: 2;
  padding: 0.25em 0;
  border-bottom: ${(props) => props.theme.border.main};
`;

const Title = styled(Heading3)`
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  border-bottom: ${(props) => props.theme.border.main};
  color: ${(props) => props.theme.color.main};
  padding: 1.875em 0;
`;

const StyledSubTitle = styled(Heading4)`
  width: 100%;
  flex: 1;
  text-align: left;
  padding: 0.25em 0;
`;

const ImageStyled = styled(Image)`
  width: 12em;
  margin-bottom: 1.2em;
`;

const WarningText = styled(Heading3)`
  width: 100%;
  text-align: left;
  color: ${(props) => props.theme.color.danger};
`;
