import LocalStorage from "utils/Storage/LocalStorage";
import * as CryptoJS from "crypto-js";

const generateCodeVerifier = (): string => {
  const charset =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
  let verifier = "";

  for (let i = 0; i < 128; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    verifier += charset.charAt(randomIndex);
  }

  return verifier;
};

const createCodeChallenge = (codeVerifier: string): string => {
  // eslint-disable-next-line import/namespace
  const hashedVerifier = CryptoJS.SHA256(codeVerifier).toString(
    // eslint-disable-next-line import/namespace
    CryptoJS.enc.Base64
  );
  const base64UrlEncoded = hashedVerifier
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");

  return base64UrlEncoded;
};

export const CodeVerifierLocalStorage = new LocalStorage<string>(
  "CodeVerifierLocalStorage"
);

export const resetCode = () => {
  const codeVerifier = generateCodeVerifier();
  CodeVerifierLocalStorage.set(codeVerifier);
  return codeVerifier;
};

export const getCode = () => {
  let codeVerifier = CodeVerifierLocalStorage.get();
  if (!codeVerifier) codeVerifier = resetCode();
  const codeChallenge = createCodeChallenge(codeVerifier);
  return { codeVerifier, codeChallenge };
};
