import { Container } from "components/core/Styled/Layouts";
import IconContainer from "components/core/IconContainer";
import { Heading3 } from "components/core/Styled/Texts";
import { rotationCss } from "components/core/Styled/AtomicCss";
import styled from "styled-components";
import { FaSpinner } from "react-icons/fa";

export type IPropsLoader = {
  className?: string;
  text?: string;
};

const Loader = ({ text, className }: IPropsLoader) => {
  return (
    <Wrapper className={className}>
      <StyledIconContainer>
        <FaSpinner />
      </StyledIconContainer>
      {text && <Heading3>{text}</Heading3>}
    </Wrapper>
  );
};

export default Loader;

const Wrapper = styled(Container)`
  flex-direction: row;
  width: 100%;
  gap: 0.375em;
  padding: 0 0.625em;
`;

const StyledIconContainer = styled(IconContainer)`
  svg {
    ${rotationCss}
  }
`;
