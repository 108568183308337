import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // customers
  UserRoles.ROLE_CUSTOMER_ADMIN,
  UserRoles.ROLE_CUSTOMER_OWNER,
  UserRoles.ROLE_CUSTOMER_USER,
];

import ApiHelper from "api/ApiHelper";
import { TPreferencesReports } from "common/types/api/preferences";
import { assertNotEmpty } from "utils/assertion";
import { API_BASE_URL } from "utils/UrlList";
import { WithCustomerInfo } from "common/types/api/customer";

export type IGetPreferencesReports = WithCustomerInfo;

export type IGetPreferencesReportsResponse = TPreferencesReports;

const getPreferencesReports = async ({
  customerId,
}: IGetPreferencesReports): Promise<IGetPreferencesReportsResponse> => {
  try {
    assertNotEmpty(customerId);
    const res = await ApiHelper.apiClient.get<IGetPreferencesReportsResponse>(
      `${API_BASE_URL}/api/v1/customers/${customerId}/reports/preferences`,
      ApiHelper.getHeaders()
    );

    const data = res.data;

    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default getPreferencesReports;
