//import { useDisplaySetting } from "common/hooks/settings/useSetting";
import { Container, Row } from "components/core/Styled/Layouts";
import Button from "components/reusable/Button";
import { Heading3 } from "components/core/Styled/Texts";
import { fromCamelCaseToString } from "utils/helpers";
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from "react-beautiful-dnd";

import React, { useCallback, useEffect, useState } from "react";
import { MdDragIndicator } from "react-icons/md";
import styled from "styled-components";

type IProps = {
  list: string[];
  action: (item: string) => void;
  reorderList: (items: string[]) => void;
  showIcon?: JSX.Element;
};

const reorder = (
  list: string[],
  startIndex: number,
  endIndex: number
): string[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
): React.CSSProperties => ({
  userSelect: "none",
  minHeight: "2.125em",
  background: isDragging ? "#F5F9FF" : "white",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
  background: isDraggingOver ? "white" : "white",
  width: "100%",
});

const DraggableList = ({
  action,
  list,
  reorderList,
  showIcon,
}: IProps): JSX.Element => {
  const [state, setState] = useState(list);

  const onItemClick = useCallback(
    (item: string) => {
      action(item);
    },
    [action]
  );

  const onDragEnd = (result: DropResult): void => {
    if (!result.destination) {
      return;
    }

    const items: string[] = reorder(
      state,
      result.source.index,
      result.destination.index
    );

    setState(items);
    reorderList(items);
  };

  useEffect(() => {
    setState(list);
  }, [list]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot): JSX.Element => (
          <Container
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {state &&
              state.map((item, index) => (
                <Draggable key={item} draggableId={item} index={index}>
                  {(provided, snapshot): JSX.Element => (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                    <Row
                      justifyContent="space-between"
                      width="100%"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <StyledButton iconBefore={<MdDragIndicator />}>
                        <Heading3>{fromCamelCaseToString(item)}</Heading3>
                      </StyledButton>
                      <Button onClick={() => onItemClick(item)}>
                        {showIcon}
                      </Button>
                    </Row>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </Container>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;

const StyledButton = styled(Button)`
  padding: 0;
  ${Container} {
    padding: 0;
  }
`;
