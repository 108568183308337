import { ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";

export type ModalProps = {
  children?: ReactNode;
  className?: string;
  modalId: string;
  enable?: boolean;
};

const ModalPortal = ({
  enable = true,
  className,
  modalId,
  children,
}: ModalProps) => {
  const [modalRoot, setModalRoot] = useState<HTMLElement>();

  useEffect(() => {
    const modalRoot = document.getElementById(modalId);
    if (!modalRoot) {
      return;
    }
    setModalRoot(modalRoot);
  }, [modalId]);

  if (!enable || !modalRoot) return null;

  return createPortal(<div className={className}>{children}</div>, modalRoot);
};

export default ModalPortal;
