import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // customers
  UserRoles.ROLE_CUSTOMER_ADMIN,
  UserRoles.ROLE_CUSTOMER_OWNER,
  UserRoles.ROLE_CUSTOMER_DEVELOPER,
  UserRoles.ROLE_CUSTOMER_USER,
];

import ApiHelper from "api/ApiHelper";
import { assertNotEmpty } from "utils/assertion";
import { API_BASE_URL } from "utils/UrlList";
import { WithCustomerInfo } from "common/types/api/customer";

export type IUnlinkDevice = WithCustomerInfo & {
  deviceId: string;
};

const unlinkDevice = async ({
  customerId,
  deviceId,
}: IUnlinkDevice): Promise<void> => {
  try {
    assertNotEmpty(customerId, deviceId);
    await ApiHelper.apiClient.delete(
      `${API_BASE_URL}/api/v1/customers/${customerId}/linked-devices/${deviceId}`,
      ApiHelper.getHeaders()
    );
    return;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default unlinkDevice;
