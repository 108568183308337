import { Container } from "components/core/Styled/Layouts";
import { IAction } from "common/types/api/document";
import Info from "components/reusable/Info";
import Document from "components/Document";
import SelectOption from "components/core/SelectOption";
import React from "react";
import styled from "styled-components";
import { useContextReportDisplay } from "common/contexts/reportDisplayContext";

export type IDocumentActionWithDocumentType = {
  documentType: string;
  info: IAction;
};

export type IProps = {};

export const DocumentChoice = ({}: IProps) => {
  const {
    documentsNameList,
    documentSelected,
    documentChangeHandler,
    selectedDocuments,
  } = useContextReportDisplay();

  if (selectedDocuments.length < 1) throw new Error("unexpected error");

  return (
    <Wrapper>
      <Info name="Document">
        <SelectOptionStyled
          defaultValue={documentSelected?.documentType}
          list={documentsNameList}
          onChange={documentChangeHandler}
          notSingleSelect
        />
      </Info>
      {documentSelected?.info && <Document info={documentSelected.info} />}
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  width: 100%;
  padding: 0.625em;
  justify-content: flex-start;
  align-items: flex-start;
`;

const SelectOptionStyled = styled(SelectOption)``;
