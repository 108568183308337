import { useContextEditCustomer } from "common/contexts/editCustomerContext";
import { IPages } from "common/contexts/editCustomerContext/useEditCustomer";
import { IProductInfo } from "common/types/api/product";
import ToggleBloc from "components/Toggle/ToggleBloc";
import { Row } from "components/core/Styled/Layouts";
import { Heading3 } from "components/core/Styled/Texts";
import Button from "components/reusable/Button";
import { useCallback } from "react";
import styled from "styled-components";

export type IProps = {
  product: IProductInfo;
  toggle?: boolean;
  toggleID?: string;
  toggleLabel?: string;
  buttonLabel?: string;
  pageID?: IPages;
};

const CustomToggle = ({
  product,
  toggle = true,
  toggleID,
  toggleLabel,
  buttonLabel = "Settings",
  pageID,
}: IProps) => {
  const { setSelectedPage, toggleProductsConfigs } = useContextEditCustomer();

  const onClick = useCallback(() => {
    if (!pageID) return;
    setSelectedPage({ page: pageID, product: product });
  }, [pageID, product, setSelectedPage]);

  const onToggle = useCallback(() => {
    if (!toggleID) return;
    toggleProductsConfigs(product.productId, toggleID);
  }, [product.productId, toggleID, toggleProductsConfigs]);

  return (
    <StyledRow>
      {toggleLabel && (
        <StyledToggleBloc
          label={toggleLabel}
          onChange={onToggle}
          toggle={toggle}
        />
      )}
      {toggle && pageID && (
        <StyledButton onClick={onClick}>
          <Heading3>{buttonLabel}</Heading3>
        </StyledButton>
      )}
    </StyledRow>
  );
};

export default CustomToggle;

const StyledToggleBloc = styled(ToggleBloc)`
  width: 100%;
  height: 3em;
  margin: 0.1em 0;
  ${Row} {
    height: 1em;
    padding: 0 0;
  }
  ${Heading3} {
  }
  .react-toggle-track {
    width: 40px;
    height: 19px;
    border-radius: 18px;
  }
  .react-toggle-thumb {
    width: 15px;
    height: 15px;
  }
  .react-toggle--checked .react-toggle-thumb {
    left: 22px;
  }
`;

const StyledButton = styled(Button)`
  border: ${(props) => props.theme.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  padding: 0.25em 1.2em;
  margin: 1em;
  align-self: center;
`;

const StyledRow = styled(Row)`
  width: 26.25em;
  height: 3em;
  justify-content: space-between;
`;
