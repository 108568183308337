import getReports from "./getReports";
import getReportById from "./getReportById";
import downloadReport from "./downloadReport";

const reportApi = {
  getReports,
  getReportById,
  downloadReport,
};

export default reportApi;
