import getPreferences from "./getPreferences";
import getPreferencesAuditing from "./getPreferencesAuditing";
import getPreferencesReports from "./getPreferencesReports";
import getPreferencesCustomers from "./getPreferencesCustomers";
import getPreferencesUsers from "./getPreferencesUsers";

const preferenceApi = {
  getPreferences,
  getPreferencesAuditing,
  getPreferencesReports,
  getPreferencesCustomers,
  getPreferencesUsers,
};

export default preferenceApi;
