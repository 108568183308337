import { TEventStatus, TLookupStatusCode } from "./documentStatus";
import { IFile, IFileID, TInfo } from "common/types/api/common";
import { EmptyType } from "common/types/common";

export type InfoSection = {
  title: string;
  attributes: EmptyType<Record<string, TInfo | TInfo[]>>;
};

export type IVerificationInfo = {
  documentType?: EmptyType<string>;
  dataConsistencyCheck?: EmptyType<{
    enabled?: EmptyType<boolean>;
    fields?: EmptyType<
      {
        name?: EmptyType<string>;
        match?: EmptyType<string>;
        sources?: EmptyType<
          {
            source?: EmptyType<string>;
            documentSide?: EmptyType<string>;
            name?: EmptyType<string>;
            value?: EmptyType<string>;
          }[]
        >;
      }[]
    >;
  }>;
  sourceDetection?: EmptyType<{
    enabled?: EmptyType<boolean>;
    allowNonPhysicalDocuments?: EmptyType<boolean>;
    selectedResolution?: EmptyType<string>;
    optimalResolution?: EmptyType<boolean>;
  }>;
  idScreenDetection?: EmptyType<{
    enabled?: EmptyType<boolean>;
    score?: EmptyType<number>;
  }>;
  idPrintDetection?: EmptyType<{
    enabled?: EmptyType<boolean>;
    score?: EmptyType<number>;
  }>;
  idPhotoTamperingDetection?: EmptyType<{
    enabled?: EmptyType<boolean>;
    score?: EmptyType<number>;
  }>;
  mrzChecksum?: EmptyType<{
    enabled?: EmptyType<boolean>;
    finalCheckDigit?: EmptyType<string>;
    valid?: EmptyType<boolean>;
    checkDigits?: EmptyType<
      {
        fieldName?: EmptyType<string>;
        fieldValue?: EmptyType<string>;
        checkDigit?: EmptyType<string>;
        valid?: EmptyType<boolean>;
      }[]
    >;
  }>;
  reading?: EmptyType<{
    enabled?: EmptyType<boolean>;
    passiveAuthentication?: EmptyType<{
      enabled?: EmptyType<boolean>;
      countrySigningCertificateAvailable?: EmptyType<boolean>;
      documentSigningCertificateAvailable?: EmptyType<boolean>;
      documentSigningCertificateSignatureValid?: EmptyType<boolean>;
      documentDataSignatureValid?: EmptyType<boolean>;
    }>;
    chipAuthentication?: EmptyType<{
      enabled?: EmptyType<boolean>;
    }>;
  }>;
  biometric?: EmptyType<{
    enabled?: EmptyType<boolean>;
    type?: EmptyType<string>;
    matchLevel?: EmptyType<number>;
  }>;
  lookup?: EmptyType<{
    enabled?: EmptyType<boolean>;
    documentFound?: EmptyType<boolean>;
  }>;
};

export type IVerificationsInfo = IVerificationInfo[];

export const opticalFraudDetection = [
  "sourceDetection",
  "idPhotoTamperingDetection",
  "idScreenDetection",
  "idPrintDetection",
];

export const isSourceDetection = (
  key: string,
  value: unknown
): value is IVerificationInfo["sourceDetection"] => {
  return key === "sourceDetection";
};

export const isIdPhotoTamperingDetection = (
  key: string,
  value: unknown
): value is IVerificationInfo["idPhotoTamperingDetection"] => {
  return key === "idPhotoTamperingDetection";
};

export const isIdScreenDetection = (
  key: string,
  value: unknown
): value is IVerificationInfo["idScreenDetection"] => {
  return key === "idScreenDetection";
};

export const isIdPrintDetection = (
  key: string,
  value: unknown
): value is IVerificationInfo["idPrintDetection"] => {
  return key === "idPrintDetection";
};

export type IScanIdInfo = {
  faceImageId: IFileID;
  frontImageId: IFileID;
  backImageId: EmptyType<IFileID>;
  content: EmptyType<InfoSection[]>;
};

export type IReadingNfcInfo = {
  photo: IFile;
  signature: IFile;
  content: EmptyType<InfoSection[]>;
};

export type IFaceMatchInfo = {
  auditTrailImageId?: IFileID;
  photo?: IFile;
  faceImageId?: IFile;
  matchLevel?: number;
  match?: boolean;
  oneToNVerificationMatch?: boolean;
  oneToNVerificationId?: string;
  verification?: EmptyType<IVerificationInfo>;
  oneToNSessions?: string[];
};

export type IBackgroundCheckInfo = {
  match: boolean;
  monitoringId: string;
  content: EmptyType<InfoSection[]>;
};

export type ILookupInfo = {
  status?: TEventStatus;
  statusCode?: EmptyType<TLookupStatusCode>;
  faceImage?: EmptyType<IFile>;
  content: EmptyType<InfoSection[]>;
};

export type IDocument = {
  documentType: string;
  scan: IScanIdInfo;
  reading: EmptyType<IReadingNfcInfo>;
  face: EmptyType<IFaceMatchInfo>;
  lookup: EmptyType<ILookupInfo>;
};

export type IToAction<TType, TData> = {
  type: TType;
  data: EmptyType<TData>;
};

export type IActionScan = IToAction<"SCAN", IScanIdInfo>;

export type IActionREADING = IToAction<"READING", IReadingNfcInfo>;

export type IActionFACE = IToAction<"FACE", IFaceMatchInfo>;

export type IActionBackgroundCheck = IToAction<
  "BACKGROUND_CHECK",
  IBackgroundCheckInfo
>;

export type IActionLookup = IToAction<"LOOKUP", ILookupInfo>;

export type IAction =
  | IActionScan
  | IActionREADING
  | IActionBackgroundCheck
  | IActionFACE
  | IActionLookup;

export type IActionType = IAction["type"];
export type IActionTypeWithoutBC = "SCAN" | "READING" | "FACE" | "LOOKUP";

export type IDocumentInfo = {
  country: string;
  documentName: string;
  documentType: string;
  canBeLookup: boolean;
  canBeNfc: boolean;
};

export type IDocumentListInfo = IDocumentInfo[];
