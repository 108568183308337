import { memo, useCallback, useEffect, useMemo } from "react";
import ListPageSize from "./ListPageSize";
import styled from "styled-components";
import { Container, Row } from "components/core/Styled/Layouts";
import { usePageSetting } from "common/hooks/settings/useSetting";
import CustomPagination from "./CustomPagination";
import LoadingPage from "components/core/Loaders/LoadingPage";

type IProps = {
  total?: number;
  pageSetting: ReturnType<typeof usePageSetting>;
};

const PaginationAction = ({ total, pageSetting }: IProps) => {
  const count = useMemo(() => {
    if (!total) return 0;
    const res = Math.ceil(total / pageSetting.pageSize);
    // if (total % pageSetting.pageSize) res += 1;
    return res;
  }, [total, pageSetting.pageSize]);

  const setSelectedNumber = useCallback(
    (newValue: number) => {
      if (newValue > count) return;
      pageSetting.updatePageNum(newValue - 1);
    },
    [count, pageSetting]
  );

  const current = useMemo(() => {
    if (!total) return undefined;
    return Math.min(total, pageSetting.pageNum + 1);
  }, [pageSetting.pageNum, total]);

  useEffect(() => {
    if (current !== undefined && count !== undefined && count < current)
      setSelectedNumber(1);
  }, [count, current, setSelectedNumber, total]);

  if (current !== undefined && count !== undefined && count < current)
    return <LoadingPage />;

  return (
    <StyledRow>
      <CustomPagination
        current={current}
        count={count}
        setSelectedNumber={setSelectedNumber}
      />
      <StyledListPageSize>
        <ListPageSize pageInfo={pageSetting} />
      </StyledListPageSize>
    </StyledRow>
  );
};

export default memo(PaginationAction);

const StyledRow = styled(Row)`
  width: 100%;
  justify-content: center;
  margin: 4em 0 10em 0;
  position: relative;
`;

const StyledListPageSize = styled(Container)`
  position: absolute;
  right: 0;
`;
