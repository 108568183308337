import { useCallback, useState } from "react";

interface Inputs {
  [key: string]: string;
}

export const useInputChange = (inputs: Inputs) => {
  const [values, setValues] = useState(inputs);

  const handleChange = useCallback((newValues: Inputs) => {
    setValues((oldValues) => ({
      ...oldValues,
      ...newValues,
    }));
  }, []);

  return { values, onChange: handleChange };
};
