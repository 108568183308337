import { Container, Divider } from "components/core/Styled/Layouts";
import React from "react";
import styled from "styled-components";
import CustomerUpdateInfo from "./CustomerUpdateInfo";
import CustomerUpdateAction from "./CustomerUpdateAction";
import CustomerProductList from "./CustomerProductList";
import { useContextEditCustomer } from "common/contexts/editCustomerContext";

const CustomerMainUpdate = () => {
  const { isTestingCustomer } = useContextEditCustomer();

  return (
    <Wrapper>
      <ContainerTop>
        <CustomerUpdateInfo />
        {isTestingCustomer && <CustomerUpdateAction />}
      </ContainerTop>
      <Divider />
      <CustomerProductList />
    </Wrapper>
  );
};

export default CustomerMainUpdate;

const Wrapper = styled(Container)`
  width: 100%;
`;

const ContainerTop = styled(Container)`
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
`;
