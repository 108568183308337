import { Container, Row } from "components/core/Styled/Layouts";
import InputField from "components/reusable/Input";
import React from "react";
import styled from "styled-components";
import { HiOutlineCheck } from "react-icons/hi";
import DateInputField from "components/Date/DateInputField";
import { useContextEditCustomer } from "common/contexts/editCustomerContext";
import ButtonAction from "components/ButtonAction";
import { Heading3, Heading4 } from "components/core/Styled/Texts";
import { HUBSPOT_COMPANY_URL } from "utils/constants";
import { TiExport } from "react-icons/ti";
import { useTheme } from "styled-components";

const CustomerUpdateInfo = () => {
  const {
    isTestingCustomer,
    touchedUpdateCustomer,
    customerUpdateFormik,
    expiryDate,
    setExpiryDate,
    updateCustomerLoading,
    onUpdateCustomer,
  } = useContextEditCustomer();

  const theme = useTheme();

  return (
    <Wrapper>
      <InputField
        label="Client ID"
        name="clientId"
        value={customerUpdateFormik.values?.clientId}
        readOnly
      />

      <StyledContainer>
        <Label>Hubspot Company ID</Label>
        {customerUpdateFormik.values?.hubspotCompanyId ? (
          <StyledHubSpot>
            <Text>{customerUpdateFormik.values?.hubspotCompanyId}</Text>
            <Link
              rel="noreferrer"
              target="_blank"
              href={`${HUBSPOT_COMPANY_URL}${customerUpdateFormik.values?.hubspotCompanyId}`}
            >
              <TiExport color={theme.color.main} />
            </Link>
          </StyledHubSpot>
        ) : (
          <StyledHubSpot>
            <Text>-</Text>
          </StyledHubSpot>
        )}
      </StyledContainer>

      <InputField
        label="Company Name"
        name="companyNameAlias"
        onChange={customerUpdateFormik.handleChange}
        handleBlur={customerUpdateFormik.handleBlur}
        touched={customerUpdateFormik.touched.companyNameAlias}
        errors={customerUpdateFormik.errors.companyNameAlias}
        value={customerUpdateFormik.values?.companyNameAlias ?? ""}
      />

      {isTestingCustomer && (
        <DateInputField
          label="Expiry Date"
          name="expiryDate"
          value={expiryDate}
          onChange={setExpiryDate}
        />
      )}

      {touchedUpdateCustomer && (
        <Row>
          <ButtonActionStyled
            label="Save"
            loading={updateCustomerLoading}
            onClick={onUpdateCustomer}
            iconBefore={<HiOutlineCheck />}
          />
        </Row>
      )}
    </Wrapper>
  );
};

export default CustomerUpdateInfo;

const Wrapper = styled(Container)`
  padding: 0 1.875em;
  margin: 1.875em 0;
  align-items: flex-start;
  justify-content: flex-start;
`;

const ButtonActionStyled = styled(ButtonAction)`
  width: 26.25em;
  margin-top: 3em;
`;

const StyledContainer = styled(Container)`
  justify-content: flex-start;
  align-items: flex-start;
`;

const StyledHubSpot = styled(Row)`
  margin: 0.375em 0;
  justify-content: flex-start;
`;

const Text = styled(Heading3)`
  font-weight: 500;
  padding: 0 0.5em 0 0;
`;

const Link = styled.a`
  text-decoration: none;
`;

const Label = styled(Heading4)`
  color: ${(props) => props.theme.color.secondary2};
  padding: 0.75em 0;
  text-align: left;
  text-transform: none;
`;
