import UsersList from "./UsersList";
import CustomButton from "components/reusable/CustomButton";
import { ContentBody } from "components/core/Styled/Layouts";
import { routesList } from "utils/routesList";
import { useGoTo } from "common/hooks/paths/useGoTo";
import { useCallback, useMemo } from "react";
import NavBar from "components/layouts/Navbar";

const UsersListPage = () => {
  const goTo = useGoTo();

  const onClickCreateUserButton = useCallback(() => {
    goTo(routesList.users.routes.create);
  }, [goTo]);

  const createUserComponent = useMemo(
    () => (
      <CustomButton onClick={onClickCreateUserButton} title="Create user" />
    ),
    [onClickCreateUserButton]
  );

  return (
    <NavBar
      title="Users"
      showListCustomers
      showEnvironmentDisplay
      showTitle
      buttonTitle="Create user"
      showStatus
      ContentComponent={createUserComponent}
    >
      <ContentBody>
        <UsersList />
      </ContentBody>
    </NavBar>
  );
};

export default UsersListPage;
