export const authorize = [];

import ApiHelper from "api/ApiHelper";
import { IAdminInfo } from "common/types/api/admin";
import { API_BASE_URL } from "utils/UrlList";

export type ICreateAdmin = Pick<
  IAdminInfo,
  "firstName" | "lastName" | "email" | "role"
>;

export type ICreateAdminResponse = IAdminInfo;

const createAdmin = async ({
  ...rest
}: ICreateAdmin): Promise<ICreateAdminResponse> => {
  try {
    const res = await ApiHelper.apiClient.post<ICreateAdminResponse>(
      `${API_BASE_URL}/api/v1/administration/users`,
      rest,
      ApiHelper.getHeaders()
    );

    const data = res.data;
    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default createAdmin;
