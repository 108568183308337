import CustomersListPage from "./CustomersListPage";
import CustomerUpdatePage from "./CustomerUpdatePage";
import { routesList } from "utils/routesList";
import ProtectedRoutes from "routes/ProtectedRoutes";
import { RouteObject } from "react-router-dom";
import CustomerCreatePage from "./CustomerCreationPage";
import { EditCustomerProvider } from "common/contexts/editCustomerContext";

export const customersRoutes: RouteObject = {
  element: (
    <ProtectedRoutes
      allowedRoles={routesList.customers.allowedRoles}
      requiredCustomer={routesList.customers.requiredCustomer}
    />
  ),
  children: [
    { path: routesList.customers.routes.main, element: <CustomersListPage /> },
    {
      path: routesList.customers.routes.update,
      element: (
        <EditCustomerProvider>
          <CustomerUpdatePage />
        </EditCustomerProvider>
      ),
    },
    {
      path: routesList.customers.routes.create,
      element: <CustomerCreatePage />,
    },
  ],
};
