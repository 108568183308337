import { Container, Row } from "components/core/Styled/Layouts";
import { Heading4 } from "components/core/Styled/Texts";
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import styled from "styled-components";

type IProps = {
  name: string;
  selected?: boolean;
};

const ListGroup = ({ name, children, selected }: PropsWithChildren<IProps>) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClickHandler = useCallback(() => {
    setIsOpen((isOpen) => !isOpen);
  }, []);

  useEffect(() => {
    if (selected) setIsOpen(true);
  }, [selected]);

  return (
    <MenuGroup>
      <StyledRow justifyContent="space-between" onClick={onClickHandler}>
        <Heading4>{name}</Heading4>
        {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
      </StyledRow>
      {isOpen && <>{children}</>}
    </MenuGroup>
  );
};

export default ListGroup;

const MenuGroup = styled(Container)`
  width: 100%;
  align-items: flex-start;
  border-bottom: ${(props) => props.theme.border.main};
  padding-bottom: 0.4em;
`;

const StyledRow = styled(Row)`
  width: 100%;
  padding: 0.75em 0.75em 0;
`;
