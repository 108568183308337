import { authRoles } from "common/types/api/roles";

export const authorize = authRoles;

import ApiHelper from "api/ApiHelper";
import { API_BASE_URL } from "utils/UrlList";
import { IProfileInfo } from "common/types/api/profile";

export type IGetUserProfile = {
  customerId?: string;
};

export type IGetUserProfileResponse = IProfileInfo;

const getUserProfile = async ({
  customerId,
}: IGetUserProfile): Promise<IGetUserProfileResponse> => {
  try {
    const res = await ApiHelper.apiClient.get<IGetUserProfileResponse>(
      `${API_BASE_URL}/api/v1/profile`,
      ApiHelper.getHeaders({
        params: {
          customerId,
        },
      })
    );

    const data = res.data;

    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default getUserProfile;
