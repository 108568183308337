import { useGetDocumentListQuery } from "services/query/documents";
import { IDocumentConfigs } from "common/types/api/hostedKyc";
import { IDocumentListInfo } from "common/types/api/document";
import { useCallback, useMemo } from "react";

export type ICountryDocument = {
  country: string;
  numberDocumentSelected: number;
  open?: boolean;
  documents: {
    documentType: string;
    documentName: string;
    selected: boolean;
    lookup: boolean;
    canBeLookup: boolean;
    nfc: boolean;
    canBeNfc: boolean;
  }[];
};

export type ICountryDocumentGroup = {
  [country: string]: ICountryDocument;
};

export type IProps = {
  documentsConfigs?: IDocumentConfigs[];
};

const useSelectedDocument = ({ documentsConfigs }: IProps) => {
  const documentListQuery = useGetDocumentListQuery();

  const fromDocumentConfigsToGroup = useCallback(
    (
      documentsConfigs: IDocumentConfigs[],
      documentTypesList: IDocumentListInfo
    ) => {
      return documentTypesList.reduce<ICountryDocumentGroup>(
        (
          acc,
          { country, documentType, documentName, canBeLookup, canBeNfc }
        ) => {
          const selected = documentsConfigs.find(
            (doc) =>
              doc.documentType === documentType && doc.country === country
          );

          if (!acc[country]) {
            return {
              ...acc,
              [country]: {
                country,
                open: true,
                numberDocumentSelected: selected ? 1 : 0,
                documents: [
                  {
                    documentType,
                    documentName,
                    selected: !!selected,
                    lookup: selected?.lookup ?? false,
                    canBeLookup,
                    canBeNfc,
                    nfc: selected?.nfc ?? false,
                  },
                ],
              },
            };
          }

          acc[country].numberDocumentSelected += selected ? 1 : 0;
          acc[country].documents.push({
            documentType,
            documentName,
            selected: !!selected,
            lookup: selected?.lookup ?? false,
            canBeLookup,
            canBeNfc,
            nfc: selected?.nfc ?? false,
          });

          return acc;
        },
        {}
      );
    },
    []
  );

  const fromGroupToDocumentConfigs = useCallback(
    (countryDocument: ICountryDocument[]) => {
      return countryDocument.reduce<IDocumentConfigs[]>((acc, group) => {
        const list = group.documents.reduce<IDocumentConfigs[]>((list, doc) => {
          if (!doc.selected) return list;
          return [
            ...list,
            {
              documentType: doc.documentType,
              lookup: doc.lookup,
              country: group.country,
              nfc: doc.nfc,
            },
          ];
        }, []);

        return [...acc, ...list];
      }, []);
    },
    []
  );

  const countryDocumentListByGroup = useMemo(() => {
    return fromDocumentConfigsToGroup(
      documentsConfigs ?? [],
      documentListQuery.data ?? []
    );
  }, [documentListQuery.data, documentsConfigs, fromDocumentConfigsToGroup]);

  return {
    countryDocumentListByGroup,
    fromGroupToDocumentConfigs,
    fromDocumentConfigsToGroup,
  };
};

export default useSelectedDocument;
