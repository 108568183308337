import { assertSearch } from "utils/assertion";
import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // internal
  UserRoles.ROLE_UQUDO_AUDITOR,
  UserRoles.ROLE_UQUDO_SALES,
];

import ApiHelper from "api/ApiHelper";
import { IGetPagination, IListPagination } from "common/types/api/common";
import { ICustomer } from "common/types/api/customer";
import { DEFAULT_LIMIT_PAGINATION } from "utils/constants";
import { API_BASE_URL } from "utils/UrlList";

export type IGetCustomers = IGetPagination;

type ICustomerResponse = ICustomer;

export type IGetCustomersResponse = IListPagination<ICustomerResponse>;

const getCustomers = async ({
  start = 0,
  limit = DEFAULT_LIMIT_PAGINATION,
  search,
  ...filterInfo
}: IGetCustomers): Promise<IGetCustomersResponse> => {
  try {
    assertSearch(search);
    const res = await ApiHelper.apiClient.post<IGetCustomersResponse>(
      `${API_BASE_URL}/api/v1/customers/search`,
      { ...filterInfo, text: search || undefined },
      ApiHelper.getHeaders({
        params: {
          start,
          limit,
        },
      })
    );
    const data = res.data;
    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default getCustomers;
