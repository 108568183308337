import CustomerUpdate from "./CustomerUpdate";
import { ContentBody } from "components/core/Styled/Layouts";
import NavBar from "components/layouts/Navbar";
import { useContextEditCustomer } from "common/contexts/editCustomerContext";

const CustomerUpdatePage = () => {
  const { onClickReturnButton, title, customerStatus, isLoading } =
    useContextEditCustomer();

  return (
    <NavBar
      title={title}
      onClickReturnButton={onClickReturnButton}
      showReturnButton
      showEnvironmentDisplay
      showTitle
      showStatus={!isLoading}
      forceStatus={customerStatus}
    >
      <ContentBody>
        <CustomerUpdate />
      </ContentBody>
    </NavBar>
  );
};

export default CustomerUpdatePage;
