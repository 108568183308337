import { getValueByKey } from "utils/helpers";
import { authCodes } from "utils/strings";
import { Container } from "components/core/Styled/Layouts";
import { Button } from "components/core/Styled/Controls";
import Logo from "components/Logo";
import { Heading1, Heading3 } from "components/core/Styled/Texts";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import { useAuthLogin } from "services/auth/token/useAuthLogin";
import { useCallback, useEffect, useMemo } from "react";
import LoadingPage from "components/core/Loaders/LoadingPage";

const LoginPage = () => {
  const [searchParams] = useSearchParams();
  const { handleLogin } = useAuthLogin();

  const code = useMemo(
    () => searchParams.get("code") ?? "auth_failed",
    [searchParams]
  );

  const message = useMemo(() => getValueByKey(authCodes, code), [code]);

  const login = useCallback(() => {
    const force = code === "logout" || code === "user_forbidden";
    handleLogin(force);
  }, [code, handleLogin]);

  useEffect(() => {
    if (code === "session_expired") {
      login();
    }
  }, [code, login]);

  if (code === "session_expired") return <LoadingPage />;

  return (
    <Wrapper>
      <StyledLogo />
      <Heading1>Customer Portal</Heading1>
      {message && <Heading3>{message}</Heading3>}
      <Button onClick={login}>Sign In</Button>
    </Wrapper>
  );
};

export default LoginPage;

const Wrapper = styled(Container)`
  background: ${(props) => props.theme.background.main};
  border: ${(props) => props.theme.border.main};
  border-radius: ${(props) => props.theme.borderRadius.big};
  gap: 1em;
  justify-content: flex-start;
  padding: 4em 2em;

  min-width: 26.25em;
  min-height: 35.125em;

  & > ${Heading3} {
    color: ${(props) => props.theme.color.danger};
    flex: 1;
    margin: 0 auto;
    max-width: 20em;
    height: 100%;
    text-transform: none;
  }

  & > ${Button} {
    width: 100%;

    background: ${(props) => props.theme.background.primary};
    color: ${(props) => props.theme.color.primary2};
    border-radius: ${(props) => props.theme.borderRadius.primary};
    border: none;
    padding: 0.5em;
    &:active {
      background: ${(props) => props.theme.background.primaryLight};
    }
  }
`;

const StyledLogo = styled(Logo)`
  font-size: 3rem;
`;
