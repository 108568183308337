import { authRoles } from "common/types/api/roles";

export const authorize = authRoles;

import ApiHelper from "api/ApiHelper";
import { API_BASE_URL } from "utils/UrlList";
import { IProfileInfo } from "common/types/api/profile";

export type IUpdateUserProfile = {
  customerId?: string;
} & Pick<IProfileInfo, "firstName" | "lastName" | "email" | "mobileNumber">;

export type IUpdateUserProfileResponse = IProfileInfo;

const updateUserProfile = async ({
  customerId,
  ...rest
}: IUpdateUserProfile): Promise<IUpdateUserProfileResponse> => {
  try {
    const res = await ApiHelper.apiClient.put<IUpdateUserProfileResponse>(
      `${API_BASE_URL}/api/v1/profile`,
      rest,
      ApiHelper.getHeaders({
        params: {
          customerId,
        },
      })
    );

    const data = res.data;

    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default updateUserProfile;
