import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // customers
  UserRoles.ROLE_CUSTOMER_ADMIN,
  UserRoles.ROLE_CUSTOMER_OWNER,
  UserRoles.ROLE_CUSTOMER_DEVELOPER,
];

import ApiHelper from "api/ApiHelper";
import { assertNotEmpty } from "utils/assertion";
import { API_BASE_URL } from "utils/UrlList";
import { IClientSecret } from "common/types/api/credentials";
import { WithCustomerInfo } from "common/types/api/customer";

export type IAddClientSecret = WithCustomerInfo;

export type IAddClientSecretResponse = IClientSecret & {
  clientSecret: string;
};

const addClientSecret = async ({
  customerId,
}: IAddClientSecret): Promise<IAddClientSecretResponse> => {
  try {
    assertNotEmpty(customerId);
    const res = await ApiHelper.apiClient.post<IAddClientSecretResponse>(
      `${API_BASE_URL}/api/v1/customers/${customerId}/credentials/client-secrets`,
      {},
      ApiHelper.getHeaders()
    );

    const data = res.data;

    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default addClientSecret;
