import { assertSearch } from "utils/assertion";

export const authorize = [];

import ApiHelper from "api/ApiHelper";
import { IGetPagination, IListPagination } from "common/types/api/common";
import { API_BASE_URL } from "utils/UrlList";
import { IAdminInfo } from "common/types/api/admin";

export type IGetAdmins = IGetPagination;

export type IAdminResponse = IAdminInfo;

export type IGetAdminsResponse = IListPagination<IAdminResponse>;

const getAdmins = async ({
  start,
  limit,
  search,
  ...filterInfo
}: IGetAdmins): Promise<IGetAdminsResponse> => {
  try {
    assertSearch(search);
    const res = await ApiHelper.apiClient.post<IGetAdminsResponse>(
      `${API_BASE_URL}/api/v1/administration/users/search`,
      { ...filterInfo, text: search || undefined },
      ApiHelper.getHeaders({
        params: {
          start,
          limit,
        },
      })
    );

    const data = res.data;

    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default getAdmins;
