import LayoutRouter, { IPropsLayout } from "./LayoutRouter";
import AuthLayout from "components/layouts/AuthLayout";
import LoadingPage from "components/core/Loaders/LoadingPage";
import { useAuth } from "common/hooks/session/useAuth";
import { PropsWithChildren, useEffect } from "react";
import { authFailedPostCallback } from "services/auth/token/tokenStorage";

const PrivateRoutes = ({
  children,
  Layout = AuthLayout,
}: PropsWithChildren<IPropsLayout>) => {
  const { loading, isLoggedIn, error } = useAuth();

  useEffect(() => {
    if (!loading) {
      // if (!isLoggedIn && !(!!error && error.isForbidden())) {
      if (!isLoggedIn) {
        authFailedPostCallback();
      }
    }
  }, [error, isLoggedIn, loading]);

  // if (!loading && !isLoggedIn && !!error && error.isForbidden())
  //   return <ForbiddenPage />;

  if (loading || !isLoggedIn) return <LoadingPage />;

  return <LayoutRouter Layout={Layout}>{children}</LayoutRouter>;
};

export default PrivateRoutes;
// export default withAuthenticationRequired(PrivateRoutes, {
//   onRedirecting: () => <LoadingPage />,
// });
