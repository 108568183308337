import LoadingPage from "components/core/Loaders/LoadingPage";
import { PropsWithChildren, Suspense, Fragment } from "react";
import { Outlet } from "react-router-dom";

export type IPropsLayout = {
  Layout?: React.FC<PropsWithChildren>;
};

const LayoutRouter = ({
  children,
  Layout = Fragment,
}: PropsWithChildren<IPropsLayout>) => {
  return (
    <Layout>
      <Suspense fallback={<LoadingPage />}>
        {children ? children : <Outlet />}
      </Suspense>
    </Layout>
  );
};

export default LayoutRouter;
