import React from "react";
import styled from "styled-components";
import Button from "components/reusable/Button";
import Checkbox from "components/reusable/Checkbox";
import Loader from "components/core/Loaders/Loader";
import { Container, Row } from "components/core/Styled/Layouts";
import { Heading3 } from "components/core/Styled/Texts";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import { HiOutlineCheck } from "react-icons/hi";
import { IProductInfo, ProductType } from "common/types/api/product";
import { useContextEditCustomer } from "common/contexts/editCustomerContext";
import { ILookupConfigurationActive } from "common/types/api/sdkConfigurations";
import { useTheme } from "styled-components";
import { TDocumentType } from "utils/strings";

const extractInfo = (product: IProductInfo | undefined) => {
  if (product?.productType === ProductType.KYC_ENROLMENT_FLOW) {
    return product?.configuration?.lookup;
  }

  if (product?.productType === ProductType.LOOKUP_API) {
    return product?.configuration;
  }

  return undefined;
};

const addInfo = (
  product: IProductInfo,
  addedInfo: ReturnType<typeof extractInfo>
): IProductInfo | undefined => {
  if (product?.productType === ProductType.KYC_ENROLMENT_FLOW) {
    return {
      ...product,
      configuration: {
        ...product.configuration,
        lookup: {
          ...product?.configuration?.lookup,
          active: true,
          ...addedInfo,
        },
      },
    };
  }

  if (product?.productType === ProductType.LOOKUP_API) {
    return {
      ...product,
      configuration: addedInfo,
    };
  }

  return undefined;
};

const CustomerLookupSettings = () => {
  const {
    setSelectedPage,
    messageUpdate,
    selectedPage,
    // update
    onUpdateProduct,
    updateProductLoading,
    // lookup
    documentListIsLoading,
    documentListByCountry,
    documentList,
  } = useContextEditCustomer();
  const theme = useTheme();

  const info = useMemo(
    () => extractInfo(selectedPage.product),
    [selectedPage.product]
  );

  const [documentCheckList, setDocumentCheckList] = useState<
    Record<string, boolean>
  >({});
  const [countryCheckList, setCountryCheckList] = useState<
    Record<string, { checked?: number; open?: boolean }>
  >({});

  const toggle = useCallback(
    (docType: string) => {
      const country = documentList[docType]?.country ?? "";
      setDocumentCheckList((_values) => {
        setCountryCheckList((list) => ({
          ...list,
          [country]: {
            checked:
              (list[country]?.checked ?? 0) + (_values[docType] ? -1 : 1),
            open: true,
          },
        }));
        return {
          ..._values,
          [docType]: !_values[docType],
        };
      });
    },
    [documentList]
  );

  const toggleCountry = useCallback(
    (country: string, e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const docs = documentListByCountry[country];
      setCountryCheckList((list) => {
        const checked = (list[country]?.checked ?? 0) > 0;
        setDocumentCheckList((_values) => {
          const newValues = {
            ..._values,
          };

          docs.forEach((docType) => {
            newValues[docType] = !checked;
          });

          return newValues;
        });

        return {
          ...list,
          [country]: {
            open: true,
            checked: checked ? 0 : docs.length,
          },
        };
      });
      e?.stopPropagation();
    },
    [documentListByCountry]
  );

  const toggleOpenCountry = useCallback((country: string) => {
    setCountryCheckList((list) => {
      return {
        ...list,
        [country]: {
          ...list[country],
          open: !list[country]?.open,
        },
      };
    });
  }, []);

  useEffect(() => {
    setDocumentCheckList({});
    setCountryCheckList({});
    info?.documentTypes?.forEach((docType) => {
      toggle(docType);
    });
  }, [info?.documentTypes, toggle]);

  const onSave = useCallback(async () => {
    if (!selectedPage.product) return;

    const documentTypes = Object.entries(documentCheckList)
      .map(([documentType, selected]) => ({ documentType, selected }))
      .filter((doc) => doc.selected)
      .map((doc) => doc.documentType as TDocumentType);

    const newInfo: ILookupConfigurationActive = {
      documentTypes,
    };

    const data = addInfo(selectedPage.product, newInfo);
    if (!data) return;
    await onUpdateProduct(data, messageUpdate);
    setSelectedPage({ page: "MAIN" });
  }, [
    documentCheckList,
    onUpdateProduct,
    selectedPage.product,
    messageUpdate,
    setSelectedPage,
  ]);

  const touched = useMemo(() => {
    return true;
  }, []);

  if (documentListIsLoading) return <Loader />;

  return (
    <Wrapper>
      <DocsList>
        <ScrollContainer>
          {Object.entries(documentListByCountry).map(([country, docList]) => (
            <CountryRow key={country}>
              <ButtonStyledCountry
                iconBefore={
                  countryCheckList?.[country]?.open ? (
                    <IoMdArrowDropdown color={theme.color.main} />
                  ) : (
                    <IoMdArrowDropright color={theme.color.main} />
                  )
                }
                onClick={() => toggleOpenCountry(country)}
              >
                <Row width="100%" justifyContent="space-between">
                  <StyledCheckbox
                    label={country}
                    checked={!!countryCheckList?.[country]?.checked}
                    onChange={(e) => toggleCountry(country, e)}
                  />
                </Row>
              </ButtonStyledCountry>
              {countryCheckList?.[country]?.open && (
                <>
                  {docList.map((docType) => (
                    <DocumentTypeRow key={docType}>
                      <StyledCheckbox
                        label={documentList?.[docType]?.documentName}
                        key={docType}
                        checked={documentCheckList?.[docType] ?? false}
                        onChange={() => toggle(docType)}
                      />
                    </DocumentTypeRow>
                  ))}
                </>
              )}
            </CountryRow>
          ))}
        </ScrollContainer>
      </DocsList>

      {!updateProductLoading ? (
        <>
          {touched && (
            <ButtonStyled onClick={onSave} iconBefore={<HiOutlineCheck />}>
              <Heading3>Save</Heading3>
            </ButtonStyled>
          )}
        </>
      ) : (
        <ButtonStyled>
          <Loader />
        </ButtonStyled>
      )}
    </Wrapper>
  );
};

export default CustomerLookupSettings;

const Wrapper = styled(Container)`
  width: 100%;
  align-items: flex-start;
  margin: 2rem 0 1rem 4rem;
  justify-content: flex-start;
`;

const DocsList = styled(Container)`
  justify-content: flex-start;
  align-self: flex-start;
  padding: 1rem;
  border: 2px solid #ebf1ff;
  border-radius: 0.5rem;
  width: 34rem;
  max-height: 15.625rem;
`;

const ScrollContainer = styled(Container)`
  justify-content: flex-start;
  align-self: flex-start;
  padding: 1rem;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
`;

const StyledCheckbox = styled(Checkbox)`
  margin: 0.5em 0;
  color: ${(props) => props.theme.color.black};
`;

const ButtonStyledCountry = styled(Button)`
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem;
`;

const CountryRow = styled(Container)`
  width: 100%;
  height: auto;
  cursor: pointer;
`;

const DocumentTypeRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 0 0 3.5rem;
`;

const ButtonStyled = styled(Button)`
  border: ${(props) => props.theme.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: 34rem;
  padding: 0 0.625em;
  margin-top: 3em;
  margin-bottom: 4em;
  min-height: 2.1875em;
`;
