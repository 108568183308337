import LoginPage from "./LoginPage";
import NotFoundPage from "./NotFoundPage";
import PublicRoutes from "routes/PublicRoutes";
import { routesList } from "utils/routesList";
import { RouteObject } from "react-router-dom";
import ForbiddenPage from "./ForbiddenPage";

const children = [
  { path: routesList.login.routes.main, element: <LoginPage /> },
];

export const publicRoutes: RouteObject = {
  element: <PublicRoutes />,
  children: children,
};

export const forbiddenRoute: RouteObject = {
  element: <ForbiddenPage />,
  path: "/forbidden",
};

export const notFoundRoute: RouteObject = {
  element: <NotFoundPage />,
  path: "*",
};
