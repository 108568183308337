import { Container, Row } from "components/core/Styled/Layouts";
import { Heading3 } from "components/core/Styled/Texts";
import { ImageSrc } from "components/reusable/Image";
import { icons } from "assets/images";
import React, { useMemo } from "react";
import styled from "styled-components";
import { IOnboardingWorkflow } from "common/types/api/hostedKyc";
import { workflowStatic } from "common/contexts/workflowContext/useWorkflowConfigs";
import { EmptyType } from "common/types/common";

type IProps = {
  onboardingWorkflow: EmptyType<IOnboardingWorkflow>;
};

const CardDisplay = ({ onboardingWorkflow }: IProps) => {
  const arr = useMemo(() => {
    const res: string[] = [];
    Object.entries(onboardingWorkflow?.scanConfig ?? {}).map(([key, value]) => {
      if (value) res.push(key);
    });

    Object.entries(onboardingWorkflow ?? {}).map(([key, value]) => {
      if (value) res.push(key);
    });

    return res
      .map((key) => workflowStatic.find((x) => x.key === key)?.title)
      .filter((value) => value !== undefined);
  }, [onboardingWorkflow]);

  return (
    <WorkflowSteps>
      {arr.map((value) => (
        <Row key={value} width="100%" justifyContent="flex-start">
          <StyledImageSrc src={icons.arrowWorkflow} />
          <StepCard>
            <Heading3>{value}</Heading3>
          </StepCard>
        </Row>
      ))}
    </WorkflowSteps>
  );
};

export default CardDisplay;

const WorkflowSteps = styled(Container)`
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 1em 0;
`;

const StepCard = styled(Container)`
  width: 14.4375em;
  min-height: 1.825em;
  align-items: center;
  justify-content: center;
  background-color: #ebf1ff;
  border-radius: ${(props) => props.theme.borderRadius.primary};
  margin: 0.6em 0 0 0;
  margin-left: 1em;
`;
const StyledImageSrc = styled(ImageSrc)`
  margin-top: -1.4em;
  height: 3em;
`;
