import { Row } from "components/core/Styled/Layouts";
import { Heading3, Heading4 } from "components/core/Styled/Texts";
import IconContainer from "components/core/IconContainer";
import styled, { useTheme } from "styled-components";
import { GrStatusWarning, GrStatusGood } from "react-icons/gr";
import { BiErrorCircle } from "react-icons/bi";

type IProps = {
  className?: string;
  isSuccess?: boolean;
  isWarning?: boolean;
  warningType?: 1 | 2;
  showIcon?: boolean;
  showText?: boolean;
  isFace?: boolean;
  isOneToN?: boolean;
};

const Status = ({
  className,
  isSuccess,
  showText = true,
  showIcon = false,
  isWarning = false,
  isFace = false,
  isOneToN = false,
  warningType,
}: IProps) => {
  const theme = useTheme();
  return (
    <Wrapper className={className}>
      {showText && !isFace && isOneToN && (
        <Styled0neNText isSuccess={isSuccess}>
          {isSuccess ? "Yes" : "No"}
        </Styled0neNText>
      )}
      {showText && !isFace && !isOneToN && (
        <StyledText isSuccess={isSuccess}>
          {isSuccess ? "OK" : "Failed"}
        </StyledText>
      )}
      {showText && isFace && (
        <StyledFaceText isSuccess={isSuccess}>
          {isSuccess ? "Yes" : "Failed"}
        </StyledFaceText>
      )}

      {showIcon && (
        <StyledIconContainer isSuccess={isSuccess}>
          {isSuccess ? (
            <GrStatusGood />
          ) : isWarning ? (
            <BiErrorCircle
              color={
                warningType === 1 ? theme.color.warning : theme.color.danger
              }
            />
          ) : (
            <GrStatusWarning />
          )}
        </StyledIconContainer>
      )}
    </Wrapper>
  );
};

export default Status;

export const Wrapper = styled(Row)`
  gap: 0.5em;
`;

export const StyledText = styled(Heading3)<{ isSuccess?: boolean }>`
  color: ${(props) =>
    props.isSuccess ? props.theme.color.success : props.theme.color.danger};
  min-width: 3em;
`;
export const Styled0neNText = styled(Heading4)<{ isSuccess?: boolean }>`
  color: ${(props) =>
    props.isSuccess ? props.theme.color.success : props.theme.color.danger};
  min-width: 3em;
`;

export const StyledFaceText = styled(Heading4)<{ isSuccess?: boolean }>`
  color: ${(props) =>
    props.isSuccess ? props.theme.color.success : props.theme.color.danger};
  min-width: 3em;
`;

export const StyledIconContainer = styled(IconContainer)<{
  isSuccess?: boolean;
}>`
  svg {
    path {
      stroke: ${(props) =>
        props.isSuccess ? props.theme.color.success : props.theme.color.danger};
    }
  }
  min-width: 2em;
`;
