import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // customers
  UserRoles.ROLE_CUSTOMER_ADMIN,
  UserRoles.ROLE_CUSTOMER_OWNER,
  UserRoles.ROLE_CUSTOMER_DEVELOPER,
];

import ApiHelper from "api/ApiHelper";
import { assertNotEmpty } from "utils/assertion";
import { API_BASE_URL } from "utils/UrlList";
import { IClientSecret } from "common/types/api/credentials";
import { WithCustomerInfo } from "common/types/api/customer";

export type IDisableClientSecret = WithCustomerInfo & {
  clientSecretId: string;
};

export type IDisableClientSecretResponse = IClientSecret;

const disableClientSecret = async ({
  customerId,
  clientSecretId,
}: IDisableClientSecret): Promise<IDisableClientSecretResponse> => {
  try {
    assertNotEmpty(customerId, clientSecretId);
    const res = await ApiHelper.apiClient.patch<IDisableClientSecretResponse>(
      `${API_BASE_URL}/api/v1/customers/${customerId}/credentials/client-secrets/${clientSecretId}/disable`,
      {},
      ApiHelper.getHeaders()
    );

    const data = res.data;

    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default disableClientSecret;
