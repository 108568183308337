import { clickBehaviorCss } from "components/core/Styled/AtomicCss";
import { Row } from "components/core/Styled/Layouts";
import IconContainer from "components/core/IconContainer";
import { Button as Btn } from "components/core/Styled/Controls";
import styled from "styled-components";
import { PropsWithChildren, useMemo } from "react";

export type IPropsButton = {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  iconBefore?: JSX.Element;
  iconAfter?: JSX.Element;
  className?: string;
  disabled?: boolean;
};

const Button = ({
  iconBefore,
  onClick,
  iconAfter,
  className,
  children,
  disabled,
}: PropsWithChildren<IPropsButton>) => {
  const buttonOnClick = useMemo(
    () => (disabled ? undefined : onClick),
    [disabled, onClick]
  );

  return (
    <ButtonContainer onClick={buttonOnClick} className={className}>
      {iconBefore && <IconContainer>{iconBefore}</IconContainer>}
      <Btn>{children}</Btn>
      {iconAfter && <IconContainer>{iconAfter}</IconContainer>}
    </ButtonContainer>
  );
};

export default Button;

const ButtonContainer = styled(Row)`
  ${clickBehaviorCss};
  color: ${(props) => props.theme.color.main};
`;
