import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // customers
  UserRoles.ROLE_CUSTOMER_ADMIN,
  UserRoles.ROLE_CUSTOMER_OWNER,
  UserRoles.ROLE_CUSTOMER_DEVELOPER,
];

import ApiHelper from "api/ApiHelper";
import { assertNotEmpty } from "utils/assertion";
import { API_BASE_URL } from "utils/UrlList";
import { IClientSecret } from "common/types/api/credentials";
import { WithCustomerInfo } from "common/types/api/customer";

export type IActivateClientSecret = WithCustomerInfo & {
  clientSecretId: string;
};

export type IActivateClientSecretResponse = IClientSecret;

const activateClientSecret = async ({
  customerId,
  clientSecretId,
}: IActivateClientSecret): Promise<IActivateClientSecretResponse> => {
  try {
    assertNotEmpty(customerId, clientSecretId);
    const res = await ApiHelper.apiClient.patch<IActivateClientSecretResponse>(
      `${API_BASE_URL}/api/v1/customers/${customerId}/credentials/client-secrets/${clientSecretId}/activate`,
      {},
      ApiHelper.getHeaders()
    );

    const data = res.data;

    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default activateClientSecret;
