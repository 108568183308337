import createAdmin from "./createAdmin";
import deleteAdmin from "./deleteAdmin";
import getAdminById from "./getAdminById";
import getAdmins from "./getAdmins";
import updateAdmin from "./updateAdmin";

const adminApi = {
  createAdmin,
  deleteAdmin,
  getAdminById,
  getAdmins,
  updateAdmin,
};

export default adminApi;
