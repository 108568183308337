import { clickBehaviorCss } from "components/core/Styled/AtomicCss";
import { Row } from "components/core/Styled/Layouts";
import { Heading4 } from "components/core/Styled/Texts";
import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";

type IProps = {
  list: string[];
  onChange: (value: string) => void;
};

const SmallMenu = ({ list, onChange }: IProps) => {
  const [selected, setSelected] = useState<string>();

  const update = useCallback(
    (newValue: string) => {
      setSelected((oldValue) => {
        if (oldValue === newValue) return oldValue;
        onChange(newValue);
        return newValue;
      });
    },
    [onChange]
  );

  useEffect(() => {
    update(list[0]);
  }, [list, update]);

  return (
    <Wrapper>
      {list.map((value) => (
        <Item
          key={value}
          onClick={() => update(value)}
          selected={selected === value}
        >
          {value}
        </Item>
      ))}
    </Wrapper>
  );
};

export default SmallMenu;

const Wrapper = styled(Row)`
  gap: 0.625em;
  background: ${(props) => props.theme.background.secondary};
  color: ${(props) => props.theme.color.main};
  border-radius: ${(props) => props.theme.borderRadius.secondary};
  margin-inline: 1em;
  padding: 0;
`;

const Item = styled(Heading4)<{ selected?: boolean }>`
  min-width: 10em;
  flex: 1;
  padding: 0.5em;
  ${clickBehaviorCss};
  ${(props) =>
    props.selected &&
    `
        color: ${props.theme.color.primary2};
        background: ${props.theme.background.primary};
        border-radius: ${props.theme.borderRadius.big};
    `};
`;
