import defaultStore, { AppStore } from "./store";
import { Provider } from "react-redux";

export type IProps = {
  children?: React.ReactNode;
  store?: AppStore;
};

export const StoreProvider = ({ children, store = defaultStore }: IProps) => {
  return <Provider store={store}>{children}</Provider>;
};
