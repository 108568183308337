import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // internal
  UserRoles.ROLE_UQUDO_AUDITOR,
  UserRoles.ROLE_UQUDO_SALES,
];

import ApiHelper from "api/ApiHelper";
import { ICustomer, WithCustomerInfo } from "common/types/api/customer";
import { assertNotEmpty } from "utils/assertion";
import { API_BASE_URL } from "utils/UrlList";

export type IGetCustomerById = WithCustomerInfo;

export type IGetCustomerByIdResponse = ICustomer;

const getCustomerById = async ({
  customerId,
}: IGetCustomerById): Promise<IGetCustomerByIdResponse> => {
  try {
    assertNotEmpty(customerId);
    const res = await ApiHelper.apiClient.get<IGetCustomerByIdResponse>(
      `${API_BASE_URL}/api/v1/customers/${customerId}`,
      ApiHelper.getHeaders()
    );

    const data = res.data;

    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default getCustomerById;
