import rootReducer from "./reducers";
import { createMiddlewares } from "middlewares";
import { MODE_DEV } from "utils/constants";
import { configureStore, PreloadedState } from "@reduxjs/toolkit";

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  const { getMiddlewares, postCallMiddlewares } = createMiddlewares();
  const store = configureStore({
    reducer: rootReducer,
    middleware: getMiddlewares,
    enhancers: [],
    preloadedState,
  });

  if (MODE_DEV && module.hot) {
    module.hot.accept("./reducers", () => store.replaceReducer(rootReducer));
  }

  postCallMiddlewares(store);
  return store;
};

const store = setupStore();

export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
export type RootState = ReturnType<typeof rootReducer>;

export default store;
