import auth from "./auth";
import { all } from "redux-saga/effects";
import createSagaMiddlewareRedux from "redux-saga";

function* rootSaga() {
  yield all([auth()]);
}

export const createSagaMiddleware = () => {
  const sagaMiddleware = createSagaMiddlewareRedux();
  const postCallSagaMiddleware = <T>(_store?: T) => {
    sagaMiddleware.run(rootSaga);
  };

  return { sagaMiddleware, postCallSagaMiddleware };
};
