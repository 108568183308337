import ListGroup from "./ListGroup";
import ListItemContainer from "./ListItemContainer";
import { usePathRoute } from "common/hooks/paths/usePathRouter";
import ExternalLinkItem from "components/ExternalLinkItem";
import { IMenuInfo } from "utils/menuList";
import { useAccessAuth } from "common/hooks/session/useAccessAuth";
import { Fragment, useCallback } from "react";

type Item = {
  type: "item" | "externalLink";
  info: IMenuInfo;
};

export type IMenuGroup = {
  name: string;
  list: Item[];
};

const MenuGroup = ({ name, list }: IMenuGroup) => {
  const { isAllowed } = useAccessAuth({
    info: list.map(({ info }) => info),
  });
  const { isCurrentPath: selected } = usePathRoute({
    to: "/",
    patterns: list.map(({ info }) => info.patterns).flat(),
  });

  const render = useCallback(({ type, info }: Item, index: number) => {
    if (type === "item") return <ListItemContainer key={index} info={info} />;
    if (type === "externalLink")
      return <ExternalLinkItem key={index} info={info} />;
    return <Fragment key={index}></Fragment>;
  }, []);

  if (!isAllowed) return <></>;

  return (
    <ListGroup name={name} selected={selected}>
      {list.map(render)}
    </ListGroup>
  );
};

export default MenuGroup;
