import { getInfoEnum, TEnumKey } from "common/types/helpers/enumHelpers";
import { TOption } from "../common";

export const InternalRoles = getInfoEnum({
  ROLE_UQUDO_ADMIN: "ROLE_UQUDO_ADMIN",
  ROLE_UQUDO_SALES: "ROLE_UQUDO_SALES",
  ROLE_UQUDO_AUDITOR: "ROLE_UQUDO_AUDITOR",
} as const);

export type TInternalRole = TEnumKey<typeof InternalRoles>;

export const CustomerRoles = getInfoEnum({
  ROLE_CUSTOMER_USER: "ROLE_CUSTOMER_USER",
  ROLE_CUSTOMER_ADMIN: "ROLE_CUSTOMER_ADMIN",
  ROLE_CUSTOMER_OWNER: "ROLE_CUSTOMER_OWNER",
  ROLE_CUSTOMER_DEVELOPER: "ROLE_CUSTOMER_DEVELOPER",
} as const);

export type TCustomerRole = TEnumKey<typeof CustomerRoles>;

export const AuthRoles = getInfoEnum({
  ...InternalRoles.entries,
  ...CustomerRoles.entries,
} as const);

export type TAuthRoles = TEnumKey<typeof AuthRoles>;

const UserRolesEnum = getInfoEnum({
  PUBLIC: "PUBLIC",
  ...AuthRoles.entries,
} as const);

export type TUserRoles = TEnumKey<typeof UserRolesEnum>;

export const isInternalRole = (role: string) => {
  return InternalRoles.keys.findIndex((e) => e == role) !== -1;
};

export const canAccess = (
  currentRole: TUserRoles,
  allowedRoles: TUserRoles[]
) => allowedRoles.includes(currentRole);

export const canAccessWithCustomer = (
  currentRole: TUserRoles,
  allowedRoles: TUserRoles[]
) => canAccess(currentRole, allowedRoles);

export const UserRoles = {
  ...UserRolesEnum,
  canAccess,
  canAccessWithCustomer,
  isInternalRole,
};

export const internalRoles: TUserRoles[] = [
  // internal
  UserRoles.ROLE_UQUDO_ADMIN,
  UserRoles.ROLE_UQUDO_AUDITOR,
  UserRoles.ROLE_UQUDO_SALES,
];

export const customerRoles: TUserRoles[] = [
  // customers
  UserRoles.ROLE_CUSTOMER_ADMIN,
  UserRoles.ROLE_CUSTOMER_OWNER,
  UserRoles.ROLE_CUSTOMER_DEVELOPER,
  UserRoles.ROLE_CUSTOMER_USER,
];

export const internalRolesLabel: Record<TInternalRole, string> = {
  [UserRoles.ROLE_UQUDO_ADMIN]: "Super Admin",
  [UserRoles.ROLE_UQUDO_AUDITOR]: "Auditor",
  [UserRoles.ROLE_UQUDO_SALES]: "Sales",
};

export const customerRolesLabel: Record<TCustomerRole, string> = {
  [UserRoles.ROLE_CUSTOMER_ADMIN]: "Admin",
  [UserRoles.ROLE_CUSTOMER_OWNER]: "Owner",
  [UserRoles.ROLE_CUSTOMER_DEVELOPER]: "Developer",
  [UserRoles.ROLE_CUSTOMER_USER]: "User",
};

export const authRoles: TUserRoles[] = [...internalRoles, ...customerRoles];

export const authRolesLabel: Record<TAuthRoles, string> = {
  ...internalRolesLabel,
  ...customerRolesLabel,
};

export const allRoles: TUserRoles[] = [
  ...authRoles,
  // public
  UserRoles.PUBLIC,
];

export const rolesToOptions = <T extends TAuthRoles>(
  roles: T[]
): TOption<T>[] => {
  return roles.map((role) => ({
    value: role as T,
    label: authRolesLabel[role] as string,
  }));
};
