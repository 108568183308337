import { IGetConfigByIdResponse } from "api/hostedKycApi/getConfigById";
import { useCallback, useMemo } from "react";
import {
  useGetHostedConfigByIdQuery,
  useGetSdkCustomerConfigMutation,
  useUpdateHostedConfigMutation,
} from "services/query/hostedKyc";
import { useIdPathname } from "common/hooks/paths/useParamsPathname";

type IConfigs = IGetConfigByIdResponse;

function useNoCodeConfigs() {
  const configId = useIdPathname();

  const getHostedConfigByIdQuery = useGetHostedConfigByIdQuery(configId);

  const updateConfigurationMutation = useUpdateHostedConfigMutation(configId);
  const updateConfigurationMutateAsync = useMemo(
    () => updateConfigurationMutation.mutateAsync,
    [updateConfigurationMutation.mutateAsync]
  );

  const refetchData = useMemo(
    () => getHostedConfigByIdQuery.refetch,
    [getHostedConfigByIdQuery.refetch]
  );

  const getSdkCustomerConfigMutation =
    useGetSdkCustomerConfigMutation(configId);

  const getSdkCustomerConfigMutationAsync = useMemo(
    () => getSdkCustomerConfigMutation.mutateAsync,
    [getSdkCustomerConfigMutation.mutateAsync]
  );

  const sdkCustomerConfig = useMemo(
    () => getSdkCustomerConfigMutation.data,
    [getSdkCustomerConfigMutation.data]
  );

  const fetchSdkCustomerConfig = useCallback(async () => {
    return await getSdkCustomerConfigMutationAsync({});
  }, [getSdkCustomerConfigMutationAsync]);

  const configsData = useMemo(
    () => getHostedConfigByIdQuery.data,
    [getHostedConfigByIdQuery.data]
  );

  const configsDisplay = useMemo(() => configsData, [configsData]);

  const saveConfigs = useCallback(
    async (configs: Partial<IConfigs>) => {
      if (!configsData) return;
      await updateConfigurationMutateAsync({ ...configsData, ...configs });
      await refetchData();
    },
    [configsData, refetchData, updateConfigurationMutateAsync]
  );

  return {
    refetchData,
    configsData,
    configId,
    configsDisplay,
    saveConfigs,
    sdkCustomerConfig,
    fetchSdkCustomerConfig,
  };
}

export default useNoCodeConfigs;
