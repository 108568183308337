import authApi from "api/authApi";
import { authActions } from "services/redux/reducers/authSlice";
import { toAppOperationError } from "utils/AppOperationError";
import {
  all,
  call,
  Effect,
  fork,
  put,
  // eslint-disable-next-line import/named
  SagaReturnType,
  takeLatest,
} from "redux-saga/effects";

function* getProfile({ payload }: ReturnType<typeof authActions.getProfile>) {
  try {
    yield call(payload.preAuthCallback);

    const profile: SagaReturnType<typeof authApi.getProfile> = yield call(
      authApi.getProfile
    );

    yield put(authActions.getProfileSuccess({ data: profile }));
  } catch (error) {
    yield put(
      authActions.getProfileFail({ error: toAppOperationError(error) })
    );
  }
}

// eslint-disable-next-line require-yield
function* logout(_action: Effect) {
  try {
    // yield call(authApi.logout);
  } catch (error) {
    console.warn(`logout failed ${(error as Error)?.message}`);
  }
}

export function* watchGetProfile() {
  yield takeLatest(authActions.getProfile, getProfile);
}

export function* watchLogout() {
  yield takeLatest(authActions.logout, logout);
}

export default function* rootSaga() {
  yield all([fork(watchGetProfile), fork(watchLogout)]);
}
