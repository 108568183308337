import MyProfileChangePassword from "./MyProfileChangePassword";
import { ContentBody } from "components/core/Styled/Layouts";
import { routesList } from "utils/routesList";
import { useGoTo } from "common/hooks/paths/useGoTo";
import { useCallback } from "react";
import NavBar from "components/layouts/Navbar";

const UserChangePassword = () => {
  const goTo = useGoTo();

  const onClickReturnButton = useCallback(() => {
    goTo(routesList.profile.routes.main);
  }, [goTo]);

  return (
    <NavBar
      title="Change Password"
      onClickReturnButton={onClickReturnButton}
      showReturnButton
      showEnvironmentDisplay
      showTitle
    >
      <ContentBody>
        <MyProfileChangePassword />
      </ContentBody>
    </NavBar>
  );
};

export default UserChangePassword;
