import { useContextEditCustomer } from "common/contexts/editCustomerContext";
import { IProductStoreDataInfo, TTemporalUnit } from "common/types/api/product";
import ToggleBloc from "components/Toggle/ToggleBloc";
import Loader from "components/core/Loaders/Loader";
import { Container, Row } from "components/core/Styled/Layouts";
import { Heading3, Heading5 } from "components/core/Styled/Texts";
import Button from "components/reusable/Button";
import InputField from "components/reusable/Input";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { HiOutlineCheck } from "react-icons/hi";
import styled from "styled-components";
import { customToast } from "utils/customToast";
import CustomSelect from "components/Select/CustomSelect";

export type TTimeOption = {
  value: TTemporalUnit;
  label: string | number;
};

const options: TTimeOption[] = [
  { value: "DAYS", label: "Days" },
  { value: "WEEKS", label: "Weeks" },
  { value: "MONTHS", label: "Months" },
  { value: "YEARS", label: "Years" },
];

const CustomerDataStorageSettings = () => {
  const {
    setSelectedPage,
    messageUpdate,
    selectedPage,
    // update
    onUpdateProduct,
    updateProductLoading,
  } = useContextEditCustomer();

  const [timeLine, setTimeLine] = useState<TTemporalUnit>("DAYS");
  const [enableRetentionPolicy, setEnableRetentionPolicy] =
    useState<boolean>(false);
  const toggleRetentionPolicy = useCallback(() => {
    setEnableRetentionPolicy((_checked) => !_checked);
  }, []);

  const formik = useFormik({
    initialValues: {
      amount: 0,
    },
    onSubmit: () => console.info("products updated"),
  });

  const onTimeLineChange = useCallback((newValue: unknown) => {
    const option = newValue as TTimeOption;
    setTimeLine(option.value);
  }, []);

  const onSave = useCallback(async () => {
    if (!selectedPage.product) return;

    if (enableRetentionPolicy && !formik.values.amount) {
      customToast.error("Please enter the expiry date");
      return;
    }

    if (enableRetentionPolicy && formik.values.amount < 0) {
      customToast.error("Please enter a positive validity day");
      return;
    }

    const data: IProductStoreDataInfo = {
      storeData: true,
      dataClearanceTime: enableRetentionPolicy
        ? {
            temporalUnit: timeLine,
            amount: formik.values.amount,
          }
        : undefined,
    };

    await onUpdateProduct(
      {
        ...selectedPage.product,
        ...data,
      },
      messageUpdate
    );
    setSelectedPage({ page: "MAIN" });
  }, [
    selectedPage.product,
    enableRetentionPolicy,
    formik.values.amount,
    timeLine,
    onUpdateProduct,
    messageUpdate,
    setSelectedPage,
  ]);

  useEffect(() => {
    setEnableRetentionPolicy(!!selectedPage.product?.dataClearanceTime);
    if (selectedPage.product?.dataClearanceTime) {
      formik.setValues({
        amount: selectedPage.product?.dataClearanceTime?.amount ?? 0,
      });
      setTimeLine(
        selectedPage.product?.dataClearanceTime?.temporalUnit ?? "DAYS"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPage.product?.dataClearanceTime]);

  const touched = useMemo(() => {
    if (!!selectedPage.product?.dataClearanceTime !== enableRetentionPolicy)
      return true;
    if (
      selectedPage.product?.dataClearanceTime?.amount ??
      0 !== formik.values.amount
    )
      return true;
    if (
      selectedPage.product?.dataClearanceTime?.temporalUnit ??
      "DAYS" !== timeLine
    )
      return true;
    return false;
  }, [
    enableRetentionPolicy,
    selectedPage.product?.dataClearanceTime,
    timeLine,
    formik.values.amount,
  ]);

  return (
    <Wrapper>
      <ToggleBloc
        label="Enable Retention Policy"
        withIcon={true}
        toggle={enableRetentionPolicy}
        onChange={toggleRetentionPolicy}
      >
        <ToggleContentStyled>
          <>
            <SubTitle>Delete data older than:</SubTitle>
            <Row width="100%" justifyContent="space-between" gap="0.375em">
              <StyledInputField
                name="amount"
                type="number"
                onChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                touched={formik.touched.amount}
                errors={formik.errors.amount}
                defaultValue={
                  formik.values.amount !== 0 ? formik.values.amount : ""
                }
              />
              <StyledSelectInput
                options={options}
                onChange={onTimeLineChange}
                selected={options.find((x) => x.value === timeLine)}
                selectStyle="100%"
              />
            </Row>
          </>
        </ToggleContentStyled>
      </ToggleBloc>

      {!updateProductLoading ? (
        <>
          {touched && (
            <ButtonStyled onClick={onSave} iconBefore={<HiOutlineCheck />}>
              <Heading3>Save</Heading3>
            </ButtonStyled>
          )}
        </>
      ) : (
        <ButtonStyled>
          <Loader />
        </ButtonStyled>
      )}
    </Wrapper>
  );
};

export default CustomerDataStorageSettings;

const Wrapper = styled(Container)`
  padding: 0 1.875em;
  margin: 1.875em 0;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: flex-start;
`;

const ButtonStyled = styled(Button)`
  border: ${(props) => props.theme.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: 26.25em;
  padding: 0 0.625em;
  margin-top: 3em;
  min-height: 2.1875em;
`;

const SubTitle = styled(Heading5)`
  padding: 1em 0;
  color: ${(props) => props.theme.color.grey};
`;

const ToggleContentStyled = styled(Container)`
  width: 100%;
  padding: 1.5em;
  padding-top: 0;
  align-items: flex-start;
  background: ${(props) => props.theme.background.secondary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
`;

const StyledSelectInput = styled(CustomSelect)`
  width: 50%;
`;

const StyledInputField = styled(InputField)`
  width: 50%;

  ${Container} {
    padding: 0 0.2em;
    background: white;
    min-height: 2.375em;
  }
`;
