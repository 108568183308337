import { UserRoles, allRoles } from "common/types/api/roles";

export const authorize = allRoles;

import ApiHelper from "api/ApiHelper";
import getSelectedCustomer from "api/authApi/getProfile/getSelectedCustomer";
import {
  ISessionInfo,
  ISessionUser,
  getProfileRole,
} from "common/types/api/profile";
import { API_BASE_URL } from "utils/UrlList";

export type IGetProfileResponse = ISessionUser;

const getProfile = async (): Promise<ISessionUser> => {
  try {
    const res = await ApiHelper.apiClient.get<ISessionInfo>(
      `${API_BASE_URL}/api/v1/session`,
      ApiHelper.getHeaders()
    );

    const profile = res.data;
    const selectedCustomer = await getSelectedCustomer(profile);
    const currentRole = getProfileRole(profile, selectedCustomer?.id);
    if (!selectedCustomer || currentRole === UserRoles.PUBLIC)
      throw new Error("unexpected response");

    return {
      ...profile,
      selectedCustomer,
    };
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default getProfile;
