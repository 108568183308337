import { ThemeProvider } from "common/theme";
import AppContainer from "components/layouts/AppContainer";
import { RoutesProvider } from "routes";
import { ServicesProvider } from "services";
import { PropsWithChildren } from "react";

const AppProvider = ({ children }: PropsWithChildren) => {
  return (
    <ThemeProvider>
      <AppContainer>
        <RoutesProvider>
          <ServicesProvider>{children}</ServicesProvider>
        </RoutesProvider>
      </AppContainer>
    </ThemeProvider>
  );
};

export default AppProvider;
