import { USE_MSW_MOCK_API } from "utils/constants";

const choiceBetween = ({
  test,
  dev,
  prod,
}: {
  test?: string;
  dev: string;
  prod?: string;
}) => {
  if (USE_MSW_MOCK_API) return test ?? dev;
  return prod ?? dev;
};

export const loginTestPage = "/login/test";

export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL ?? "https://portal.dev.uqudo.io";

export const AUTH_API_BASE_URL =
  process.env.REACT_APP_AUTH_API_BASE_URL ?? "https://auth.dev.uqudo.io";

export const REDIRECTION = {
  successfulAuthentication: choiceBetween({
    dev: "/dashboard",
    prod: process.env.REACT_APP_SUCCESSFUL_AUTHENTICATION,
  }),

  failureAuthenticationOrLogoutRedirection: choiceBetween({
    dev: "/login",
    prod: process.env.REACT_APP_FAILURE_AUTHENTICATION_OR_LOGOUT_REDIRECTION,
  }),

  support: choiceBetween({
    dev: "https://support.uqudo.com/",
    prod: process.env.REACT_APP_SUPPORT,
  }),
  status: choiceBetween({
    dev: "https://status.uqudo.com/",
    prod: process.env.REACT_APP_STATUS,
  }),

  documentation: choiceBetween({
    dev: "https://docs.uqudo.com/",
    prod: process.env.REACT_APP_DOCUMENTATION,
  }),
};

export const ROUTES = {
  analytic: {
    main: "/",
    dashboard: REDIRECTION.successfulAuthentication,
  },
  reports: {
    main: "/reports",
    display: "/reports/:id",
  },
  audits: {
    main: "/audits",
    display: "/audits/:id",
  },
  users: {
    main: "/users",
    create: "/users/create",
    update: "/users/:id/update",
  },
  customers: {
    main: "/customers",
    update: "/customers/:id/update-customer",
    create: "/customers/create-customer",
  },
  admins: {
    main: "/admins",
    create: "/admins/create",
    update: "/admins/:id/update",
  },
  login: {
    main: REDIRECTION.failureAuthenticationOrLogoutRedirection,
    test: loginTestPage,
  },
  profile: {
    main: "/profile",
    update: "/profile/change-password",
  },
  company: {
    main: "/company",
  },
  linkApp: {
    main: "/linkApp",
  },
  credentials: {
    main: "/credentials",
  },
  noCodeIntegration: {
    main: "/noCodeIntegration",
    update: "/noCodeIntegration/:id/update",
    editText: "/noCodeIntegration/:id/edit-text",
    onboardingWorkflow: "/noCodeIntegration/:id/onboarding-workflow",
  },
  cors: {
    main: "/cors",
  },
};
