import Button from "components/reusable/Button";
import Loader from "components/core/Loaders/Loader";
import { Container } from "components/core/Styled/Layouts";
import { Heading3 } from "components/core/Styled/Texts";
import InputField from "components/reusable/Input";
import CustomSelect from "components/Select/CustomSelect";
import { errorString, haveErrors, validateEmail } from "utils/assertion";
import { ICreateUser } from "api/userApi/createUser";
import { useCreateUserMutation } from "services/query/users";
import { routesList } from "utils/routesList";
import { useGoTo } from "common/hooks/paths/useGoTo";
import { TElement } from "common/types/common";
import { IoMdAdd } from "react-icons/io";
import React, { useCallback, useMemo, useState } from "react";
import { useFormik } from "formik";
import styled from "styled-components";
import { customToast } from "utils/customToast";
import { getMessageError } from "utils/helpers";
import { useCurrentRole } from "common/hooks/session/useCurrentRole";
import { rolesToOptions, UserRoles } from "common/types/api/roles";

export type IUserProps = {
  firstName: string;
  lastName: string;
  email: string;
  role?: string;
};

export type IUserErrorsProps = {
  firstName?: string;
  lastName?: string;
  email?: string;
  role?: string;
};

const options = rolesToOptions([
  UserRoles.ROLE_CUSTOMER_ADMIN,
  UserRoles.ROLE_CUSTOMER_USER,
  UserRoles.ROLE_CUSTOMER_OWNER,
  UserRoles.ROLE_CUSTOMER_DEVELOPER,
]);
type IOption = TElement<typeof options>;

const validate = (values: IUserProps) => {
  const errors: IUserErrorsProps = {};
  errors.firstName = errorString({ firstName: values.firstName });
  errors.lastName = errorString({ lastName: values.lastName });

  if (!values.email) {
    errors.email = "Email is required";
  } else {
    if (!validateEmail(values.email)) {
      errors.email = "Please Enter valid Email";
    }
  }

  return errors;
};

const UserCreate = () => {
  const [role, setRole] = useState<IOption["value"]>();
  const createUsersQuery = useCreateUserMutation();
  const createUsersMutateAsync = useMemo(
    () => createUsersQuery.mutateAsync,
    [createUsersQuery.mutateAsync]
  );

  const goTo = useGoTo();
  const { currentRole } = useCurrentRole();

  const onRoleChange = useCallback((option: IOption) => {
    setRole(option.value);
  }, []);

  const { handleChange, handleBlur, values, errors, touched } = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
    },
    validate,
    onSubmit: () => console.info("User submitted"),
  });

  const onCreateUserClick = useCallback(async () => {
    if (haveErrors(errors)) {
      customToast.error("Please fill the required inputs");
      return;
    }

    if (!role) {
      customToast.error("Please fill the required inputs");
      return;
    }
    try {
      const userData: Omit<ICreateUser, "customerId"> = { ...values, role };
      await createUsersMutateAsync(userData);
      customToast.success("User created successfully");
      goTo(routesList.users.routes.main);
    } catch (error) {
      customToast.error(getMessageError(error, "User creation failed"));
      console.error(error);
    }
  }, [errors, role, values, createUsersMutateAsync, goTo]);

  return (
    <Wrapper>
      <InputField
        label="First name"
        name="firstName"
        onChange={handleChange}
        handleBlur={handleBlur}
        touched={touched.firstName}
        errors={errors.firstName}
      />
      <InputField
        label="Last name"
        name="lastName"
        onChange={handleChange}
        handleBlur={handleBlur}
        touched={touched.lastName}
        errors={errors.lastName}
      />
      <InputField
        label="Email"
        name="email"
        onChange={handleChange}
        handleBlur={handleBlur}
        touched={touched.email}
        errors={errors.email}
      />
      <CustomSelect
        label="Role"
        options={
          currentRole === "ROLE_CUSTOMER_ADMIN"
            ? options.filter((role) => role.value !== "ROLE_CUSTOMER_OWNER")
            : options
        }
        onChange={onRoleChange}
      />
      {!createUsersQuery.isLoading ? (
        <ButtonStyled onClick={onCreateUserClick} iconBefore={<IoMdAdd />}>
          <Heading3>Create</Heading3>
        </ButtonStyled>
      ) : (
        <ButtonStyled>
          <Loader />
        </ButtonStyled>
      )}
    </Wrapper>
  );
};

export default UserCreate;

const Wrapper = styled(Container)`
  padding: 0 1.875em;
  margin: 1.875em 0;
  align-items: flex-start;
  min-height: 80vh;
  justify-content: flex-start;
`;

const ButtonStyled = styled(Button)`
  border: ${(props) => props.theme.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: 26.25em;
  padding: 0 0.625em;
  margin-top: 3em;
  min-height: 2.1875em;
`;
