import { IBackgroundCheckInfo } from "common/types/api/document";
import { Container, Divider, Row } from "components/core/Styled/Layouts";
import { Heading3, Heading4 } from "components/core/Styled/Texts";
import { fromCamelCaseToString, infoToString } from "utils/helpers";
import { TEventInfo } from "common/types/api/documentEvent";
import { Fragment } from "react";
import styled from "styled-components";

type IProps = {
  info: IBackgroundCheckInfo;
};

export const BackgroundCheckTitle = ({ info }: IProps) => {
  if (!info.match) {
    return <WarningText>No Match Found</WarningText>;
  }

  if (!info.content?.length) {
    return (
      <>
        <WarningText>Match Found</WarningText>
        <WarningText>
          You have enabled the ICAD (Alert Decisioning), records are only
          available on the RDC portal.
        </WarningText>
      </>
    );
  }

  return (
    <WarningText>{`${info.content?.length || 0} Records Found`}</WarningText>
  );
};

export const BackgroundCheck = ({ info }: IProps) => {
  return (
    <Wrapper>
      <Title>Background Report</Title>
      <BackgroundCheckTitle info={info} />
      {!!info.content?.length && (
        <>
          <Title>Matching information</Title>
          {info.content?.map((section, index) => (
            <Fragment key={index}>
              <Section>
                <WarningIndex>{`${index + 1}`}</WarningIndex>
                {section.attributes &&
                  Object.entries(section.attributes).map(
                    ([name, value], index) => (
                      <Row
                        key={index}
                        gap="1em"
                        width="100%"
                        justifyContent="space-between"
                      >
                        <StyledSubTitle>
                          {fromCamelCaseToString(name)}
                        </StyledSubTitle>
                        <RowValue>
                          <Heading3 textAlign="left">
                            {infoToString(value)}
                          </Heading3>
                        </RowValue>
                      </Row>
                    )
                  )}
              </Section>
              <Divider />
            </Fragment>
          ))}
        </>
      )}
    </Wrapper>
  );
};

type IPropsError = {
  info: IBackgroundCheckInfo;
  infoEvent: TEventInfo;
};

export const BackgroundCheckError = ({ infoEvent }: IPropsError) => {
  return (
    <Wrapper>
      <Title>Background Report</Title>
      <WarningText>{infoEvent.statusCode}</WarningText>
    </Wrapper>
  );
};

export default BackgroundCheck;

const Wrapper = styled(Container)`
  width: 100%;
  justify-content: flex-start;
  gap: 1em;
  padding: 0.625em;
  margin-bottom: 2em;
`;

const Section = styled(Container)`
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 1em;
`;

const RowValue = styled(Row)`
  width: 100%;
  justify-content: space-between;
  flex: 2;
  border-bottom: ${(props) => props.theme.border.main};
`;

const Title = styled(Heading3)`
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  border-bottom: ${(props) => props.theme.border.main};
  color: ${(props) => props.theme.color.main};
  padding: 1.875em 0;
`;

const StyledSubTitle = styled(Heading4)`
  width: 100%;
  flex: 1;
  text-align: left;
`;

const WarningText = styled(Heading3)`
  width: 100%;
  text-align: left;
  color: ${(props) => props.theme.color.danger};
`;

const WarningIndex = styled(Heading3)`
  width: 1.7em;
  height: 1.7em;
  padding: 0;
  line-height: 1.6;
  align-self: flex-start;
  text-align: center;
  color: ${(props) => props.theme.color.danger};
  border-radius: 50%;
  border: 0.0625em solid ${(props) => props.theme.color.danger};
`;
