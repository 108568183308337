import { IDate } from "common/types/api/common";
import { EmptyType } from "common/types/common";
import moment from "moment";

export const formatDate = (date: EmptyType<IDate>) => {
  if (!date) return "";
  return new Date(date).toLocaleString("en-GB");
};

export const formatLocalDate = (
  date: EmptyType<IDate>,
  {
    day = "numeric",
    month = "long",
    year = "numeric",
  }: Intl.DateTimeFormatOptions = {}
) => {
  if (!date) return "";
  return new Date(date).toLocaleDateString("en-GB", { day, month, year });
};

export const formatLocalDateWihDot = (
  date: EmptyType<IDate>,
  {
    day = "numeric",
    month = "numeric",
    year = "numeric",
  }: Intl.DateTimeFormatOptions = {}
) => {
  if (!date) return "";
  return new Date(date).toLocaleDateString("en-GB", { day, month, year });
};

export const formatLocalDateType = (date: EmptyType<Date>) => {
  if (!date) return undefined;
  return moment(date).toISOString(true);
};

export function dateToString(date: EmptyType<Date>) {
  if (!date) return "";
  return date.toLocaleDateString("en-GB");
}

export function stringToDate(dateStr: EmptyType<IDate>) {
  if (!dateStr) return undefined;
  return new Date(dateStr);
}

export function dateTimeToString(date: EmptyType<Date>) {
  if (!date) return "";
  return date.toISOString();
}

export function isEqualDateWithoutTIme(
  date1: Date | undefined,
  date2: Date | undefined
) {
  if (!date1 || !date2) return date1 == date2;
  return date1.toDateString() === date2.toDateString();
}
