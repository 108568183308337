import FiltersBloc from "components/Filters/FiltersBloc";
import { DropdownOptions, Options } from "components/Select/DropdownOptions";
import { Container } from "components/core/Styled/Layouts";
import Table, { IHeader } from "components/core/Table";
import { ICellComponentProps } from "components/core/Table/CellComponentDefault";
import ConfirmationPopup from "components/Modal/ConfirmationPopup";
import {
  useDeleteUserMFAMutation,
  useDeleteUserMutation,
  useGetUsersQuery,
} from "services/query/users";
import NormalPagination from "components/pagination/NormalPagination";
import { routesList } from "utils/routesList";
import { useGoTo } from "common/hooks/paths/useGoTo";
import { useDisplaySetting } from "common/hooks/settings/useSetting";
import Popup from "components/Modal/Popup";
import React, { useCallback, useMemo, useState } from "react";
import { MdModeEdit } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import { LuShieldAlert } from "react-icons/lu";
import styled from "styled-components";
import { customToast } from "utils/customToast";
import { getMessageError } from "utils/helpers";
import { TableTypes } from "common/types/api/preferences";
import { authRolesLabel } from "common/types/api/roles";

export const usersHeaders = ["firstName", "lastName", "email", "role"];
export const usersHeadersDefault = ["firstName", "lastName", "email", "role"];

type IRow = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string | undefined;
  settings: {
    id: string;
    refetch: () => void;
  };
};

const headers: IHeader[] = [
  {
    Header: "First Name",
    accessor: "firstName",
  },
  {
    Header: "Last Name",
    accessor: "lastName",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Role",
    accessor: "role",
  },

  {
    Header: "",
    accessor: "settings",
  },
];

export type IProps = {
  id: string;
  refetch: () => void;
};

export const UserOptions = ({ id, refetch }: IProps) => {
  const goTo = useGoTo();

  const [confirmDeleteUser, setConfirmDeleteUser] = useState<boolean>(false);
  const [confirmResetUserMfa, setConfirmResetUserMfa] =
    useState<boolean>(false);
  const deleteUserQuery = useDeleteUserMutation(id);
  const resetUserMfaQuery = useDeleteUserMFAMutation(id);
  const deleteUserMutateAsync = useMemo(
    () => deleteUserQuery.mutateAsync,
    [deleteUserQuery.mutateAsync]
  );

  const resetUserMfaMutateAsync = useMemo(
    () => resetUserMfaQuery.mutateAsync,
    [resetUserMfaQuery.mutateAsync]
  );

  const onEditUser = useCallback(() => {
    goTo(routesList.users.routes.update, id);
  }, [goTo, id]);

  const onDeleteUser = useCallback(() => {
    setConfirmDeleteUser(!confirmDeleteUser);
  }, [confirmDeleteUser]);

  const onResetUserMFA = useCallback(() => {
    setConfirmResetUserMfa(!confirmResetUserMfa);
  }, [confirmResetUserMfa]);

  const onConfirmResetUserMfa = useCallback(async () => {
    try {
      await resetUserMfaMutateAsync({});
      customToast.success("User MFA was reset successfully!");
    } catch (error) {
      customToast.error(getMessageError(error, "Failed to reset user MFA."));
    } finally {
      setConfirmResetUserMfa(false);
    }
  }, [resetUserMfaMutateAsync]);

  const onConfirmDeleteUser = useCallback(async () => {
    try {
      await deleteUserMutateAsync({});
      customToast.success("User deleted successfully");
      refetch();
    } catch (error) {
      customToast.error(getMessageError(error, "user deletion failed"));
      console.error(error);
    } finally {
      setConfirmDeleteUser(false);
    }
  }, [deleteUserMutateAsync, refetch]);

  return (
    <StyledOptions>
      <ConfirmationPopup
        text="Are you sure you want to delete this user ?"
        cancelText="Cancel"
        confirmText="Confirm"
        close={onDeleteUser}
        onConfirmDelete={onConfirmDeleteUser}
        show={confirmDeleteUser}
      />
      <ConfirmationPopup
        text="Are you sure you want to reset MFA for this user?"
        cancelText="Cancel"
        confirmText="Confirm"
        close={onResetUserMFA}
        onConfirmDelete={onConfirmResetUserMfa}
        show={confirmResetUserMfa}
      />

      <Popup>
        <DropdownOptions>
          <Options
            text="Edit user"
            icon={<MdModeEdit />}
            onClick={onEditUser}
          />
          <Options
            text="Delete user"
            icon={<RiDeleteBin5Line />}
            onClick={onDeleteUser}
          />
          <Options
            text="Reset MFA"
            icon={<LuShieldAlert />}
            onClick={onResetUserMFA}
          />
        </DropdownOptions>
      </Popup>
    </StyledOptions>
  );
};

export const CellComponent = ({ cell, row, index }: ICellComponentProps) => {
  const info = cell.value as IProps;
  return (
    <td {...cell.getCellProps()}>
      {index === row.cells.length - 1 ? (
        <>{<UserOptions id={info.id} refetch={info.refetch} />}</>
      ) : (
        cell.render("Cell")
      )}
    </td>
  );
};

const UsersList = () => {
  const getUsersQuery = useGetUsersQuery();
  const getUsersRefetch = useMemo(
    () => getUsersQuery.refetch,
    [getUsersQuery.refetch]
  );

  const { displaySetting } = useDisplaySetting(TableTypes.USERS);
  const displaySettingList = displaySetting
    ? displaySetting
    : usersHeadersDefault;
  const goTo = useGoTo();

  const listRow: IRow[] = useMemo(() => {
    if (getUsersQuery.allData === undefined) return [];
    return getUsersQuery.allData.results.map((val) => ({
      id: val.id,
      email: val.email,
      firstName: val.firstName,
      lastName: val.lastName,
      role: authRolesLabel?.[val.role] ?? "",
      settings: { id: val.id, refetch: getUsersRefetch },
    }));
  }, [getUsersQuery.allData, getUsersRefetch]);

  const onRowClick = useCallback(
    (row: unknown) => {
      const { id } = row as IRow;
      goTo(routesList.users.routes.update, id);
    },
    [goTo]
  );

  const customHeader = useMemo(() => {
    const headersWithoutSettings = headers.filter((a) => a.Header !== "");
    return headersWithoutSettings
      .sort((a, b) =>
        displaySettingList.indexOf(a.accessor) <
        displaySettingList.indexOf(b.accessor)
          ? -1
          : 1
      )
      .concat(headers.filter((a) => a.Header === ""));
  }, [displaySettingList]);

  const startIndex = useMemo(() => {
    if (!getUsersQuery.allData?.total) return 1;
    return (
      getUsersQuery.pageSetting.pageNum * getUsersQuery.pageSetting.pageSize + 1
    );
  }, [
    getUsersQuery.allData?.total,
    getUsersQuery.pageSetting.pageNum,
    getUsersQuery.pageSetting.pageSize,
  ]);

  return (
    <Wrapper>
      <FiltersBloc type={TableTypes.USERS} />
      <NormalPagination
        messageEmpty="No Users found"
        queryPagination={getUsersQuery}
      >
        <TableStyled
          keyAccessor="id"
          columns={customHeader.filter(
            (x) =>
              x.accessor ===
                displaySettingList?.find((y) => y === x.accessor) ||
              x.accessor === "settings"
          )}
          data={listRow}
          withIndex
          startIndex={startIndex}
          CellComponent={CellComponent}
          onRowClick={onRowClick}
          sortSettingLabel={TableTypes.USERS}
          sortList={usersHeaders}
          sortListDefault={usersHeadersDefault}
          sortable
          editable
        />
      </NormalPagination>
    </Wrapper>
  );
};

export default UsersList;

const Wrapper = styled(Container)`
  padding: 0 1.875em;
  margin: 1.875em 0;
  margin-top: 0.8em;
  min-height: 80vh;
  justify-content: flex-start;
  align-items: flex-start;
`;

const StyledOptions = styled(Container)`
  width: 1.25em;
  height: 1.25em;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    background-color: white;
  }
`;

const TableStyled = styled(Table)`
  // min-height: 80vh;
  justify-content: flex-start;
  align-items: flex-start;
  td {
    position: relative;
    word-wrap: break-word;
  }
  th {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;
