import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // internal
  UserRoles.ROLE_UQUDO_AUDITOR,
  UserRoles.ROLE_UQUDO_SALES,
  // customers
  UserRoles.ROLE_CUSTOMER_ADMIN,
  UserRoles.ROLE_CUSTOMER_OWNER,
  UserRoles.ROLE_CUSTOMER_DEVELOPER,
];

import ApiHelper from "api/ApiHelper";
import { ICors } from "common/types/api/cors";
import { assertNotEmpty } from "utils/assertion";
import { API_BASE_URL } from "utils/UrlList";
import { WithCustomerInfo } from "common/types/api/customer";

export type IGetConfigurations = WithCustomerInfo;

export type IGetConfigurationsResponse = ICors;

const getConfigurations = async ({
  customerId,
}: IGetConfigurations): Promise<IGetConfigurationsResponse> => {
  try {
    assertNotEmpty(customerId);
    const res = await ApiHelper.apiClient.get<IGetConfigurationsResponse>(
      `${API_BASE_URL}/api/v1/customers/${customerId}/cors/config`,
      ApiHelper.getHeaders()
    );

    const data = res.data;
    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default getConfigurations;
