import { Heading3 } from "components/core/Styled/Texts";
import styled from "styled-components";

export type IStatusDisplayProps = {
  status: string;
  content: string;
  showBorder?: boolean;
};

export const StatusDisplay = ({
  status,
  content,
  showBorder = true,
}: IStatusDisplayProps) => {
  return (
    <StatusContainer showBorder={showBorder} status={status}>
      {content}
    </StatusContainer>
  );
};

const StatusContainer = styled(Heading3)<{
  status: string;
  showBorder?: boolean;
}>`
  padding: ${(props) => (props.showBorder ? "0.375em 1.375em" : "0")};
  max-width: 8.75em;
  color: ${(props) =>
    props.status === "Success" ||
    props.status === "Completed" ||
    props.status === "Active" ||
    props.status === "Enabled"
      ? props.theme.color.success
      : props.theme.color.danger};
  ${(props) =>
    props.showBorder &&
    `
        border: ${props.theme.border.main};
        border-radius: ${props.theme.borderRadius.veryBig};
        border-color: ${
          props.status === "Success" ||
          props.status === "Completed" ||
          props.status === "Active" ||
          props.status === "Enabled"
            ? props.theme.color.success
            : props.theme.color.danger
        };
    `};
`;
