import EditTexts from "./EditTextPage";
import { ContentBody } from "components/core/Styled/Layouts";
import { useGoTo } from "common/hooks/paths/useGoTo";
import { routesList } from "utils/routesList";
import { useCallback } from "react";
import { useContextNoCode } from "common/contexts/noCodeContext";
import NavBar from "components/layouts/Navbar";

const EditTextPage = () => {
  const goTo = useGoTo();
  const { configId } = useContextNoCode();

  const onClickReturnButton = useCallback(() => {
    goTo(routesList.noCodeIntegration.routes.update, configId);
  }, [configId, goTo]);

  return (
    <NavBar
      title="Text Settings"
      onClickReturnButton={onClickReturnButton}
      showListCustomers
      showEnvironmentDisplay
      showTitle
      showReturnButton
      showStatus
    >
      <ContentBody>
        <EditTexts />
      </ContentBody>
    </NavBar>
  );
};

export default EditTextPage;
