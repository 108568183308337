import { EmptyType } from "common/types/common";
import { ITextSettings } from "common/types/api/hostedKyc";
// eslint-disable-next-line import/named
import { isObject } from "lodash";

export const EDIT_TEXTS_HOSTED: ITextSettings = {
  welcome: {
    title: "Verify your Identity",
    description:
      "We'll guide you through a few easy steps to confirm your identity. It'll only take about a minute.",
    button: "Next",
  },
  selectDocument: {
    title: "Choose a Document",
    description:
      "Please select a document from the list below that you possess and intend to use for verification. If you wish to choose a national document, please select the country of origin.",
    descriptionOnlyOneCountry:
      "Please select a document from the list below that you possess and intend to use for verification.",
    selectCountry: "Select Country...",
    selectDocument: "Select National Document",
    selectPassport: "Or select Passport (Any Country)",

    button: "Next",
  },
  verification: {
    title: "Document Onboarding",
    description: "Prepare the following documents:",
    button: "Start",
  },
  qr: {
    title: "Verify your Identity",
    subTitle:
      "We'll guide you through a few easy steps to confirm your identity. It'll only take about a minute. Just scan the QR code with your phone to carry on.",
    descriptionTitle: "How to Scan the QR Code?",
    description:
      "Open the camera app on your phone and point it at the QR code to scan.",
  },
  error: {
    title: "Oops! Something went wrong",
    description: "The verification was not successfully completed.",
    button: "Please refresh the page and try again",
  },
  success: {
    title: "Thank you. You have been successfully onboarded.",
  },
};

export const hostedTextOrder = [
  { value: "welcome", label: "Welcome" },
  { value: "selectDocument", label: "Choose Document" },
  { value: "verification", label: "Prepare Documents" },
  { value: "qr", label: "QR Code" },
  { value: "success", label: "Onboarding Successful" },
  { value: "error", label: "Errors" },
];

export const mergeWithDefaultValue = (
  textSettings?: EmptyType<ITextSettings>
) => {
  const res = { ...(textSettings ?? {}) };

  Object.keys(EDIT_TEXTS_HOSTED).forEach((key) => {
    const defaultValue = EDIT_TEXTS_HOSTED[key];
    const value = res[key];
    if (isObject(defaultValue)) {
      if (!isObject(value)) {
        res[key] = defaultValue;
      } else {
        res[key] = {
          ...defaultValue,
          ...value,
        };
      }
    } else {
      res[key] = res?.[key] ?? EDIT_TEXTS_HOSTED?.[key];
    }
  });

  return res;
};
