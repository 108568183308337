import { useMemo } from "react";
import { useCurrentUser } from "common/hooks/session/useCurrentUser";
import { ContentBody } from "components/core/Styled/Layouts";
import AnalyticsDisplayProdPageFrame from "./AnalyticsDisplayProdPageFrame";
import AnalyticsDisplayTestingPage from "./AnalyticsDisplayTestingPage";
import NavBar from "components/layouts/Navbar";

const AnalyticsPage = () => {
  const { selectedCustomer } = useCurrentUser();

  const content = useMemo(
    () =>
      selectedCustomer?.type === "PRODUCTION" ? (
        <AnalyticsDisplayProdPageFrame />
      ) : (
        <AnalyticsDisplayTestingPage />
      ),
    [selectedCustomer]
  );

  return (
    <NavBar
      title="Analytics"
      showListCustomers
      showEnvironmentDisplay
      showTitle
      showStatus
    >
      <ContentBody>{content}</ContentBody>
    </NavBar>
  );
};

export default AnalyticsPage;
