import { ParameterFunction, RemoveAwaitType } from "common/types/common";
import { AppOperationError } from "utils/AppOperationError";
import {
  MutationKey,
  QueryKey,
  useMutation,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";

export const useAppQuery = <R>(
  fun: () => Promise<R> | R,
  key: QueryKey = [],
  options?: UseQueryOptions<
    RemoveAwaitType<ReturnType<typeof fun>>,
    AppOperationError
  >
) => {
  return useQuery<RemoveAwaitType<ReturnType<typeof fun>>, AppOperationError>(
    key,
    fun,
    options
  );
};

export const useAppMutation = <T, R>(
  fun: (arg: T) => Promise<R>,
  key?: MutationKey
) => {
  return useMutation<
    RemoveAwaitType<ReturnType<typeof fun>>,
    AppOperationError,
    ParameterFunction<typeof fun>
  >(fun, {
    mutationKey: key,
  });
};
