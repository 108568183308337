import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // customers
  UserRoles.ROLE_CUSTOMER_ADMIN,
  UserRoles.ROLE_CUSTOMER_OWNER,
  UserRoles.ROLE_CUSTOMER_DEVELOPER,
];

import ApiHelper from "api/ApiHelper";
import { IConfigHostedKyc } from "common/types/api/hostedKyc";
import { API_BASE_URL } from "utils/UrlList";
import { assertNotEmpty } from "utils/assertion";
import { WithCustomerInfo } from "common/types/api/customer";

export type IGetConfigById = WithCustomerInfo & {
  configId: string;
};

export type IGetConfigByIdResponse = IConfigHostedKyc;

const getConfigById = async ({
  customerId,
  configId,
}: IGetConfigById): Promise<IGetConfigByIdResponse> => {
  try {
    assertNotEmpty(customerId, configId);
    const res = await ApiHelper.apiClient.get<IGetConfigByIdResponse>(
      `${API_BASE_URL}/api/v1/customers/${customerId}/hosted-service/kyc/config/${configId}`,
      ApiHelper.getHeaders()
    );

    const data = res.data;
    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default getConfigById;
