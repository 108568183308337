import createUser from "./createUser";
import deleteUser from "./deleteUser";
import deleteUserMFA from "./deleteUserMFA";
import getUserById from "./getUserById";
import getUsers from "./getUsers";
import updateUser from "./updateUser";

const userApi = {
  createUser,
  deleteUser,
  getUserById,
  getUsers,
  updateUser,
  deleteUserMFA,
};

export default userApi;
