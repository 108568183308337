import SearchList from "./Search/SearchList";
import getCustomersDropDownList, {
  IGetCustomerNameResponse,
} from "api/customerApi/getCustomersDropDownList";
import { useAppSelector } from "services/redux/hooks";
import { selectSelectedIsHasMoreThanOneCustomer } from "services/redux/reducers/authSlice";
import { useUpdateSelectedCustomer } from "common/hooks/session/useSelectCustomer";
import { useCallback, useMemo } from "react";
import { useMatchCurrentPath } from "common/hooks/paths/usePathRouter";
import { menuList } from "utils/menuList";
import { ICustomerDropDownList } from "common/types/api/customer";

const toOption = (val: IGetCustomerNameResponse) => {
  return {
    label: val.companyNameAlias,
    value: val as ICustomerDropDownList,
  };
};

const ListCustomers = () => {
  const { customerSelected, updateSelectedCustomer } =
    useUpdateSelectedCustomer();
  const defaultOption = useMemo<ReturnType<typeof toOption>>(
    () => ({
      label: customerSelected.companyNameAlias,
      value: customerSelected,
    }),
    [customerSelected]
  );

  const isLinkAppPath = useMatchCurrentPath({
    patterns: menuList.linkApp.patterns,
  });

  const isTestingOnly = useMemo(() => {
    return isLinkAppPath;
  }, [isLinkAppPath]);

  const getCustomers: typeof getCustomersDropDownList = useCallback(
    ({ filters, ...arg }) => {
      const localFilters = [...(filters ?? [])];

      if (isTestingOnly) {
        localFilters.push({
          field: "type",
          values: ["TESTING"],
        });
      }

      return getCustomersDropDownList({ ...arg, filters: localFilters });
    },
    [isTestingOnly]
  );

  const onChangeSelected = useCallback(
    (newValue: ReturnType<typeof toOption>) => {
      const newSelectedCustomer = newValue.value;
      updateSelectedCustomer(newSelectedCustomer);
    },
    [updateSelectedCustomer]
  );

  const IsHasMoreThanOneCustomer = useAppSelector(
    selectSelectedIsHasMoreThanOneCustomer
  );
  if (!IsHasMoreThanOneCustomer) return <></>;

  return (
    <>
      <SearchList
        toOption={toOption}
        fun={getCustomers}
        onChangeSelected={onChangeSelected}
        defaultValue={defaultOption}
        showDefaultValue
        selectStyle="20em"
        preSearch
      />
    </>
  );
};

export default ListCustomers;
