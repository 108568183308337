import IconContainer from "components/core/IconContainer";
import { Row } from "components/core/Styled/Layouts";
import { SubTitle } from "components/core/Styled/Texts";
import React, { useCallback, useMemo } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import styled from "styled-components";
import { RiDeleteBin5Line } from "react-icons/ri";
import Button from "components/reusable/Button";
import { useContextEditCustomer } from "common/contexts/editCustomerContext";
import { Wrapper } from "components/Status/Status";
import { IProductType, ProductType } from "common/types/api/product";
import useShowHide from "common/hooks/helpers/useShowHide";
import ConfirmationPopup from "components/Modal/ConfirmationPopup";

export type IProps = {
  productId: string;
  productType: IProductType;
  title: string;
  isOpen: boolean;
  setOpen: () => void;
};

const ProductHeaderDisplay = ({
  productId,
  productType,
  title,
  isOpen,
  setOpen,
}: IProps) => {
  const {
    // delete
    onDeleteProduct,
    deleteProductLoading,
  } = useContextEditCustomer();

  const popupDelete = useShowHide();

  const haveBody = useMemo(() => {
    if (productType === ProductType.KYC_ENROLMENT_FLOW) {
      return true;
    }

    if (productType === ProductType.FACE_FLOW) {
      return true;
    }

    if (productType === ProductType.LOOKUP_API) {
      return true;
    }

    if (productType === ProductType.SCREENING_API) {
      return true;
    }

    return false;
  }, [productType]);

  const onDeleteClick = useCallback(() => {
    popupDelete.hide();
    onDeleteProduct({ productId });
  }, [onDeleteProduct, popupDelete, productId]);

  const onShowPopup: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      popupDelete.show();
      event.stopPropagation();
    },
    [popupDelete]
  );

  return (
    <>
      <ConfirmationPopup
        text="Are you sure you want to delete this product ?"
        cancelText="Cancel"
        confirmText="Confirm"
        show={popupDelete.isVisible}
        close={popupDelete.hide}
        onConfirmDelete={onDeleteClick}
      />

      <Header onClick={setOpen} haveBody={false}>
        <Row gap="1em">
          <StyledTitle>{title}</StyledTitle>
        </Row>
        <IconContainer>
          <ContainerAction>
            <Button onClick={onShowPopup} disabled={deleteProductLoading}>
              <RiDeleteBin5Line />
            </Button>
            {isOpen ? (
              <IoIosArrowUpStyled hide={!haveBody ? "hide" : undefined} />
            ) : (
              <IoIosArrowDownStyled hide={!haveBody ? "hide" : undefined} />
            )}
          </ContainerAction>
        </IconContainer>
      </Header>
    </>
  );
};

export default ProductHeaderDisplay;

const IoIosArrowUpStyled = styled(IoIosArrowUp)<{ hide?: string }>`
  visibility: ${(props) => (props.hide === "hide" ? "hidden" : "visible")};
`;

const IoIosArrowDownStyled = styled(IoIosArrowDown)<{ hide?: string }>`
  visibility: ${(props) => (props.hide === "hide" ? "hidden" : "visible")};
`;

const Header = styled(Row)<{ haveBody?: boolean }>`
  width: 100%;
  height: 3em;
  justify-content: space-between;
  padding: 0.78em 0.9375em;
  margin-bottom: 0.6em;
  background: ${(props) =>
    props.haveBody
      ? props.theme.background.primaryLight
      : props.theme.background.primaryLighter};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  gap: 0.75em;
  cursor: pointer;
`;

const StyledTitle = styled(SubTitle)`
  min-width: 10em;
  color: ${(props) => props.theme.color.main};
  text-align: left;
`;

const ContainerAction = styled(Wrapper)``;
