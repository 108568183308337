import { useAppMutation, useAppQuery } from "./hooks";
import corsApi from "api/corsApi";
import { useSelectCustomer } from "common/hooks/session/useSelectCustomer";
import { IUpdateConfigurations } from "api/corsApi/updateConfigurations";

export const useGetCorsConfigurationsQuery = () => {
  const { customerId } = useSelectCustomer();

  const query = useAppQuery(() => {
    return corsApi.getConfigurations({
      customerId,
    });
  }, ["useGetCorsConfigurationsQuery", customerId]);

  return query;
};

export const useUpdateCorsConfigurationsMutation = () => {
  const { customerId } = useSelectCustomer();

  const query = useAppMutation(
    (arg: Omit<IUpdateConfigurations, "customerId">) => {
      return corsApi.updateConfigurations({
        customerId,
        ...arg,
      });
    }
  );

  return query;
};
