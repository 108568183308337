import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // internal
  UserRoles.ROLE_UQUDO_SALES,
];

import ApiHelper from "api/ApiHelper";
import { API_BASE_URL } from "utils/UrlList";
import { assertNotEmpty } from "utils/assertion";
import { WithCustomerInfo } from "common/types/api/customer";

export type IDeleteCustomer = WithCustomerInfo;

const deleteCustomer = async ({
  customerId,
}: IDeleteCustomer): Promise<void> => {
  try {
    assertNotEmpty(customerId);
    await ApiHelper.apiClient.delete(
      `${API_BASE_URL}/api/v1/customers/${customerId}`,
      ApiHelper.getHeaders()
    );
    return;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default deleteCustomer;
