import { EventStatus } from "common/types/api/documentStatus";
import { Container, Row } from "components/core/Styled/Layouts";
import { IEvent } from "common/types/api/documentEvent";
import { Heading3, SubTitle, Text } from "components/core/Styled/Texts";
import { formatDate } from "utils/dateHelpers";
import Status from "components/Status/Status";
import IconContainer from "components/core/IconContainer";
import Document, { DocumentError } from "components/Document";
import React, { Fragment } from "react";
import styled from "styled-components";
import { useCallback, useEffect, useState } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

type IPropsEventInfo = {
  info: IEvent;
};

const EventBody = ({ info }: IPropsEventInfo) => {
  if (info.status !== EventStatus.SUCCESS) {
    return <DocumentError info={info} />;
  }

  return <Document info={info} />;
};

type IPropsEvent = {
  info: IEvent;
  numberOpenAllClicked?: number;
  index: number;
};

const titleList = {
  SCAN: "Scan",
  READING: "Read NFC",
  FACE: "Face Match",
  BACKGROUND_CHECK: "AML",
  LOOKUP: "Lookup",
};

const Event = ({ index, info, numberOpenAllClicked = 0 }: IPropsEvent) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (numberOpenAllClicked) setIsOpen(true);
  }, [numberOpenAllClicked]);

  const onClickHandler = useCallback(() => {
    setIsOpen((isOpen) => !isOpen);
  }, []);

  return (
    <>
      <Header onClick={onClickHandler}>
        <Row gap="1em">
          <StyledIndex>{index}</StyledIndex>
          <StyledTitle>{titleList[info.type]}</StyledTitle>
          <Status
            isSuccess={info.status === EventStatus.SUCCESS}
            showIcon
            showText
          />
          <Text>{formatDate(info.timestamp)}</Text>
        </Row>
        <IconContainer>
          {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </IconContainer>
      </Header>
      {isOpen && <EventBody info={info} />}
    </>
  );
};

export type IPropsEventList = {
  numberOpenAllClicked: number;
  list: IEvent[];
};

export const EventList = ({ list, numberOpenAllClicked }: IPropsEventList) => {
  return (
    <Wrapper>
      {list.map((event, index) => (
        <Fragment key={index}>
          {event.data && (
            <Event
              key={index}
              index={index + 1}
              info={event}
              numberOpenAllClicked={numberOpenAllClicked}
            />
          )}
        </Fragment>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  width: 100%;
  gap: 0.625em;
  align-items: flex-start;
`;

const Header = styled(Row)`
  width: 100%;
  justify-content: space-between;
  padding: 0.78em 0.9375em;
  background: ${(props) => props.theme.background.secondary};
  gap: 0.75em;
  cursor: pointer;
`;

const StyledIndex = styled(Heading3)`
  color: ${(props) => props.theme.color.secondary};
  min-width: 2em;
`;

const StyledTitle = styled(SubTitle)`
  min-width: 10em;
  color: ${(props) => props.theme.color.main};
  text-align: left;
`;
