import { credentialsRoutes } from "./Credentials";
import { NoCodeIntegrationRoutes } from "./NoCodeIntegration";
import PrivateRoutes from "routes/PrivateRoutes";
import { RouteObject } from "react-router-dom";
import { corsRoutes } from "./Cors";

export const developmentRoutes: RouteObject = {
  element: <PrivateRoutes />,
  children: [credentialsRoutes, NoCodeIntegrationRoutes, corsRoutes],
};
