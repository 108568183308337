import { AppOperationError } from "utils/AppOperationError";
import { IPayloadErrorFailed } from "common/types/redux/actionTypes";
import { LoadingStatus, TLoadingStatus } from "common/types/redux/state";
import { getRootPathname } from "utils/helpers";
import { navigateToPage } from "utils/navigateToPage";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "services/redux/store";
import { ISessionUser, getProfileRole } from "common/types/api/profile";
import { TUserRoles, UserRoles } from "common/types/api/roles";
import { CustomerSelectedIdLocalStorage } from "utils/Storage";

export type IGetProfile = {
  preAuthCallback: (redirect?: boolean) => Promise<boolean>;
};

export type IGetProfileSuccess = {
  data: ISessionUser;
};

type AuthState = {
  status: TLoadingStatus;
  data: ISessionUser | null;
  error: AppOperationError | null;
};

const initialState: AuthState = {
  status: LoadingStatus.IDLE,
  data: null,
  error: null,
};

export const selectDataAuth = (state: RootState) => state.auth.data;

export const selectIsAuth = createSelector(
  [selectDataAuth],
  (profile: ISessionUser | null) => !!profile
);

export const selectSelectedCustomer = createSelector(
  [selectDataAuth],
  (profile: ISessionUser | null) => profile?.selectedCustomer
);

export const selectSelectedCustomerId = createSelector(
  [selectSelectedCustomer],
  (selectedCustomer: ISessionUser["selectedCustomer"] | undefined) =>
    selectedCustomer?.id
);

export const selectCurrentRole = createSelector(
  [selectDataAuth, selectSelectedCustomerId],
  (profile: ISessionUser | null, selectedCustomerId: string | undefined) => {
    return getProfileRole(profile, selectedCustomerId);
  }
);

export const selectIsInternal = createSelector(
  [selectCurrentRole],
  (role: TUserRoles) => UserRoles.isInternalRole(role)
);

export const selectSelectedIsHasMoreThanOneCustomer = createSelector(
  [selectDataAuth, selectIsInternal],
  (profile: ISessionUser | null, isInternal: boolean) => {
    if (isInternal) return true;
    if (!profile?.customerRoles) return false;
    return profile?.customerRoles?.length > 1;
  }
);

export const selectStatusAuth = (state: RootState) => state.auth.status;
export const selectIsLoadingAuth = createSelector(
  [selectStatusAuth],
  (status) => status === LoadingStatus.PENDING
);

export const selectIsShouldBeRecalledAuth = createSelector(
  [selectStatusAuth],
  LoadingStatus.isShouldBeRecalled
);

export const selectNotFinishedAuth = createSelector(
  [selectStatusAuth],
  LoadingStatus.notFinished
);

export const selectErrorAuth = (state: RootState) => state.auth.error;

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    getProfile: (state, _action: PayloadAction<IGetProfile>) => {
      if (LoadingStatus.isShouldBeRecalled(state.status)) {
        state.status = LoadingStatus.PENDING;
      }
    },
    getProfileSuccess: (state, action: PayloadAction<IGetProfileSuccess>) => {
      state.status = LoadingStatus.RESOLVED;
      state.data = action.payload.data;
      CustomerSelectedIdLocalStorage.set(state.data.selectedCustomer.id);
    },
    getProfileFail: (state, action: PayloadAction<IPayloadErrorFailed>) => {
      state.status = LoadingStatus.REJECTED;
      state.error = action.payload.error;
    },
    logout: (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = initialState;
      CustomerSelectedIdLocalStorage.clear();
    },
    updateSelectedCustomer: (
      state,
      action: PayloadAction<ISessionUser["selectedCustomer"]>
    ) => {
      if (!state.data)
        throw new Error("unexpected error you should be already login");
      if (state.data.selectedCustomer?.id === action.payload?.id) return;
      state.data.selectedCustomer = action.payload;
      CustomerSelectedIdLocalStorage.set(state.data.selectedCustomer.id);
      const root = getRootPathname();
      navigateToPage(root);
    },
    patchSelectedCustomer: (
      state,
      action: PayloadAction<
        Partial<Omit<ISessionUser["selectedCustomer"], "id">>
      >
    ) => {
      if (!state.data)
        throw new Error("unexpected error you should be already login");
      state.data.selectedCustomer = {
        ...state.data.selectedCustomer,
        ...action.payload,
      };
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
