import { createSagaMiddleware } from "./saga";
// import { MODE_DEBUG } from "utils/constants";
// import logger from "redux-logger";
import { CurriedGetDefaultMiddleware } from "@reduxjs/toolkit/dist/getDefaultMiddleware";

export const createMiddlewares = () => {
  const { sagaMiddleware, postCallSagaMiddleware } = createSagaMiddleware();

  const postCallMiddlewares = <T>(store?: T) => {
    postCallSagaMiddleware(store);
  };

  const middlewares = [sagaMiddleware];

  const getMiddlewares = (
    getDefaultMiddleware: CurriedGetDefaultMiddleware
  ) => {
    const res = getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
    }).prepend(middlewares);
    // add logger only in the debug mode
    // if (MODE_DEBUG) return res.concat(logger as SagaMiddleware<object>);
    return res;
  };

  return { getMiddlewares, postCallMiddlewares };
};
