import AppErrorBoundary from "components/AppErrorBoundary";
import { Container } from "components/core/Styled/Layouts";
import { CustomToastContainer } from "utils/customToast";
import styled from "styled-components";
import { PropsWithChildren } from "react";

const AppContainer = ({ children }: PropsWithChildren) => {
  return (
    <Wrapper>
      <AppErrorBoundary>
        <CustomToastContainer />
        {children}
      </AppErrorBoundary>
    </Wrapper>
  );
};

export default AppContainer;

const Wrapper = styled(Container)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  background: ${(props) => props.theme.background.empty};
  color: ${(props) => props.theme.color.primary};
`;
