import { useAppQuery } from "./hooks";
import fileApi from "api/fileApi";

export const useGetFileQuery = (customerId: string, fileId: string) => {
  const query = useAppQuery(() => {
    return fileApi.getFileById({
      fileId,
      customerId,
    });
  }, ["useGetFileQuery", customerId, fileId]);
  return query;
};
