import { MODE_DEBUG } from "utils/constants";
import { AxiosFactory } from "utils/CustomAxios";
import { API_BASE_URL } from "utils/UrlList";
import { AxiosInstance } from "axios";

class ApiClient {
  public static axiosInstance: AxiosInstance | undefined = undefined;

  private static createApiInstance(): AxiosInstance {
    return AxiosFactory.create({
      config: {
        baseURL: API_BASE_URL,
      },
      modeDebug: MODE_DEBUG,
    });
  }

  public static getApi(): AxiosInstance {
    if (this.axiosInstance === undefined) {
      return this.createApiInstance();
    }

    return this.axiosInstance;
  }
}

export default ApiClient;
