import Button from "./Button";
import { Heading4, Heading5 } from "components/core/Styled/Texts";
import { TextArea } from "components/core/Styled/Controls";
import { Container, Row } from "components/core/Styled/Layouts";
import styled from "styled-components";
import {
  ChangeEventHandler,
  MouseEventHandler,
  useEffect,
  useRef,
} from "react";

export type IProps = {
  defaultValue?: string | number;
  label?: string;
  placeholder?: string;
  readOnly?: boolean;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onReset?: MouseEventHandler<HTMLDivElement>;
  handleBlur?: ChangeEventHandler<HTMLInputElement>;
  touched?: boolean;
  errors?: string | undefined;
  name?: string;
  className?: string;
  type?: string;
  value?: string | number;
  withReset?: boolean;
};

const TextAreaField = ({
  defaultValue,
  label = "",
  placeholder = "",
  readOnly,
  onChange,
  touched,
  errors,
  name,
  className,
  value,
  withReset,
  onReset,
}: IProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textAreaRef && textAreaRef.current) {
      textAreaRef.current.style.height =
        textAreaRef.current?.scrollHeight + "px";
    }
  }, []);

  return (
    <StyledContainer className={className}>
      {label && <StyledTitle>{label}</StyledTitle>}
      <WrapperRow>
        <Wrapper readOnly={readOnly}>
          <TextArea
            value={value}
            name={name}
            placeholder={placeholder}
            defaultValue={defaultValue}
            onChange={onChange}
            readOnly={readOnly}
            onInput={(e: React.FormEvent<HTMLTextAreaElement>) => {
              e.currentTarget.style.height =
                e.currentTarget.scrollHeight + "px";
            }}
            ref={textAreaRef}
            rows={1}
          />
        </Wrapper>
        {withReset && <ResetButton onClick={onReset}>Reset</ResetButton>}
      </WrapperRow>
      {touched && errors && <Error>{errors}</Error>}
    </StyledContainer>
  );
};

export default TextAreaField;

const StyledContainer = styled(Container)`
  flex-direction: column;
  width: 26.25em;
  align-items: flex-start;
`;
const Wrapper = styled(Container)<{ readOnly?: boolean }>`
  gap: 0.375em;
  padding: ${(props) => (props.readOnly ? "0" : "0.625em")};
  border: ${(props) =>
    props.readOnly ? "none" : props.theme.border.secondary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: 100%;
  min-height: 2.1875em;
  align-items: flex-start;
  ${TextArea} {
    width: 100%;
    color: ${(props) => props.theme.color.black};
    resize: none;
    max-height: 6em;
    font-size: 0.875em;
  }
`;

const StyledTitle = styled(Heading4)`
  color: ${(props) => props.theme.color.secondary2};
  padding: 0.75em 0;
`;
const Error = styled(Heading5)`
  color: ${(props) => props.theme.color.danger};
  padding-top: 0.5em;
`;

const ResetButton = styled(Button)`
  border: ${(props) => props.theme.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: 4.375em;
  padding: 0 0.625em;
  min-height: 2.1875em;
`;

const WrapperRow = styled(Row)`
  width: 26.25em;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1em;
`;
