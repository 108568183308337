import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const useGoTo = (baseURL = "") => {
  const navigate = useNavigate();

  const go = useCallback(
    (path: string, id?: string) => {
      const url = `${baseURL}${path}`.replaceAll(":id", id ?? "");
      navigate(url);
    },
    [baseURL, navigate]
  );

  return go;
};
