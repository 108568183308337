import { Input } from "components/core/Styled/Controls";
import { Container } from "components/core/Styled/Layouts";
import IconContainer from "components/core/IconContainer";
import styled, { useTheme } from "styled-components";
import { FaSearch } from "react-icons/fa";
import {
  ChangeEventHandler,
  useDeferredValue,
  useState,
  useEffect,
  useCallback,
} from "react";
import { MdOutlineClear } from "react-icons/md";

export type IProps = {
  update: (newValue: string) => void;
  defaultValue?: string;
  clearable?: boolean;
  time?: number;
};

const Search = ({
  update,
  defaultValue = "",
  clearable,
  time = 300,
}: IProps) => {
  const [value, setValue] = useState(defaultValue);
  const debounceValue = useDeferredValue(value);
  const theme = useTheme();

  useEffect(() => {
    const handler = setTimeout(() => {
      update(debounceValue);
    }, time);

    return () => {
      clearTimeout(handler);
    };
  }, [debounceValue, time, update]);

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setValue(event.target.value);
    },
    []
  );

  const onClearSearch = useCallback(() => {
    update("");
    setValue("");
  }, [update]);

  return (
    <Wrapper>
      <StyledIconContainer>
        <FaSearch />
      </StyledIconContainer>
      <StyledInput value={value} placeholder="Search..." onChange={onChange} />
      {clearable && (
        <StyledIconContainer onClick={onClearSearch}>
          <MdOutlineClear color={theme.color.grey} />
        </StyledIconContainer>
      )}
    </Wrapper>
  );
};

export default Search;

const Wrapper = styled(Container)`
  flex-direction: row;
  align-items: center;
  padding: 0 0.625em;
  min-height: 2.1875em;
  gap: 0.375em;
  border: ${(props) => props.theme.border.secondary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  max-width: 14em;
  color: ${(props) => props.theme.color.primary};
`;

const StyledIconContainer = styled(IconContainer)`
  color: ${(props) => props.theme.color.secondary};
  svg {
    width: 0.875em;
  }
`;

const StyledInput = styled(Input)`
  width: 100%;
  color: ${(props) => props.theme.color.black};
`;
