import { useAppMutation, useAppQuery } from "./hooks";
import credentialsApi from "api/credentialsApi";
import { useSelectCustomer } from "common/hooks/session/useSelectCustomer";
import { IDeleteClientSecret } from "api/credentialsApi/deleteClientSecret";
import { IActivateClientSecret } from "api/credentialsApi/activateClientSecret";
import { IDisableClientSecret } from "api/credentialsApi/disableClientSecret";

export const useGetClientSecretsQuery = () => {
  const { customerId } = useSelectCustomer();

  const query = useAppQuery(() => {
    return credentialsApi.getClientSecrets({
      customerId,
    });
  }, ["useGetClientSecretsQuery", customerId]);

  return query;
};

export const useGetCustomersCredentialsQuery = () => {
  const { customerId } = useSelectCustomer();

  const query = useAppQuery(() => {
    return credentialsApi.getCustomersCredentials({
      customerId,
    });
  }, ["useGetCustomersCredentialsQuery", customerId]);

  return query;
};

export const useAddClientSecretMutation = () => {
  const { customerId } = useSelectCustomer();

  const query = useAppMutation(() => {
    return credentialsApi.addClientSecret({
      customerId,
    });
  });

  return query;
};

export const useDeleteClientSecretMutation = () => {
  const { customerId } = useSelectCustomer();

  const query = useAppMutation(
    (arg: Omit<IDeleteClientSecret, "customerId">) => {
      return credentialsApi.deleteClientSecret({
        ...arg,
        customerId,
      });
    }
  );

  return query;
};

export const useActivateClientSecretMutation = () => {
  const { customerId } = useSelectCustomer();

  const query = useAppMutation(
    (arg: Omit<IActivateClientSecret, "customerId">) => {
      return credentialsApi.activateClientSecret({
        ...arg,
        customerId,
      });
    }
  );

  return query;
};

export const useDisableClientSecretMutation = () => {
  const { customerId } = useSelectCustomer();

  const query = useAppMutation(
    (arg: Omit<IDisableClientSecret, "customerId">) => {
      return credentialsApi.disableClientSecret({
        ...arg,
        customerId,
      });
    }
  );

  return query;
};
