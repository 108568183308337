import { UserRoles } from "common/types/api/roles";

export const authorize = [
  UserRoles.ROLE_CUSTOMER_OWNER,
  UserRoles.ROLE_CUSTOMER_ADMIN,
  UserRoles.ROLE_CUSTOMER_USER,
];

import ApiHelper from "api/ApiHelper";
import { IAuditInfo } from "common/types/api/audit";
import { IGetPagination, IListPagination } from "common/types/api/common";
import { assertNotEmpty, assertSearch } from "utils/assertion";
import { DEFAULT_LIMIT_PAGINATION } from "utils/constants";
import { API_BASE_URL } from "utils/UrlList";
import { WithCustomerInfo } from "common/types/api/customer";

export type IGetAudits = IGetPagination & WithCustomerInfo;

export type IAuditResponse = Pick<
  IAuditInfo,
  | "sessionId"
  | "name"
  | "lastUpdate"
  | "status"
  | "source"
  | "documentsTypes"
  | "documentId"
>;

export type IGetAuditsResponse = IListPagination<IAuditResponse>;

const getAudits = async ({
  customerId,
  start = 0,
  limit = DEFAULT_LIMIT_PAGINATION,
  search,
  ...filterInfo
}: IGetAudits): Promise<IGetAuditsResponse> => {
  try {
    assertNotEmpty(customerId);
    assertSearch(search);
    const res = await ApiHelper.apiClient.post<IGetAuditsResponse>(
      `${API_BASE_URL}/api/v1/customers/${customerId}/auditing/search`,
      { ...filterInfo, text: search || undefined },
      ApiHelper.getHeaders({
        params: {
          start,
          limit,
        },
      })
    );

    const data = res.data;

    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default getAudits;
