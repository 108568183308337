import DraggableList from "components/DraggableSettings";
import Button from "components/reusable/Button";
import { Heading3, Heading4 } from "components/core/Styled/Texts";
import { Container, Row } from "components/core/Styled/Layouts";
import { TTableTypes } from "common/types/api/preferences";
import { useDisplaySetting } from "common/hooks/settings/useSetting";
import React, { RefObject, useCallback, useState } from "react";
import styled from "styled-components";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";

type IProps = {
  label?: TTableTypes;
  list: string[];
  defaultList: string[];
  setIsOpen: (isOpen: boolean) => void;
  reference?: RefObject<HTMLDivElement>;
};

const TableSettings = ({
  label,
  list,
  defaultList,
  reference,
  setIsOpen,
}: IProps) => {
  const { displaySetting, updateDisplaySetting } = useDisplaySetting(label);
  const fullList = list;
  const shownList = displaySetting ? displaySetting : defaultList;
  const hiddenList = fullList.filter(
    (x) => x !== shownList.find((y) => y === x)
  );

  const [shown, setShown] = useState(shownList);
  const [hidden, setHidden] = useState(hiddenList);

  const onShow = useCallback(
    (item: string) => {
      setShown([...shown, item]);
      setHidden(hidden.filter((x) => x !== item));
    },
    [hidden, shown]
  );

  const onHide = useCallback(
    (item: string) => {
      setHidden([...hidden, item]);
      setShown(shown.filter((x) => x !== item));
    },
    [hidden, shown]
  );

  const reorder = useCallback((list: string[]) => {
    setShown(list);
  }, []);

  const reorderHidden = useCallback((list: string[]) => {
    setHidden(list);
  }, []);

  const applyDefaultView = useCallback(() => {
    setShown(defaultList);
    setHidden(list.filter((x) => x !== defaultList.find((y) => y === x)));
  }, [defaultList, list]);

  const applyDisplaySettings = useCallback(() => {
    setIsOpen(false);
    updateDisplaySetting(shown);
  }, [setIsOpen, shown, updateDisplaySetting]);

  return (
    <>
      <Pointer />
      <Wrapper ref={reference}>
        <Title>View Option</Title>
        <DefaultButton onClick={applyDefaultView}>
          <Heading3>Default View</Heading3>
        </DefaultButton>

        <DraggableWrapper>
          <Row width="100%" justifyContent="space-between">
            <Label>Shown</Label>
            {/* <TriggerButton>
            <Heading4>Show All</Heading4>
          </TriggerButton> */}
          </Row>
          <DraggableList
            list={shown}
            action={onHide}
            reorderList={reorder}
            showIcon={<HiOutlineEye />}
          />

          <Row width="100%" justifyContent="space-between">
            <Label>Hidden</Label>
            {/* <TriggerButton>
            <Heading4>Show All</Heading4>
          </TriggerButton> */}
          </Row>
          <DraggableList
            list={hidden}
            action={onShow}
            reorderList={reorderHidden}
            showIcon={<HiOutlineEyeOff />}
          />
        </DraggableWrapper>
        <DefaultButton onClick={applyDisplaySettings}>
          <Heading3>Apply</Heading3>
        </DefaultButton>
      </Wrapper>
    </>
  );
};

export default TableSettings;

const Wrapper = styled(Container)`
  position: absolute;
  top: 4.4em;
  right: 0.5em;
  padding: 1em 0.625em;
  border-radius: ${(props) => props.theme.borderRadius.primary};
  box-shadow: ${(props) => props.theme.boxShadow.main};
  background-color: white;
  width: 15.5em;
  align-items: flex-start;
  z-index: 10;
`;

const Title = styled(Heading3)`
  color: ${(props) => props.theme.color.grey};
`;

const DefaultButton = styled(Button)`
  border: ${(props) => props.theme.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: 100%;
  padding: 0 0.625em;
  margin: 1em 0;
  min-height: 2.125em;
`;

const DraggableWrapper = styled(Container)`
  width: 100%;
`;
const Label = styled(Heading4)`
  color: ${(props) => props.theme.color.grey};
`;

const Pointer = styled.div`
  position: absolute;
  height: 1.25em;
  width: 1.25em;
  margin: auto 0;
  transform: rotate(-135deg);
  border-radius: 0 0 0.375em 0;
  box-shadow: ${(props) => props.theme.boxShadow.main};
  background-color: ${(props) => props.theme.background.main};
  right: 1.6em;
  top: 3.6em;
  z-index: 1;
`;
