import { IHistoryBrowser } from "./historyBrowser";
import { useLayoutEffect, useState } from "react";
import { Router } from "react-router-dom";

export type IProps = {
  children?: React.ReactNode;
  historyBrowser: IHistoryBrowser;
  basename?: string;
};

const CustomRouter = ({ basename, children, historyBrowser }: IProps) => {
  const [state, setState] = useState({
    action: historyBrowser.action,
    location: historyBrowser.location,
  });

  useLayoutEffect(() => historyBrowser.listen(setState), [historyBrowser]);

  return (
    <Router
      basename={basename}
      // eslint-disable-next-line react/no-children-prop
      children={children}
      navigator={historyBrowser}
      location={state.location}
      navigationType={state.action}
    />
  );
};

export default CustomRouter;
