import { analyticsRoutes } from "./Analytics";
import { developmentRoutes } from "./Development";
import { forbiddenRoute, notFoundRoute, publicRoutes } from "./Public";
import { administrationRoutes } from "./Administration";
import { settingsRoutes } from "./Settings";
import { useRoutes } from "react-router-dom";
import { useEffect } from "react";
import { clearStorages } from "utils/Storage";
import { useCurrentLocation } from "common/hooks/paths/useParamsPathname";

const Pages = () => {
  const element = useRoutes([
    analyticsRoutes,
    settingsRoutes,
    developmentRoutes,
    administrationRoutes,
    publicRoutes,
    forbiddenRoute,
    notFoundRoute,
  ]);

  const currentLocation = useCurrentLocation();

  useEffect(() => {
    clearStorages(currentLocation.pathname);
  }, [currentLocation.pathname]);

  return element;
};

export default Pages;
