import ReturnButton from "components/core/ReturnButton";
import { Nav, Row } from "components/core/Styled/Layouts";
import { Heading2 } from "components/core/Styled/Texts";
import ListCustomers from "components/ListCustomers";
import Search from "components/Search/Search";
import { PropsWithChildren } from "react";
import styled from "styled-components";

export type INavBarWithoutStatusProps = {
  title: string;
  subTitle?: string;
  onClickReturnButton?: () => void;
  onClickCreateUserButton?: () => void;
  searchValue?: string;
  updateSearch?: (newValue: string) => void;
  showReturnButton?: boolean;
  showTitle?: boolean;
  showSubTitle?: boolean;
  showEnvironmentDisplay?: boolean;
  showSearch?: boolean;
  showListCustomers?: boolean;
  showCreateUser?: boolean;
  buttonTitle?: string;
  addedComponent?: React.ReactNode;
  ContentComponent?: React.ReactNode;
};

const NavBarWithoutStatus = ({
  onClickReturnButton,
  title,
  subTitle,
  searchValue,
  updateSearch,
  showReturnButton,
  showTitle,
  showSubTitle,
  showSearch,
  showListCustomers,
  addedComponent,
  ContentComponent,
}: PropsWithChildren<INavBarWithoutStatusProps>) => {
  return (
    <Nav>
      <Row gap="1.25em">
        {showReturnButton && <ReturnButton onClick={onClickReturnButton} />}
        {showTitle && <Heading2>{title}</Heading2>}
        {showSubTitle && <SubTitle>{subTitle}</SubTitle>}
      </Row>
      <Row gap="1.25em">
        {addedComponent}
        {showSearch && updateSearch && (
          <Search defaultValue={searchValue} update={updateSearch} />
        )}
        {ContentComponent}
        {showListCustomers && <ListCustomers />}
      </Row>
    </Nav>
  );
};

export default NavBarWithoutStatus;

const SubTitle = styled(Heading2)`
  color: ${(props) => props.theme.color.main};
  text-transform: none;
`;
