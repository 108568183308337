import Button from "components/reusable/Button";
import Loader from "components/core/Loaders/Loader";
import InputField from "components/reusable/Input";
import { errorString, haveErrors } from "utils/assertion";
import { routesList } from "utils/routesList";
import { useGoTo } from "common/hooks/paths/useGoTo";
import { Container, Row } from "components/core/Styled/Layouts";
import { Heading3, Heading4, Heading5 } from "components/core/Styled/Texts";
import ConfirmationPopup from "components/Modal/ConfirmationPopup";
import {
  useDeleteUserAccountMutation,
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
} from "services/query/profile";
import { IUpdateUserProfile } from "api/profileApi/updateUserProfile";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import "react-phone-number-input/style.css";
import { customToast } from "utils/customToast";
import { getMessageError } from "utils/helpers";
import { HiOutlineCheck } from "react-icons/hi";
import { useCustomAuth } from "services/auth/useCustomAuth";

export type IUserProps = {
  firstName?: string;
  lastName?: string;
  email?: string;
};

export type IUserErrorsProps = {
  firstName?: string;
  lastName?: string;
  email?: string;
};

const MyProfileDisplay = () => {
  const { handleLogout } = useCustomAuth();

  const goTo = useGoTo();
  const [phoneValue, setPhoneValue] = useState<string>();
  const [confirmDeleteUser, setConfirmDeleteUser] = useState<boolean>(false);
  const [confirmChangeEmail, setConfirmChangeEmail] = useState<boolean>(false);
  const userProfileQuery = useGetUserProfileQuery();
  const userProfileRefetch = useMemo(
    () => userProfileQuery.refetch,
    [userProfileQuery.refetch]
  );

  const userUpdateQuery = useUpdateUserProfileMutation();
  const userUpdateMutateAsync = useMemo(
    () => userUpdateQuery.mutateAsync,
    [userUpdateQuery.mutateAsync]
  );

  const userDeleteQuery = useDeleteUserAccountMutation();
  const userDeleteMutateAsync = useMemo(
    () => userDeleteQuery.mutateAsync,
    [userDeleteQuery.mutateAsync]
  );

  const user = useMemo(() => {
    if (userProfileQuery.data === undefined) return undefined;
    return {
      email: userProfileQuery.data.email,
      firstName: userProfileQuery.data.firstName,
      lastName: userProfileQuery.data.lastName,
      mobileNumber: userProfileQuery.data.mobileNumber,
    };
  }, [userProfileQuery.data]);

  const validate = (values: IUserProps) => {
    const errors: IUserErrorsProps = {};

    errors.firstName = errorString({
      firstName: values.firstName,
    });
    errors.lastName = errorString({
      lastName: values.lastName,
    });
    errors.email = errorString({
      email: values.email,
    });

    return errors;
  };

  const {
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setValues: setFormikValues,
  } = useFormik({
    initialValues: {
      firstName: (user && user.firstName) || "",
      lastName: (user && user.lastName) || "",
      email: (user && user.email) || "",
    },
    validate,
    onSubmit: () => console.info("User edited"),
  });

  const onDeleteUser = useCallback(() => {
    setConfirmDeleteUser(!confirmDeleteUser);
  }, [confirmDeleteUser]);

  const onChangeEmail = useCallback(() => {
    setConfirmChangeEmail(!confirmChangeEmail);
  }, [confirmChangeEmail]);

  const onConfirmDeleteUser = useCallback(async () => {
    try {
      await userDeleteMutateAsync({});
      customToast.success("User deleted successfully");
    } catch (error) {
      customToast.error(getMessageError(error, "user deletion failed"));
      console.error(error);
    } finally {
      setConfirmDeleteUser(false);
      await handleLogout();
    }
  }, [handleLogout, userDeleteMutateAsync]);

  const onClickChangePassword = useCallback(() => {
    goTo(routesList.profile.routes.update);
  }, [goTo]);

  const onUpdateUserClick = useCallback(async () => {
    if (haveErrors(errors)) {
      customToast.error("Please fill the required inputs");
      return;
    }

    const mobileNumber = phoneValue as string;
    const userCreationData: Omit<IUpdateUserProfile, "customerId"> = {
      ...values,
      mobileNumber,
    };
    if (!phoneValue || !isValidPhoneNumber(phoneValue)) {
      customToast.error("Please fill the required inputs");
      return;
    }
    try {
      await userUpdateMutateAsync(userCreationData);
      customToast.success("User updated successfully");
      userProfileRefetch();
    } catch (error) {
      customToast.error(getMessageError(error, "User update failed"));
      console.error(error);
    } finally {
      setConfirmChangeEmail(false);
    }
  }, [errors, phoneValue, userProfileRefetch, userUpdateMutateAsync, values]);

  const onConfirmChangeEmail = useCallback(async () => {
    if (values.email !== userProfileQuery.data?.email) {
      setConfirmChangeEmail(!confirmChangeEmail);
    } else {
      await onUpdateUserClick();
    }
  }, [
    confirmChangeEmail,
    onUpdateUserClick,
    userProfileQuery.data?.email,
    values.email,
  ]);

  useEffect(() => {
    if (userProfileQuery.data !== undefined) {
      setFormikValues({
        firstName: userProfileQuery.data.firstName,
        lastName: userProfileQuery.data.lastName,
        email: userProfileQuery.data.email,
      });
      setPhoneValue(userProfileQuery.data.mobileNumber);
    }
  }, [setFormikValues, userProfileQuery.data]);

  return (
    <Wrapper>
      <ConfirmationPopup
        text="Are you sure you want to delete this user ?"
        cancelText="Cancel"
        confirmText="Confirm"
        close={onDeleteUser}
        onConfirmDelete={onConfirmDeleteUser}
        show={confirmDeleteUser}
      />
      <ConfirmationPopup
        text="By changing the email, you will be logged out and you can only login with your new email"
        cancelText="Cancel"
        confirmText="Yes, Continue"
        close={onChangeEmail}
        onConfirmDelete={onUpdateUserClick}
        show={confirmChangeEmail}
      />
      <Title>User Details</Title>
      <InputField
        label="First Name"
        name="firstName"
        onChange={handleChange}
        handleBlur={handleBlur}
        touched={touched.firstName}
        errors={errors.firstName}
        value={values?.firstName}
      />
      <InputField
        label="Last Name"
        name="lastName"
        onChange={handleChange}
        handleBlur={handleBlur}
        touched={touched.lastName}
        errors={errors.lastName}
        value={values?.lastName}
      />
      <InputField
        label="Email"
        name="email"
        onChange={handleChange}
        handleBlur={handleBlur}
        touched={touched.email}
        errors={errors.email}
        value={values?.email}
      />

      <Label>Phone number</Label>
      <PhoneInputStyled>
        <PhoneInput
          defaultCountry="AE"
          value={phoneValue}
          onChange={setPhoneValue}
        />
      </PhoneInputStyled>
      {phoneValue && !isValidPhoneNumber(phoneValue) && (
        <Error>Please enter a valid Phone Number</Error>
      )}

      {!userUpdateQuery.isLoading ? (
        <ButtonStyled
          onClick={onConfirmChangeEmail}
          iconBefore={<HiOutlineCheck />}
        >
          <Heading3>Save</Heading3>
        </ButtonStyled>
      ) : (
        <ButtonStyled>
          <Loader />
        </ButtonStyled>
      )}
      <Divider />
      <Title>Edit Details</Title>
      <EditButtonStyled onClick={onClickChangePassword}>
        <Heading3>Change password</Heading3>
      </EditButtonStyled>
      <Divider />
      <Title>Delete account</Title>
      <SubTitle>This will remove all of your personal data forever.</SubTitle>
      <Button onClick={onDeleteUser}>
        <Heading3>Delete</Heading3>
      </Button>
    </Wrapper>
  );
};

export default MyProfileDisplay;

const Wrapper = styled(Container)`
  padding: 0 1.875em;
  margin: 1.875em 0;
  align-items: flex-start;
  justify-content: flex-start;
  height: 80vh;
`;

const ButtonStyled = styled(Button)`
  border: ${(props) => props.theme.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: 26.25em;
  padding: 0 0.625em;
  margin-top: 3em;
  min-height: 2.1875em;
`;

const EditButtonStyled = styled(Button)`
  border: ${(props) => props.theme.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: 9.625em;
  padding: 0 0.625em;
  margin: 1em 0;
  min-height: 2.1875em;
`;

const PhoneInputStyled = styled(Row)`
  width: 26.25em;
  min-height: 2.1875em;
  justify-content: flex-start;
  padding: 0 0.625em;
  border-radius: ${(props) => props.theme.borderRadius.primary};
  background: ${(props) => props.theme.background.main};
  border: ${(props) => props.theme.border.secondary};

  .PhoneInput {
    width: 100%;
    height: 100%;
  }
  input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 0 0.75em;
  }
`;

const Error = styled(Heading5)`
  width: 100%;
  text-align: left;
  color: ${(props) => props.theme.color.danger};
  padding: 0.5em 0;
`;

const Label = styled(Heading4)`
  color: ${(props) => props.theme.color.secondary2};
  padding: 0.75em 0;
`;

const Title = styled(Heading3)`
  font-size: 1.125em;
  font-weight: 600;
  color: ${(props) => props.theme.color.secondary2};
`;

const SubTitle = styled(Heading3)`
  color: ${(props) => props.theme.color.secondary2};
  padding: 1em 0;
`;

export const Divider = styled.div`
  width: 26.25em;
  height: 0;
  border-top: ${(props) => props.theme.border.main};
  margin-top: 2em;
  padding: 0.5em 0;
`;
