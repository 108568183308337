import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // internal
  UserRoles.ROLE_UQUDO_SALES,
];

import ApiHelper from "api/ApiHelper";
import { assertNotEmpty } from "utils/assertion";
import { API_BASE_URL } from "utils/UrlList";
import { WithCustomerInfo } from "common/types/api/customer";

export type IDisableCustomer = WithCustomerInfo;

export type IDisableCustomerResponse = void;

const disableCustomer = async ({
  customerId,
}: IDisableCustomer): Promise<IDisableCustomerResponse> => {
  try {
    assertNotEmpty(customerId);
    const res = await ApiHelper.apiClient.patch<IDisableCustomerResponse>(
      `${API_BASE_URL}/api/v1/customers/${customerId}/disable`,
      {},
      ApiHelper.getHeaders()
    );

    const data = res.data;
    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default disableCustomer;
