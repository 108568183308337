import getUserProfile from "./getUserProfile";
import updateUserPassword from "./updateUserPassword";
import updateUserProfile from "./updateUserProfile";
import deleteUserAccount from "./deleteUserAccount";

const profileApi = {
  getUserProfile,
  updateUserProfile,
  updateUserPassword,
  deleteUserAccount,
};

export default profileApi;
