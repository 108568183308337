import { navigateToPage } from "utils/navigateToPage";
import { useCallback, useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";

export const useCurrentPath = () => {
  const { pathname } = useLocation();
  return pathname;
};

type IUseMatchCurrentPath = {
  patterns: string | string[];
};

export const useMatchCurrentPath = ({ patterns }: IUseMatchCurrentPath) => {
  const pathname = useCurrentPath();

  const match = useMemo<boolean>(() => {
    if (Array.isArray(patterns)) {
      return patterns.some((pattern) => !!matchPath(pattern, pathname));
    }

    return !!matchPath(patterns, pathname);
  }, [patterns, pathname]);

  return match;
};

type IUsePathRoute = {
  patterns: string | string[];
  to: string;
};

export const usePathRoute = ({ patterns, to }: IUsePathRoute) => {
  const isCurrentPath = useMatchCurrentPath({ patterns });

  const navigateTo = useCallback(() => {
    navigateToPage(to);
  }, [to]);

  return { isCurrentPath, navigateTo };
};
