import {
  selectIsAuth,
  selectStatusAuth,
  authActions,
  selectNotFinishedAuth,
  selectErrorAuth,
  selectIsInternal,
} from "services/redux/reducers/authSlice";
import { useAppDispatch, useAppSelector } from "services/redux/hooks";
import { LoadingStatus } from "common/types/redux/state";
import { useEffect } from "react";
import { useAuthLogin } from "services/auth/token/useAuthLogin";

export const useAuth = () => {
  const status = useAppSelector(selectStatusAuth);
  const error = useAppSelector(selectErrorAuth);
  const loading = useAppSelector(selectNotFinishedAuth);
  const isLoggedIn = useAppSelector(selectIsAuth);
  const isInternal = useAppSelector(selectIsInternal);
  const dispatch = useAppDispatch();
  const { handleAuth } = useAuthLogin();

  useEffect(() => {
    if (status === LoadingStatus.IDLE) {
      dispatch(authActions.getProfile({ preAuthCallback: handleAuth }));
    }
  }, [dispatch, status, handleAuth]);

  return { loading, isLoggedIn, error, isInternal };
};
