import { Container } from "components/core/Styled/Layouts";
import { Heading2 } from "components/core/Styled/Texts";
import styled, { useTheme } from "styled-components";

export type IProps = {
  text?: string;
};

const EmptyState = ({ text }: IProps) => {
  const theme = useTheme();
  return (
    <Wrapper>
      {text && <Heading2 color={theme.color.secondary}>{text}</Heading2>}
    </Wrapper>
  );
};
export default EmptyState;

const Wrapper = styled(Container)`
  width: 100%;
  gap: 0.375em;
  padding: auto;
`;
