import { getCode } from "services/auth/codeGenerators";
import {
  AUTHORIZE_URL,
  CLIENT_ID,
  CODE_CHALLENGE_METHOD,
  SCOPE,
  getAuthorizationCode,
  getRedirectUri,
} from "services/auth/constants";
import getToken from "./getTokenApi";
import { AppOperationError } from "utils/AppOperationError";
import { TokenLocalStorage } from "./tokenStorage";
import { useCallback } from "react";
import { navigateToPage } from "utils/navigateToPage";
import { toSearchParams } from "utils/helpers";

export const useAuthLogin = () => {
  const handleLogin = useCallback(async (force = false) => {
    const { codeChallenge } = getCode();
    const redirectUri = getRedirectUri();

    const authParams = force
      ? {
          response_type: "code",
          client_id: CLIENT_ID,
          redirect_uri: redirectUri,
          code_challenge: codeChallenge,
          code_challenge_method: CODE_CHALLENGE_METHOD,
          scope: SCOPE,
          prompt: "login",
        }
      : {
          response_type: "code",
          client_id: CLIENT_ID,
          redirect_uri: redirectUri,
          code_challenge: codeChallenge,
          code_challenge_method: CODE_CHALLENGE_METHOD,
          scope: SCOPE,
        };

    navigateToPage(`${AUTHORIZE_URL}?${toSearchParams(authParams)}`);
    // await loginWithRedirect({
    //   authorizationParams: {
    //     response_type: "code",
    //     client_id: CLIENT_ID,
    //     redirect_uri: redirectUri,
    //     code_challenge: codeChallenge,
    //     code_challenge_method: CODE_CHALLENGE_METHOD,
    //     scope: SCOPE,
    //   },
    // });
  }, []);

  const handleAuth = useCallback(
    async (redirect = false) => {
      const token = TokenLocalStorage.get();
      if (token) return true;
      const code = getAuthorizationCode();

      if (code) {
        await getToken({});
        return true;
      } else if (redirect) {
        await handleLogin();
        return false;
      }

      throw new AppOperationError("UNAUTHORIZED");
    },
    [handleLogin]
  );

  return {
    handleAuth,
    handleLogin,
  };
};
