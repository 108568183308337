import { USE_MSW_MOCK_API } from "utils/constants";
// import { onUnhandledRequest } from "tests/mocks/server/logger";

const setupWorker = async () => {
  if (USE_MSW_MOCK_API) {
    // const { worker } = await import("tests/mocks/server/browser");
    // worker.start({
    //   onUnhandledRequest,
    // });
  }
};

export default setupWorker;
