import Status from "components/Status/Status";
import { IFaceMatchInfo } from "common/types/api/document";
import { Container, Divider, Row } from "components/core/Styled/Layouts";
import { Heading3, Heading4 } from "components/core/Styled/Texts";
import Image from "components/reusable/Image";
import { TEventInfo } from "common/types/api/documentEvent";
import styled from "styled-components";
import { useCallback, useMemo } from "react";
import Verification from "./Verification";
import { routesList } from "utils/routesList";
import { useGoTo } from "common/hooks/paths/useGoTo";
import { useLocation } from "react-router-dom";

type IProps = {
  info: IFaceMatchInfo;
};

export const FaceMatchPictures = ({ info }: IProps) => {
  const pictures = useMemo(() => {
    if (info.photo) {
      return {
        first: info.photo,
        second: info.auditTrailImageId,
      };
    }
    return {
      first: info.faceImageId,
      second: info.auditTrailImageId,
    };
  }, [info.auditTrailImageId, info.faceImageId, info.photo]);

  if (!pictures.first && !pictures.second) return <></>;

  return (
    <>
      <Head>Face Matching</Head>
      <PictureContainer>
        {pictures.first && (
          <Container>
            <Title>ID Document</Title>
            <ImageStyled info={pictures.first} type="face" />
          </Container>
        )}
        {pictures.second && (
          <Container>
            <Title>Selfie</Title>
            <ImageStyled info={pictures.second} type="face" />
          </Container>
        )}
      </PictureContainer>
      <Divider />
    </>
  );
};

export const FaceInfo = ({ info }: IProps) => {
  if (info.matchLevel === undefined) return <></>;
  return (
    <>
      <Row
        gap="1em"
        width="100%"
        padding="0em 0.625em 0em 0"
        justifyContent="space-between"
      >
        <StyledSubTitle>Matching Level</StyledSubTitle>
        <Heading4>{info.matchLevel}</Heading4>
      </Row>

      <Row gap="1em" width="100%" justifyContent="space-between">
        <StyledSubTitle>Matching</StyledSubTitle>
        <Status isFace isSuccess={info.match} />
      </Row>

      <Row gap="1em" width="100%" justifyContent="space-between">
        <StyledSubTitle>Liveness</StyledSubTitle>
        <Status isFace isSuccess={info.match} />
      </Row>
    </>
  );
};

export const OneToNFaceInfo = ({ info }: IProps) => {
  const goTo = useGoTo();
  const { pathname } = useLocation();
  const goToReportClick = useCallback(
    (sessionId: string) => {
      goTo(routesList.reports.routes.display, sessionId);
    },
    [goTo]
  );
  if (
    pathname.includes("audits") ||
    info.oneToNVerificationId === null ||
    info.oneToNVerificationId === undefined
  )
    return null;
  return (
    <>
      <Head>1:n Face Search</Head>

      <Row gap="1em" width="100%" justifyContent="space-between">
        <StyledSubTitle>Unique Face</StyledSubTitle>
        <Status isOneToN isSuccess={!info.oneToNVerificationMatch} />
      </Row>

      <Row
        gap="1em"
        width="100%"
        padding="0em 0.625em 0em 0"
        justifyContent="space-between"
      >
        <StyledSubTitle>1:n Verification Id</StyledSubTitle>
        <TextFace>{info.oneToNVerificationId}</TextFace>
      </Row>
      {info.oneToNSessions && info.oneToNSessions.length > 0 && (
        <StyledRow gap="1em">
          <StyledSubTitle>Matching Record(s)</StyledSubTitle>
          <StyledContainer>
            {info.oneToNSessions.map((session: string) => {
              return (
                <TextFaceLink
                  key={session}
                  onClick={() => goToReportClick(session)}
                >
                  {session}
                </TextFaceLink>
              );
            })}
          </StyledContainer>
        </StyledRow>
      )}
    </>
  );
};

export const FaceMatch = ({ info }: IProps) => {
  return (
    <Wrapper>
      <FaceMatchPictures info={info} />
      <DescriptionContainer>
        <FaceInfo info={info} />
        <OneToNFaceInfo info={info} />
        {info.verification && <Verification info={info.verification} />}
      </DescriptionContainer>
    </Wrapper>
  );
};

type IPropsError = {
  info: IFaceMatchInfo;
  infoEvent: TEventInfo;
};

export const FaceMatchError = ({ info, infoEvent }: IPropsError) => {
  return (
    <Wrapper>
      <FaceMatchPictures info={info} />
      <Divider />
      <DescriptionContainer>
        <WarningText>{infoEvent.statusCode}</WarningText>
      </DescriptionContainer>
    </Wrapper>
  );
};

export default FaceMatch;

const Wrapper = styled(Container)`
  width: 100%;
  justify-content: flex-start;
  max-width: 30em;
`;

const TextFace = styled(Heading4)`
  text-transform: none;
`;
const TextFaceLink = styled(Heading4)`
  text-transform: none;
  color: ${(props) => props.theme.color.main};
  cursor: pointer;
`;
const StyledRow = styled(Row)`
  width: 100%;
  padding: 0em 0.625em 0em 0;
  justify-content: space-between;
  align-items: flex-start;
`;
const StyledContainer = styled(Container)`
  align-items: flex-end;
`;
const Head = styled(Heading3)`
  width: 100%;
  font-weight: 500;
  text-align: left;
  color: ${(props) => props.theme.color.main};
  padding: 0.675em 0;
  font-size: 0.95rem;
`;
const PictureContainer = styled(Row)`
  width: 100%;
  gap: 3em;
  justify-content: flex-start;
  align-items: flex-start;
`;

const DescriptionContainer = styled(Container)`
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.25em 0;
`;

const Title = styled(Heading3)`
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  border-bottom: ${(props) => props.theme.border.main};
  color: ${(props) => props.theme.color.black};
  padding: 0.675em 0 1.4em 0;
  font-size: 0.8rem;
`;

const StyledSubTitle = styled(Heading4)`
  width: 100%;
  flex: 1;
  text-align: left;
  padding: 0.25em 0;
`;

const ImageStyled = styled(Image)`
  width: 12em;
  height: 15em;
  padding: 0.5em;
  object-fit: cover;
`;

const WarningText = styled(Heading3)`
  width: 100%;
  text-align: left;
  color: ${(props) => props.theme.color.danger};
`;
