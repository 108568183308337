import { EmptyType } from "common/types/common";

export type IPaginationInfo = {
  start: number;
  limit: number;
  size: number;
};

export type IListPagination<T> = {
  total: number;
  results: T[];
  pagination: IPaginationInfo;
};

export type IFile = string;
export type IFileID = string;
export const isFileId = (info: IFile | IFileID): info is IFileID => {
  const len = info?.length;
  return len > 0 && len < 80;
};

export type IDate = string;
export type TInfo = EmptyType<string | number | boolean | IDate>;

export type IPagination = {
  start: number;
  limit: number;
};

export type ITextSearch = {
  search?: string;
};

export type ISortInfo = {
  field: string;
  direction: "asc" | "desc";
};

export type ISortsInfo = {
  sort?: ISortInfo;
};

export type IPageInfo = {
  size: number;
  num: number;
};

export type IFilterInfo = {
  field: string;
  values: TInfo[];
};

export type IFilterDate = {
  dateFrom?: IDate;
  dateTo?: IDate;
};

export type IFiltersInfo = ITextSearch &
  IFilterDate & {
    filters?: IFilterInfo[];
  };

export type IFilterSort = IFiltersInfo & ISortsInfo;
export type IGetPagination = IPagination & IFilterSort;

export type IForUpdate = {
  updateVersion: number;
};

export const getUpdateInfo = <T extends IForUpdate>(obj: T): IForUpdate => {
  return {
    updateVersion: obj.updateVersion,
  };
};

export type ISource = {
  sdkType: string;
  sdkVersion: string;
};

export type IDisplayHeader = string[];
