import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // customers
  UserRoles.ROLE_CUSTOMER_ADMIN,
  UserRoles.ROLE_CUSTOMER_OWNER,
  UserRoles.ROLE_CUSTOMER_USER,
];

import ApiHelper from "api/ApiHelper";
import { IVerificationsInfo } from "common/types/api/document";
import {
  IReportInfo,
  addVerificationsToDocument,
} from "common/types/api/report";
import { assertNotEmpty } from "utils/assertion";
import { API_BASE_URL } from "utils/UrlList";
import getReportSessions from "./getReportSessions";
import { WithCustomerInfo } from "common/types/api/customer";

export type IGetReportById = WithCustomerInfo & {
  sessionId: string;
};

export type IGetReportByIdResponse = IReportInfo;

const getReportById = async ({
  customerId,
  sessionId,
}: IGetReportById): Promise<IGetReportByIdResponse> => {
  try {
    assertNotEmpty(customerId, sessionId);
    const res = await ApiHelper.apiClient.get<
      IGetReportByIdResponse & {
        verifications: IVerificationsInfo;
      }
    >(
      `${API_BASE_URL}/api/v1/customers/${customerId}/reports/${sessionId}`,
      ApiHelper.getHeaders()
    );

    const { sessionIds } = await getReportSessions({
      sessionId,
      customerId,
    });

    return addVerificationsToDocument(res.data, sessionIds);
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default getReportById;
