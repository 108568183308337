import { assertSearch } from "utils/assertion";
import { UserRoles } from "common/types/api/roles";

export const authorize = [UserRoles.ROLE_UQUDO_SALES];

import ApiHelper from "api/ApiHelper";
import { IGetPagination, IListPagination } from "common/types/api/common";
import { ICompanieInfo } from "common/types/api/client";
import { API_BASE_URL } from "utils/UrlList";

export type ISearchHubspotCompanies = IGetPagination;

export type ISearchHubspotCompaniesResponse = IListPagination<ICompanieInfo>;

const searchHubspotCompanies = async ({
  search,
}: ISearchHubspotCompanies): Promise<ISearchHubspotCompaniesResponse> => {
  try {
    assertSearch(search, true, 1);
    const res = await ApiHelper.apiClient.get<ISearchHubspotCompaniesResponse>(
      `${API_BASE_URL}/api/v1/companies`,
      ApiHelper.getHeaders({
        params: {
          text: search,
        },
      })
    );
    const data = res.data;
    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default searchHubspotCompanies;
