import MyProfileDisplay from "./MyProfileDisplay";
import { ContentBody } from "components/core/Styled/Layouts";
import NavBar from "components/layouts/Navbar";

const MyProfilePage = () => {
  return (
    <NavBar title="My Profile" showEnvironmentDisplay showTitle>
      <ContentBody>
        <MyProfileDisplay />
      </ContentBody>
    </NavBar>
  );
};

export default MyProfilePage;
