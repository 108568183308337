import { Heading4, Heading5 } from "components/core/Styled/Texts";
import { Input } from "components/core/Styled/Controls";
import { Container } from "components/core/Styled/Layouts";
import styled from "styled-components";
import { ChangeEventHandler } from "react";
import React from "react";

export type IProps = {
  defaultValue?: string | number;
  label?: string;
  placeholder?: string;
  readOnly?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  handleBlur?: ChangeEventHandler<HTMLInputElement>;
  touched?: boolean;
  errors?: string | undefined;
  name?: string;
  className?: string;
  type?: string;
  value?: string | number;
};
// eslint-disable-next-line react/display-name
const InputField = React.forwardRef(
  (
    {
      defaultValue,
      label = "",
      placeholder = "",
      type = "text",
      readOnly,
      onChange,
      handleBlur,
      touched,
      errors,
      name,
      className,
      value,
    }: IProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    return (
      <StyledContainer className={className}>
        {label && <StyledTitle>{label}</StyledTitle>}
        <Wrapper readOnly={readOnly}>
          <Input
            ref={ref}
            value={value}
            type={type}
            name={name}
            placeholder={placeholder}
            defaultValue={defaultValue}
            onChange={onChange}
            onBlur={handleBlur}
            readOnly={readOnly}
          />
        </Wrapper>
        {touched && errors && <Error>{errors}</Error>}
      </StyledContainer>
    );
  }
);

export default InputField;

const StyledContainer = styled(Container)`
  flex-direction: column;
  width: 26.25em;
  align-items: flex-start;
`;

const Wrapper = styled(Container)<{ readOnly?: boolean }>`
  gap: 0.375em;
  padding: ${(props) => (props.readOnly ? "0" : "0 0.625em")};
  border: ${(props) =>
    props.readOnly ? "none" : props.theme.border.secondary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: 100%;
  min-height: 2.1875em;
  align-items: flex-start;
  ${Input} {
    width: 100%;
    color: ${(props) => props.theme.color.black};
  }
`;

const StyledTitle = styled(Heading4)`
  color: ${(props) => props.theme.color.secondary2};
  padding: 0.75em 0;
`;

const Error = styled(Heading5)`
  color: ${(props) => props.theme.color.danger};
  padding-top: 0.5em;
`;
