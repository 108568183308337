import AuditDisplay from "./AuditDisplay";
import { ContentBody } from "components/core/Styled/Layouts";
import { routesList } from "utils/routesList";
import { useGoTo } from "common/hooks/paths/useGoTo";
import { useIdPathname } from "common/hooks/paths/useParamsPathname";
import { useCallback, useMemo } from "react";
import Navbar from "components/layouts/Navbar";

const AuditDisplayPage = () => {
  const sessionID = useIdPathname();

  const goTo = useGoTo();

  const subTitle = useMemo(() => {
    if (!sessionID) return undefined;
    return ` - ${sessionID}`;
  }, [sessionID]);

  const onClickReturnButton = useCallback(() => {
    goTo(routesList.audits.routes.main);
  }, [goTo]);

  return (
    <Navbar
      title="Audit"
      subTitle={subTitle}
      onClickReturnButton={onClickReturnButton}
      showReturnButton
      showSubTitle
      showListCustomers
      showEnvironmentDisplay
      showTitle
      showStatus
    >
      <ContentBody>
        <AuditDisplay sessionID={sessionID} />
      </ContentBody>
    </Navbar>
  );
};

export default AuditDisplayPage;
