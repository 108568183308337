import { toast, ToastContainer } from "react-toastify";
import LocalStorage from "./Storage/LocalStorage";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";

export const CustomToastMessageLocalStorage = new LocalStorage<{
  type: "success" | "error" | "warn";
  message: string;
}>("TOAST_MESSAGE_LOCAL_STORAGE");

export class persistentCustomToast {
  static success(message: string) {
    CustomToastMessageLocalStorage.set({ type: "success", message });
  }
  static error(message: string) {
    CustomToastMessageLocalStorage.set({ type: "error", message });
  }
  static warn(message: string) {
    CustomToastMessageLocalStorage.set({ type: "warn", message });
  }

  static process() {
    const res = CustomToastMessageLocalStorage.get();
    if (!res) return;
    if (res.type === "success") toast.success(res.message);
    else if (res.type === "error") toast.error(res.message);
    else if (res.type === "warn") toast.warn(res.message);
    CustomToastMessageLocalStorage.clear();
  }
}

export const CustomToastContainer = () => {
  useEffect(() => {
    persistentCustomToast.process();
  }, []);

  return <ToastContainer />;
};

export { toast as customToast };
