import getCustomersDropDownList from "./getCustomersDropDownList";
import getCustomers from "./getCustomers";
import createCustomer from "./createCustomer";
import deleteCustomer from "./deleteCustomer";
import getCustomerById from "./getCustomerById";
import updateCustomer from "./updateCustomer";
import promoteCustomer from "./promoteCustomer";
import getCustomerGlobalInfoById from "./getCustomerGlobalInfoById";
import enableCustomer from "./enableCustomer";
import disableCustomer from "./disableCustomer";

const customersApi = {
  getCustomersDropDownList,
  getCustomers,
  createCustomer,
  deleteCustomer,
  getCustomerById,
  updateCustomer,
  promoteCustomer,
  getCustomerGlobalInfoById,
  disableCustomer,
  enableCustomer,
};

export default customersApi;
