import { clickBehaviorCss, flexCss } from "./AtomicCss";
import styled from "styled-components";
import { Container } from "./Layouts";

export const Button = styled.button`
  ${flexCss};
  ${clickBehaviorCss};
  padding: 0.25em;
  animation-timing-function: ease-out;
  animation-duration: 300ms;
  border: none;
  background-color: inherit;
  color: inherit;
  width: auto;
`;

export const Img = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: contain;
`;

export const Input = styled.input`
  ${flexCss};
  padding: 0.125em;
  color: ${(props) => props.theme.color.secondary};
  border: none;
  outline-width: 0;
`;

export const TextArea = styled.textarea`
  ${flexCss};
  padding: 0.125em;
  color: ${(props) => props.theme.color.secondary};
  border: none;
  outline-width: 0;
`;

export const Select = styled.select`
  margin: 0;
  padding: 0 0.625em;
  animation-timing-function: ease-out;
  animation-duration: 300ms;
`;

export const ImageLoader = styled(Container)<{ type?: "face" | "document" }>`
  background-color: ${(props) => props.theme.background.lightGrey};
  width: ${(props) => (props.type === "face" ? "12em" : "30em")};
  height: ${(props) => (props.type === "face" ? "15em" : "18em")};
  margin: ${(props) => (props.type === "face" ? "0" : "3em 0")};
`;

export const Option = styled.option``;
