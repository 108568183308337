import LocalStorage from "./LocalStorage";
import { IGetConfigByIdResponse } from "api/hostedKycApi/getConfigById";
import { routesList } from "utils/routesList";

export const CustomerSelectedIdLocalStorage = new LocalStorage<string>(
  "CUSTOMER_SELECTED_ID_LOCAL_STORAGE"
);

export const PageSizeStorage = new LocalStorage<number>(
  "PAGE_SIZE_LOCAL_STORAGE"
);

export const ErrorSaved = new LocalStorage<string>("ERROR_SAVED");

export const ConfigsLocalStorage = new LocalStorage<
  Partial<IGetConfigByIdResponse> | undefined
>("CONFIGS_LOCAL_STORAGE");

export const clearStorages = (pathname: string) => {
  if (!pathname.startsWith(`${routesList.noCodeIntegration.routes.main}/`)) {
    ConfigsLocalStorage.clear();
  }
};
