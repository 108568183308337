export const authorize = [];

import ApiHelper from "api/ApiHelper";
import { API_BASE_URL } from "utils/UrlList";
import { assertNotEmpty } from "utils/assertion";
import { IAdminInfo } from "common/types/api/admin";

export type IGetAdminById = {
  adminId: string;
};

export type IGetAdminByIdResponse = IAdminInfo;

const getAdminById = async ({
  adminId,
}: IGetAdminById): Promise<IGetAdminByIdResponse> => {
  try {
    assertNotEmpty(adminId);
    const res = await ApiHelper.apiClient.get<IGetAdminByIdResponse>(
      `${API_BASE_URL}/api/v1/administration/users/${adminId}`,
      ApiHelper.getHeaders()
    );

    const data = res.data;

    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default getAdminById;
