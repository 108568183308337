import { getInfoEnum, TEnumKey, TEnumValue } from "./enumHelpers";

const CodeStatusEnum = getInfoEnum({
  // 2xx success
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NON_CONTENT: 204,
  // 3xx redirection
  MOVED_PERMANENTLY: 301,
  MOVED_TEMPORARILY: 302,
  NOT_MODIFIED: 304,
  USE_PROXY: 305,
  // 4xx client errors
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  PROXY_AUTHENTICATION_REQUIRED: 407,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  TOO_MANY_REQUESTS: 429,
  // 5xx server errors
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  HTTP_VERSION_NOT_SUPPORTED: 505,
} as const);

export const CodeStatus = {
  ...CodeStatusEnum,
  isServerCodeStatus: (code: number): boolean => {
    return code < 600 && code >= 500;
  },
};

export type TCodeStatus = TEnumKey<typeof CodeStatus>;
export type TCodeStatusValue = TEnumValue<typeof CodeStatus>;
