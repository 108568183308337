import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // internal
  UserRoles.ROLE_UQUDO_AUDITOR,
  UserRoles.ROLE_UQUDO_SALES,
];

import ApiHelper from "api/ApiHelper";
import { IProductsInfo } from "common/types/api/product";
import { API_BASE_URL } from "utils/UrlList";
import { assertNotEmpty } from "utils/assertion";
import { WithCustomerInfo } from "common/types/api/customer";

export type IGetProducts = WithCustomerInfo;

export type IGetProductsResponse = IProductsInfo;

const getProducts = async ({
  customerId,
}: IGetProducts): Promise<IGetProductsResponse> => {
  try {
    assertNotEmpty(customerId);
    const res = await ApiHelper.apiClient.get<IGetProductsResponse>(
      `${API_BASE_URL}/api/v1/customers/${customerId}/products`,
      ApiHelper.getHeaders()
    );

    const data = res.data;
    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default getProducts;
