import styled from "styled-components";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { SubTitle } from "components/core/Styled/Texts";
import Button from "components/reusable/Button";

type IPropsPageNumber = {
  num: number;
  setSelectedNumber: (newValue: number) => void;
  current: number;
};

export const PageNumber = ({
  num,
  setSelectedNumber,
  current,
}: IPropsPageNumber) => {
  return (
    <StyledPageNumber
      selected={current === num}
      onClick={() => setSelectedNumber(num)}
    >
      <Label>{num}</Label>
    </StyledPageNumber>
  );
};

type IPropsPagesNumber = {
  start: number;
  length: number;
  current: number;
  setSelectedNumber: (newValue: number) => void;
};

export const PagesNumber = ({
  start,
  length,
  current,
  setSelectedNumber,
}: IPropsPagesNumber) => {
  return (
    <>
      {Array.from({ length: length }, (_, index) => (
        <PageNumber
          key={start + index}
          num={start + index}
          setSelectedNumber={setSelectedNumber}
          current={current}
        />
      ))}
    </>
  );
};

type IProps = {
  title?: string;
  onClick?: () => void;
  disabled?: boolean;
};

export const NextButton = ({ title, onClick, disabled }: IProps) => {
  return (
    <StyledNextPage
      disabled={disabled}
      iconAfter={<IoIosArrowForward />}
      onClick={onClick}
    >
      <ButtonLabel isNext>{title}</ButtonLabel>
    </StyledNextPage>
  );
};

export const PrevButton = ({ title, onClick, disabled }: IProps) => {
  return (
    <StyledNextPage
      disabled={disabled}
      iconBefore={<IoIosArrowBack />}
      onClick={onClick}
    >
      <ButtonLabel>{title}</ButtonLabel>
    </StyledNextPage>
  );
};

export const BreakPagination = () => {
  return (
    <StyledBreak>
      <Label>...</Label>
    </StyledBreak>
  );
};

const StyledPageNumber = styled(Button)<{ selected?: boolean }>`
  width: 3em;
  height: 3em;
  margin: 0 0.15em;
  border: ${(props) => props.theme.border.secondary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  background-color: ${(props) =>
    props.selected ? props.theme.background.primary : "none"};
  color: ${(props) =>
    props.selected ? "white" : props.theme.color.secondary2};
`;

const StyledBreak = styled(Button)`
  width: 3em;
  height: 3em;
  margin: 0 0.15em;
  border: ${(props) => props.theme.border.secondary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  color: ${(props) => props.theme.color.secondary2};
`;

const StyledNextPage = styled(Button)<{ disabled?: boolean }>`
  color: ${(props) =>
    props.disabled ? props.theme.color.grey : props.theme.color.black};
  svg {
    color: ${(props) =>
      props.disabled ? props.theme.color.grey : props.theme.color.black};
  }
`;

const Label = styled(SubTitle)`
  padding: 0 0.2em;
`;

const ButtonLabel = styled(SubTitle)<{ isNext?: boolean }>`
  padding: ${(props) => (props.isNext ? "0 0 0 0.5em" : "0 0.5em 0 0")};
`;
