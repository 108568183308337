import Button from "components/reusable/Button";
import { Container, Row } from "components/core/Styled/Layouts";
import ConfirmationPopup from "components/Modal/ConfirmationPopup";
import Table, { IHeader } from "components/core/Table";
import { QRCodeSVG } from "qrcode.react";
import { ICellComponentProps } from "components/core/Table/CellComponentDefault";
import { getMessageError } from "utils/helpers";
import { formatDate } from "utils/dateHelpers";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { RiDeleteBin5Line } from "react-icons/ri";
import { customToast } from "utils/customToast";
import primaryTheme from "common/theme/primaryTheme";
import { Heading2, Heading3 } from "components/core/Styled/Texts";
import { ImageSrc } from "components/reusable/Image";
import { images } from "assets/images";
import {
  useGenerateQRQuery,
  useGetLinkedDevicesQuery,
  useUnlinkDeviceMutation,
} from "services/query/linkedDevices";

type IRow = {
  id: string;
  linkedDevices: string;
  dateLinked: string;
  settings: {
    id: string;
    refetch: () => void;
  };
};

const headers: IHeader[] = [
  {
    Header: "Linked Devices",
    accessor: "linkedDevices",
  },
  {
    Header: "Date Linked",
    accessor: "dateLinked",
  },

  {
    Header: "",
    accessor: "settings",
  },
];

export type IProps = {
  id: string;
  refetch: () => void;
};
export const CellComponent = ({ cell, row, index }: ICellComponentProps) => {
  const info = cell.value as IProps;
  const [confirmDeleteDevice, setConfirmDeleteDevice] =
    useState<boolean>(false);
  const unlinkDevicQuery = useUnlinkDeviceMutation();
  const onDeleteDevice: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.stopPropagation();
      setConfirmDeleteDevice(!confirmDeleteDevice);
    },
    [confirmDeleteDevice]
  );

  const onConfirmDeleteDevice = useCallback(async () => {
    try {
      await unlinkDevicQuery.mutateAsync({ deviceId: info.id });
      info.refetch();
      customToast.success("Device deleted successfully");
    } catch (error) {
      customToast.error(getMessageError(error, "Device deletion failed"));
      console.error(error);
    } finally {
      setConfirmDeleteDevice(false);
    }
  }, [info, unlinkDevicQuery]);

  return (
    <td>
      {index === row.cells.length - 1 ? (
        <>
          <StyledOptions>
            <ConfirmationPopup
              text="Are you sure you want to delete this Device?"
              cancelText="Cancel"
              confirmText="Confirm"
              close={onDeleteDevice}
              onConfirmDelete={onConfirmDeleteDevice}
              show={confirmDeleteDevice}
            />

            <Button onClick={onDeleteDevice}>
              <RiDeleteBin5Line color={primaryTheme.color.main} />
            </Button>
          </StyledOptions>
        </>
      ) : (
        cell.render("Cell")
      )}
    </td>
  );
};

const LinkAppPageDisplay = () => {
  const linkedDevicesQuery = useGetLinkedDevicesQuery();
  const deviceQrCodeQuery = useGenerateQRQuery();
  const linkedDevicesRefetch = useMemo(
    () => linkedDevicesQuery.refetch,
    [linkedDevicesQuery.refetch]
  );

  const QRCodeRefetch = useMemo(
    () => deviceQrCodeQuery.refetch,
    [deviceQrCodeQuery.refetch]
  );
  const listRow: IRow[] = useMemo(() => {
    if (linkedDevicesQuery.data === undefined) return [];
    return linkedDevicesQuery.data.linkedDevices.map((val) => ({
      id: val.deviceId,
      linkedDevices: val.deviceModel,
      dateLinked: formatDate(val.linkedDate),
      settings: {
        id: val.deviceId,
        refetch: linkedDevicesRefetch,
      },
    }));
  }, [linkedDevicesQuery.data, linkedDevicesRefetch]);

  useEffect(() => {
    const qrCodeInterval = setInterval(() => {
      QRCodeRefetch();
    }, 30000);
    return () => {
      clearInterval(qrCodeInterval);
    };
  }, [QRCodeRefetch]);

  return (
    <Wrapper>
      <Subtitle>
        Link the uqudo demo app with your account to use all features that has
        been enabled to you.
      </Subtitle>
      <StyledRow>
        {deviceQrCodeQuery.isSuccess && (
          <StyledQrCode value={deviceQrCodeQuery.data.qrCode} />
        )}

        <DownloadSection>
          <Title>Download</Title>
          <Row width="100%" justifyContent="flex-start" gap="0.6em">
            <a
              href="https://apps.apple.com/ng/app/uqudo-kyc-app/id1548639647"
              target="_blank"
              rel="noreferrer"
            >
              <StyledImage src={images.appStore} />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=io.uqudo.sdk.demo"
              target="_blank"
              rel="noreferrer"
            >
              <StyledImage src={images.playStore} />
            </a>
            <a
              href="https://appgallery.huawei.com/app/C109687731"
              target="_blank"
              rel="noreferrer"
            >
              <StyledImage src={images.appGallery} />
            </a>
          </Row>
        </DownloadSection>
      </StyledRow>
      <TableStyled
        keyAccessor="id"
        columns={headers}
        data={listRow}
        withIndex
        CellComponent={CellComponent}
      />
    </Wrapper>
  );
};

export default LinkAppPageDisplay;

const Wrapper = styled(Container)`
  padding: 0 1.875em;
  margin: 1.25em 0;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Subtitle = styled(Heading3)`
  padding-bottom: 1.875em;
  text-transform: none;
`;

const Title = styled(Heading2)`
  padding-bottom: 1.25em;
  text-transform: none;
`;

const StyledRow = styled(Row)`
  width: 100%;
  justify-content: flex-start;
`;

const DownloadSection = styled(Container)`
  width: 100%;
  align-items: flex-start;
  padding-left: 3.75em;
`;
const StyledOptions = styled(Container)`
  width: 1.25em;
  height: 1.25em;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    background-color: white;
  }
`;

const StyledImage = styled(ImageSrc)`
  width: 8.625em;
`;

const StyledQrCode = styled(QRCodeSVG)`
  width: 12em;
  height: 12em;
`;

const TableStyled = styled(Table)`
  min-height: 20vh;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 2.5em;
  td {
    position: relative;
    word-wrap: break-word;
  }

  th {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;
