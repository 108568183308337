import analyticsApi from "api/analyticsApi";
import { useSelectCustomer } from "common/hooks/session/useSelectCustomer";
import { useAppQuery } from "services/query/hooks";
import { useCallback } from "react";

export const useGetCustomerDashboardAnalyticsQuery = () => {
  const { customerId } = useSelectCustomer();

  const get = useCallback(async () => {
    return analyticsApi.getCustomerDashboardAnalytics({
      customerId,
    });
  }, [customerId]);

  const query = useAppQuery(
    get,
    ["useGetCustomerDashboardAnalyticsQuery", customerId],
    {
      retry: (failureCount, error) => {
        if (error.message === "Report will be available soon") {
          return false;
        }
        return failureCount < 3;
      },
    }
  );

  return query;
};
