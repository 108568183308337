import { IMenuInfo } from "utils/menuList";
import { UserRoles } from "common/types/api/roles";
import { useMemo } from "react";
import { useCurrentRole } from "common/hooks/session/useCurrentRole";

type IProps = {
  info: IMenuInfo | IMenuInfo[];
};

export const useAccessAuth = ({ info }: IProps) => {
  const { currentRole, selectedCustomer } = useCurrentRole();

  const infos = useMemo(() => {
    if (Array.isArray(info)) {
      return info.map(({ requiredCustomer, allowedFun, allowedRoles }) => ({
        requiredCustomer,
        allowedFun,
        allowedRoles,
      }));
    }
    return [
      {
        requiredCustomer: info.requiredCustomer,
        allowedFun: info.allowedFun,
        allowedRoles: info.allowedRoles,
      },
    ];
  }, [info]);

  const isAllowed = useMemo(() => {
    return infos.some((info) => {
      if (info.allowedFun && !info.allowedFun(selectedCustomer)) return false;
      return UserRoles.canAccessWithCustomer(
        currentRole,
        info.allowedRoles || []
      );
    });
  }, [currentRole, infos, selectedCustomer]);

  return { isAllowed };
};
