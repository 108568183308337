import ReportsListPage from "./ReportsListPage";
import ReportDisplayPage from "./ReportDisplayPage";
import { routesList } from "utils/routesList";
import ProtectedRoutes from "routes/ProtectedRoutes";
import { RouteObject } from "react-router-dom";

export const reportsRoutes: RouteObject = {
  element: (
    <ProtectedRoutes
      allowedRoles={routesList.reports.allowedRoles}
      requiredCustomer={routesList.reports.requiredCustomer}
    />
  ),
  children: [
    { path: routesList.reports.routes.main, element: <ReportsListPage /> },
    { path: routesList.reports.routes.display, element: <ReportDisplayPage /> },
  ],
};
