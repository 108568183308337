import { headCss, textCss } from "./AtomicCss";
import { addCssTextProperties, ITextProperties } from "./helpers";
import styled from "styled-components";

export const Text = styled.p<ITextProperties>`
  ${textCss};
  ${addCssTextProperties};
`;

export const Heading1 = styled.h1<ITextProperties>`
  ${headCss};
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 1.6875rem;
  ${addCssTextProperties};
`;

export const Heading2 = styled.h2<ITextProperties>`
  ${headCss};
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5rem;
  ${addCssTextProperties};
`;

export const Heading3 = styled.h3<ITextProperties>`
  ${headCss};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  ${addCssTextProperties};
`;

export const SubTitle = styled.h3<ITextProperties>`
  ${headCss};
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.1875rem;
  ${addCssTextProperties};
`;

export const Heading4 = styled.h4<ITextProperties>`
  ${headCss};
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.875rem;
  ${addCssTextProperties};
`;

export const Heading5 = styled.h5<ITextProperties>`
  ${headCss};
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  ${addCssTextProperties};
`;

export const Heading6 = styled.h6<ITextProperties>`
  ${headCss};
  ${addCssTextProperties};
`;
