import ListItemContainer from "./Menu/ListItemContainer";
import { navigateToPage } from "utils/navigateToPage";
import { IMenuInfo } from "utils/menuList";
import { useCallback } from "react";

type IProps = {
  info: IMenuInfo;
};

const ExternalLinkItem = ({ info }: IProps) => {
  const onClick = useCallback(() => {
    navigateToPage(info.path, true);
  }, [info.path]);

  return <ListItemContainer info={info} onClick={onClick} />;
};

export default ExternalLinkItem;
