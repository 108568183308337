import { useAppMutation } from "./hooks";
import productApi from "api/productApi";
import { IAddProduct } from "api/productApi/addProduct";
import { IDeleteProduct } from "api/productApi/deleteProduct";
import { IUpdateProduct } from "api/productApi/updateProduct";
import { useAppQuery } from "services/query/hooks";

export const useGetProductsQuery = (customerId: string) => {
  const query = useAppQuery(() => {
    return productApi.getProducts({
      customerId,
    });
  }, ["useGetProductsQuery", customerId]);

  return query;
};

export const useCreateProductMutation = (customerId: string) => {
  const query = useAppMutation((arg: Omit<IAddProduct, "customerId">) => {
    return productApi.addProduct({
      ...arg,
      customerId,
    });
  });

  return query;
};

export const useDeleteProductMutation = (customerId: string) => {
  const query = useAppMutation((arg: Omit<IDeleteProduct, "customerId">) => {
    return productApi.deleteProduct({
      ...arg,
      customerId,
    });
  });

  return query;
};

export const useUpdateProductMutation = (customerId: string) => {
  const query = useAppMutation((arg: Omit<IUpdateProduct, "customerId">) => {
    return productApi.updateProduct({
      ...arg,
      customerId,
    });
  });

  return query;
};
