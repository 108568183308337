import { Container } from "components/core/Styled/Layouts";
import React from "react";
import styled from "styled-components";
import LoadingPage from "components/core/Loaders/LoadingPage";
import CustomerMainUpdate from "./CustomerMainUpdate";
import { useContextEditCustomer } from "common/contexts/editCustomerContext";
import CustomerScreeningSettings from "./CustomerSDKConfig/CustomerScreeningSettings";
import CustomerDataStorageSettings from "./CustomerSDKConfig/CustomerDataStorageSettings";
import CustomerLookupSettings from "./CustomerSDKConfig/CustomerLookupSettings";

const CustomerUpdate = () => {
  const { isLoading, selectedPage } = useContextEditCustomer();

  if (isLoading) return <LoadingPage />;

  return (
    <Wrapper>
      {selectedPage.page === "MAIN" && <CustomerMainUpdate />}
      {selectedPage.page === "SCREENING" && <CustomerScreeningSettings />}
      {selectedPage.page === "DATA_STORAGE" && <CustomerDataStorageSettings />}
      {selectedPage.page === "LOOKUP" && <CustomerLookupSettings />}
    </Wrapper>
  );
};

export default CustomerUpdate;

const Wrapper = styled(Container)`
  width: 100%;
  justify-content: flex-start;
`;
