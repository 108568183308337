import { REDIRECTION } from "utils/UrlList";
import { getSearchParams } from "utils/helpers";

// constants
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || "";
export const AUTH0_DOMAIN = process.env.REACT_APP_UQUDO_AUTH0_DOMAIN || "";
export const CODE_CHALLENGE_METHOD = "S256";
export const REDIRECT_URI = `${window.location.origin}/auth-callback`;
export const SCOPE = "openid profile email";
export const AUTHORIZE_URL = `https://${AUTH0_DOMAIN}/authorize`;

export const getAuthorizationCode = () => {
  const authorizationCode = getSearchParams("code");
  return authorizationCode;
};

export const getRedirectUri = () => {
  let pathname = "/";
  pathname = REDIRECTION.successfulAuthentication;
  const redirectUri = `${window.location.origin}${pathname}`;
  return redirectUri;
};
