import { Container, Row } from "components/core/Styled/Layouts";
import Button from "components/reusable/Button";
import { Heading3 } from "components/core/Styled/Texts";
import { IoMdAdd } from "react-icons/io";
import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { useContextEditCustomer } from "common/contexts/editCustomerContext";
import ButtonAction from "components/ButtonAction";
import { productListName } from "common/types/api/product";

const AddProduct = () => {
  const {
    listProductsToAdd,
    // add
    onAddProduct,
    addProductLoading,
  } = useContextEditCustomer();

  const createRef = useRef() as RefObject<HTMLDivElement>;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onOpenModel: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.stopPropagation();
      setIsOpen((isOpen) => !isOpen);
    },
    []
  );

  const handleClickOutside = useCallback(
    (event: MouseEvent | TouchEvent) => {
      const isInside =
        !!createRef?.current &&
        createRef.current.contains(event.target as Node);
      const isOutside = isOpen && !isInside;
      if (isOpen) {
        event.stopPropagation();
      }

      if (isOutside) {
        setIsOpen(false);
      }

      return false;
    },
    [isOpen]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  const onClickAddProduct: typeof onAddProduct = useCallback(
    (arg) => {
      setIsOpen(false);
      return onAddProduct(arg);
    },
    [onAddProduct]
  );

  if (!listProductsToAdd || listProductsToAdd?.length === 0) return <></>;

  return (
    <CustomRow>
      <ButtonActionStyled
        label="Add Product"
        loading={addProductLoading}
        onClick={onOpenModel}
        iconBefore={<IoMdAdd />}
      />
      {isOpen && (
        <DropdownWrapper ref={createRef}>
          {listProductsToAdd?.map((productType) => (
            <StyledCreateButton
              key={productType}
              onClick={() => onClickAddProduct(productType)}
            >
              <Heading3>{productListName[productType]}</Heading3>
            </StyledCreateButton>
          ))}
        </DropdownWrapper>
      )}
    </CustomRow>
  );
};

export default AddProduct;

const CustomRow = styled(Row)`
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 1.75em;
`;

const ButtonActionStyled = styled(ButtonAction)`
  width: 12em;
  margin-top: 1em;
`;

const DropdownWrapper = styled(Container)`
  position: absolute;
  padding: 0.6em 0.625em;
  border-radius: ${(props) => props.theme.borderRadius.primary};
  box-shadow: ${(props) => props.theme.boxShadow.main};
  background-color: white;
  width: 12em;
  align-items: flex-start;
  z-index: 10;
`;

const StyledCreateButton = styled(Button)`
  width: 100%;
  min-height: 2.0625em;
  margin: 0.3em 0;
  color: ${(props) => props.theme.color.black};
  justify-content: flex-start;
  &:hover {
    color: ${(props) => props.theme.color.main};
  }
`;
