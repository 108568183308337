import ApiHelper from "api/ApiHelper";
import { AUTH_API_BASE_URL } from "utils/UrlList";
import { TokenLocalStorage } from "./tokenStorage";

export type IRevokeTokenResponse = void;

const revokeToken = async (): Promise<IRevokeTokenResponse> => {
  const tokenInfo = TokenLocalStorage.get();
  if (!tokenInfo) return;
  try {
    const res = await ApiHelper.apiClient.post<IRevokeTokenResponse>(
      `${AUTH_API_BASE_URL}/api/oauth/revoke`,
      new URLSearchParams({
        token: tokenInfo.token,
      }).toString(),
      ApiHelper.getHeaders({
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
    );
    return res.data;
  } catch (error) {
    ApiHelper.errorHandler(error, false);
  }
};

export default revokeToken;
