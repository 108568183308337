import { Container } from "components/core/Styled/Layouts";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import {
  IProductInfo,
  productListName,
  ProductType,
} from "common/types/api/product";
import ProductToggle from "./ProductHeaderDisplay";
import LookupApiDisplay from "./LookupApiDisplay";
import ScreeningApiDisplay from "./ScreeningApiDisplay";
import KycEnrolmentFlowDisplay from "./KycEnrolmentFlowDisplay";
import FaceFlowDisplay from "./FaceFlowDisplay";

type IProps = {
  product: IProductInfo;
};

const ProductDisplay = ({ product }: IProps) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleOpen = useCallback(() => {
    setIsOpen((_isOpen) => !_isOpen);
  }, []);

  const content = useMemo(() => {
    if (product.productType === ProductType.KYC_ENROLMENT_FLOW) {
      return <KycEnrolmentFlowDisplay product={product} />;
    }

    if (product.productType === ProductType.FACE_FLOW) {
      return <FaceFlowDisplay product={product} />;
    }

    if (product.productType === ProductType.LOOKUP_API) {
      return <LookupApiDisplay product={product} />;
    }

    if (product.productType === ProductType.SCREENING_API) {
      return <ScreeningApiDisplay product={product} />;
    }

    return <></>;
  }, [product]);

  return (
    <Wrapper width="100%">
      <ProductToggle
        productType={product.productType}
        productId={product.productId}
        title={productListName[product.productType]}
        isOpen={isOpen}
        setOpen={toggleOpen}
      />
      {isOpen && <>{content}</>}
    </Wrapper>
  );
};

export default ProductDisplay;

const Wrapper = styled(Container)`
  margin: 0.275em 0;
  align-items: flex-start;
  justify-content: flex-start;
`;
