import { Container, Side } from "components/core/Styled/Layouts";
import Logo from "components/Logo";
import Menu from "components/Menu";
import styled from "styled-components";

const SideBar = () => {
  return (
    <Wrapper>
      <ContainerLogo>
        <StyledLogo />
      </ContainerLogo>
      <Menu />
    </Wrapper>
  );
};

export default SideBar;

const Wrapper = styled(Side)`
  border: ${(props) => props.theme.border.main};
  border-top: 0;
  overflow-x: auto;
  justify-content: flex-start;
`;

const ContainerLogo = styled(Container)`
  height: 4.95em;
  width: 100%;
`;

const StyledLogo = styled(Logo)`
  margin: 1.75em auto;
`;
