import FiltersBloc from "components/Filters/FiltersBloc";
import { Container } from "components/core/Styled/Layouts";
import { formatDate } from "utils/dateHelpers";
import Table, { IHeader } from "components/core/Table";
import { documentTypes } from "utils/strings";
import { useGetReportsQuery } from "services/query/reports";
import { routesList } from "utils/routesList";
import { useGoTo } from "common/hooks/paths/useGoTo";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import NormalPagination from "components/pagination/NormalPagination";
import { TableTypes } from "common/types/api/preferences";
import { useDisplaySetting } from "common/hooks/settings/useSetting";

export const reportsHeaders = [
  "sessionId",
  "name",
  "date",
  "documentsTypes",
  "sdkVersion",
  "sdkType",
  "documentId",
];

export const reportsHeadersDefault = [
  "name",
  "documentsTypes",
  "date",
  "sessionId",
];

type IRow = {
  sessionId?: string;
  name?: string;
  date?: string;
  documentsTypes?: string;
  source?: unknown;
  documentId?: string;
};

const headers: IHeader[] = [
  {
    Header: "Session ID",
    accessor: "sessionId",
  },
  {
    Header: "Name",
    accessor: "name",
  },

  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Sdk Type",
    accessor: "sdkType",
  },
  {
    Header: "Sdk Version",
    accessor: "sdkVersion",
  },
  {
    Header: "Documents Types",
    accessor: "documentsTypes",
  },
  {
    Header: "Document ID",
    accessor: "documentId",
  },
  {
    Header: "",
    accessor: "settings",
  },
];

const ReportsList = () => {
  const getReportsQuery = useGetReportsQuery();

  const { displaySetting } = useDisplaySetting(TableTypes.REPORTS);
  const documents = documentTypes as Record<string, string>;
  const displaySettingList = displaySetting
    ? displaySetting
    : reportsHeadersDefault;
  const goTo = useGoTo();

  const listRow: IRow[] = useMemo(() => {
    if (getReportsQuery.allData === undefined) return [];
    return getReportsQuery.allData.results.map((val) => ({
      sessionId: val.sessionId,
      name: val.name,
      date: formatDate(val.date),
      documentsTypes: val.documentsTypes
        ? val.documentsTypes
            .map((document) => documents[document as string])
            .toString()
        : "",
      sdkVersion: val.source?.sdkVersion,
      sdkType: val.source?.sdkType,
      documentId: val.documentId as string,
    }));
  }, [documents, getReportsQuery.allData]);

  const onRowClick = useCallback(
    (row: unknown) => {
      const { sessionId } = row as IRow;
      goTo(routesList.reports.routes.display, sessionId);
    },
    [goTo]
  );

  const customHeader = useMemo(() => {
    const headersWithoutSettings = headers.filter((a) => a.Header !== "");
    return headersWithoutSettings
      .sort((a, b) =>
        displaySettingList.indexOf(a.accessor) <
        displaySettingList.indexOf(b.accessor)
          ? -1
          : 1
      )
      .concat(headers.filter((a) => a.Header === ""));
  }, [displaySettingList]);

  const startIndex = useMemo(() => {
    if (!getReportsQuery.allData?.total) return 1;
    return (
      getReportsQuery.pageSetting.pageNum *
        getReportsQuery.pageSetting.pageSize +
      1
    );
  }, [
    getReportsQuery.allData?.total,
    getReportsQuery.pageSetting.pageNum,
    getReportsQuery.pageSetting.pageSize,
  ]);

  return (
    <Wrapper>
      <FiltersBloc type={TableTypes.REPORTS} />
      <NormalPagination
        messageEmpty="No Reports found"
        queryPagination={getReportsQuery}
      >
        <TableStyled
          keyAccessor="sessionId"
          columns={customHeader.filter(
            (x) =>
              x.accessor ===
                displaySettingList?.find((y) => y === x.accessor) ||
              x.accessor === "settings"
          )}
          data={listRow}
          withIndex
          startIndex={startIndex}
          onRowClick={onRowClick}
          sortSettingLabel={TableTypes.REPORTS}
          sortList={reportsHeaders}
          sortListDefault={reportsHeadersDefault}
          sortable
          editable
        />
      </NormalPagination>
    </Wrapper>
  );
};

export default ReportsList;

const Wrapper = styled(Container)`
  padding: 0 1.875em;
  margin: 1.875em 0;
  margin-top: 0.8em;
  min-height: 80vh;
  justify-content: flex-start;
  align-items: flex-start;
`;

const TableStyled = styled(Table)`
  // min-height: 80vh;
  justify-content: flex-start;
  align-items: flex-start;
  th {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  td {
    word-wrap: break-word;
  }
`;
