import Button from "components/reusable/Button";
import { Container } from "components/core/Styled/Layouts";
import { Heading3, Heading4 } from "components/core/Styled/Texts";
import { routesList } from "utils/routesList";
import { useGoTo } from "common/hooks/paths/useGoTo";
import { IoMdAdd } from "react-icons/io";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { customToast } from "utils/customToast";
import { getMessageError } from "utils/helpers";
import searchHubspotCompanies from "api/clientApi/searchHubspotCompanies";
import { ICompanieInfo } from "common/types/api/client";
import SearchList from "components/Search/SearchList";
import Loader from "components/core/Loaders/Loader";
import { useCreateCustomerMutation } from "services/query/customers";

const toOptionCompanyName = (val: ICompanieInfo) => {
  return {
    label: val.hubspotCompanyName + " (" + val.hubspotCompanyId + ")",
    value: val,
  };
};

const CustomerCreate = () => {
  const [companieInfo, setCompanieInfo] = useState<ICompanieInfo>();
  const goTo = useGoTo();
  const createCustomerQuery = useCreateCustomerMutation();
  const createCustomerMutateAsync = useMemo(
    () => createCustomerQuery.mutateAsync,
    [createCustomerQuery.mutateAsync]
  );

  const onChangeSelectedCompanyName = useCallback(
    (newValue: ReturnType<typeof toOptionCompanyName>) => {
      setCompanieInfo(newValue.value);
    },
    []
  );

  const onCreateCustomerClick = useCallback(async () => {
    if (!companieInfo) {
      customToast.error("Please fill the required inputs");
      return;
    }
    try {
      await createCustomerMutateAsync({
        hubspotCompanyId: companieInfo.hubspotCompanyId,
      });
      customToast.success("Customer created successfully");
      goTo(routesList.customers.routes.main);
    } catch (error) {
      customToast.error(getMessageError(error, "Customer creation failed"));
      console.error(error);
    }
  }, [companieInfo, createCustomerMutateAsync, goTo]);

  return (
    <Wrapper>
      <StyledTitle>Company Name</StyledTitle>

      <SelectWrapper>
        <SearchList
          toOption={toOptionCompanyName}
          fun={searchHubspotCompanies}
          onChangeSelected={onChangeSelectedCompanyName}
          customStyleEnabled
          preSearch
          minLengthSearchPattern={1}
        />
      </SelectWrapper>

      {!createCustomerQuery.isLoading ? (
        <ButtonStyled onClick={onCreateCustomerClick} iconBefore={<IoMdAdd />}>
          <Heading3>Create</Heading3>
        </ButtonStyled>
      ) : (
        <ButtonStyled>
          <Loader />
        </ButtonStyled>
      )}
    </Wrapper>
  );
};

export default CustomerCreate;

const Wrapper = styled(Container)`
  padding: 0 1.875em;
  margin: 1.875em 0;
  align-items: flex-start;
  min-height: 80vh;
  justify-content: flex-start;
`;

const ButtonStyled = styled(Button)`
  border: ${(props) => props.theme.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: 26.25em;
  padding: 0 0.625em;
  margin-top: 2.5em;
  min-height: 2.1875em;
`;

const SelectWrapper = styled(Container)`
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 0.375em;
  border-radius: ${(props) => props.theme.borderRadius.primary};
  min-height: 2.1875em;
  color: ${(props) => props.theme.color.primary};
`;

const StyledTitle = styled(Heading4)`
  color: ${(props) => props.theme.color.secondary2};
  padding: 0 0 0.75em 0;
`;
