import { useAppQuery } from "./hooks";
import documentApi from "api/documentApi";

export const useGetDocumentListQuery = () => {
  const query = useAppQuery(() => {
    return documentApi.getDocumentList({});
  }, ["useGetDocumentListQuery"]);

  return query;
};
