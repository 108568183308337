import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // internal
  UserRoles.ROLE_UQUDO_SALES,
];

import ApiHelper from "api/ApiHelper";
import { ICustomer, WithCustomerInfo } from "common/types/api/customer";
import { API_BASE_URL } from "utils/UrlList";
import { assertNotEmpty } from "utils/assertion";

export type IUpdateCustomer = WithCustomerInfo &
  Pick<ICustomer, "companyNameAlias" | "expiryDate" | "updateVersion">;

export type IUpdateCustomerResponse = ICustomer;

const updateCustomer = async ({
  customerId,
  ...rest
}: IUpdateCustomer): Promise<IUpdateCustomerResponse> => {
  try {
    assertNotEmpty(customerId);
    const res = await ApiHelper.apiClient.put<IUpdateCustomerResponse>(
      `${API_BASE_URL}/api/v1/customers/${customerId}`,
      rest,
      ApiHelper.getHeaders()
    );

    const data = res.data;
    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default updateCustomer;
