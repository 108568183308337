import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // customers
  UserRoles.ROLE_CUSTOMER_ADMIN,
  UserRoles.ROLE_CUSTOMER_OWNER,
  UserRoles.ROLE_CUSTOMER_USER,
];

import ApiHelper from "api/ApiHelper";
import { TPreferencesAuditing } from "common/types/api/preferences";
import { assertNotEmpty } from "utils/assertion";
import { API_BASE_URL } from "utils/UrlList";
import { WithCustomerInfo } from "common/types/api/customer";

export type IGetPreferencesAuditing = WithCustomerInfo;

export type IGetPreferencesAuditingResponse = TPreferencesAuditing;

const getPreferencesAuditing = async ({
  customerId,
}: IGetPreferencesAuditing): Promise<IGetPreferencesAuditingResponse> => {
  try {
    assertNotEmpty(customerId);
    const res = await ApiHelper.apiClient.get<IGetPreferencesAuditingResponse>(
      `${API_BASE_URL}/api/v1/customers/${customerId}/auditing/preferences`,
      ApiHelper.getHeaders()
    );

    const data = res.data;

    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default getPreferencesAuditing;
