import ApiClient from "./ApiClient";
import { AppErrorCode, AppOperationError } from "utils/AppOperationError";
import { ApiErrorCode } from "utils/CustomAxios/ApiError";
import ApiError, { isApiError } from "utils/CustomAxios/ApiError/ApiError";
import { removeEmptyValue } from "utils/helpers";
import { CodeStatus, TCodeStatusValue } from "common/types/helpers/CodeStatus";
import { AppErrorCodeType } from "utils/AppOperationError/AppErrorCode";
import axios, {
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
} from "axios";
import _ from "lodash";
import {
  TokenLocalStorage,
  authFailedPostCallback,
} from "services/auth/token/tokenStorage";
import { customToast } from "utils/customToast";

class ApiHelper {
  public static apiClient = ApiClient.getApi();

  public static getHeaders<D>({
    withAccessToken = true,
    params = {},
    headers = {},
    ...args
  }: ({ withAccessToken?: boolean } & AxiosRequestConfig<D>) | undefined = {}) {
    let additionalHeaders: AxiosRequestHeaders = {};
    if (withAccessToken) {
      const { token = "", tokenType = "" } = TokenLocalStorage.get() ?? {};
      additionalHeaders = { Authorization: `${tokenType} ${token}` };
    }

    return {
      // withCredentials: true,
      // crossDomain: true,
      ...args,
      params: removeEmptyValue(params),
      headers: {
        ...additionalHeaders,
        ...headers,
      },
    };
  }

  public static async getFile(
    path: string,
    args: ReturnType<typeof ApiHelper.getHeaders>
  ) {
    const response = await fetch(path, args as RequestInit);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);

    return {
      data: url,
    };
  }

  public static throwErrorIfStatus(
    res: AxiosResponse,
    status: TCodeStatusValue | ((code: number) => boolean),
    errorCode: AppErrorCodeType
  ) {
    if ((_.isFunction(status) && status(res.status)) || res.status === status) {
      throw new AppOperationError(errorCode, res.data.errorMessage);
    }
  }

  public static apiErrorHandler(error: unknown): asserts error is ApiError {
    if (!isApiError(error))
      throw new AppOperationError(AppErrorCode.UNEXPECTED_ERROR);

    if (error.code === ApiErrorCode.REQUEST_ERROR)
      throw new AppOperationError(AppErrorCode.REQUEST_ERROR);
  }

  public static getErrorStatus(error: ApiError): AxiosResponse | undefined {
    if (axios.isAxiosError(error.axiosError) && error.axiosError?.response)
      return error.axiosError?.response;

    return undefined;
  }

  public static commonErrorResponseHandler(res: AxiosResponse) {
    ApiHelper.throwErrorIfStatus(
      res,
      CodeStatus.UNAUTHORIZED,
      AppErrorCode.UNAUTHORIZED
    );
    ApiHelper.throwErrorIfStatus(
      res,
      CodeStatus.FORBIDDEN,
      AppErrorCode.FORBIDDEN
    );
    ApiHelper.throwErrorIfStatus(
      res,
      CodeStatus.isServerCodeStatus,
      AppErrorCode.SERVER_ERROR
    );
    ApiHelper.throwErrorIfStatus(
      res,
      CodeStatus.CONFLICT,
      AppErrorCode.CONFLICT
    );
    ApiHelper.throwErrorIfStatus(
      res,
      CodeStatus.BAD_REQUEST,
      AppErrorCode.INVALID_DATA
    );
    ApiHelper.throwErrorIfStatus(
      res,
      CodeStatus.NOT_FOUND,
      AppErrorCode.NOT_FOUND
    );
  }

  public static errorHandler(
    error: unknown,
    checkRedirect: boolean,
    customCheck?: (res: AxiosResponse) => void,
    checkDisplay = true
  ): never {
    ApiHelper.apiErrorHandler(error);
    const res = ApiHelper.getErrorStatus(error);
    if (res !== undefined) {
      if (checkRedirect) ApiHelper.redirectToIf(res);
      if (checkDisplay) ApiHelper.displayErrorIf(res);
      customCheck?.(res);
      ApiHelper.commonErrorResponseHandler(res);
    }
    throw new AppOperationError(AppErrorCode.UNEXPECTED_ERROR);
  }

  public static displayErrorIf = (res: AxiosResponse) => {
    if (res.status === CodeStatus.FORBIDDEN) {
      customToast.warn(res.data.errorMessage ?? "this action is forbidden");
    }
  };

  public static redirectToIf = (res: AxiosResponse) => {
    if (res.status === CodeStatus.UNAUTHORIZED) {
      authFailedPostCallback("session_expired");
    }
  };
}

export default ApiHelper;
