import Button from "components/reusable/Button";
import Loader from "components/core/Loaders/Loader";
import InputField from "components/reusable/Input";
import { errorPassword, errorString, haveErrors } from "utils/assertion";
import { Container } from "components/core/Styled/Layouts";
import { Heading3 } from "components/core/Styled/Texts";
import { routesList } from "utils/routesList";
import { useUpdateUserPasswordMutation } from "services/query/profile";
import { IUpdateUserPassword } from "api/profileApi/updateUserPassword";
import { useGoTo } from "common/hooks/paths/useGoTo";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import "react-phone-number-input/style.css";
import { customToast } from "utils/customToast";
import { getMessageError } from "utils/helpers";
import { HiOutlineCheck } from "react-icons/hi";

type IUserProps = {
  oldPassword?: string;
  newPassword?: string;
};

type IUserErrorsProps = {
  oldPassword?: string;
  newPassword?: string;
};

const validate = (values: IUserProps) => {
  const errors: IUserErrorsProps = {};

  errors.oldPassword = errorString({
    oldPassword: values.oldPassword,
  });

  errors.newPassword = errorPassword(values.newPassword);

  return errors;
};

const MyProfileChangePassword = () => {
  const updatePasswordQuery = useUpdateUserPasswordMutation();
  const updatePasswordMutateAsync = useMemo(
    () => updatePasswordQuery.mutateAsync,
    [updatePasswordQuery.mutateAsync]
  );

  const goTo = useGoTo();

  const { handleChange, handleBlur, values, errors, touched } = useFormik({
    initialValues: {
      newPassword: "",
      oldPassword: "",
    },
    validate,
    onSubmit: () => console.info("Customer edited"),
  });

  const onChangePasswordClick = useCallback(async () => {
    if (haveErrors(errors)) {
      customToast.error("Please fill the required inputs");
      return;
    }

    const userCreationData: Omit<IUpdateUserPassword, "customerId"> = {
      ...values,
    };
    try {
      await updatePasswordMutateAsync(userCreationData);
      customToast.success("User password updated successfully");
      setTimeout(() => {
        goTo(routesList.profile.routes.main);
      }, 4000);
    } catch (error) {
      customToast.error(getMessageError(error, "User password update failed"));
      console.error(error);
    }
  }, [errors, goTo, updatePasswordMutateAsync, values]);

  return (
    <Wrapper>
      <InputField
        label="Old Password"
        name="oldPassword"
        type="password"
        onChange={handleChange}
        handleBlur={handleBlur}
        touched={touched.oldPassword}
        errors={errors.oldPassword}
        defaultValue={values?.oldPassword}
      />
      <InputField
        label="New Password"
        name="newPassword"
        type="password"
        onChange={handleChange}
        handleBlur={handleBlur}
        touched={touched.newPassword}
        errors={errors.newPassword}
        defaultValue={values?.newPassword}
      />

      {!updatePasswordQuery.isLoading ? (
        <ButtonStyled
          onClick={onChangePasswordClick}
          iconBefore={<HiOutlineCheck />}
        >
          <Heading3>Change Password</Heading3>
        </ButtonStyled>
      ) : (
        <ButtonStyled>
          <Loader />
        </ButtonStyled>
      )}
    </Wrapper>
  );
};

export default MyProfileChangePassword;

const Wrapper = styled(Container)`
  padding: 0 1.875em;
  margin: 1.875em 0;
  align-items: flex-start;
  justify-content: flex-start;
  height: 80vh;
`;

const ButtonStyled = styled(Button)`
  border: ${(props) => props.theme.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: 26.25em;
  padding: 0 0.625em;
  margin-top: 3em;
  min-height: 2.1875em;
`;
