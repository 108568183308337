import { IScanIdInfo } from "common/types/api/document";
import { Container, Divider, Row } from "components/core/Styled/Layouts";
import { Heading3, Heading4 } from "components/core/Styled/Texts";
import { fromCamelCaseToString, infoToString } from "utils/helpers";
import Image from "components/reusable/Image";
import { TEventInfo } from "common/types/api/documentEvent";
import { Fragment } from "react";
import styled from "styled-components";

type IProps = {
  info: IScanIdInfo;
};

export const Scan = ({ info }: IProps) => {
  return (
    <Wrapper>
      <DescriptionContainer>
        {info.content?.map((section, index) => (
          <Fragment key={index}>
            <Section>
              <Title>{section.title}</Title>
              {section.attributes &&
                Object.entries(section.attributes).map(
                  ([name, value], index) => (
                    <Row
                      key={index}
                      gap="1em"
                      width="100%"
                      minHeight="2.1875em"
                      justifyContent="space-between"
                      alignContent="center"
                    >
                      <StyledSubTitle>
                        {fromCamelCaseToString(name)}
                      </StyledSubTitle>
                      <RowValue>
                        <StyledValue>{infoToString(value)}</StyledValue>
                      </RowValue>
                    </Row>
                  )
                )}
            </Section>
            <Divider />
          </Fragment>
        ))}
      </DescriptionContainer>
      <PictureContainer>
        <ImageStyled info={info.frontImageId} />
        {info.backImageId && <ImageStyled info={info.backImageId} />}
      </PictureContainer>
    </Wrapper>
  );
};

type IPropsError = {
  info: IScanIdInfo;
  infoEvent: TEventInfo;
};

export const ScanError = ({ info, infoEvent }: IPropsError) => {
  return (
    <Wrapper>
      <DescriptionContainer>
        <WarningText>{infoEvent.statusCode}</WarningText>
      </DescriptionContainer>
      <PictureContainer>
        <ImageStyled info={info.frontImageId} />
        {info.backImageId && <ImageStyled info={info.backImageId} />}
      </PictureContainer>
    </Wrapper>
  );
};

export default Scan;

const Wrapper = styled(Row)`
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;

const PictureContainer = styled(Container)`
  width: 100%;
`;
const StyledValue = styled(Heading3)`
  text-align: left;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
`;
const DescriptionContainer = styled(Container)`
  width: 100%;
  justify-content: flex-start;
`;

const Section = styled(Container)`
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 1em;
`;

const RowValue = styled(Row)`
  width: 100%;
  justify-content: space-between;
  flex: 2;
  padding: 0.25em 0;
  border-bottom: ${(props) => props.theme.border.main};
`;

const Title = styled(Heading3)`
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  border-bottom: ${(props) => props.theme.border.main};
  color: ${(props) => props.theme.color.main};
  padding: 1.875em 0;
`;

const StyledSubTitle = styled(Heading4)`
  width: 100%;
  flex: 1;
  text-align: left;
  padding: 0.25em 0;
`;

const ImageStyled = styled(Image)`
  width: 35em;
  padding: 3em;
`;

const WarningText = styled(Heading3)`
  width: 100%;
  text-align: left;
  color: ${(props) => props.theme.color.danger};
`;
