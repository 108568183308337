import ReportIcon from "assets/images/reports_icon.svg";
import AuditIcon from "assets/images/audit_icon.svg";
import calendarIcon from "assets/images/calendar.svg";
import reqId from "assets/images/id_req.png";
import opId from "assets/images/id_op.png";
import faceCheck from "assets/images/face.png";
import bgCheck from "assets/images/bg_check.png";
import arrowWorkflow from "assets/images/arrowWorkflow.svg";
import emptyWorkflow from "assets/images/emptyWorkflow.png";
import qrCode from "assets/images/qrcode.png";
import appStore from "assets/images/apple.png";
import playStore from "assets/images/play.png";
import appGallery from "assets/images/gallery.png";
export const icons = {
  ReportIcon,
  AuditIcon,
  reqId,
  opId,
  faceCheck,
  bgCheck,
  arrowWorkflow,
  calendarIcon,
};
export const images = {
  emptyWorkflow,
  qrCode,
  appGallery,
  appStore,
  playStore,
};
