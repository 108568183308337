import { UserRoles } from "common/types/api/roles";

export const authorize = [
  // customers
  UserRoles.ROLE_CUSTOMER_ADMIN,
  UserRoles.ROLE_CUSTOMER_OWNER,
  UserRoles.ROLE_CUSTOMER_DEVELOPER,
];

import ApiHelper from "api/ApiHelper";
import { IGetPagination, IListPagination } from "common/types/api/common";
import { IConfigHostedKyc } from "common/types/api/hostedKyc";
import { API_BASE_URL } from "utils/UrlList";
import { assertNotEmpty, assertSearch } from "utils/assertion";
import { DEFAULT_LIMIT_PAGINATION } from "utils/constants";
import { WithCustomerInfo } from "common/types/api/customer";

export type ISearchConfig = WithCustomerInfo & IGetPagination;

export type ISearchConfigResponse = Pick<
  IConfigHostedKyc,
  "id" | "name" | "linkId" | "type" | "webhook"
>;

export type ISearchConfigsResponse = IListPagination<ISearchConfigResponse>;

const searchConfig = async ({
  customerId,
  start = 0,
  limit = DEFAULT_LIMIT_PAGINATION,
  search,
  ...filterInfo
}: ISearchConfig): Promise<ISearchConfigsResponse> => {
  try {
    assertNotEmpty(customerId);
    assertSearch(search);
    const res = await ApiHelper.apiClient.post<ISearchConfigsResponse>(
      `${API_BASE_URL}/api/v1/customers/${customerId}/hosted-service/kyc/config/search`,
      { ...filterInfo, text: search || undefined },
      ApiHelper.getHeaders({
        params: {
          start,
          limit,
        },
      })
    );

    const data = res.data;
    return data;
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default searchConfig;
