import { Img } from "./core/Styled/Controls";
import logo from "assets/images/uqudo_logo.svg";
import styled from "styled-components";

type IProps = {
  className?: string;
};

const Logo = ({ className }: IProps) => {
  return <StyledLogo src={logo} className={className} />;
};

export default Logo;

export const StyledLogo = styled(Img)`
  width: 4.125em;
  height: auto;
`;
