import React from "react";
import styled from "styled-components";
import Button from "components/reusable/Button";
import Checkbox from "components/reusable/Checkbox";
import Loader from "components/core/Loaders/Loader";
import { Container, Row } from "components/core/Styled/Layouts";
import { Heading3 } from "components/core/Styled/Texts";
import InputField from "components/reusable/Input";
import { useInputChange } from "common/hooks/helpers/useChangeInput";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { RiDeleteBin5Line } from "react-icons/ri";
import useToggle from "common/hooks/helpers/useToggle";
import { HiOutlineCheck } from "react-icons/hi";
import { IProductInfo, ProductType } from "common/types/api/product";
import { useContextEditCustomer } from "common/contexts/editCustomerContext";
import { customToast } from "utils/customToast";
import { IBackgroundCheckConfigurationActive } from "common/types/api/sdkConfigurations";
import CustomSelect from "components/Select/CustomSelect";

const extractInfo = (product: IProductInfo | undefined) => {
  if (product?.productType === ProductType.KYC_ENROLMENT_FLOW) {
    return product?.configuration?.backgroundCheck;
  }

  if (product?.productType === ProductType.SCREENING_API) {
    return product?.configuration;
  }

  return undefined;
};

const addInfo = (
  product: IProductInfo,
  addedInfo: ReturnType<typeof extractInfo>
): IProductInfo | undefined => {
  if (product?.productType === ProductType.KYC_ENROLMENT_FLOW) {
    return {
      ...product,
      configuration: {
        ...product.configuration,
        backgroundCheck: {
          ...product?.configuration?.backgroundCheck,
          active: true,
          ...addedInfo,
        },
      },
    };
  }

  if (product?.productType === ProductType.SCREENING_API) {
    return {
      ...product,
      configuration: addedInfo,
    };
  }

  return undefined;
};

export type TAuthOption = {
  value: "BASIC_AUTHENTICATION" | "CUSTOM_HEADERS" | "NONE";
  label: string | number;
};

const options: TAuthOption[] = [
  { value: "BASIC_AUTHENTICATION", label: "Basic authentication" },
  { value: "CUSTOM_HEADERS", label: "Customer headers" },
  { value: "NONE", label: "Not set" },
];

const CustomerScreeningSettings = () => {
  const {
    setSelectedPage,
    messageUpdate,
    selectedPage,
    // update
    onUpdateProduct,
    updateProductLoading,
  } = useContextEditCustomer();

  const screeningInfo = useMemo(
    () => extractInfo(selectedPage.product),
    [selectedPage.product]
  );

  const [monitoring, setMonitoring, toggleMonitoring] = useToggle(false);

  const [authType, setAuthType] = useState<
    "BASIC_AUTHENTICATION" | "CUSTOM_HEADERS" | "NONE"
  >("NONE");

  const [newHeaders, setNewHeaders] = useState([
    { id: 0, name: "", value: "" },
  ]);

  const { values, onChange } = useInputChange({
    usernameBg: "",
    passwordBg: "",
    usernameAuth: "",
    passwordAuth: "",
    webhook: "",
    firmNumber: "",
  });

  const onAddHeader = useCallback(() => {
    if (newHeaders.length < 5)
      setNewHeaders([
        ...newHeaders,
        { id: newHeaders[newHeaders.length - 1].id + 1, name: "", value: "" },
      ]);
  }, [newHeaders]);

  const onRemoveHeader = useCallback((index: number) => {
    setNewHeaders((headers) => headers.filter((e) => e.id !== index));
  }, []);

  const handleChangeKeyHeader = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      index: number,
      type: "key" | "value"
    ) => {
      const updateHeaders = [...newHeaders];
      if (type === "key") updateHeaders[index].name = event.target.value;
      if (type === "value") updateHeaders[index].value = event.target.value;

      setNewHeaders(updateHeaders);
    },
    [newHeaders]
  );

  const onAuthTypeChange = useCallback((newValue: unknown) => {
    const option = newValue as TAuthOption;
    setAuthType(option.value);
  }, []);

  const onSave = useCallback(async () => {
    if (!selectedPage.product) return;
    if (values.usernameBg === "" || values.passwordBg === "") {
      customToast.error("Please fill the credentials inputs");
      return;
    }

    if (monitoring && (values.webhook === "" || values.firmNumber === "")) {
      customToast.error("Please fill the required inputs");
      return;
    }

    if (
      monitoring &&
      authType === "BASIC_AUTHENTICATION" &&
      (values.usernameAuth === "" || values.passwordAuth === "")
    ) {
      customToast.error("Please fill the authentication inputs");
      return;
    }

    if (monitoring && authType === "CUSTOM_HEADERS") {
      const empty = newHeaders.find(
        (header) => header.name === "" || header.value === ""
      );
      if (empty) {
        customToast.error("Please fill the authentication headers inputs");
        return;
      }
    }

    const newScreeningInfo: IBackgroundCheckConfigurationActive = {
      credentials: {
        username: values.usernameBg,
        password: values.passwordBg,
      },
      monitoring: { active: false },
    };

    if (monitoring) {
      newScreeningInfo.monitoring = {
        active: true,
        customerEndpointWebhook: values.webhook,
        firmNumber: values.firmNumber,
        authorization: {
          type: "NONE",
        },
      };

      if (authType === "CUSTOM_HEADERS") {
        newScreeningInfo.monitoring.authorization = {
          type: "CUSTOM_HEADERS",
          customHeaders: {
            headers: newHeaders.map((header) => ({
              name: header.name,
              value: header.value,
            })),
          },
        };
      }

      if (authType === "BASIC_AUTHENTICATION") {
        newScreeningInfo.monitoring.authorization = {
          type: "BASIC_AUTHENTICATION",
          basicAuthentication: {
            username: values.usernameAuth,
            password: values.passwordAuth,
          },
        };
      }
    }

    const data = addInfo(selectedPage.product, newScreeningInfo);
    if (!data) return;
    await onUpdateProduct(data, messageUpdate);
    setSelectedPage({ page: "MAIN" });
  }, [
    authType,
    monitoring,
    newHeaders,
    onUpdateProduct,
    selectedPage.product,
    messageUpdate,
    values.firmNumber,
    values.passwordAuth,
    values.passwordBg,
    values.usernameAuth,
    values.usernameBg,
    values.webhook,
    setSelectedPage,
  ]);

  const touched = useMemo(() => {
    return true;
  }, []);

  useEffect(() => {
    if (screeningInfo !== undefined) {
      if (screeningInfo.monitoring) {
        if (screeningInfo.monitoring.active) {
          setMonitoring(screeningInfo.monitoring.active);
          setAuthType(screeningInfo.monitoring.authorization.type);
          onChange({
            usernameBg: screeningInfo.credentials?.username ?? "",
            passwordBg: screeningInfo.credentials?.password ?? "",
            webhook: screeningInfo.monitoring.customerEndpointWebhook,
            firmNumber: screeningInfo.monitoring.firmNumber,
          });
          switch (screeningInfo.monitoring.authorization.type) {
            case "BASIC_AUTHENTICATION":
              onChange({
                usernameAuth:
                  screeningInfo.monitoring.authorization.basicAuthentication
                    .username,
                passwordAuth:
                  screeningInfo.monitoring.authorization.basicAuthentication
                    .password,
                usernameBg: screeningInfo.credentials?.username ?? "",
                passwordBg: screeningInfo.credentials?.password ?? "",
                webhook: screeningInfo.monitoring.customerEndpointWebhook,
                firmNumber: screeningInfo.monitoring.firmNumber,
              });
              break;
            case "CUSTOM_HEADERS":
              setNewHeaders(
                screeningInfo.monitoring.authorization.customHeaders.headers.map(
                  (header, i) => ({
                    id: i,
                    name: header.name,
                    value: header.value,
                  })
                )
              );
              break;
          }
        } else {
          if (screeningInfo.credentials) {
            onChange({
              usernameBg: screeningInfo.credentials.username,
              passwordBg: screeningInfo.credentials.password,
            });
            //      setAuthType("NONE");
          }
        }
      } else {
        if (screeningInfo.credentials) {
          onChange({
            usernameBg: screeningInfo.credentials.username,
            passwordBg: screeningInfo.credentials.password,
          });
          // setAuthType("NONE");
        }
      }
    }
  }, [onChange, screeningInfo, setMonitoring]);

  return (
    <Wrapper>
      <StyledInputField
        label="Username"
        name="usernameBg"
        onChange={(e) => onChange({ usernameBg: e.target.value })}
        value={values.usernameBg}
      />
      <StyledInputField
        label="Password"
        name="passwordBg"
        onChange={(e) => onChange({ passwordBg: e.target.value })}
        value={values.passwordBg}
      />

      <Checkbox
        onChange={toggleMonitoring}
        checked={monitoring}
        label="Monitoring"
      />

      {monitoring && (
        <>
          <StyledInputField
            label="Customer endpoint webhook"
            name="webhook"
            onChange={(e) => onChange({ webhook: e.target.value })}
            value={values.webhook}
          />
          <StyledInputField
            label="Firm Number"
            name="firmNumber"
            onChange={(e) => onChange({ firmNumber: e.target.value })}
            value={values.firmNumber}
          />
          <StyledSelectInput
            options={options}
            onChange={onAuthTypeChange}
            selectStyle="100%"
            label="Authorization type"
            selected={options.find((option) => option.value === authType)}
          />
          {authType === "BASIC_AUTHENTICATION" && (
            <>
              <StyledInputField
                label="Username"
                name="usernameAuth"
                onChange={(e) => onChange({ usernameAuth: e.target.value })}
                value={values.usernameAuth}
              />
              <StyledInputField
                label="Password"
                name="passwordAuth"
                onChange={(e) => onChange({ passwordAuth: e.target.value })}
                value={values.passwordAuth}
              />
            </>
          )}
          {authType === "CUSTOM_HEADERS" && (
            <>
              {newHeaders.map((header) => {
                return (
                  <StyledHeaders key={header.id}>
                    <KeyInput
                      label={header.id === 0 ? "Key" : ""}
                      name="key"
                      value={header.name}
                      onChange={(e) =>
                        handleChangeKeyHeader(e, header.id, "key")
                      }
                    />
                    <ValueInput
                      label={header.id === 0 ? "Value" : ""}
                      name="value"
                      value={header.value}
                      onChange={(e) =>
                        handleChangeKeyHeader(e, header.id, "value")
                      }
                    />
                    {header.id === 0 ? (
                      <AddButton onClick={onAddHeader}>
                        <IoMdAdd />
                      </AddButton>
                    ) : (
                      <AddButton onClick={() => onRemoveHeader(header.id)}>
                        <RiDeleteBin5Line />
                      </AddButton>
                    )}
                  </StyledHeaders>
                );
              })}
            </>
          )}
        </>
      )}

      {!updateProductLoading ? (
        <>
          {touched && (
            <ButtonStyled onClick={onSave} iconBefore={<HiOutlineCheck />}>
              <Heading3>Save</Heading3>
            </ButtonStyled>
          )}
        </>
      ) : (
        <ButtonStyled>
          <Loader />
        </ButtonStyled>
      )}
    </Wrapper>
  );
};

export default CustomerScreeningSettings;

const Wrapper = styled(Container)`
  padding: 0 1.875em;
  margin: 1.25em 0;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: flex-start;
`;

export const Divider = styled.div`
  width: 34em;
  height: 0;
  border-top: ${(props) => props.theme.border.main};
  margin-top: 1em;
  padding: 0.5em 0;
`;

const StyledInputField = styled(InputField)`
  width: 100%;

  ${Container} {
    padding: 0 0.2em;
    background: white;
    min-height: 2.375em;
  }
`;

const StyledHeaders = styled(Row)`
  width: 100%;
  align-items: flex-end;
  gap: 0.625em;
  margin-bottom: 1.25em;
`;

const KeyInput = styled(InputField)`
  flex: 1;

  ${Container} {
    padding: 0 0.2em;
    background: white;
    min-height: 2.375em;
  }
`;

const ValueInput = styled(InputField)`
  flex: 2;

  ${Container} {
    padding: 0 0.2em;
    background: white;
    min-height: 2.375em;
  }
`;

const AddButton = styled(Button)`
  border: ${(props) => props.theme.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: 2.1875em;
  min-height: 2.1875em;
`;

const StyledSelectInput = styled(CustomSelect)`
  width: 100%;
`;

const ButtonStyled = styled(Button)`
  border: ${(props) => props.theme.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: 34em;
  padding: 0 0.625em;
  margin-top: 3em;
  margin-bottom: 4em;
  min-height: 2.1875em;
`;
