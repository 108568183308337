import Button from "components/reusable/Button";
import { Container, Row } from "components/core/Styled/Layouts";
import { Heading3, Heading4, Heading5 } from "components/core/Styled/Texts";
import Popup from "components/Modal/Popup";
import { DropdownOptions, Options } from "components/Select/DropdownOptions";
import ConfirmationPopup from "components/Modal/ConfirmationPopup";
import InputField from "components/reusable/Input";
import { Input } from "components/core/Styled/Controls";
import Table, { IHeader } from "components/core/Table";
import {
  useActivateClientSecretMutation,
  useAddClientSecretMutation,
  useDeleteClientSecretMutation,
  useDisableClientSecretMutation,
  useGetClientSecretsQuery,
  useGetCustomersCredentialsQuery,
} from "services/query/credentials";
import { ICellComponentProps } from "components/core/Table/CellComponentDefault";
import { StatusDisplay } from "components/Status/StatusDisplay";
import { getMessageError } from "utils/helpers";
import { formatDate } from "utils/dateHelpers";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { IoMdAdd } from "react-icons/io";
import { RiDeleteBin5Line } from "react-icons/ri";
import { FiCopy } from "react-icons/fi";
import { customToast } from "utils/customToast";
import { BsEyeFill, BsEyeSlash } from "react-icons/bs";
import { TbCloudDownload } from "react-icons/tb";

type IRow = {
  id: string;
  secrets: string;
  creationDate: string;
  status: string;
  settings: {
    id: string;
    status: string;
    refetch: () => void;
  };
};

const headers: IHeader[] = [
  {
    Header: "Secrets",
    accessor: "secrets",
  },
  {
    Header: "Creation Date",
    accessor: "creationDate",
  },
  {
    Header: "Status",
    accessor: "status",
  },

  {
    Header: "",
    accessor: "settings",
  },
];

export type IProps = {
  id: string;
  status: string;
  refetch: () => void;
};

export const UserOptions = ({ id, refetch, status }: IProps) => {
  const deleteClientSecretsQuery = useDeleteClientSecretMutation();
  const deleteClientSecretsMutateAsync = useMemo(
    () => deleteClientSecretsQuery.mutateAsync,
    [deleteClientSecretsQuery.mutateAsync]
  );

  const activateClientSecretsQuery = useActivateClientSecretMutation();
  const activateClientSecretsMutateAsync = useMemo(
    () => activateClientSecretsQuery.mutateAsync,
    [activateClientSecretsQuery.mutateAsync]
  );

  const disableClientSecretsQuery = useDisableClientSecretMutation();
  const disableClientSecretsMutateAsync = useMemo(
    () => disableClientSecretsQuery.mutateAsync,
    [disableClientSecretsQuery.mutateAsync]
  );

  const [confirmDeleteSecret, setConfirmDeleteSecret] =
    useState<boolean>(false);

  const onActivateSecret = useCallback(
    async (clientSecretId: string) => {
      try {
        await activateClientSecretsMutateAsync({ clientSecretId });
        customToast.success("Client Secret activated successfully");
        refetch();
      } catch (error) {
        customToast.error(
          getMessageError(error, "Client secret activation failed")
        );
        console.error(error);
      }
    },
    [activateClientSecretsMutateAsync, refetch]
  );

  const onDisableSecret = useCallback(
    async (clientSecretId: string) => {
      try {
        await disableClientSecretsMutateAsync({ clientSecretId });
        customToast.success("Client Secret disabled successfully");
        refetch();
      } catch (error) {
        customToast.error(
          getMessageError(error, "Client secret disable failed")
        );
        console.error(error);
      }
    },
    [disableClientSecretsMutateAsync, refetch]
  );

  const onDeleteSecret = useCallback(() => {
    setConfirmDeleteSecret(!confirmDeleteSecret);
  }, [confirmDeleteSecret]);

  const onConfirmDeleteSecret = useCallback(
    async (clientSecretId: string) => {
      try {
        await deleteClientSecretsMutateAsync({ clientSecretId });
        customToast.success("Client Secret deleted successfully");
        refetch();
      } catch (error) {
        customToast.error(
          getMessageError(error, "Client secret deletion failed")
        );
        console.error(error);
      } finally {
        setConfirmDeleteSecret(false);
      }
    },
    [deleteClientSecretsMutateAsync, refetch]
  );

  return (
    <StyledOptions>
      <ConfirmationPopup
        text="Are you sure you want to delete this client secret ?"
        cancelText="Cancel"
        confirmText="Confirm"
        close={onDeleteSecret}
        onConfirmDelete={() => onConfirmDeleteSecret(id)}
        show={confirmDeleteSecret}
      />

      <Popup>
        <DropdownOptions>
          {status === "Disabled" ? (
            <Options
              text="Activate"
              icon={<BsEyeFill />}
              onClick={() => onActivateSecret(id)}
            />
          ) : (
            <Options
              text="Disable"
              icon={<BsEyeSlash />}
              onClick={() => onDisableSecret(id)}
            />
          )}
          <Options
            text="Delete"
            icon={<RiDeleteBin5Line />}
            onClick={onDeleteSecret}
          />
        </DropdownOptions>
      </Popup>
    </StyledOptions>
  );
};

export const CellComponent = ({ cell, row, index }: ICellComponentProps) => {
  const info = cell.value as IProps;
  return (
    <td {...cell.getCellProps()}>
      {index === row.cells.length - 1 ? (
        <>
          {
            <UserOptions
              id={info.id}
              refetch={info.refetch}
              status={info.status}
            />
          }
        </>
      ) : cell.column.id === "status" ? (
        <StatusDisplay
          showBorder={false}
          status={cell.value as string}
          content={cell.value as string}
        />
      ) : (
        cell.render("Cell")
      )}
    </td>
  );
};
const CredentialsPageDisplay = () => {
  const credentialsDataQuery = useGetCustomersCredentialsQuery();
  const clientSecretsQuery = useGetClientSecretsQuery();
  const clientSecretsRefetch = useMemo(
    () => clientSecretsQuery.refetch,
    [clientSecretsQuery.refetch]
  );

  const addClientSecretsQuery = useAddClientSecretMutation();
  const addClientSecretsMutateAsync = useMemo(
    () => addClientSecretsQuery.mutateAsync,
    [addClientSecretsQuery.mutateAsync]
  );

  const [confirmAddSecret, setConfirmAddSecret] = useState<boolean>(false);
  const [createdClientSecret, setCreatedClientSecret] = useState<string>();

  const listRow: IRow[] = useMemo(() => {
    if (clientSecretsQuery.data === undefined) return [];
    return clientSecretsQuery.data.clientSecrets.map((val) => ({
      id: val.id,
      secrets: val.clientSecretMask,
      creationDate: formatDate(val.createdDate as string),
      status: val.status === "ACTIVE" ? "Active" : "Disabled",
      settings: {
        id: val.id,
        refetch: clientSecretsRefetch,
        status: val.status === "ACTIVE" ? "Active" : "Disabled",
      },
    }));
  }, [clientSecretsQuery.data, clientSecretsRefetch]);

  const onAddSecret = useCallback(() => {
    setConfirmAddSecret(!confirmAddSecret);
  }, [confirmAddSecret]);

  const onDownloadSecret = useCallback(() => {
    const fileData = createdClientSecret as string;
    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "client-secret.txt";
    link.href = url;
    link.click();
    customToast.success("Client Secret Downloaded");
  }, [createdClientSecret]);

  const onCreateSecret = useCallback(async () => {
    try {
      const newClientSecret = await addClientSecretsMutateAsync({});
      setCreatedClientSecret(newClientSecret.clientSecret);
      setConfirmAddSecret(true);
      customToast.success("Client Secret activated successfully");

      clientSecretsRefetch();
    } catch (error) {
      customToast.error(
        getMessageError(error, "Client secret activation failed")
      );
      console.error(error);
    }
  }, [addClientSecretsMutateAsync, clientSecretsRefetch]);

  const copyToClipBoard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        customToast.success("Copied!");
      },
      () => {
        customToast.error("Copy failed!");
      }
    );
  };

  const onRowClick = useCallback(() => {
    // const { id } = row as IRow;
  }, []);

  return (
    <Wrapper>
      <Title>Data credentials</Title>
      <SubTitle>Control access to your data.</SubTitle>
      <StyledRow>
        <StyledInputField
          label="Authorization Base URL"
          name="baseUrl"
          value={credentialsDataQuery.data?.authorizationUrl}
          readOnly
        />
        <CopyButton
          iconBefore={<FiCopy />}
          onClick={() =>
            copyToClipBoard(credentialsDataQuery.data?.authorizationUrl ?? "")
          }
        >
          <Heading3>Copy</Heading3>
        </CopyButton>
      </StyledRow>

      <StyledRow>
        <StyledInputField
          label="Main API Base URL"
          name="mainUrl"
          value={credentialsDataQuery.data?.mainApiUrl}
          readOnly
        />
        <CopyButton
          iconBefore={<FiCopy />}
          onClick={() =>
            copyToClipBoard(credentialsDataQuery.data?.mainApiUrl ?? "")
          }
        >
          <Heading3>Copy</Heading3>
        </CopyButton>
      </StyledRow>

      <StyledRow>
        <StyledInputField
          label="Client ID"
          name="clientId"
          value={credentialsDataQuery.data?.clientId}
          readOnly
        />
        <CopyButton
          iconBefore={<FiCopy />}
          onClick={() =>
            copyToClipBoard(credentialsDataQuery.data?.clientId ?? "")
          }
        >
          <Heading3>Copy</Heading3>
        </CopyButton>
      </StyledRow>

      <Row width="100%" justifyContent="space-between">
        <Title>Client secrets management</Title>
        <AddButton iconBefore={<IoMdAdd />} onClick={onCreateSecret}>
          <Heading3>Add Secret</Heading3>
        </AddButton>
      </Row>
      <ConfirmationPopup
        title="Your New Client Secret"
        cancelText="Cancel"
        confirmText="Download"
        close={onAddSecret}
        onConfirmDelete={onDownloadSecret}
        show={confirmAddSecret}
        icon={<TbCloudDownload />}
        closeWithIcon
      >
        <StyledRow popup>
          <StyledInputField defaultValue={createdClientSecret} readOnly />
          <CopyButton
            iconBefore={<FiCopy />}
            onClick={() => copyToClipBoard(createdClientSecret ?? "")}
          >
            <Heading3>Copy</Heading3>
          </CopyButton>
        </StyledRow>
        <SubTitle popup>
          A new client secret created successfully, please save it for later use
        </SubTitle>
      </ConfirmationPopup>

      <TableStyled
        keyAccessor="id"
        columns={headers}
        data={listRow}
        withIndex
        CellComponent={CellComponent}
        onRowClick={onRowClick}
      />
    </Wrapper>
  );
};

export default CredentialsPageDisplay;

const Wrapper = styled(Container)`
  padding: 0 1.875em;
  margin: 1.25em 0;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Title = styled(Heading3)`
  font-size: 1.125em;
  color: ${(props) => props.theme.color.black};
  padding: 0.75em 0;
`;

export const Divider = styled.div`
  width: 26.25em;
  height: 0;
  border-top: ${(props) => props.theme.border.main};
  margin-top: 1em;
  padding: 0.5em 0;
`;

const SubTitle = styled(Heading5)<{ popup?: boolean }>`
  padding: ${(props) => (props.popup ? "1.25em 0 2.5em 0" : "0.2em 0")};
  margin-bottom: ${(props) => (props.popup ? "0" : "1.875em")};
`;

const StyledRow = styled(Row)<{ popup?: boolean }>`
  width: 26.25em;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: ${(props) => (props.popup ? "0" : "1.675em")};
`;

const StyledInputField = styled(InputField)`
  border-bottom: ${(props) => props.theme.border.main};

  ${Heading4} {
    padding: 0.625em 0;
    line-height: 0;
  }
  ${Container} {
    gap: 0;
    ${Input} {
      color: ${(props) => props.theme.color.main};
      font-size: 0.875em;
    }
  }
`;

const CopyButton = styled(Button)`
  border: ${(props) => props.theme.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  min-width: 5.75em;
  min-height: 2.1875em;
  margin: 0 0.725em;
`;

const AddButton = styled(Button)`
  border: ${(props) => props.theme.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: 8em;
  min-height: 2.1875em;
`;

const StyledOptions = styled(Container)`
  width: 1.25em;
  height: 1.25em;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    background-color: white;
  }
`;
const TableStyled = styled(Table)`
  min-height: 40vh;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 1.5em;
  td {
    position: relative;
    word-wrap: break-word;
  }

  th {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;
