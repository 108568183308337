import { useAppQuery } from "./hooks";
import reportApi from "api/reportApi";
import { DEFAULT_LIMIT_PAGINATION } from "utils/constants";
import { useSelectCustomer } from "common/hooks/session/useSelectCustomer";
import { TableTypes } from "common/types/api/preferences";
import { useCallback, useMemo } from "react";
import { IParmPagination, usePagination } from "./usePagination";
import { selectFilterSortSetting } from "services/redux/reducers/settingsSlice";

const selectFilterSort = selectFilterSortSetting(TableTypes.REPORTS);

export const useGetReportByIdQuery = (sessionId: string) => {
  const { customerId } = useSelectCustomer();

  const query = useAppQuery(() => {
    return reportApi.getReportById({
      sessionId,
      customerId,
    });
  }, ["useGetReportByIdQuery", sessionId, customerId]);

  return query;
};

export const useGetReportsQuery = (sizeOfPage = DEFAULT_LIMIT_PAGINATION) => {
  const { customerId } = useSelectCustomer();
  const defaultValues = useMemo(
    () => ({
      customerId,
    }),
    [customerId]
  );

  const ifCondition = useCallback(
    (values: IParmPagination & typeof defaultValues) => {
      return !!values.customerId;
    },
    []
  );

  const getApi = useCallback(
    (param: IParmPagination & typeof defaultValues) => {
      return reportApi.getReports(param);
    },
    []
  );

  const query = usePagination({
    key: "useGetUsersQuery",
    sizeOfPage,
    getApi,
    defaultValues,
    ifCondition,
    selectFilterSort,
    type: TableTypes.REPORTS,
  });

  return query;
};

export const useDownloadReportByIdQuery = (sessionId: string) => {
  const { customerId } = useSelectCustomer();

  const query = useAppQuery(() => {
    return reportApi.downloadReport({
      sessionId,
      customerId,
    });
  }, ["useDownloadReportByIdQuery", sessionId, customerId]);

  return query;
};
