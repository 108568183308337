import { Container } from "components/core/Styled/Layouts";
import { Heading3, Heading5 } from "components/core/Styled/Texts";
import { PropsWithChildren } from "react";
import styled from "styled-components";

type IProps = {
  name: string;
  content?: string;
};

const Info = ({ name, content, children }: PropsWithChildren<IProps>) => {
  return (
    <Wrapper>
      <StyledTitle>{name}</StyledTitle>
      <StyledContent>{children ? children : content}</StyledContent>
    </Wrapper>
  );
};

export default Info;

const Wrapper = styled(Container)`
  align-items: flex-start;
  flex-direction: column;
`;

const StyledTitle = styled(Heading5)`
  color: ${(props) => props.theme.color.secondary2};
  text-align: left;
`;

const StyledContent = styled(Heading3)`
  padding: 1.5625em 0;
  text-align: left;
`;
