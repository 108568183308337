import { authRoles } from "common/types/api/roles";

export const authorize = authRoles;

import ApiHelper from "api/ApiHelper";
import { assertNotEmpty } from "utils/assertion";
import {
  ICustomerGlobalInfo,
  WithCustomerInfo,
} from "common/types/api/customer";
import { API_BASE_URL } from "utils/UrlList";

export type IGetCustomerGlobalInfoById = WithCustomerInfo;

export type IGetCustomerGlobalInfoByIdResponse = ICustomerGlobalInfo;

const getCustomerGlobalInfoById = async ({
  customerId,
}: IGetCustomerGlobalInfoById): Promise<IGetCustomerGlobalInfoByIdResponse> => {
  try {
    assertNotEmpty(customerId);
    const res = await ApiHelper.apiClient.get<
      Omit<IGetCustomerGlobalInfoByIdResponse, "id"> & {
        customerIdentifier: string;
      }
    >(
      `${API_BASE_URL}/api/v1/customers/global/info`,
      ApiHelper.getHeaders({
        params: {
          customerId,
        },
      })
    );

    const { customerIdentifier, ...rest } = res.data;

    return {
      ...rest,
      id: customerIdentifier,
    };
  } catch (error) {
    ApiHelper.errorHandler(error, true);
  }
};

export default getCustomerGlobalInfoById;
