import Button from "components/reusable/Button";
import { Container } from "components/core/Styled/Layouts";
import { Heading3 } from "components/core/Styled/Texts";
import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Calendar } from "react-calendar";
import styled from "styled-components";
import { BsCalendar } from "react-icons/bs";
import { Heading4 } from "components/core/Styled/Texts";
import { ChangeEventHandler } from "react";
import { formatLocalDateWihDot } from "utils/dateHelpers";
import { EmptyType } from "common/types/common";

export type IProps = {
  defaultValue?: string | number;
  label?: string;
  placeholder?: string;
  readOnly?: boolean;
  onChange: (newValue: EmptyType<Date>) => void;
  handleBlur?: ChangeEventHandler<HTMLInputElement>;
  touched?: boolean;
  errors?: string | undefined;
  name?: string;
  className?: string;
  type?: string;
  value?: EmptyType<Date>;
};

const DateInputField = ({ label = "", onChange, className, value }: IProps) => {
  const ref = useRef() as RefObject<HTMLDivElement>;
  const [calendarModel, setCalendarModel] = useState<boolean>(false);

  const onOpenCalendarModel = useCallback(() => {
    setCalendarModel((_calendarModel) => !_calendarModel);
  }, []);

  const handleClickOutside = useCallback((event: MouseEvent | TouchEvent) => {
    const dateRef = ref?.current && !ref.current.contains(event.target as Node);
    if (dateRef) {
      setCalendarModel(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleDateChange = useCallback(
    (_value: EmptyType<Date>) => {
      _value?.setUTCHours(23, 59, 59);
      if (_value) onChange(_value);
    },
    [onChange]
  );

  const valueStr = useMemo(
    () => formatLocalDateWihDot(value?.toDateString()),
    [value]
  );

  return (
    <StyledDatePicker className={className}>
      <Label>{label}</Label>
      <ButtonDateStyled
        update
        onClick={onOpenCalendarModel}
        iconAfter={<BsCalendar />}
      >
        <Heading3>{valueStr || "--/--/----"}</Heading3>
      </ButtonDateStyled>
      {calendarModel && (
        <DateWrapper ref={ref}>
          <Calendar
            onChange={handleDateChange}
            value={value}
            calendarType="US"
            minDate={new Date()}
          />
        </DateWrapper>
      )}
    </StyledDatePicker>
  );
};

export default DateInputField;

const StyledDatePicker = styled(Container)`
  position: relative;
  align-items: flex-start;
`;

const Label = styled(Heading4)`
  color: ${(props) => props.theme.color.secondary2};
  padding: 0.75em 0;
  text-align: left;
  text-transform: none;
`;

const DateWrapper = styled(Container)`
  position: absolute;
  top: 6em;
  right: 0;
  z-index: 5;
  border: ${(props) => props.theme.border.primary};
  width: 26.25em;
  background-color: white;
  padding: 0.5em 0;
  .react-calendar__navigation {
    display: flex;
    width: 26.25em;
    height: 3.5em;
    .react-calendar__navigation__label {
      font-weight: 500;
    }

    .react-calendar__navigation__arrow {
      flex-grow: 0.333;
    }
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    font-size: 0.75em;
  }

  .react-calendar__tile:disabled {
    background: #cccccc;
    color: #ffffff;
  }

  button {
    margin: 0.1875em;
    background-color: white;
    border: 0;
    border-radius: 0.1875em;
    color: black;
    padding: 0.3125em 0;
    text-align: center;
    height: 2.5em;
    &:hover {
      background-color: #3377ff;
      color: white;
      cursor: pointer;
    }

    &:active {
      background-color: #3377ff;
    }
  }
  .react-calendar__month-view__days {
    display: grid !important;
    grid-template-columns: 14.2% 14.2% 14.2% 14.2% 14.2% 14.2% 14.2%;

    .react-calendar__tile {
      max-width: initial !important;
    }
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    opacity: 0.7;
  }
  .react-calendar__month-view__days__day--weekend {
    color: #b4bbc4;
  }
  .react-calendar__tile--range {
    background-color: #3377ff;
    color: white;
  }
`;

const ButtonDateStyled = styled(Button)<{ update?: boolean }>`
  border: ${(props) => props.theme.border.secondary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: ${(props) => (props.update ? "26.25em;" : "12.625em")};
  padding: 0 0.625em;
  min-height: 2.1875em;
  justify-content: space-between;
`;
