import { IParmPagination, usePagination } from "./usePagination";
import { useAppMutation, useAppQuery } from "./hooks";
import { DEFAULT_LIMIT_PAGINATION } from "utils/constants";
import userApi from "api/userApi";
import { ICreateUser } from "api/userApi/createUser";
import { IUpdateUser } from "api/userApi/updateUser";
import { selectFilterSortSetting } from "services/redux/reducers/settingsSlice";
import { TableTypes } from "common/types/api/preferences";
import { useCallback, useMemo } from "react";
import { useSelectCustomer } from "common/hooks/session/useSelectCustomer";

const selectFilterSort = selectFilterSortSetting(TableTypes.USERS);

export const useGetUsersQuery = (sizeOfPage = DEFAULT_LIMIT_PAGINATION) => {
  const { customerId } = useSelectCustomer();
  const defaultValues = useMemo(
    () => ({
      customerId,
    }),
    [customerId]
  );

  const ifCondition = useCallback(
    (values: IParmPagination & typeof defaultValues) => {
      return !!values.customerId;
    },
    []
  );

  const getApi = useCallback(
    (param: IParmPagination & typeof defaultValues) => {
      return userApi.getUsers(param);
    },
    []
  );

  const query = usePagination({
    key: "useGetUsersQuery",
    sizeOfPage,
    getApi,
    selectFilterSort,
    defaultValues,
    ifCondition,
    type: TableTypes.USERS,
  });

  return query;
};

export const useGetUserByIdQuery = (userId: string) => {
  const { customerId } = useSelectCustomer();

  const query = useAppQuery(() => {
    return userApi.getUserById({
      customerId,
      userId,
    });
  }, ["useGetUserByIdQuery", userId, customerId]);

  return query;
};

export const useCreateUserMutation = () => {
  const { customerId } = useSelectCustomer();

  const query = useAppMutation((arg: Omit<ICreateUser, "customerId">) => {
    return userApi.createUser({
      ...arg,
      customerId,
    });
  });

  return query;
};

export const useUpdateUserMutation = (userId: string) => {
  const { customerId } = useSelectCustomer();
  const query = useAppMutation(
    (arg: Omit<IUpdateUser, "customerId" | "userId">) => {
      return userApi.updateUser({
        ...arg,
        customerId,
        userId,
      });
    }
  );

  return query;
};

export const useDeleteUserMutation = (userId: string) => {
  const { customerId } = useSelectCustomer();

  const query = useAppMutation(() => {
    return userApi.deleteUser({
      userId,
      customerId,
    });
  });

  return query;
};

export const useDeleteUserMFAMutation = (userId: string) => {
  const { customerId } = useSelectCustomer();

  const query = useAppMutation(() => {
    return userApi.deleteUserMFA({
      userId,
      customerId,
    });
  });

  return query;
};
