import { createContext, ReactNode, useContext } from "react";
import useReportDisplayInfo, {
  IUseReportDisplayInfoProps,
} from "./useReportDisplayInfo";

type IUseReportDisplayInfoReturnProps = ReturnType<typeof useReportDisplayInfo>;

export const ReportDisplayContext =
  createContext<IUseReportDisplayInfoReturnProps | null>(null);

type IProps = IUseReportDisplayInfoProps & {
  children?: ReactNode;
};

export const ReportDisplayProvider = ({ children, ...rest }: IProps) => {
  const value = useReportDisplayInfo(rest);

  return (
    <ReportDisplayContext.Provider value={value}>
      {children}
    </ReportDisplayContext.Provider>
  );
};

export const useContextReportDisplay = () => {
  const value = useContext(ReportDisplayContext);
  if (!value) throw Error("context not provided");
  return value;
};
