import EmptyState from "components/EmptyState";
import LoadingPage from "components/core/Loaders/LoadingPage";
import { PropsWithChildren } from "react";
import { IReturnUsePagination } from "services/query/usePagination";
import PaginationAction from "./PaginationAction";

type IProps<R, T> = {
  messageEmpty: string;
  queryPagination: IReturnUsePagination<R, T>;
};

const NormalPagination = <R, T>({
  messageEmpty,
  queryPagination,
  children,
}: PropsWithChildren<IProps<R, T>>) => {
  if (queryPagination.isLoading && queryPagination.allData === undefined)
    return <LoadingPage />;

  if (queryPagination.isEmpty) return <EmptyState text={messageEmpty} />;

  return (
    <>
      {children}
      <PaginationAction
        total={queryPagination.allData?.total}
        pageSetting={queryPagination.pageSetting}
      />
    </>
  );
};

export default NormalPagination;
