const primaryTheme = {
  color: {
    main: "#3377FF",
    primary: "#061B3B",
    primary2: "#FFFFFF",
    secondary: "#B4BBC4",
    secondary2: "#44546C",
    success: "#1ED482",
    danger: "#FF2F2F",
    warning: "#FFCC00",
    black: "#000000",
    grey: "#B4BBC4",
  },
  background: {
    empty: "#E5E5E5",
    main: "#FFFFFF",
    mainLight: "#F5F9FF",
    primary: "#3377FF",
    primaryLight: "rgba(51, 119, 255, 0.5)",
    primaryLighter: "rgba(51, 119, 255, 0.25)",
    secondary: "#F5F9FF",
    secondaryLight: "#D6E4FF",
    grey: "#F8F8F8",
    lightGrey: "#ededed",
    lightSuccess: "#E8FBF2",
    lightDanger: "#FFE9E9",
  },
  border: {
    main: "1px solid #EBF1FF",
    primary: "1px solid #3377FF",
    primaryGrey: "1px solid #44546C",
    secondaryGrey: "1px solid #B4BBC4",
    secondary: "1px solid #D6E4FF",
  },
  borderRadius: {
    primary: "0.5em",
    secondary: "0.375em",
    big: "0.75em",
    veryBig: "1.25em",
  },
  boxShadow: {
    main: "0 5px 14px rgba(0, 0, 0, 0.15);",
    primary: "0 5px 14px rgba(0, 0, 0, 0.15);",
    secondary: "0 5px 14px rgba(0, 0, 0, 0.15);",
  },
};

export default primaryTheme;
