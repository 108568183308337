import Button from "components/reusable/Button";
import Loader from "components/core/Loaders/Loader";
import { Container } from "components/core/Styled/Layouts";
import { Heading3 } from "components/core/Styled/Texts";
import InputField from "components/reusable/Input";
import CustomSelect from "components/Select/CustomSelect";
import { errorString, haveErrors, validateEmail } from "utils/assertion";
import { ICreateAdmin } from "api/adminsApi/createAdmin";
import { useCreateAdminMutation } from "services/query/admins";
import { routesList } from "utils/routesList";
import { useGoTo } from "common/hooks/paths/useGoTo";
import { IoMdAdd } from "react-icons/io";
import React, { useCallback, useMemo, useState } from "react";
import { useFormik } from "formik";
import styled from "styled-components";
import { customToast } from "utils/customToast";
import { getMessageError } from "utils/helpers";
import { rolesToOptions, UserRoles } from "common/types/api/roles";
import { TElement } from "common/types/common";

export type IAdminProps = {
  firstName: string;
  lastName: string;
  email: string;
  role?: string;
};

export type IAdminErrorsProps = {
  firstName?: string;
  lastName?: string;
  email?: string;
  role?: string;
};

const options = rolesToOptions([
  UserRoles.ROLE_UQUDO_AUDITOR,
  UserRoles.ROLE_UQUDO_SALES,
]);
type IOption = TElement<typeof options>;

const AdminCreate = () => {
  const [role, setRole] = useState<IOption["value"]>();
  const createAdminsQuery = useCreateAdminMutation();
  const createAdminsMutateAsync = useMemo(
    () => createAdminsQuery.mutateAsync,
    [createAdminsQuery.mutateAsync]
  );

  const goTo = useGoTo();

  const validate = useCallback((values: IAdminProps) => {
    const errors: IAdminErrorsProps = {};
    errors.firstName = errorString({ firstName: values.firstName });
    errors.lastName = errorString({ lastName: values.lastName });

    if (!values.email) {
      errors.email = "Email is required";
    } else {
      if (!validateEmail(values.email)) {
        errors.email = "Please Enter valid Email";
      }
    }

    return errors;
  }, []);

  const onRoleChange = useCallback((option: IOption) => {
    setRole(option.value);
  }, []);

  const { handleChange, handleBlur, values, errors, touched } = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
    },
    validate,
    onSubmit: () => console.info("Admin submitted"),
  });

  const onCreateAdminClick = useCallback(async () => {
    if (haveErrors(errors) || !role) {
      customToast.error("Please fill the required inputs");
      return;
    }
    try {
      const adminData: Omit<ICreateAdmin, "customerId"> = {
        ...values,
        role,
      };
      await createAdminsMutateAsync(adminData);
      customToast.success("Admin created successfully");
      goTo(routesList.admins.routes.main);
    } catch (error) {
      customToast.error(getMessageError(error, "Admin creation failed"));
      console.error(error);
    }
  }, [errors, role, values, createAdminsMutateAsync, goTo]);

  return (
    <Wrapper>
      <InputField
        label="First name"
        name="firstName"
        onChange={handleChange}
        handleBlur={handleBlur}
        touched={touched.firstName}
        errors={errors.firstName}
      />
      <InputField
        label="Last name"
        name="lastName"
        onChange={handleChange}
        handleBlur={handleBlur}
        touched={touched.lastName}
        errors={errors.lastName}
      />
      <InputField
        label="Email"
        name="email"
        onChange={handleChange}
        handleBlur={handleBlur}
        touched={touched.email}
        errors={errors.email}
      />
      <CustomSelect label="Role" options={options} onChange={onRoleChange} />
      {!createAdminsQuery.isLoading ? (
        <ButtonStyled onClick={onCreateAdminClick} iconBefore={<IoMdAdd />}>
          <Heading3>Create</Heading3>
        </ButtonStyled>
      ) : (
        <ButtonStyled>
          <Loader />
        </ButtonStyled>
      )}
    </Wrapper>
  );
};

export default AdminCreate;

const Wrapper = styled(Container)`
  padding: 0 1.875em;
  margin: 1.875em 0;
  align-items: flex-start;
  min-height: 80vh;
  justify-content: flex-start;
`;

const ButtonStyled = styled(Button)`
  border: ${(props) => props.theme.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: 26.25em;
  padding: 0 0.625em;
  margin-top: 3em;
  min-height: 2.1875em;
`;
