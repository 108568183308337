import { Heading3 } from "./Styled/Texts";
import React, { useCallback, useMemo } from "react";
import styled, { css, useTheme } from "styled-components";
import Select from "react-select";

export type IElementList = {
  id?: string | number;
  value?: string;
  label: string;
};

type IProps = {
  defaultValue?: IElementList["value"] | undefined;
  list: IElementList[];
  onChange?: (value: string) => void;
  className?: string;
  notSingleSelect?: boolean;
  width?: string;
};

const SelectOption = ({
  className,
  list,
  onChange,
  notSingleSelect = false,
  defaultValue = undefined,
  width,
}: IProps) => {
  const theme = useTheme();
  const value = useMemo(() => {
    const v = list.find((k) => k.value === defaultValue);
    if (!v) return list[0];
    return v;
  }, [defaultValue, list]);

  const onChangeHandler = useCallback(
    (obj: unknown) => {
      const newValue = obj as IElementList;
      onChange?.(newValue.value ?? newValue.label);
    },
    [onChange]
  );

  const customStyles = {
    control: (provided: Record<string, unknown>) => ({
      ...provided,
      borderColor: "white",
      height: "2em",
      width: width ? width : "26.1em",
      borderRadius: theme.borderRadius.primary,
      "&:hover": {
        borderColor: "white",
      },
    }),
  };

  if (notSingleSelect && list.length === 1)
    return <Heading3 className={className}>{value.label}</Heading3>;

  return (
    <SelectStyled
      className={className}
      options={list}
      onChange={onChangeHandler}
      styles={customStyles}
      defaultValue={value}
      blurInputOnSelect
    />
  );
};

export default SelectOption;

const cssSelect = css`
  min-width: 10em;
  color: ${(props) => props.theme.color.primary};
  border: ${(props) => props.theme.border.main};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  padding: 0.5625em 1em;
  min-height: 2.1875em;
`;

const SelectStyled = styled(Select)`
  ${cssSelect}
`;
