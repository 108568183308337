import FiltersBloc from "components/Filters/FiltersBloc";
import { DropdownOptions, Options } from "components/Select/DropdownOptions";
import { Container } from "components/core/Styled/Layouts";
import Table, { IHeader } from "components/core/Table";
import { ICellComponentProps } from "components/core/Table/CellComponentDefault";
import ConfirmationPopup from "components/Modal/ConfirmationPopup";
import {
  useDeleteAdminMutation,
  useGetAdminsQuery,
} from "services/query/admins";
import NormalPagination from "components/pagination/NormalPagination";
import { routesList } from "utils/routesList";
import { useGoTo } from "common/hooks/paths/useGoTo";
import { useDisplaySetting } from "common/hooks/settings/useSetting";
import Popup from "components/Modal/Popup";
import React, { useCallback, useMemo, useState } from "react";
import { MdModeEdit } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import styled from "styled-components";
import { customToast } from "utils/customToast";
import { getMessageError } from "utils/helpers";
import { TableTypes } from "common/types/api/preferences";
import { authRolesLabel } from "common/types/api/roles";

export const adminsHeaders = ["firstName", "lastName", "email", "role"];
export const adminsHeadersDefault = ["firstName", "lastName", "email", "role"];

type IRow = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string | undefined;
  settings: {
    id: string;
    refetch: () => void;
  };
};

const headers: IHeader[] = [
  {
    Header: "First Name",
    accessor: "firstName",
  },
  {
    Header: "Last Name",
    accessor: "lastName",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Role",
    accessor: "role",
  },

  {
    Header: "",
    accessor: "settings",
  },
];

export type IProps = {
  id: string;
  refetch: () => void;
};

export const AdminOptions = ({ id, refetch }: IProps) => {
  const goTo = useGoTo();

  const [confirmDeleteAdmin, setConfirmDeleteAdmin] = useState<boolean>(false);
  const deleteAdminQuery = useDeleteAdminMutation(id);
  const deleteAdminMutateAsync = useMemo(
    () => deleteAdminQuery.mutateAsync,
    [deleteAdminQuery.mutateAsync]
  );

  const onEditAdmin = useCallback(() => {
    goTo(routesList.admins.routes.update, id);
  }, [goTo, id]);

  const onDeleteAdmin = useCallback(() => {
    setConfirmDeleteAdmin(!confirmDeleteAdmin);
  }, [confirmDeleteAdmin]);

  const onConfirmDeleteAdmin = useCallback(async () => {
    try {
      await deleteAdminMutateAsync({});
      customToast.success("Admin deleted successfully");
      refetch();
    } catch (error) {
      customToast.error(getMessageError(error, "admin deletion failed"));
      console.error(error);
    } finally {
      setConfirmDeleteAdmin(false);
    }
  }, [deleteAdminMutateAsync, refetch]);

  return (
    <StyledOptions>
      <ConfirmationPopup
        text="Are you sure you want to delete this admin ?"
        cancelText="Cancel"
        confirmText="Confirm"
        close={onDeleteAdmin}
        onConfirmDelete={onConfirmDeleteAdmin}
        show={confirmDeleteAdmin}
      />

      <Popup>
        <DropdownOptions>
          <Options
            text="Edit admin"
            icon={<MdModeEdit />}
            onClick={onEditAdmin}
          />
          <Options
            text="Delete admin"
            icon={<RiDeleteBin5Line />}
            onClick={onDeleteAdmin}
          />
        </DropdownOptions>
      </Popup>
    </StyledOptions>
  );
};

export const CellComponent = ({ cell, row, index }: ICellComponentProps) => {
  const info = cell.value as IProps;
  return (
    <td {...cell.getCellProps()}>
      {index === row.cells.length - 1 ? (
        <>{<AdminOptions id={info.id} refetch={info.refetch} />}</>
      ) : (
        cell.render("Cell")
      )}
    </td>
  );
};

const AdminList = () => {
  const getAdminsQuery = useGetAdminsQuery();
  const getAdminsRefetch = useMemo(
    () => getAdminsQuery.refetch,
    [getAdminsQuery.refetch]
  );

  const { displaySetting } = useDisplaySetting(TableTypes.ADMINS);
  const displaySettingList = displaySetting
    ? displaySetting
    : adminsHeadersDefault;
  const goTo = useGoTo();

  const listRow: IRow[] = useMemo(() => {
    if (getAdminsQuery.allData === undefined) return [];
    return getAdminsQuery.allData.results.map((val) => ({
      id: val.id,
      email: val.email,
      firstName: val.firstName,
      lastName: val.lastName,
      role: authRolesLabel?.[val.role] ?? "",
      settings: { id: val.id, refetch: getAdminsRefetch },
    }));
  }, [getAdminsQuery.allData, getAdminsRefetch]);

  const onRowClick = useCallback(
    (row: unknown) => {
      const { id } = row as IRow;
      goTo(routesList.admins.routes.update, id);
    },
    [goTo]
  );

  const customHeader = useMemo(() => {
    const headersWithoutSettings = headers.filter((a) => a.Header !== "");
    return headersWithoutSettings
      .sort((a, b) =>
        displaySettingList.indexOf(a.accessor) <
        displaySettingList.indexOf(b.accessor)
          ? -1
          : 1
      )
      .concat(headers.filter((a) => a.Header === ""));
  }, [displaySettingList]);

  const startIndex = useMemo(() => {
    if (!getAdminsQuery.allData?.total) return 1;
    return (
      getAdminsQuery.pageSetting.pageNum * getAdminsQuery.pageSetting.pageSize +
      1
    );
  }, [
    getAdminsQuery.allData?.total,
    getAdminsQuery.pageSetting.pageNum,
    getAdminsQuery.pageSetting.pageSize,
  ]);

  return (
    <Wrapper>
      <FiltersBloc type={TableTypes.ADMINS} />
      <NormalPagination
        messageEmpty="No Admins found"
        queryPagination={getAdminsQuery}
      >
        <TableStyled
          keyAccessor="id"
          columns={customHeader.filter(
            (x) =>
              x.accessor ===
                displaySettingList?.find((y) => y === x.accessor) ||
              x.accessor === "settings"
          )}
          data={listRow}
          withIndex
          startIndex={startIndex}
          CellComponent={CellComponent}
          onRowClick={onRowClick}
          sortSettingLabel={TableTypes.ADMINS}
          sortList={adminsHeaders}
          sortListDefault={adminsHeadersDefault}
          sortable
          editable
        />
      </NormalPagination>
    </Wrapper>
  );
};

export default AdminList;

const Wrapper = styled(Container)`
  padding: 0 1.875em;
  margin: 1.875em 0;
  margin-top: 0.8em;
  min-height: 80vh;
  justify-content: flex-start;
  align-items: flex-start;
`;

const StyledOptions = styled(Container)`
  width: 1.25em;
  height: 1.25em;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    background-color: white;
  }
`;

const TableStyled = styled(Table)`
  // min-height: 80vh;
  justify-content: flex-start;
  align-items: flex-start;
  td {
    position: relative;
    word-wrap: break-word;
  }
  th {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;
